import React from 'react';
import {faCalendar} from '@fortawesome/free-solid-svg-icons/faCalendar';

import AdminLayout from 'Pages/Admin/AdminLayout';

import TimesheetsCalendar from 'Components/HumanResources/Timesheets/Calendar';

export default class Calendar extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Calendar" titleIcon={faCalendar}>
                <TimesheetsCalendar
                    // title='Calendar'
                    admin={true}
                    userId={this.props.match?.params?.user ?? null}
                    views='timeGridDay timeGridWeek dayGridMonth'
                    defaultView='timeGridDay'
                />
            </AdminLayout>
        );
    }
}

import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';
import {SecondaryButton} from 'Components/Button';
import {ModalTrigger} from 'Components/Modal';

import MedicationTakenIndex from 'Pages/ServiceUsers/MedicationsTaken';
import StoreMedicationDetails from 'Pages/ServiceUsers/MedicationDetails/store';

import MedicationsApi from 'Services/Api/ServiceUsers/ServiceUserMedications';

import store from './store';

export default class Index extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Name',
            value: 'name',
            type: 'string',
            orderable: 'name'
        },
        {
            label: 'Dosage',
            value: 'dosage',
            type: 'string',
            orderable: 'dosage'
        },
        {
            label: 'Time',
            value: 'time',
            type: 'string',
            orderable: 'time',
            valueClassName: 'capitalize'
        },
        {
            label: 'Method',
            value: 'method',
            type: 'string',
            orderable: 'method'
        },
        {
            label: 'What Is It For',
            value: 'what_is_it_for',
            type: 'string',
            orderable: 'what_is_it_for',
            hideByDefault: true
        },
        {
            label: 'Side Effects',
            value: 'side_effects',
            type: 'string',
            orderable: 'side_effects',
            hideByDefault: true
        },
        {
            label: 'Specific Requirements',
            value: 'specific_requirements',
            type: 'string',
            orderable: 'specific_requirements',
            hideByDefault: true
        },
        {
            label: 'Start Date',
            value: 'start_date',
            type: 'date',
            orderable: 'start_date',
            hideByDefault: true
        },
        {
            label: 'End Date',
            value: 'end_date',
            type: 'date',
            orderable: 'end_date',
            hideByDefault: true
        }
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        const {service_user_id} = this.props;

        return [
            {
                type: "modalTrigger",
                component: MedicationTakenIndex,
                props: {
                    medication: item
                },
                text: 'Taken'
            },
            {
                type: "modalTrigger",
                component: store,
                props: {
                    service_user_id,
                    medication: item
                },
                text: 'Update'
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'medication',
                    itemId: item.id,
                    onDeleteApi: MedicationsApi.delete
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {service_user_id} = this.props;

        return (
            <div>
                <div className="bg-app-supporting-yellow p-6 w-full md:w-3/4 mx-auto mb-6">
                    <p className="text-center text-lg font-bold mb-8">
                        Medications
                    </p>

                    <div className="flex justify-center items-center">
                        <ModalTrigger
                            component={store}
                            props={{
                                service_user_id
                            }}
                        >
                            <SecondaryButton
                                text="Add New Medication"
                            />
                        </ModalTrigger>
                    </div>

                    <IndexTable
                        columns={this.columns}
                        displaySearchBar={false}
                        loadDataCallback={(data) => MedicationsApi.get(null, {
                            ...data,
                            service_user_id
                        })}
                        rowActions={this.getRowActions}
                        eventApi={MedicationsApi}
                    />
                </div>

                <div className="bg-app-supporting-yellow p-6 w-full md:w-3/4 mx-auto flex flex-col justify-center items-center text-center">
                    <p className="text-center text-lg font-bold mb-8">
                        Additional Medication Info
                    </p>

                    <StoreMedicationDetails
                        service_user_id={service_user_id}
                    />
                </div>
            </div>
        )
    }
}

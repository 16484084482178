import React from 'react';

import {Alert} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {FormHandler, Textarea, DatePicker} from 'Components/Form';

import MedicationsTakenApi from 'Services/Api/ServiceUsers/ServiceUserMedicationsTaken';

class Update extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = this.props.medication_taken ? 'Successfully updated.' : 'Successfully added.';

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.props.medication_taken) {
            this.props.setForm(this.props.medication_taken);
        }
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<void>}
     */
    handleSubmit = (form) => {
        const  {medication_taken} = this.props;

        if (medication_taken) {
            return MedicationsTakenApi.patch(medication_taken.id, form);
        } else {
            return MedicationsTakenApi.post(null, form);
        }
    };

    /**
     * @method handleSuccess
     */
    handleSuccess = () => {
        this.props.onClose();
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {medication_taken, form, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {medication_taken ? 'Update' : 'Create'} Medication Taken
                </ModalHeader>

                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.handleSuccess)}>
                    <ModalBody>
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="text-center mb-6">
                            <b>{medication_taken?.service_user_medication?.name}</b>
                            <br/>
                            ({medication_taken?.service_user_medication?.dosage} @ {medication_taken?.service_user_medication?.time})
                            <br/>
                            (Administered By {medication_taken?.administered_by?.full_name})
                        </div>

                        <DatePicker 
                            containerClassName="mb-4"
                            label="Date & Time"
                            selected={form.date_time} 
                            onChange={v => handleInput('date_time', v)}
                            showTimeSelect={true}
                            timeIntervals={5}
                        />

                        <Textarea
                            label="Notes"
                            value={form.notes}
                            onChange={v => handleInput('notes', v)}
                        />
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton 
                            text={medication_taken ? 'Update' : 'Create'} 
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler(Update);

import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';

import RatesBreakdownTable from './RatesBreakdownTable';

export default class RatesBreakdownModal extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    Timesheet Rates
                </ModalHeader>

                <ModalBody>
                    <RatesBreakdownTable
                        timesheetIds={this.props.timesheetIds}
                    />
                </ModalBody>
            </Modal>
        );
    }
}
import React from 'react';
import { Loading } from "Components/Partials";
import { FormHandler } from "Components/Form";
import { PrimaryButton } from "Components/Button";
import DocumentsApi from "Services/Api/Admin/ServiceUsers/Documents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalTrigger } from "Components/Modal";
import ServiceDocumentCreateModal from "Pages/ServiceUsers/ServiceDocumentCreateModal";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import User from "Services/User";
import ViewServiceDocumentModal from "Pages/ServiceUsers/ViewServiceDocumentModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

class CareDocumentsTab extends React.Component {
    state = {
        documents: [],
        loading: true,
    };

    componentDidMount() {
        this.loadDocuments ();

        DocumentsApi.on('create', this.loadDocuments);
        DocumentsApi.on('update', this.loadDocuments);
        DocumentsApi.on('delete', this.loadDocuments);
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        DocumentsApi.removeListener('create', this.loadDocuments);
        DocumentsApi.removeListener('delete', this.loadDocuments);
    };

    loadDocuments = async () => {
        this.setState ( { loading: true } );

        let response = null;

        if ( User && User.belongsToGroup('Service User Family') && !User.isAdmin) {
            response = await DocumentsApi.getFamilyDocuments ( this.props.user[0]?.id );
        } else {
            response = await DocumentsApi.get ( { user: this.props.user.id } );
        }

        if ( response && response.data ) {
            this.setState ( { documents: response.data.data, loading: false } );
        }
    }

    handleDelete = async (document) => {
        // Call the delete API
        await DocumentsApi.deleteDocuments( document.id, document.user_id);
        // Reload the documents
        this.loadDocuments();
    }

    render() {
        const { user, working  } = this.props;
        const { documents, loading } = this.state;

        let headerCell = 'bg-gray-300 px-4 py-2 border border-app-leading text-center';
        let cell = 'px-4 py-2 border border-app-leading text-center';

        if (working || loading) {
            return <Loading/>;
        }

        // Group documents by category
        const documentsByCategory = documents.reduce((groups, document) => {
            const category = document.category || 'No category';
            (groups[category] = groups[category] || []).push(document);
            return groups;
        }, {});

        return (
            <div className={ "flex-col justify-start" }>
                <div className="flex justify-start ">
                    { User.isAdmin && (
                        <ModalTrigger
                            component={ ServiceDocumentCreateModal }
                            props={ {
                                user,
                            } }
                        >
                            <PrimaryButton
                                text="Upload new service user document"
                            />
                        </ModalTrigger>
                    ) }
                </div>
                <div className="max-w-3xl items-start ">
                    { Object.entries ( documentsByCategory ).map ( ( [ category, documents ] ) => (
                        <div key={ category }>
                            <h2 className="text-lg leading-6 font-medium text-gray-900 my-6 text-left">{ category ?? 'No Category ' }</h2>

                            <table className="table-fixed ">
                                <tbody>
                                { documents.map ( document => (
                                    <tr key={ document.id }>
                                        <td className={ cell }>{ document.name }</td>
                                        { (User.belongsToGroup ( 'Staff' ) ||  User.belongsToGroup ( 'Service User Family' ) ||  User.isAdmin) && (
                                            <td className={ cell }>
                                                <div
                                                    onClick={ ( event ) => {
                                                        event.stopPropagation ();
                                                    } }
                                                >
                                                    <ModalTrigger
                                                        component={ ViewServiceDocumentModal }
                                                        props={ {
                                                            pdfUrl: document.file?.url,
                                                            title: document.name,
                                                        } }
                                                    >
                                                       <FontAwesomeIcon
                                                           icon={ faEye }
                                                           size="1x"
                                                           className={ "mx-1 cursor-pointer" }
                                                       />
                                                    </ModalTrigger>
                                                </div>
                                            </td>
                                        ) }
                                        { User.isAdmin && (
                                            <td className={ cell }>
                                                <button
                                                    onClick={ () => this.handleDelete ( document ) }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={ faTrash }
                                                        size="1x"
                                                        className={ "mx-1 cursor-pointer" }
                                                    />
                                                </button>
                                            </td>
                                        ) }
                                    </tr>
                                ) ) }
                                { documents.length === 0 && (
                                    <tr>
                                        <td
                                            colSpan={ User.isAdmin ? 3 : ( User.belongsToGroup ( 'Staff' ) ? 2 : 1 ) }
                                            className={ cell }
                                        >No documents found</td>
                                    </tr>
                                ) }
                                </tbody>
                            </table>
                        </div>
                    ) ) }

                    { ( Object.keys ( documentsByCategory ).length === 0 || documents.length === 0 ) && (
                        <div className="left">
                            <p>No documents found</p>
                        </div>
                    ) }
                </div>
            </div>
        );
    }
}

export default FormHandler ( CareDocumentsTab );

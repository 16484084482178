import React from 'react';

import Unauthenticated from 'Components/Layouts/Unauthenticated';
import AuthLayout from 'Components/Layouts/AuthLayout';
import {FormHandler, Input, Select} from 'Components/Form';
// import CashierSubscriptionSelector from 'Components/Cashier/SubscriptionSelector';

import Auth from 'Services/Api/Auth/Auth';
import GroupsApi from 'Services/Api/Groups/Groups';

class Register extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Your account has been successfully created. You will receive an email shortly to confirm your ' +
        'email address.';

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, handleInput, getFieldError, handleSubmit, working, alert} = this.props;

        return (
            <Unauthenticated>
                <AuthLayout
                    title="Register"
                    backLink="/login"
                    backLinkText="Already have an account?"
                    submitText="Register"
                    onSubmit={(e) => handleSubmit(e, Auth.register, this.success, true)}
                    working={working}
                    alert={alert}
                >
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <Input
                                label="First Name"
                                type="text"
                                value={form.first_name}
                                error={getFieldError('first_name')}
                                onChange={v => handleInput('first_name', v)}
                            />
                        </div>

                        <div>
                            <Input
                                label="Last Name"
                                type="text"
                                value={form.last_name}
                                error={getFieldError('last_name')}
                                onChange={v => handleInput('last_name', v)}
                            />
                        </div>
                    </div>

                    <Input
                        containerClassName="mb-4"
                        label="Email"
                        type="email"
                        value={form.email}
                        error={getFieldError('email')}
                        onChange={v => handleInput('email', v)}
                    />

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Input
                                label="Password"
                                type="password"
                                value={form.password}
                                error={getFieldError('password')}
                                onChange={v => handleInput('password', v)}
                            />
                        </div>

                        <div>
                            <Input
                                label="Password Confirmation"
                                type="password"
                                value={form.password_confirmation}
                                error={getFieldError('password_confirmation')}
                                onChange={v => handleInput('password_confirmation', v)}
                            />
                        </div>
                    </div>

                    {window.base.features.groups &&
                        <div>
                            <Select
                                containerClassName="mt-4"
                                label="Group"
                                value={form.group_ids}
                                error={getFieldError('group_ids')}
                                onChange={(v) => handleInput('group_ids', v)}
                                isAsync
                                searchCallback={(data) => GroupsApi.get(null, {
                                    ...data,
                                    hasNoTeam: true
                                })}
                                searchLabelKey="name"
                                allowNull={!window.base.features.user_must_belong_to_a_group}
                                isMulti={window.base.features.allow_multiple_groups_per_user}
                            />
                        </div>
                    }

                    {window.base.features.subscriptions &&
                        <CashierSubscriptionSelector
                            form={form}
                            handleInput={handleInput}
                            getFieldError={getFieldError}
                            admin={false}
                            collectPaymentMethod={true}
                            group_ids={form.group_ids}
                        />
                    }
                </AuthLayout>
            </Unauthenticated>
        );
    }
}

export default FormHandler(Register);
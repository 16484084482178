import React from 'react';
import {isEqual} from 'lodash';

import {Loading} from 'Components/Partials';
import Base from 'Components/HumanResources/Timesheets/Base';
import RatesBreakdownTable from 'Components/HumanResources/Timesheets/RatesBreakdownTable';

import {getRecordActions} from 'Services/HrHelpers';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';

import IndexUserTable from './IndexUserTable';

export default class IndexUser extends Base {
    /**
     * @var state
     */
    state = {
        loading: false,
        user: null,
        records: null
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        if (this.props.userId) {
            await this.fetchUser();
        }

        this.fetchRecords();
    }

	/**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.filters, this.props.filters)) {
            this.fetchRecords();
        }
    }

    /**
     * @method fetchRecords
     */
    fetchRecords = async () => {
        const {userId, filters} = this.props;

        this.setState({loading: true});

        const endDate = new Date(filters['start_date_time[beforeDateTime]']);
        endDate.setHours(23, 59, 59);

        var response = await AdminTimesheetsApi.get(null, {
            ...filters,
            'start_date_time[beforeDateTime]': endDate.toISOString(),
            employee_id: userId
        });

        if (response.success) {
            this.setState({
                records: response.data.data,
                loading: false
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {dataType} = this.props;
        const {user} = this.state;

        return (
            <div>
                <h2 className="p-4 text-left text-2xl text-app-red">
                    {user?.full_name}
                </h2>

                {(!dataType || dataType === 'records') && this.renderRecords()}
                {(dataType && dataType === 'rates') && this.renderRates()}
            </div>
        );
    }

    /**
     * @method renderRecords
     * @return {JSX.Element}
     */
    renderRecords() {
        const {userId, columns, filters} = this.props;

        return (
            <IndexUserTable
                columns={columns.map((column) => {
                    // Remove filters from column headers.
                    return {
                        ...column,
                        filters: null
                    };
                })}
                defaultFilters={filters}
                displaySearchBar={false}
                loadDataCallback={(data) => {
                    const endDate = new Date(filters['start_date_time[beforeDateTime]']);
                    endDate.setHours(23, 59, 59);

                    return AdminTimesheetsApi.get(null, {
                        ...data,
                        'start_date_time[beforeDateTime]': endDate.toISOString(),
                        employee_id: userId,
                        order_by: 'start_date_time',
                        order_direction: 'desc'
                    });
                }}
                rowActions={(record) => getRecordActions(record, true)}
                rowActionsLayout="row"
                eventApi={AdminTimesheetsApi}
            />
        );
    }

    /**
     * @method renderRates
     * @return {JSX.Element}
     */
    renderRates() {
        const {loading, records} = this.state;

        if (loading) {
            return <Loading/>;
        }

        return (
            <div className="p-4">
                <RatesBreakdownTable
                    timesheetIds={records.map((record) => record.id)}
                />
            </div>
        );
    }
}

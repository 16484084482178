import React from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';

import Authenticated from 'Components/Layouts/Authenticated';
import {IndexTable} from 'Components/Utilities';
import {BooleanFilter, DateTimeFilter, AsyncSelectFilter} from 'Components/Filters';

import CovidTestEntryApi from 'Services/Api/Admin/Covid/CovidTestEntry';
import AdminUsersApi from 'Services/Api/Admin/Users';

export default class Index extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Employee',
            value: (record) => record?.user?.full_name,
            type: 'callback',
            orderable: null,
            filters: [
                {
                    label: 'Employee',
                    column: 'user_id',
                    component: AsyncSelectFilter,
                    props: {
                        searchCallback: (data) => AdminUsersApi.get(null, data),
                        searchLabelKey: 'full_name'
                    }
                }
            ]
        },
        {
            label: 'Barcode',
            value: 'barcode',
            type: 'string',
            orderable: 'barcode'
        },
        {
            label: 'Date & Time',
            value: 'date_time',
            type: 'datetime',
            orderable: 'date_time',
            filters: [
                {
                    label: 'Date & Time Before',
                    column: 'date_time',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'before'
                    }
                },
                {
                    label: 'Date & Time After',
                    column: 'date_time',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'after'
                    }
                }
            ]
        },
        {
            label: 'Result',
            value: 'result',
            type: 'boolean_fa',
            orderable: 'result',
            filters: [
                {
                    label: 'Result',
                    column: 'result',
                    component: BooleanFilter
                }
            ]
        },
        {
            label: 'Uploaded To NHS',
            value: 'uploaded_to_nhs',
            type: 'boolean_fa',
            orderable: 'uploaded_to_nhs',
            filters: [
                {
                    label: 'Uploaded To NHS',
                    column: 'uploaded_to_nhs',
                    component: BooleanFilter
                }
            ]
        },
    ];

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <Authenticated title="Covid Test Entries" titleIcon={faCheckCircle}>
                <IndexTable
                    columns={this.columns}
                    loadDataCallback={(data) => CovidTestEntryApi.get(null, data)}
                    eventApi={CovidTestEntryApi}
                    actionsJustify="center"
                />
            </Authenticated>
        )
    }
}

import React from 'react';

import {SecondaryButton} from 'Components/Button';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {Networking} from 'Components/Networking';

export default class NetworkingModal extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose} />

                <ModalBody>
                    <Networking
                        displayUsers={false}
                        displayChats={false}
                        displayChat={true}
                        fullScreen={false}
                        useGroups={false}
                        selectedMessageToType={this.props.selectedMessageToType}
                        selectedMessageToId={this.props.selectedMessageToId}
                        additional_key={this.props.additional_key}
                    />
                </ModalBody>

                <ModalFooter alignment="center">
                    <SecondaryButton
                        text="Close"
                        onClick={this.props.onClose}
                    />
                </ModalFooter>
            </Modal>
        )
    }
}
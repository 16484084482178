import React from "react";

const YesNo = ({ name, onChange, value }) => {
    return (
        <div className="flex space-x-6">
            <label>
                <input
                    type="radio"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    name={name}
                    value="yes"
                    checked={value === 'yes'}
                    onChange={() => onChange('yes')}
                />
                <span className="ml-2 text-sm text-gray-500">Yes</span>
            </label>

            <label>
                <input
                    type="radio"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    name={name}
                    value="no"
                    checked={value === 'no'}
                    onChange={() => onChange('no')}
                />
                <span className="ml-2 text-sm text-gray-500">No</span>
            </label>
        </div>
    );
};

export default YesNo;

import React from 'react';
import { Link, withRouter } from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import { Alert, Loading } from "Components/Partials";
import { Input, FileUpload, FormHandler } from "Components/Form";
import { PrimaryButton, SecondaryButton } from "Components/Button";

import AdminSurveyApi from "Services/Api/Admin/Survey";
import AdminSurveyImportApi from "Services/Api/Admin/SurveyImport";

class ImportSurvey extends React.Component {
    /**
     * @var successMessage
     * @type {string}
     */
    successMessage = 'Survey is processing.';

    /**
     * @method componentDidMount
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => AdminSurveyImportApi.post({ survey: this.props.survey }, form);

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { form, alert, working, handleInput, handleSubmit, getFieldError } = this.props;

        return (
            <form
                className="divide-y divide-gray-200"
                onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.successMessage)}
            >
                <div className="mx-6">
                    {alert !== null && (<Alert {...alert} />)}

                    <div>
                        Import question set
                    </div>

                    <div className="my-4 p-4 bg-gray-300 rounded">
                        <FileUpload
                            error={getFieldError('import_file')}
                            containerClassName="mt-4 flex flex-col justify-center items-center gap-4"
                            value={form.import_file}
                            onChange={v => handleInput('import_file', v)}
                            accept=".xlsx"
                            placeholder="Select file"
                        />
                    </div>
                </div>

                <div className="p-6 flex justify-end gap-4">
                    <PrimaryButton
                        text="Import"
                        working={working}
                    />
                </div>
            </form>
        );
    }
}

export default withRouter(FormHandler(ImportSurvey));

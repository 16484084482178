import React from 'react';

import {PrimaryButton} from 'Components/Button';
import {Loading} from 'Components/Partials';

import AdminTestsApi from 'Services/Api/Admin/Tests';

export default class EnvTest extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        data: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.getData();
    }

    /**
     * @method sendTestEmail
     */
    getData = async () => {
        const response = await AdminTestsApi.env();

        if (response.status === 200) {
            this.setState({
                working: false,
                data: response.data
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, data} = this.state;

        return (
            <div className="p-4 bg-gray-300 rounded">
                <p className="font-bold flex flex-row items-center gap-4 mb-4">
                    Connections Installed
                </p>

                {working && (<Loading />)}

                {!working && 
                    <table>
                        {Object.entries(data).map((datum, i) => (
                            <tr key={i}>
                                <th className="text-right p-2">
                                    {datum[0]}:
                                </th>
                                <td className={`text-left p-2 ${datum[1] ? 'text-black' : 'text-red-500'}`}>
                                    {datum[1] ? 'Installed' : 'Not Installed'}
                                </td>
                            </tr>
                        ))}
                    </table>
                }
            </div>
        )
    }
}
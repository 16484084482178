import React from 'react';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';

import Authenticated from 'Components/Layouts/Authenticated';
import { PrimaryButton } from 'Components/Button';
import { ModalTrigger } from 'Components/Modal';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';
import ViewCmsElementRecords from 'Components/Utilities/ViewCmsElementRecords';

import { translation } from 'Services/TranslationHelpers';

export default class Narratives extends React.Component {
    /**
     * @method render
     * @return {*}
     */
    render () {
        return (
            <Authenticated title="Narratives" titleIcon={faClipboardCheck}>
                <div className="mb-6">
                    <ModalTrigger
                        component={CreateCmsElementRecordModal}
                        props={{
                            showElementsDropdown: true,
                            element_id: null,
                            element_record_id: null,
                            service_user_id: null,
                            attach_to_service_user: true
                        }}
                    >
                        <PrimaryButton
                            className="font-league-spartan font-bold text-xl"
                            text={translation('cms', 'narrative_submit')}
                        />
                    </ModalTrigger>
                </div>

                <ViewCmsElementRecords
                    attach_to_service_user={true}
                />
            </Authenticated>
        );
    }
}

import ApiBase from '../ApiBase';

class Settings extends ApiBase {
    route = '/settings/settings';

    /**
     * @method getAvailableTimezones
     * @return {Promise<*>}
     */
    getAvailableTimezones = () => {
        return this.get(this.route + '/get-available-timezones');
    };
}

export default new Settings();

import React from 'react';

import {Label, Input} from 'Components/Form';

/*
Example...
<Address
    label="Address"
    value={form.address}
    id="address"
    getFieldError={(v) => getFieldError('address.' + v)}
    onChange={(v) => handleInput('address', v)}
/>
*/

export default class Address extends React.Component {
    /**
     * @method handleChange
     * @param {string} key
     * @param {string} v
     */
    handleChange = (key, v) =>  {
        const {value, onChange} = this.props;

        onChange({
            ...value,
            [key]: v
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {
            containerClassName,
            label,
            id,
            value,
            instructions,
            error = null,
            getFieldError
        } = this.props;

        return (
            <div className={containerClassName ?? 'bg-gray-200 p-4 flex flex-col gap-4'}>
                {label && (
                    <Label 
                        label={label} 
                        htmlFor={id} 
                        instructions={instructions}
                        error={error}
                    />
                )}

                <Input
                    label="Line 1"
                    value={value?.line_1}
                    error={getFieldError('line_1')}
                    onChange={(v) => this.handleChange('line_1', v)}
                />

                <Input
                    label="Line 2"
                    value={value?.line_2}
                    error={getFieldError('line_2')}
                    onChange={(v) => this.handleChange('line_2', v)}
                />

                <Input
                    label="City"
                    value={value?.city}
                    error={getFieldError('city')}
                    onChange={(v) => this.handleChange('city', v)}
                />

                <Input
                    label="County"
                    value={value?.county}
                    error={getFieldError('county')}
                    onChange={(v) => this.handleChange('county', v)}
                />

                <Input
                    label="Country"
                    value={value?.country}
                    error={getFieldError('country')}
                    onChange={(v) => this.handleChange('country', v)}
                />

                <Input
                    label="Postcode"
                    value={value?.postcode}
                    error={getFieldError('postcode')}
                    onChange={(v) => this.handleChange('postcode', v)}
                />
            </div>
        );
    }
}
import React from 'react';
import {Redirect} from 'react-router-dom';

import User from 'Services/User';

/**
 * @function Auth
 * @param {*|JSX.Element} component
 * @return {*|JSX.Element}
 * @constructor
 */
const Auth = ({component}) => {
    const auth = User.loggedIn;

    if (auth && User && User?.belongsToGroup('Service User Family') && !User.isAdmin
        && !window.location.pathname.includes('service-users') && !window.location.pathname.includes('logout')) {
        return <Redirect to="/service-users" />;
    }

    return auth ? component : <Redirect to="/login" />;
};

export default Auth;

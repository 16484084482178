import React from 'react';
import {withRouter} from 'react-router-dom';

import {Loading} from 'Components/Partials';
import {Select} from 'Components/Form';
import {DraggableIndexTable} from 'Components/Utilities';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';
import {DateTimeFilter, AsyncSelectFilter} from 'Components/Filters';

import ElementApi from 'Services/Api/Cms/Elements/Element';
import RecordApi from 'Services/Api/Cms/Elements/Record';
import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';

import {translation} from 'Services/TranslationHelpers';
import {translationFromJson} from 'Services/TranslationHelpers2';
import {getRecordValue, getFieldFilter, getRowActions} from 'Services/CmsHelpers';
import User from "Services/User";

class ViewCmsElementRecords extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        elements: null,
        selected_element: null,
        columns: null
    };

    /**
     * @method componentDidUpdate
     */
    componentDidMount() {
        this.getElements();
    }

    /**
     * @method getElements
     */
    getElements = async () => {
        const {
            attach_to_service_user = null,
            health_and_safety = null,
            quality_assurance = null,
            medication_management = null
        } = this.props;

        this.setState({working: true});
        const url = User.belongsToGroup('Service User Family') && !User.isAdmin ? 'service-users-family' : null;
        const response = url ?  await ElementApi.getFamily(null, {
            attach_to_service_user,
            quality_assurance,
            health_and_safety,
            medication_management,
        }) : await ElementApi.get(url, {
            attach_to_service_user,
            quality_assurance,
            health_and_safety,
            medication_management,
        });

        this.setState({
            working: false,
            elements: response.data.data,
        });

        if (this.props.match?.params?.element) {
            this.changeSelectedElement(this.props.match?.params?.element, false);
        }
    }

    /**
     * @method changeSelectedElement
     * @param {string} selected_element_id
     * @param {boolean} pushToHistory
     */
    changeSelectedElement = async (selected_element_id, pushToHistory = true) => {
        const {elements} = this.state;

        if (!selected_element_id) {
            this.setState({
                selected_element: null
            });

            return;
        }

        this.setState({
            selected_element: elements.filter(o => o.id === selected_element_id)[0]
        });

        if (pushToHistory) {
            // TODO swap '/admin/cms/elements/records/' for current page.
            history.pushState(null, null, '/admin/cms/elements/records/' + selected_element_id);
        }
    }

    /**
     * @method getColumns
     * @return {array}
     */
    getColumns = () => {
        const {selected_element} = this.state;

        // Convert the element fields to columns.
        let columns = Object.entries(selected_element.fields)
            .filter(data => data[1].show_in_index_table === true)
            .map((data, j) => {
                let field = data[1];

                return {
                    label: translationFromJson(field.label),
                    value: (record) => getRecordValue(record, field.identifier, field.type),
                    type: 'callback',
                    orderable: 'value:' + field.identifier,
                    filters: getFieldFilter(field)
                };
        });

        // Narrative columns (Service User and Date of Observation).
        if (selected_element.attach_to_service_user) {
            const url = User.belongsToGroup('Service User Family') && !User.isAdmin ? 'service-users-family' : null;

            columns.push(
                {
                    label: 'Service User',
                    value: (record) => record.service_user?.full_name,
                    type: 'callback',
                    orderable: null,
                    filters: [
                        {
                            label: 'Service User',
                            column: 'service_user_id_in',
                            component: AsyncSelectFilter,
                            props: {
                                searchCallback: (data) => ServiceUsersApi.get(url, data),
                                searchLabelKey: 'full_name'
                            },
                            defaultMethod: false
                        }
                    ]
                },
                {
                    label: 'Date of Observation',
                    value: 'date_of_observation',
                    type: 'datetime',
                    orderable: 'date_of_observation',
                    filters: [
                        {
                            label: 'Date of Observation Before',
                            column: 'date_of_observation',
                            component: DateTimeFilter,
                            props: {
                                type: 'datetime',
                                direction: 'before'
                            }
                        },
                        {
                            label: 'Date of Observation After',
                            column: 'date_of_observation',
                            component: DateTimeFilter,
                            props: {
                                type: 'datetime',
                                direction: 'after'
                            }
                        }
                    ]
                },
            );
        }

        // "Order" column.
        // if (selected_element.orderable) {
        //     columns.push({
        //         label: 'Order',
        //         value: 'order',
        //         type: 'string',
        //         orderable: 'order',
        //     });
        // }

        // "Created By" and "Created At" columns.
        columns.push(
            {
                label: 'Created By',
                value: (record) => record.user.full_name,
                type: 'callback',
                orderable: null,
                hideByDefault: true,
            },
            {
                label: 'Created At',
                value: 'created_at',
                type: 'datetime',
                orderable: 'created_at',
                hideByDefault: true
            }
        );

        return columns;
    }

    /**
     * @method getFirstFieldIdentifier
     * @return {string|null}
     */
    getFirstFieldIdentifier = () =>  {
        const {selected_element} = this.state;

        if (!selected_element) {
            return null;
        }

        let fields = Object.entries(selected_element.fields)
            .filter(data => data[1].show_in_index_table === true);

        if (!fields || fields.length === 0) {
            return null;
        }

        return fields[0][1].identifier;
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {service_user_id} = this.props;
        const {working, elements, selected_element} = this.state;

        let element_options = elements && elements.map((element, key) => {
            return {
                label: translationFromJson(element.label),
                value: element.id
            };
        });

        let selected_element_name = '';

        let first_field_identifier = this.getFirstFieldIdentifier();

        if (selected_element) {
            console.log({
                order_by: selected_element.orderable ?
                    'order' :
                    'date_of_observation',
                order: (selected_element.orderable || !first_field_identifier) ?
                    'asc' :
                    'desc'
            });
        }

        if(selected_element) {
            selected_element_name = translationFromJson ( selected_element?.label ) ?? '';
        }
        return (
            <div className="h-full flex flex-col">
                <div className="mx-4">
                    <Select
                        value={selected_element?.id ?? null}
                        onChange={(v) => this.changeSelectedElement(v, this.props.push_to_history ?? true)}
                        options={element_options}
                        placeholder="Select a type..."
                    />
                </div>

                <div className="mt-8 h-full flex flex-col">
                    {working && (<Loading />)}

                    {!working && !selected_element &&
                        <p className="text-center font-bold mt-4">
                            Select a type above to display the records here
                        </p>
                    }

                    {!working && selected_element &&
                        <DraggableIndexTable
                            columns={this.getColumns()}
                            defaultOrder={{
                                order_by: selected_element.orderable ?
                                    'order' :
                                    'date_of_observation',
                                order: (selected_element.orderable || !first_field_identifier) ?
                                    'asc' :
                                    'desc'
                            }}
                            displaySearchBar={true}
                            storeModal={{
                                component: CreateCmsElementRecordModal,
                                props:{
                                    showElementsDropdown: true,
                                    element_id: selected_element?.id,
                                    service_user_id
                                }
                            }}

                        loadDataCallback={(data) => User.belongsToGroup('Service User Family') && !User.isAdmin ? RecordApi.getFamily(null, {
                                ...data,
                                cms_element_id: selected_element.id,
                                service_user_id: service_user_id ?? null
                            }) : RecordApi.get(null, {
                                ...data,
                                cms_element_id: selected_element.id,
                                service_user_id: service_user_id ?? null
                            })
                        }
                            rowActions={(item) => getRowActions(selected_element.id, item)}
                            eventApi={RecordApi}
                            report="CmsElementRecords"
                            orderable={selected_element.orderable}
                            saveOrderCallback={RecordApi.updateOrder}
                            key={selected_element.id}
                            selected_element_name={selected_element_name}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ViewCmsElementRecords);

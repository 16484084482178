
import React from "react";
import { DateTime } from "luxon";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "Components/Modal";
import { PrimaryButton, SecondaryButton } from "Components/Button";
import { FormHandler, Toggle, Input, DatePicker, Textarea } from "Components/Form";
import { Alert } from "Components/Partials";
import QueryTimesheetModal from "Components/HumanResources/Timesheets/QueryTimesheetModal";
import { Loading } from "Components/Partials";

import { Toast, Modal as ModalService } from "Services";
import AdminTimesheetsApi from "Services/Api/Admin/HumanResources/Timesheets";
import UserSettingsApi from "Services/Api/Admin/HumanResources/UserSettings";

class ApproveHoliday extends React.Component {
    /**
     * @method render
     * @type {{form: {days_breakdown: []}}}
     */
    state = {
        days: [],
        loading: true,
        overLappingUsers: [],
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        const { setForm, timesheet } = this.props;

        if (timesheet) {
            await setForm({
                ...timesheet,
            });
        }

        this.setDays();
        this.fetchHrSettingsForUser();
        this.fetchUsersWithOverlappingTimesheets();
    };

    /**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        const { form } = this.props;

        if (form.start_date_time !== prevProps.form.start_date_time || form.end_date_time !== prevProps.form.end_date_time) {
            this.setDays();
        }
    };

    /**
     * @method fetchHrSettingsForUser
     * @returns {Promise<void>}
     */
    fetchHrSettingsForUser = async () => {
        this.setState({ loading: true });

        const { timesheet } = this.props;

        const response = await UserSettingsApi.get({
            user: timesheet.employee_id,
        });

        if (response.success) {
            this.setState({
                hrSettings: response.data?.data ?? {},
                loading: false,
            });
        }
    };

    /**
     * @method fetchHrSettingsForUser
     * @returns {Promise<void>}
     */
    fetchUsersWithOverlappingTimesheets = async () => {
        this.setState({ loading: true });

        const { timesheet } = this.props;

        const response = await AdminTimesheetsApi.getUsersWithOverlappingTimesheets(timesheet.id);

        if (response.success) {
            this.setState({
                overLappingUsers: response.data ?? {},
                loading: false,
            });
        }
    };

    /**
     * @method getDate
     * @param field
     * @returns {DateTime}
     */
    getDate = (field) => {
        const { form } = this.props;
        let date = null;

        if (form[field]?.iso_string) {
            date = DateTime.fromFormat(form[field].date_utc, "dd/MM/yyyy");
        } else if (form[field]) {
            date = DateTime.fromJSDate(form[field]);
        } else {
            date = DateTime.fromFormat(form[field].date_utc, "dd/MM/yyyy");
        }

        if (field === 'start_date_time') {
            date = date.startOf('day');
        } else if (field === 'end_date_time') {
            date = date.endOf('day');
        }

        return date;
    };

    /**
     * @method setDays
     */
    setDays = () => {
        // Get days breakdown between the start and end date
        const { timesheet, form, setForm } = this.props;

        let start = this.getDate('start_date_time');
        let end = this.getDate('end_date_time');

        let days = [];
        let startIndex = start;

        while (startIndex <= end) {
            days.push(startIndex);

            startIndex = startIndex.plus({ days: 1 });
        }

        let daysBreakdown = form.days_breakdown ?? timesheet.days_breakdown ?? [];
        daysBreakdown = daysBreakdown.filter((day) => {
            const d = DateTime.fromSQL(day);

            return d >= start && d <= end;
        });

        setForm({
            days_breakdown: daysBreakdown,
            days_cost: timesheet.days_cost ?? daysBreakdown?.length ?? 0,
        });

        this.setState({
            days,
        });
    };

    /**
     * @method handleQueryTimesheet
     */
    handleQueryTimesheet = () => {
        ModalService.open({
            component: QueryTimesheetModal,
            props: {
                timesheet: this.props.timesheet,
                admin: true,
            },
        });
    };

    /**
     * @method handleRequest
     * @returns {Promise<boolean|{status: number}>}
     */
    handleRequest = (form) => AdminTimesheetsApi.patch({
        record: this.props.timesheet.id
    }, {
        ...this.props.timesheet,
        ...form,
        approved: true,
    });

    /**
     * @method handleSuccess
     * @returns {*}
     */
    handleSuccess = () => {
        Toast.success("Holiday has been approved");

        // Refresh...

        return this.props.onClose();
    };

    /**
     * @method handleToggle
     * @param date
     */
    handleToggleDay = (date) => {
        const day = date.toFormat("yyyy-MM-dd");
        const { form, setForm } = this.props;
        const { hrSettings } = this.state;

        let days_breakdown = form.days_breakdown ?? [];

        if (days_breakdown.includes(day)) {
            days_breakdown = days_breakdown.filter((d) => d !== day);
        } else {
            days_breakdown.push(day);
        }

        setForm({
            days_breakdown,
            days_cost: days_breakdown.length > hrSettings?.holiday_remaining ? hrSettings?.holiday_remaining : days_breakdown.length,
        });
    };

    /**
     * @method renderOverlappingUsers
     */

    renderOverlappingUsers = () => {
        const { overLappingUsers } = this.state;

        if (overLappingUsers.length === 0) {
            return null;
        }

        return (
            <div className="bg-red-100 border border-red-200 text-black p-4 rounded mb-4">
            <h3 className="font-semibold text-lg mb-2">
                Overlapping Holidays
            </h3>
            <div className="overflow-x-auto max-h-96">
                <table className="table-auto w-full">
                    <thead className="bg-gray-300">
                        <tr className="border-b border-gray-200">
                            <th className="px-2 py-1 font-bold text-black border border-app-leading text-center">
                                Name
                            </th>
                            <th className="px-2 py-1 font-bold text-black border border-app-leading text-center">
                                Start Date Time
                            </th>
                            <th className="px-2 py-1 font-bold text-black border border-app-leading text-center">
                                End Date Time
                            </th>
                            <th className="px-2 py-1 font-bold text-black border border-app-leading text-center">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {overLappingUsers.map((user, index) => (
                            <tr key={index}>
                                <td className="px-2 py-1 border border-app-leading text-center">
                                    {user.employee.full_name}
                                </td>
                                <td className="px-1 py-1 border border-app-leading text-center">
                                    {user.start_date_time.date_timezone}
                                </td>
                                <td className="px-2 py-1 border border-app-leading text-center">
                                    {user.end_date_time.date_timezone}
                                </td>
                                <td className="border border-app-leading text-center">
                                    {user.is_rejected ? (
                                        <div className="-py-1 text-white bg-red-500">
                                            {`Rejected by ${user.user.full_name}`}
                                        </div>
                                    ) : user.approved_by ? (
                                        <div className="-py-1 text-black bg-green-500">
                                            {`Approved by ${user.approved_by.full_name}`}
                                        </div>
                                    ) : (
                                        <div className="-py-1 text-white bg-red-500">
                                            Awaiting approval
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        );
    }
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render() {
        const { loading, handleSubmit, form, alert, handleInput, working, onClose, timesheet } = this.props;
        const { days, hrSettings } = this.state;

        let hoursTotal = timesheet?.hours_cost ?? ( Number ( hrSettings?.holiday_remaining ) * Number ( hrSettings?.hours_per_day ) );

        return (
            <Modal>
                <ModalHeader onClose={ onClose }>
                    Approve Holiday
                </ModalHeader>

                { loading && (
                    <Loading />
                ) }

                { this.renderOverlappingUsers () }

                { !loading && (
                    <form onSubmit={ ( e ) => handleSubmit ( e, this.handleRequest, this.handleSuccess ) }>
                        <ModalBody>
                            <div className="space-y-4">
                                { alert && ( <Alert { ...alert } /> ) }

                                <Input
                                    label="Holiday Allowance"
                                    type="number"
                                    value={ hrSettings?.holiday_allowed ?? 0 }
                                    readOnly={ true}
                                />

                                <Input
                                    label="Holiday Remaining"
                                    type="number"
                                    value={hrSettings?.holiday_remaining ?? 0}
                                    readOnly={true}
                                />

                                <Input
                                    label="Average Hours Per Day"
                                    type="number"
                                    value={hrSettings?.hours_per_day ?? 0}
                                    readOnly={true}
                                />

                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <DatePicker
                                        label="Start"
                                        selected={ form.start_date_time }
                                        onChange={ date => handleInput('start_date_time', date) }
                                        showTimeSelect={ false }
                                        selectsStart
                                        startDate={ form.start_date_time }
                                        endDate={ form.end_date_time }
                                    />

                                    <DatePicker
                                        label="End"
                                        selected={ form.end_date_time }
                                        startDate={ form.start_date_time }
                                        endDate={ form.end_date_time }
                                        minDate={ form.start_date_time }
                                        onChange={ date => handleInput('end_date_time', date) }
                                        showTimeSelect={ false }
                                        selectsEnd
                                    />
                                </div>

                                <Input
                                    label="Days per Week"
                                    type="number"
                                    value={hrSettings?.days_per_week ?? 0}
                                    readOnly={true}
                                />

                                <div className="space-y-2">
                                    {days.map((day, index) => {
                                        const checked = form.days_breakdown && form.days_breakdown?.includes(day.toFormat("yyyy-MM-dd"));
                                        let hours = 0;

                                        if (checked) {
                                             hours = hrSettings?.hours_per_day;

                                            if (hours > hoursTotal) {
                                                hours = hoursTotal;
                                            }

                                            hoursTotal -= hours;
                                        }

                                        return (
                                            <div key={index} className="flex justify-between items-center space-x-4 text-sm text-gray-500">
                                                <Toggle
                                                    label={day.toLocaleString(DateTime.DATE_HUGE)}
                                                    value={checked}
                                                    onChange={() => this.handleToggleDay(day)}
                                                />

                                                {checked && (
                                                    <span>
                                                        {Number(hours).toFixed(2)} hrs
                                                    </span>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>

                                <Input
                                    label="Days"
                                    type="number"
                                    value={form.days_cost}
                                    onChange={(v) => handleInput('days_cost', v)}
                                />

                                <Textarea
                                    label="Notes"
                                    containerClassName="mb-4"
                                    value={form.description}
                                    onChange={v => handleInput('description', v)}
                                />
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <div className="flex justify-between items-center space-x-4">
                                <SecondaryButton
                                    onClick={this.handleQueryTimesheet}
                                    text="Query"
                                />

                                <PrimaryButton
                                    text="Approve"
                                    working={working}
                                />
                            </div>
                        </ModalFooter>
                    </form>
                )}
            </Modal>
        );
    }
}

export default FormHandler(ApproveHoliday);

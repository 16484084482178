import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, DangerButton} from 'Components/Button';

import {translation} from 'Services/TranslationHelpers';

export default class Confirm extends React.Component {
    /**
     * @method handleConfirm
     * @return {Promise<void>}
     */
    handleConfirm = async () => {
        await this.props.onConfirm();
        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {title, message} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {title ?? translation('misc', 'are_you_sure')}
                </ModalHeader>

                {message &&
                    <ModalBody>
                        <p className="text-center">
                            {message}
                        </p>
                    </ModalBody>
                }

                <ModalFooter alignment="center">
                    <PrimaryButton 
                        className="mr-2" 
                        text={translation('misc', 'cancel')} 
                        onClick={this.props.onClose} />

                    <DangerButton 
                        onClick={this.handleConfirm} 
                        text={translation('misc', 'continue')} />
                </ModalFooter>
            </Modal>
        )
    }
};

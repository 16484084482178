import React from 'react';

import ActionsButton from 'Components/Partials/ActionsButton';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'Components/Modal';
import { DangerButton, PrimaryButton } from 'Components/Button';

import AdminUsersApi from "Services/Api/Admin/Users";

import Toast from "Services/Toast";

/**
 * @function HrDropdown
 * @param {object} user
 * @return {JSX.Element}
 */
const HrDropdown = ({user}) => {
    let buttons = [
        {
            type: "link",
            to: "/admin/human-resources/profile/"+user.id,
            text: "HR Profile"
        },
        {
            type: "link",
            to: "/admin/human-resources/settings/"+user.id,
            text: "HR Settings"
        }
    ];

    if (window.base.features.rates) {
        buttons.push({
            type: "link",
            to: "/admin/human-resources/user-rates/"+user.id,
            text: "HR Rates"
        });
    }

    buttons.push(
        {
            type: "link",
            to: "/admin/human-resources/calendar/"+user.id,
            text: "HR Calendar"
        },
        {
            type: "link",
            to: "/admin/human-resources/time-worked/"+user.id,
            text: "HR " + window.base.hr_timesheet_types['time_worked']?.label
        },
        {
            type: "link",
            to: "/admin/human-resources/holidays/"+user.id,
            text: "HR " + window.base.hr_timesheet_types['holiday']?.label
        },
        {
            type: "link",
            to: "/admin/human-resources/absences/"+user.id,
            text: "HR " + window.base.hr_timesheet_types['sick']?.label
        },
        {
            type: "link",
            to: "/admin/human-resources/documents/"+user.id,
            text: "HR Documents"
        },
        {
            type: "modalTrigger",
            component: ArchiveUser,
            props: {
                userId: user.id,
            },
            text: "Archive User"
        },
    );

    return (
        <ActionsButton buttons={buttons} />
    );
};

const ArchiveUser = ({ onClose, userId }) => {
    const archive = async () => {
        const { success } = await AdminUsersApi.archiveToggle(userId, true);

        if (success) {
            Toast.success("User archived successfully");
            onClose();
        }
    };

    return (
        <Modal>
            <ModalHeader>
                Archive User
            </ModalHeader>

            <ModalBody>
                <p>Are you sure you want to archive this user?</p>
            </ModalBody>

            <ModalFooter>
                <div className="flex justify-center space-x-4 items-center w-full">
                    <PrimaryButton
                        onClick={onClose}
                        text="Cancel"
                    />

                    <DangerButton
                        onClick={archive}
                        text="Archive"
                    />
                </div>
            </ModalFooter>
        </Modal>
    );
};

/**
 * @function HrTitle
 * @param {object} user
 * @param {string} title
 * @return {JSX.Element}
 */
const HrTitle = ({user, title}) => (
    <div className="flex justify-start items-center">
        <h2 className="text-lg leading-6 font-medium text-gray-900 mr-4">
            HR {title} {user && <>- {user.full_name}</>}
        </h2>

        {user &&
            <HrDropdown user={user} />
        }
    </div>
);

export {
    HrDropdown,
    HrTitle,
    ArchiveUser,
};

import React from 'react';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * @function Loading
 * @return {*}
 * @constructor
 */
const Loading = ({size = '5x', padding = "py-10"}) => {
    return (
        <div className={`flex justify-center align-center w-full ${padding}`}>
            <FontAwesomeIcon
                icon={faSpinner}
                spin
                size={size}
                className="fill-current mr-1"
            />
        </div>
    );
};

export default Loading;

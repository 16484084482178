import ApiBase from '../../ApiBase';

class Element extends ApiBase {
    route = '/cms/elements/elements';

    getFamily = async (u, data) => {
        return this.get('/service-users-family/cms/elements/elements/', data);
    }
}

export default new Element();

import React from 'react';

import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';

import {IndexTable} from 'Components/Utilities';
import {PrimaryButton} from 'Components/Button';
import {PaginationBar} from 'Components/Partials';
import DraggableRow from 'Components/Utilities/DraggableRow';

/*
See CMS Package -> Components/Utilities/ViewCmsElementRecords as an example.

Props
Same as IndexTable plus the below;
 * @prop {boolean} orderable   
 * @prop {function} saveOrderCallback
*/

export default class DraggableIndexTable extends IndexTable {
    /**
     * @method loadData
     * @param {integer} page
     */
    loadData = async (page = 1) => {
        this.setState({ 
            working: true,
        });

        const {data} = this.state;

        const response = await this.props.loadDataCallback({
            page,
            ...this.state.filters,
            ...this.state.order,
            paginate: !this.state.draggable
        });

        this.setState({
            working: false,
            data: response.data,
        });
    }

    /**
     * @method handleDraggableChange
     * @param {boolean} draggable
     */
    handleDraggableChange = async (draggable) => {
        if (!draggable) {
            await this.handleSaveOrder();

            this.loadData();
        } else {
            this.setState({
                draggable,
                alert: null,
                working: true
            }, () => {
                this.loadData();
            });
        }
    }

    /**
     * @method handleMoveRecord
     * @param {integer} oldOrder
     * @param {integer} newOrder
     * @param {string} record_id
     */
    handleMoveRecord = (oldOrder, newOrder, record_id) => {
        const {data} = this.state;

        // 0 indexed
        oldOrder++;
        newOrder++;

        this.setState({
            data: {
                ...data,
                data: data.data.map(record => {
                    if (record.id === record_id) {
                        record.order = newOrder;
                    } else if (record.order <= newOrder && record.order > oldOrder) {
                        record.order = record.order - 1;
                    } else if (record.order >= newOrder && record.order < oldOrder) {
                        record.order = record.order + 1;
                    }

                    return record;
                }),
            }
        });
    }

    /**
     * @method handleSaveOrder
     */
    handleSaveOrder = async () => {
        this.setState({working: true});

        const {data} = this.state;

        const response = await this.props.saveOrderCallback({
            records: data.data
        });

        if (response.status !== 200) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message
                },
                working: false
            });
            return;
        }

        this.setState({
            alert: {
                type: 'success',
                message: 'Order saved successfully'
            },
            draggable: false,
        })
    }

    /**
     * @method handleSort
     * @param {object} a
     * @param {object} b
     */
    handleSort = (a, b) => {
        const {draggable} = this.state;

        if (draggable) {
            return a.order - b.order;
        } else {
            return 1;
        }
    }

    /**
     * @method renderTable
     * @return {JSX.Element}
     */
    renderTable() {
        const {columns, rowActions, orderable} = this.props;
        const {data, draggable} = this.state;

        let total = this.getTotal();

        return (
            <div className={`h-full flex flex-col ${total !== 0 ? 'justify-between' : 'justify-end'}`}>
                {total !== 0 &&
                    <div>
                        <DndProvider backend={HTML5Backend}>
                            {orderable &&
                                <div className="mb-10 flex justify-center">
                                    <PrimaryButton 
                                        text={draggable ? "Save Reordering" : "Enable Drag and Drop Reordering"}
                                        onClick={() => this.handleDraggableChange(!draggable)}
                                    />
                                </div>
                            }

                            <div className="overflow-x-auto">
                                <table className="table-auto mx-auto">
                                    <thead>
                                        <tr className={this.classNames.headerRow}>
                                            {columns.map((column, i) => this.renderColumnHeader(column, i))}

                                            {this.renderColumnSelect()}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.data.sort(this.handleSort).map((record, i) => {
                                            return (
                                                <DraggableRow
                                                    key={record.id} 
                                                    id={record.id} 
                                                    index={i} 
                                                    field={record} 
                                                    moveField={this.handleMoveRecord}
                                                    disabled={!draggable}
                                                    rowClassName={this.classNames.row(i)}
                                                >
                                                    {columns.map((column, j) => this.renderColumnValue(record, column, i, j))}

                                                    {rowActions && this.renderRowActions(record)}
                                                </DraggableRow>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </DndProvider>
                    </div>
                }

                {data?.meta &&
                    <div className="mt-12">
                        <PaginationBar
                            total={data.meta.total}
                            pageCount={data.meta.last_page}
                            page={data.meta.current_page}
                            goToPage={this.loadData}
                        />
                    </div>
                }
            </div>
        );
    }
}
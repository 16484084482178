import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';
import FieldsIndex from 'Pages/Admin/Cms/Elements/Fields/index';

import {Loading} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';

import ElementApi from 'Services/Api/Admin/Cms/Elements/Element';
import {translationFromJson} from 'Services/TranslationHelpers2';

export default class AdminCmsElementsShow extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        element_id: this.props.match?.params?.element ?? '',
        element: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadElement();
    }

    /**
     * @method loadElement
     */
    loadElement = async () => {
        const {element_id} = this.state;

        this.setState({ working: true });

        const response = await ElementApi.get(element_id);

        this.setState({
            working: false,
            element: response.data.data,
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, element_id} = this.state;

        return (
            <AdminLayout title="Forms">
                <div className="m-4">
                    {working && (<Loading />)}

                    {!working && this.renderElementData()}

                    <FieldsIndex element_id={element_id} />
                </div>
            </AdminLayout>
        )
    }

    /**
     * @method renderElementData
     * @return {JSX.Element}
     */
    renderElementData() {
        const {element_id, element} = this.state;

        var values = {
            'Label': translationFromJson(element.label),
            'Attach To Service User (Narrative)': element.attach_to_service_user  ? 'Yes' : 'No',
            'Health & Safety Form': element.health_and_safety  ? 'Yes' : 'No',
            'Quality Assurance Form': element.quality_assurance  ? 'Yes' : 'No',
            'Medical Management Form': element.medication_management  ? 'Yes' : 'No',
            'Allow Likes': element.allow_likes ? 'Yes' : 'No',
            'Allow Comments': element.allow_comments ? 'Yes' : 'No',
            'Allow Tags': element.allow_tags ? 'Yes' : 'No',
            'Display Form On Frontend': element.display_form_on_frontend ? 'Yes' : 'No',
            'Orderable': element.orderable ? 'Yes' : 'No'
        }

        if (Object.keys(window.base.languages).length > 1) {
            values['Filter Languages'] = element.filter_languages ? 'Yes' : 'No';
        }

        values['Created At'] = element.created_at.date_time_timezone;

        return (
            <div className="overflow-x-auto">
                <table className="table-auto mx-auto">
                    <thead>
                        <tr>
                            {Object.entries(values).map((value, i) => (
                                <th className="border px-4 py-2 text-center bg-gray-300" key={i}>
                                    {value[0]}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {Object.entries(values).map((value, i) => (
                                <td className="border px-4 py-2 text-center" key={i}>
                                    {value[1]}
                                </td>
                            ))}

                            <td className="border px-4 py-2 text-center">
                                <Link to={'/admin/cms/elements/store/'+element_id}>
                                    <PrimaryButton
                                        text="Update"
                                    />
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

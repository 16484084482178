import React from 'react';
import {withRouter} from 'react-router-dom';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {ModalTrigger} from 'Components/Modal';
import {Loading, PaginationBar } from 'Components/Partials';
import Delete from 'Components/Partials/Modals/Delete';
import {DangerButton, PrimaryButton} from 'Components/Button';
import {HrTitle} from 'Components/HumanResources/AdminComponents';

import {Toast} from 'Services';
import DocumentsApi from 'Services/Api/Admin/HumanResources/Documents';

import Base from '../Base';
import Create from './Create';
import ToggleVisibility from './ToggleVisibility';

class Documents extends Base {
    /**
     * @var state
     */
    state = {
        loading: false,
        user: null,
        documents: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.setUser();
        this.fetchDocuments();

        DocumentsApi.on('create', this.fetchDocuments);
        DocumentsApi.on('update', this.fetchDocuments);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        DocumentsApi.removeListener('create', this.fetchDocuments);
        DocumentsApi.removeListener('update', this.fetchDocuments);
    };

    /**
     * @method fetchDocuments
     * @param {number} page
     * @return {Promise<void>}
     */
    fetchDocuments = async (page = 1) => {
        this.setState({ loading: true });

        const request = await DocumentsApi.get({
            user: this.props.match.params.user
        }, {
            page
        });

        if (request.success) {
            return this.setState({
                loading: false,
                documents: request.data
            });
        }

        Toast.error();
    };

    /**
     * @method handleDelete
     * @param {object} document
     * @return {Promise<void>}
     */
    handleDelete = async (document) => {
        const request = await DocumentsApi.delete({
            user: document.user_id,
            document: document.id
        });

        if (request.success) {
            Toast.success('Document has been successfully deleted from the system');

            this.fetchDocuments();
        }
    };

    /**
     * @method handleDownload
     * @param {object} document
     */
    handleDownload = (document) => {
        DocumentsApi.downloadFile(
            `/admin/human-resources/${document.user_id}/documents/${document.id}/download`,
            {},
            `document-${document.id}.pdf`
        );
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { user, documents, loading } = this.state;

        return (
            <AdminLayout user={user?.full_name ?? 'Loading...'}>
                {loading && (
                    <Loading />
                )}

                {!loading && documents && (
                    <div className="divide-y divide-gray-200">
                        <div className="p-6 flex justify-between">
                            <HrTitle user={user} title="Documents" />

                            <ModalTrigger
                                component={Create}
                                props={{
                                    user
                                }}
                            >
                                <PrimaryButton
                                    text="Create"
                                />
                            </ModalTrigger>
                        </div>

                        <div className="p-6">
                            {documents.meta.total === 0 && (
                                <p className="text-center">
                                    There are no documents available.
                                </p>
                            )}

                            {documents.meta.total > 0 && (
                                <ul className="mt-2 divide-y divide-gray-200">
                                    {documents.data.map(document => (
                                        <li className="py-4 flex items-center justify-between" key={document.id}>
                                            <div className="flex flex-col cursor-pointer" onClick={() => this.handleDownload(document)}>
                                                <p className="text-sm font-medium text-gray-900">
                                                    {document.name}
                                                </p>

                                                <div className="flex mt-1">
                                                    {document.categories.map(category => (
                                                        <div className="mr-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">{category.name}</div>
                                                    ))}

                                                    {document.visible_to_user && (
                                                        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                            Visible to user
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="flex">
                                                <ModalTrigger
                                                    component={ToggleVisibility}
                                                    props={{
                                                        user,
                                                        document
                                                    }}
                                                >
                                                    <PrimaryButton
                                                        text={document.visible_to_user ? 'Hide from User' : 'Show to User'}
                                                        className="mr-2"
                                                    />
                                                </ModalTrigger>

                                                <ModalTrigger
                                                    component={Create}
                                                    props={{
                                                        user,
                                                        document
                                                    }}
                                                >
                                                    <PrimaryButton
                                                        text="Edit"
                                                        className="mr-2"
                                                    />
                                                </ModalTrigger>

                                                <ModalTrigger
                                                    component={Delete}
                                                    props={{
                                                        onDelete: () => this.handleDelete(document)
                                                    }}
                                                >
                                                    <DangerButton text="Remove" />
                                                </ModalTrigger>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {documents.meta.total > 0 && (
                            <div className="p-6">
                                <PaginationBar
                                    total={documents.meta.total}
                                    pageCount={documents.meta.last_page}
                                    page={documents.meta.current_page}
                                    goToPage={this.fetchDocuments}
                                />
                            </div>
                        )}
                    </div>
                )}
            </AdminLayout>
        );
    }
}

export default withRouter(Documents);

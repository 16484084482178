import React from 'react';

import { PrimaryButton, SecondaryButton } from 'Components/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'Components/Modal';

import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';

import { Toast } from 'Services';
import { resourceDateIsAfterNow } from 'Services/DateHelpers';

export default class SubmitTimesheetModal extends React.Component {
    /**
     * @method handleSubmitTimesheet
     */
    handleSubmitTimesheet = async () => {
        const { timesheet } = this.props;

        const request = await TimesheetsApi.patch({
            timesheet: timesheet.id,
        }, {
            ...timesheet,
            submitted: true,
        });

        if (request.success) {
            Toast.success('Your timesheet has been submitted');
        }

        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { timesheet } = this.props;

        if (resourceDateIsAfterNow(timesheet.end_date_time)) {
            return (
                <Modal>
                    <ModalHeader onClose={this.props.onClose}>
                        Submit Timesheet
                    </ModalHeader>

                    <ModalBody>
                        <p className="text-center">
                            You can not submit this timesheet yet.
                        </p>
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <div className="space-x-4">
                            <SecondaryButton
                                text="Close"
                                onClick={this.props.onClose}
                            />
                        </div>
                    </ModalFooter>
                </Modal>
            )
        }

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    Submit Timesheet
                </ModalHeader>

                <ModalBody>
                    <p className="text-center">
                        Are you sure you want to submit this timesheet?
                    </p>
                </ModalBody>

                <ModalFooter alignment="center">
                    <div className="space-x-4">
                        <SecondaryButton
                            text="No"
                            onClick={this.props.onClose}
                        />

                        <PrimaryButton
                            text="Yes"
                            onClick={this.handleSubmitTimesheet}
                        />
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

import React from 'react';
import {Link} from "react-router-dom";

import {faUsers} from '@fortawesome/free-solid-svg-icons/faUsers';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {FormHandler, Input, Checkbox, Toggle, Select, ColourPicker, Textarea} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';
// import CashierSubscriptionSelector from 'Components/Cashier/SubscriptionSelector';

import AdminUsersApi from 'Services/Api/Admin/Users';
import AdminGroupsApi from 'Services/Api/Admin/Groups';
import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';
import Label from "../../../Components/Form/Label";

class UsersStore extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'User ' + (this.props.match?.params?.user ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     * @type {{user_id: string}}
     */
    state = {
        user_id: this.props.match?.params?.user ?? '',
        group_name: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.state.user_id) {
            this.loadUser();
        } else {
            this.props.setInitialValues({
                first_name: '',
                last_name: '',
                email: '',
                username: '',
                password: '',
                password_confirmation: '',
                is_admin: false,
                enabled: true,
                automatically_verify_email: false,
                group_ids: null,
                send_user_invite: false,
                send_password_reset_link: false,
                notes: '',
                is_property: false,
                family_user_tabs: {
                    narratives: false,
                    care_documents: false,
                    family: false,
                    timesheets: false,
                    //calendar: false,
                    //narrative_schedule: false,
                    //medications: false,
                    mars: false,
                    narrative_history: false,
                    //archive: false
                }
            });
        }
    }

    /**
     * @method handleFamilyUserTabChange
     * @param tab
     * @param value
     */
    handleFamilyUserTabChange = (tab, value) => {
        const {form, handleInput} = this.props;
        handleInput('family_user_tabs', {
            ...form.family_user_tabs,
            [tab]: value
        });
    };

    /**
     * @method loadUser
     * @param {int} page
     */
    loadUser = async (page = 1) => {
        const {user_id} = this.state;

        const response = await AdminUsersApi.get(user_id);

        let group_ids = response.data.data.groups.map(g => g.id);

        if (!window.base.features.allow_multiple_groups_per_user) {
            group_ids = group_ids[0];
        }

        this.props.setForm({
            ...response.data.data,
            group_ids,
            family_user_tabs: response.data.data.family_user_tabs || {
                narratives: false,
                care_documents: false,
                family: false,
                timesheets: false,
                //calendar: false,
                //narrative_schedule: false,
                //medications: false,
                mars: false,
                narrative_history: false,
                //archive: false
            }
        });

        this.setState({
            group_name: response.data.data.groups && response.data.data.groups[0] ?
                response.data.data.groups[0].name :
                null
        });
    }

    /**
     * @method handleGroup
     * @param {object} v
     */
    handleGroup = (v) => {
        const {handleInput} = this.props;

        if (window.base.features.allow_multiple_groups_per_user) {
            console.error('NOT SET UP FOR allow_multiple_groups_per_user.');
        }

        handleInput('group_ids', v.id);

        this.setState({
            group_name: v.name
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {user_id} = this.state;

        if (user_id) {
            return AdminUsersApi.patch(user_id, form);
        } else {
            return AdminUsersApi.post(null, form);
        }
    };

    renderFamilyUserTabs() {
        const { form } = this.props;

        if (!form.family_user_tabs) {
            return null;
        }
        return (
            <div>
                <Label
                    label="Family User Tabs"
                    htmlFor="family_user_tabs"
                />
                <Checkbox
                    label="Narratives"
                    value={ form.family_user_tabs.narratives }
                    onChange={ ( e ) => this.handleFamilyUserTabChange ( 'narratives', e.target.checked ) }
                    labelPlacement={ 'right' }
                />
                <Checkbox
                    label="Care Documents"
                    value={ form.family_user_tabs.care_documents }
                    onChange={ ( e ) => this.handleFamilyUserTabChange ( 'care_documents', e.target.checked ) }
                    labelPlacement={ 'right' }
                />
                <Checkbox
                    label="Family"
                    value={ form.family_user_tabs.family }
                    onChange={ ( e ) => this.handleFamilyUserTabChange ( 'family', e.target.checked ) }
                    labelPlacement={ 'right' }
                />
                <Checkbox
                    label="Timesheets"
                    value={ form.family_user_tabs.timesheets }
                    onChange={ ( e ) => this.handleFamilyUserTabChange ( 'timesheets', e.target.checked ) }
                    labelPlacement={ 'right' }

                />
                <Checkbox
                    label="MARS"
                    value={ form.family_user_tabs.mars }
                    onChange={ ( e ) => this.handleFamilyUserTabChange ( 'mars', e.target.checked ) }
                    labelPlacement={ 'right' }
                />
                <Checkbox
                    label="Narrative History"
                    value={ form.family_user_tabs.narrative_history }
                    onChange={ ( e ) => this.handleFamilyUserTabChange ( 'narrative_history', e.target.checked ) }
                    labelPlacement={ 'right' }
                />
            </div>
        );
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { user_id, group_name } = this.state;
        const { form, working, alert, setForm, handleInput, getFieldError, handleSubmit } = this.props;

        return (
            <AdminLayout
                title="Users"
                titleIcon={ faUsers }
            >
                <form
                    className="divide-y divide-gray-200"
                    onSubmit={ ( e ) => handleSubmit ( e, this.handleSubmit, this.success, ( user_id ? false : true ) ) }
                >
                    <div className="mx-6">
                        { alert !== null && ( <Alert { ...alert } /> ) }

                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <Input
                                    label="First Name"
                                    value={ form.first_name }
                                    id="first_name"
                                    error={ getFieldError ( 'first_name' ) }
                                    onChange={ ( v ) => handleInput ( 'first_name', v ) }
                                />
                            </div>

                            <div>
                                <Input
                                    label="Last Name"
                                    value={ form.last_name }
                                    id="last_name"
                                    error={ getFieldError ( 'last_name' ) }
                                    onChange={ ( v ) => handleInput ( 'last_name', v ) }
                                />
                            </div>

                            <div>
                                <Input
                                    label="Email"
                                    value={ form.email }
                                    id="email"
                                    error={ getFieldError ( 'email' ) }
                                    onChange={(v) => handleInput('email', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    label="Username"
                                    value={form.username}
                                    id="username"
                                    error={getFieldError('username')}
                                    onChange={(v) => handleInput('username', v)}
                                />
                            </div>

                            {!user_id &&
                                <>
                                    {(form.is_admin || !window.base.using_hard_password) &&
                                        <>
                                            {form.send_user_invite &&
                                                <div>
                                                    <Toggle
                                                        label="Send User Invite Email With Secure Link To Set Password"
                                                        labelPlacement="top"
                                                        value={form.send_password_reset_link}
                                                        id="send_password_reset_link"
                                                        value={form.send_password_reset_link}
                                                        error={getFieldError('send_password_reset_link')}
                                                        onChange={(v) => handleInput('send_password_reset_link', v)}
                                                    />
                                                </div>
                                            }

                                            {!form.send_password_reset_link &&
                                                <>
                                                    <div>
                                                        <Input
                                                            label="Password"
                                                            type="password"
                                                            value={form.password}
                                                            id="password"
                                                            error={getFieldError('password')}
                                                            onChange={v => handleInput('password', v)}
                                                        />
                                                    </div>

                                                    <div>
                                                        <Input
                                                            label="Password Confirmation"
                                                            type="password"
                                                            value={form.password_confirmation}
                                                            id="password_confirmation"
                                                            error={getFieldError('password_confirmation')}
                                                            onChange={v => handleInput('password_confirmation', v)}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }

                                    <div>
                                        <Toggle
                                            label="Send User Invite Email"
                                            labelPlacement="top"
                                            value={form.send_user_invite}
                                            id="send_user_invite"
                                            value={form.send_user_invite}
                                            error={getFieldError('send_user_invite')}
                                            onChange={(v) => {
                                                if (v === false) {
                                                    setForm({
                                                        ...form,
                                                        send_user_invite: false,
                                                        send_password_reset_link: false
                                                    });
                                                } else {
                                                    handleInput('send_user_invite', v);
                                                }
                                            }}
                                        />
                                    </div>

                                    {window.base.features.verify_registrations &&
                                        <div className="my-auto">
                                            <Checkbox
                                                label="Automatically Verify Email"
                                                value={form.automatically_verify_email}
                                                id="automatically_verify_email"
                                                error={getFieldError('automatically_verify_email')}
                                                onChange={(v) => handleInput('automatically_verify_email', v.target.checked)}
                                            />
                                        </div>
                                    }
                                </>
                            }

                            <div>
                                <Toggle
                                    label="Admin"
                                    labelPlacement="top"
                                    value={form.is_admin}
                                    id="is_admin"
                                    value={form.is_admin}
                                    error={getFieldError('is_admin')}
                                    onChange={(v) => handleInput('is_admin', v)}
                                />
                            </div>

                            {!form.is_admin && window.base.features.groups &&
                                <div>
                                    <Select
                                        label="Group"
                                        id="group"
                                        value={form.group_ids}
                                        error={getFieldError('group_ids')}
                                        onChange={(v) => this.handleGroup(v)}
                                        isAsync
                                        searchCallback={(data) => AdminGroupsApi.get(null, {
                                            ...data,
                                            hasNoTeam: true
                                        })}
                                        searchLabelKey="name"
                                        allowNull={!window.base.features.user_must_belong_to_a_group}
                                        isMulti={window.base.features.allow_multiple_groups_per_user}
                                        returnObject={true}
                                    />
                                </div>
                            }

                            {group_name === 'Service User' &&
                                <div>
                                    <Toggle
                                        label="Property"
                                        labelPlacement="top"
                                        value={form.is_property}
                                        id="is_property"
                                        value={form.is_property}
                                        error={getFieldError('is_property')}
                                        onChange={(v) => handleInput('is_property', v)}
                                    />
                                </div>
                            }

                            {group_name === 'Service User Family' &&
                                <>
                                    <div>
                                        <Select
                                            label="Connected Service User"
                                            id="connected_service_user_id"
                                            value={form.connected_service_user_id}
                                            error={getFieldError('connected_service_user_id')}
                                            onChange={(v) => handleInput('connected_service_user_id', v)}
                                            isAsync
                                            searchCallback={(data) => ServiceUsersApi.get(null, {
                                                ...data,
                                                enabled: true
                                            })}
                                            searchLabelKey="full_name"
                                            allowNull={false}
                                        />
                                    </div>

                                    <div>
                                        <Input
                                            label="Relationship To Service User"
                                            value={form.relationship_to_connected_service_user}
                                            id="relationship_to_connected_service_user"
                                            error={getFieldError('relationship_to_connected_service_user')}
                                            onChange={(v) => handleInput('relationship_to_connected_service_user', v)}
                                        />
                                    </div>

                                    {  form?.connected_service_user_id &&
                                        this.renderFamilyUserTabs()
                                    }
                                </>
                            }

                            <div>
                                <Textarea
                                    label="Notes"
                                    value={form.notes}
                                    id="notes"
                                    value={form.notes}
                                    error={getFieldError('notes')}
                                    onChange={(v) => handleInput('notes', v)}
                                />
                            </div>

                            <div>
                                <ColourPicker
                                    label="Colour"
                                    labelPlacement="left"
                                    value={form.colour}
                                    id="colour"
                                    value={form.colour}
                                    error={getFieldError('colour')}
                                    onChange={(v) => handleInput('colour', v)}
                                />
                            </div>

                            <div>
                                <Toggle
                                    label="Enabled"
                                    labelPlacement="top"
                                    value={form.enabled}
                                    id="enabled"
                                    value={form.enabled}
                                    error={getFieldError('enabled')}
                                    onChange={(v) => handleInput('enabled', v)}
                                />
                            </div>
                        </div>

                        {window.base.features.subscriptions && !user_id &&
                            <div className="mb-4">
                                <CashierSubscriptionSelector
                                    form={form}
                                    handleInput={handleInput}
                                    getFieldError={getFieldError}
                                    admin={true}
                                    collectPaymentMethod={true}
                                    group_ids={form.group_ids}
                                />
                            </div>
                        }
                    </div>

                    <div className="p-6 flex justify-end gap-4">
                        <Link to="/admin/users">
                            <SecondaryButton type="button" text="Back" />
                        </Link>

                        <PrimaryButton
                            text="Save"
                            working={working}
                        />
                    </div>
                </form>
            </AdminLayout>
        )
    }
}

export default FormHandler(UsersStore);

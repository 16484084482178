import React from 'react';

import {Container} from 'Components/Utilities';
import PushNotifications from 'Components/Partials/PushNotifications';

export default class Authenticated extends React.Component {
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        const {children, pageTitle} = this.props;

        return (
            <React.Fragment>
                {window.base.features.allow_push_notifications && <PushNotifications />}

                <Container>
                    {children}
                </Container>
            </React.Fragment>
        )
    };
}

import React from "react";
import { DateTime } from "luxon";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "Components/Modal";
import { PrimaryButton } from "Components/Button";
import { convertDecimalToTime } from "Services/BaseHelpers";

class ViewAverageHistory extends React.Component {
    render () {
        const { onClose, holiday } = this.props;

        return (
            <Modal>
                <ModalHeader onClose={onClose}>
                    Average History
                </ModalHeader>

                <ModalBody>
                    <table className="table-auto mx-auto">
                        <thead>

                        <tr className="bg-gray-300">
                            <th className="px-4 py-2 border border-app-leading text-center">Date</th>
                            <th className="px-4 py-2 border border-app-leading text-center">Total Hours Worked for Month</th>
                            <th className="px-4 py-2 border border-app-leading text-center">Average Hours Per Week Worked</th>
                            <th className="px-4 py-2 border border-app-leading text-center">Hours per one day of A/L</th>
                        </tr>
                        </thead>

                        <tbody>
                        {holiday?.average_history?.map((history, index) => (
                            <tr key={`holiday-${index}`}>
                                <td className="px-4 py-2 border border-app-leading text-center">
                                    {DateTime.fromISO(history?.date?.iso_string).toLocaleString(DateTime.DATE_MED)}
                                </td>
                                <td className="px-4 py-2 border border-app-leading text-center">{convertDecimalToTime(history.total_month_hours)}</td>
                                <td className="px-4 py-2 border border-app-leading text-center">{convertDecimalToTime(history.average_hours_per_week)}</td>
                                <td className="px-4 py-2 border border-app-leading text-center">{convertDecimalToTime(history.average_hours_per_day)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </ModalBody>

                <ModalFooter>
                    <PrimaryButton
                        onClick={onClose}
                        text="Close"
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

export default ViewAverageHistory;

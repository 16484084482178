import React from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';

import Authenticated from 'Components/Layouts/Authenticated';
import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input, Label, FileUpload} from 'Components/Form';
import {Alert} from 'Components/Partials';
import {DisplayTimeZone, DisplayLanguage} from 'Components/Settings';

import {User} from 'Services';
import {getFallbackAvatarUrl} from 'Services/AuthHelpers';
import Me from 'Services/Api/Me/Me';

class Profile extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Your details have been updated successfully';

    /**
     * @var displayAvatar
     * @type {boolean}
     */
    displayAvatar = false;

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.props.setForm(User.data);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        return (
            <Authenticated title="My Profile" titleIcon={faUser}>
                <form
                    autoComplete="off"
                    className="space-y-4"
                    onSubmit={(e) => handleSubmit(e, Me.update, this.success)}
                >
                    <div className="p-6">
                        {alert !== null && (<Alert {...alert} />)}

                        {this.displayAvatar && 
                            <FileUpload
                                containerClassName="m-8 mt-4 flex flex-col justify-center items-center gap-4"
                                value={form.avatar_path}
                                onChange={v => handleInput('avatar_path', v)}
                                accept="image/*"
                                placeholder="Update Picture"
                                fallbackImage={getFallbackAvatarUrl(User.data)}
                            />
                        }

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <Input
                                    labelTextColor="text-app-backup"
                                    label="First Name"
                                    value={form.first_name}
                                    id="first_name"
                                    error={getFieldError('first_name')}
                                    onChange={(v) => handleInput('first_name', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    labelTextColor="text-app-backup"
                                    label="Last Name"
                                    value={form.last_name}
                                    id="last_name"
                                    error={getFieldError('last_name')}
                                    onChange={(v) => handleInput('last_name', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    labelTextColor="text-app-backup"
                                    label="Email"
                                    value={form.email}
                                    id="email"
                                    error={getFieldError('email')}
                                    onChange={(v) => handleInput('email', v)}
                                    readOnly={!window.base.features.allow_email_change}
                                />
                            </div>

                            <div>
                                <Label 
                                    labelTextColor="text-app-backup"
                                    label="TimeZone"
                                    htmlFor="timezone"
                                />
                                <DisplayTimeZone editMode={true} />
                            </div>

                            {Object.keys(window.base.languages).length > 1 &&
                                <div>
                                    <Label 
                                        labelTextColor="text-app-backup"
                                        label="Language"
                                        htmlFor="language"
                                    />
                                    <DisplayLanguage editMode={true} />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="p-6 flex justify-between">
                        <PrimaryButton
                            text="Save"
                            working={working}
                        />
                    </div>
                </form>
            </Authenticated>
        );
    }
}

export default FormHandler(Profile);

import React from 'react';

import {
    faHome,
    faBusinessTime,
    faCalendar,
    faPencilAlt,
    faIdCard,
    faUsers,
    faLayerGroup,
    faGlobeEurope,
    faChartLine,
    faFileExcel,
    faEnvelope,
    faCog,
    faDollarSign,
    faTachometerAlt,
    faComments,
    faClipboardCheck,
    faCalendarCheck,
    faPoundSign,
    faUmbrellaBeach,
    faBoxArchive,
    faQuestion,
    faListCheck,
    faPills, faCheck,
} from '@fortawesome/free-solid-svg-icons';

import { User } from 'Services';
import {filterItemsByAccess} from 'Services/BaseHelpers';

import NavItem from './NavItem';

export default class Nav extends React.Component {
    /**
     * @type {{items: []}}
     */
    state = {
        items: [],
    };

    /**
     * @method setItems
     */
    setItems = () => this.setState({
        items: [
            {
                to: "/",
                icon: faHome,
                title: "Dashboard",
                exact: true
            },
            {
                to: "/service-users",
                icon: faUsers,
                title: "Service Users",
                group: ['Staff', 'Service User Family']
            },
            {
                to: "/settings/human-resources/my-calendar",
                icon: faCalendar,
                title: "My Calendar",
                group: 'Staff',
            },
            {
                to: "/settings/human-resources/annual-leave-calendar",
                icon: faUmbrellaBeach,
                title: "Annual Leave Calendar",
                group: 'Staff',
            },
            {
                to: "/settings/human-resources/holidays",
                icon: faUmbrellaBeach,
                title: "Annual Leave",
                group: 'Staff',
            },
            // {
            //     to: "/settings/human-resources/appointment-calendar",
            //     icon: faCalendarCheck,
            //     title: "Appointment Calendar",
            //     group: 'Staff',
            // },
            {
                to: "/settings/human-resources/timesheets",
                icon: faBusinessTime,
                title: "My Timesheets",
                group: 'Staff',
            },
            // {
            //     to: "/covid",
            //     icon: faCheckCircle,
            //     title: "My Covid Tests",
            //     group: 'Staff',
            // },
            {
                to: "/medical-management",
                icon: faPills,
                title: "Medical Management",
                group: 'Staff',
            },
            {
                to: "/quality-assurance",
                icon: faListCheck,
                title: "Quality Assurance",
                group: 'Staff',
            },
            {
                to: "/health-and-safety",
                icon: faClipboardCheck,
                title: "Health & Safety",
                group: 'Staff',
            },
            {
                to: "/narratives",
                icon: faClipboardCheck,
                title: "Narratives",
                group: 'Staff',
            },
            {
                to: "/networking",
                icon: faComments,
                title: "Messages",
                group: 'Staff'
            },
            {
                icon: faUsers,
                title: "Users",
                is_admin: true,
                sub: [
                    {
                        to: "/admin/users",
                        icon: faUsers,
                        title: "Users",
                    },
                    {
                        feature: "allow_groups_update",
                        to: "/admin/groups",
                        icon: faLayerGroup,
                        title: "User Groups",
                    },
                    {
                        to: "/admin/archive",
                        icon: faBoxArchive,
                        title: "Archive",
                    }
                ]
            },
            {
                icon: faIdCard,
                title: "Staff HR",
                is_admin: true,
                sub: [
                    {
                        scope: "hr-admin",
                        to: "/admin/human-resources/calendar",
                        icon: faCalendar,
                        title: "Calendar",
                    },
                    {
                        scope: "hr-admin",
                        to: "/admin/human-resources/timesheets",
                        icon: faBusinessTime,
                        title: "Timesheets",
                    },
                    {
                        scope: "hr-admin",
                        to: "/admin/human-resources/pay-summary",
                        icon: faPoundSign,
                        title: "Pay Summary",
                    },
                    // {
                    //     scope: "hr-admin",
                    //     to: "/admin/covid",
                    //     icon: faCheckCircle,
                    //     title: "Covid Tests",
                    // },
                    // {
                    //     scope: "hr-admin",
                    //     to: "/admin/covid/missing",
                    //     icon: faTimesCircle,
                    //     title: "Missing Covid Tests",
                    // },
                    {
                        scope: "hr-admin",
                        to: "/admin/human-resources",
                        icon: faIdCard,
                        title: "Staff",
                        exact: true
                    },
                    {
                        scope: "hr-admin",
                        to: "/admin/human-resources/holiday-approvals",
                        icon: faCheck,
                        title: (
                            <div className="flex items-center space-x-2">
                                <div>Holiday Approvals</div>

                                { User.data.has_holiday_to_approve && (
                                    <div className="h-3 w-3 bg-red-500 rounded-full"/>
                                ) }
                            </div>
                        ),
                    },
                    {
                        scope: "hr-admin",
                        to: "/admin/human-resources/holiday-dashboard",
                        icon: faTachometerAlt,
                        title: 'Holiday Dashboard',
                    },
                ]
            },
            {
                icon: faCog,
                title: "Misc",
                is_admin: true,
                sub: [
                    {
                        to: "/admin/stats",
                        icon: faChartLine,
                        title: "Stats",
                    },
                    {
                        to: "/admin/reports",
                        icon: faFileExcel,
                        title: "Reports",
                    },
                    {
                        to: "/admin/imports",
                        icon: faFileExcel,
                        title: "Imports",
                    },
                    {
                        to: "/admin/cms/elements",
                        icon: faPencilAlt,
                        title: "Form Builder",
                        exact: true
                    },
                    {
                        to: "/admin/cms/elements/records",
                        icon: faPencilAlt,
                        title: "Form Records",
                    },
                    {
                        scope: "hr-admin",
                        to: "/admin/human-resources/rates",
                        icon: faDollarSign,
                        title: "HR Rates",
                    },
                    {
                        to: "/admin/translations",
                        icon: faGlobeEurope,
                        title: "Translations",
                    },
                    {
                        to: "/admin/push-notifications",
                        icon: faEnvelope,
                        title: "Push Notifications",
                    },
                    {
                        to: "/admin/tests",
                        icon: faTachometerAlt,
                        title: "Site Tests",
                    },
                    {
                        to: "/admin/surveys",
                        icon: faQuestion,
                        title: "Surveys",
                    },
                ],
            },
        ],
    });

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.setItems();

        User.on('change', this.setItems);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        User.removeListener('change', this.setItems);
    };

    /**
     * @method render
     * @return {*}
     */
    render() {
        const { items } = this.state;

        return filterItemsByAccess(items).map((item, i) => (
            <NavItem
                item={item}
                key={i}
            />
        ))
    }
}

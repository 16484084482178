import React from 'react';
import reactStringReplace from 'react-string-replace';

import {RenderWYSIWYG} from 'Components/Form/WYSIWYG';

/**
 * @method translation
 * @param {string} group
 * @param {string} key
 * @param {object} data
 * @return {string}
 */
const translation = (group, key, data = null, displayError = true) => {
    let translation = '';

    // Get the translation value.
    if (!window.base.translations[group] || !window.base.translations[group][key]) {
        if (displayError) {
            console.error(
                'No translation value found for group: ' + group + ' and key: ' + key
            );
        }
        return;
    } else {
        translation = window.base.translations[group][key];
    }

    // Remove accidental double escaping.
    translation = translation.replace(/\\\"/g, '\"');

    // Replace placeholder data.
    if (translation && data) {
        translation = translationData(translation, data);
    }

    // Render translation.
    return renderTranslation(translation);
}

/**
 * @method translationData
 * @param {string} translation
 * @param {object} data
 * @return {string}
 */
const translationData = (translation, data) => {
    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            let placeholder = ':' + key.toUpperCase();
            
            let value = data[key];

            // If the value is a string then do a simple regex search and replace.
            // If the value is not a string then use reactStringReplace() to replace it properly.
            if (typeof value === 'string' || value instanceof String) {
                var regex = new RegExp(placeholder, 'g');

                translation = translation.replace(regex, value);
            } else {
                translation = reactStringReplace(translation, placeholder, (match, i) => (value));
            }
        }
    }

    return translation;
}

/**
 * If the value is just a string then we return that via RenderWYSIWYG.
 *
 * If the value has been broken up in to an array by reactStringReplace() above
 * then we need to loop through the array and piece it together.
 * 
 * @method renderTranslation
 * @param {string} translation
 * @return {string}
 */
const renderTranslation = (translation) => {
    if (typeof translation === 'string' || translation instanceof String) {
        if (translation.startsWith('<')) {
            return <RenderWYSIWYG content={translation} />;
        } else {
            return translation;
        }
    } else {
        return Object.entries(translation).map((holder, key) => {
            let value = holder[1];

            if (typeof value === 'string' || value instanceof String) {
                return <RenderWYSIWYG content={value} />;
            } else {
                return value;
            }
        });
    }
}

export {
    translation
}

import React from 'react';

import isEqual from 'lodash/isEqual';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {AsyncPaginate} from 'react-select-async-paginate';

import {isAdminPage} from 'Services/BaseHelpers';

import Label from './Label';
import {getStyles_default, getStyles_colouredOptions} from './SelectStyles';

/*
Examples

Select ...
<Select
    label="Role"
    value={form.role}
    id="role"
    onChange={(v) => handleInput('role', v)}
    options={roles}
/>

CreatableSelect ...
<Select
    label="Tags"
    value={form.tags}
    id="tags"
    onChange={v => handleInput('tags', v)}
    options={options}
    isMulti
    isCreatable
/>

AsyncPaginate (the searchCallback api request must have a 'search' filter applied to it) ...
<Select
    label="Nominated By"
    value={form.nominated_by}
    id="nominated_by"
    onChange={(v) => handleInput('nominated_by', v)}
    isAsync
    searchCallback={(data) => UsersApi.get(null, data)}
    searchLabelKey="email"
    allowNull={true}
/>

Or if you wish to receive more than just the id of the api object in AsyncPaginate you can add;
<Select
    ... as above for AsyncPaginate ...
    onChange={(v) => {
        handleInput('nominated_by', v.id);

        ... do something else with v, e.g. store it in state ...
    }}
    returnObject={true}
/>
*/

export default class Select extends React.Component {
    /**
     * @var state
     * @type {{isLoading: boolean}}
     * @type {{options: array}}
     */
    state = {
        isLoading: false,
        options: [],
        previousSearch: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        this.loadMissingOptions();
    }

    /**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate = async (prevProps) => {
        if (!isEqual(prevProps.value, this.props.value)) {
            this.loadMissingOptions();
        }
    }

    /**
     * If values exist on an isAsync or isCreatable select before any search is made
     * we need to load in those options.
     *
     * @method loadMissingOptions
     */
    loadMissingOptions = async () => {
        const {isAsync, isMulti, isCreatable, value} = this.props;

        // Handle isAsync.
        if (isAsync && value) {
            let totalOptions = this.getTotalOptions();

            if (isMulti) {
                var missingOptions = value.filter(o =>
                    totalOptions.filter(t => o === this.getValue(t)).length === 0
                ).length !== 0;
            } else {
                var missingOptions = totalOptions.filter(t => value === this.getValue(t)).length === 0
            }

            if (missingOptions) {
                this.callSearchApi(
                    1,
                    null,
                    isMulti ? value.join(',') : value
                );
            }
        }

        // Handle isCreatable.
        if (isCreatable && value) {
            // Collect the existing options.
            let {options} = this.state;

            // Add the new options if not already in the list.
            value.forEach((this_value) => {
                let found = options.find(option => option.value === this_value);

                if (!found) {
                    options.push({
                        label: this_value,
                        value: this_value
                    });
                }
            });

            // Update the list of options.
            this.setState({
                options
            });
        }
    }

    /**
     * @method handleChange
     * @param {object} option
     */
    handleChange = (option) => {
        const {onChange, isMulti} = this.props;

        // If no option selected then return null to parent.
        if (!option) {
            onChange(null);
        }

        // Return the values to the parent.
        isMulti ?
            onChange(option?.map(o => o.value)) :
            onChange(option?.value);
    };

    /**
     * @method handleCreate
     * @param {object} createdValue
     */
    handleCreate = (createdValue) => {
        const {isMulti} = this.props;

        this.setState({ isLoading: true });

        // Collect the existing options.
        const {options} = this.state;

        // Create the new option.
        const option = {
            label: createdValue,
            value: createdValue
        };

        // Add the newly created option to the list of options.
        this.setState({
            isLoading: false,
            options: [...options, option],
        }, () => {
            // Auto-select the new option.
            let {value, onChange} = this.props;

            if (isMulti) {
                if (value) {
                    value.push(createdValue);
                } else {
                    value = [createdValue];
                }
            } else {
                value = createdValue;
            }

            onChange(value);
        });
    }

    /**
     * @method handleSearch
     * @param {string} search
     * @return {object}
     */
    handleSearch = async (search, loadedOptions, { page }) => {
        let [options, response] = await this.callSearchApi(page, search, null);

        if (!response.data.meta) {
            console.error('Error. Api request must be paginated.');
        }

        if (this.state.previousSearch !== search) {
            this.setState({
                previousSearch: search
            });
        }

        // Return the data.
        return {
            options,
            hasMore: response.data.meta.current_page !== response.data.meta.last_page,
            additional: {
                page: page + 1,
            },
        };
    }

    /**
     * @method callSearchApi
     * @param {integer} page
     * @param {string|null} search
     * @param {array|null} ids
     * @return {[object, object]}
     */
    callSearchApi = async (page, search, ids) => {
        const {searchCallback} = this.props;

        // Call the search api.
        const response = await searchCallback({
            page, search, ids
        });

        // Collect the existsing options so that we can check below for duplicates.
        let totalOptions = this.getTotalOptions();

        // Add the options to the list of options.
        this.setState({
            options: [
                ...this.state.options,
                ...this.getOptionsFromSearchApiResponse(response, page, search, ids, totalOptions, false)
            ]
        });

        // Return the data.
        return [
            this.getOptionsFromSearchApiResponse(response, page, search, ids, totalOptions, true),
            response
        ];
    }

    /**
     * @method getOptionsFromSearchApiResponse
     * @param {object} response
     * @param {integer} page
     * @param {string|null} search
     * @param {array|null} ids
     * @param {object} totalOptions
     * @param {boolean} ignoreFromIds
     * @return {object}
     */
    getOptionsFromSearchApiResponse = (response, page, search, ids, totalOptions, ignoreFromIds) => {
        const {searchLabelKey, allowNull, isMulti, colouredOptions, returnObject = false} = this.props;

        // Collect the options from the api response.
        let options = response.data.data.map((option, key) => {
            /*
            - Select will automatically clear the options-cache when the search term changes so if the
              search term has not changed then we need to remove duplicates.
            - If "ignoreFromIds" is true then we return values collected from the ids search request
              even if they exist in the totalOptions, so that the search bar caches them properly.
            */
            if (this.state.previousSearch === search && 
                totalOptions.filter(
                    o => option.id === this.getValue(o) && 
                    (!ignoreFromIds || !o.fromIds)
                ).length !== 0)
            {
                return null;
            }

            return {
                label: typeof searchLabelKey === 'function' ? searchLabelKey(option) : option[searchLabelKey],
                value: returnObject ? option : option.id,
                color: colouredOptions ? option.colour : null,
                fromIds: ids ? true : false
            };
        });

        // Remove null options.
        options = options.filter(o => o !== null);

        // If first page and allowNull and the search text is empty;
        // then display all the options with a "None" value at the start.
        // (Not required for isMulti input.)
        if (!search && allowNull && !isMulti && page === 1 && !ids) {
            options = [
                {
                    label: 'None',
                    value: null
                },
                ...options,
            ];
        }

        return options;
    }

    /**
     * @method getTotalOptions
     * @return {object}
     */
    getTotalOptions = () => {
        let totalOptions = [];

        // Merge state and props options.
        if (this.state.options && this.props.options) {
            totalOptions = [
                ...this.state.options,
                ...this.props.options
            ];
        } else if (this.state.options) {
            totalOptions = [
                ...this.state.options,
            ];
        } else if (this.props.options) {
            totalOptions = [
                ...this.props.options,
            ];
        }

        // Remove duplicates.
        // (https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects)
        totalOptions = totalOptions.filter((value, index, self) =>
            index === self.findIndex((t) => (
                this.getValue(t) === this.getValue(value)
            ))
        )

        // Return total options.
        return totalOptions;
    }

    /**
     * @method getSelectedOptions
     * @param {object} totalOptions
     * @return {object}
     */
    getSelectedOptions = (totalOptions) => {
        const {value, isMulti} = this.props;

        let selectedOptions = null;

        // Filter totalOptions by "value" to get the selectedOptions.
        if (value && totalOptions) {
            if (isMulti) {
                selectedOptions = totalOptions.filter(o => value.indexOf(this.getValue(o)) !== -1);
            } else {
                selectedOptions = totalOptions.filter(o => value === this.getValue(o))[0] ?? null;
            }
        }

        return selectedOptions;
    }

    /**
     * @method getValue
     * @param {object} option
     * @return {string}
     */
    getValue = (option) => {
        const {returnObject = false} = this.props;

        if (returnObject) {
            return option?.value?.id;
        }

        return option?.value;
    }

    /**
     * @method getStyles
     * @return {object}
     */
    getStyles = () => {
        if (this.props.colouredOptions) {
            return getStyles_colouredOptions()
        } else {
            return getStyles_default();
        }
    }

    /**
     * Swap the dropdown-indicator if "icon" prop passed through.
     * 
     * E.g ... 
     * import {faSearch} from '@fortawesome/free-solid-svg-icons';
     * icon={faSearch}
     * 
     * @method getComponents
     * @return {object}
     */
    getComponents = () => {
        const {icon} = this.props;

        if (!icon) {
            return;
        }

        const DropdownIndicator = props => {
            return (
                <components.DropdownIndicator {...props}>
                    <FontAwesomeIcon
                        icon={icon}
                        size="1x"
                    />
                </components.DropdownIndicator>
            );
        };

        return { DropdownIndicator };
    }

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {containerClassName, label, id, onChange, isCreatable, isAsync, instructions, error = null} = this.props;
        const {isLoading} = this.state;

        let totalOptions = this.getTotalOptions();
        let selectedOptions = this.getSelectedOptions(totalOptions);
        let styles = this.getStyles();

        return (
            <div className={containerClassName}>
                {label && (
                    <Label
                        label={label}
                        htmlFor={id}
                        instructions={instructions}
                        error={error}
                    />
                )}

                {isCreatable &&
                    <CreatableSelect
                        {...this.props}
                        value={selectedOptions}
                        options={totalOptions}
                        onChange={this.handleChange}
                        menuPosition="fixed"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onCreateOption={this.handleCreate}
                        isClearable
                        styles={styles}
                        components={this.getComponents()}
                    />
                }

                {isAsync &&
                    <AsyncPaginate
                        {...this.props}
                        value={selectedOptions}
                        onChange={this.handleChange}
                        menuPosition="fixed"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        loadOptions={this.handleSearch}
                        cacheOptions
                        styles={styles}
                        additional={{
                            page: 1,
                        }}
                        components={this.getComponents()}
                    />
                }

                {!isCreatable && !isAsync &&
                    <ReactSelect
                        {...this.props}
                        value={selectedOptions}
                        options={totalOptions}
                        onChange={this.handleChange}
                        menuPosition="fixed"
                        styles={styles}
                        components={this.getComponents()}
                    />
                }
            </div>
        )
    }
};

import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-solid-svg-icons';

import {Notifications, Notification} from 'Components/Comms';
import {User} from "Services";

export default class HeaderNotifications extends Notifications {
    /**
     * @var openRef
     * @type {HTMLDivElement}
     */
    openRef = null;

    /**
     * @var state
     * @type {{showNotifications: boolean, notifications: []}}
     */
    state = {
        working: true,
        showNotifications: false,
        notifications: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.componentConstructor();

        document.addEventListener('mousedown', this.handleCloseMenus);
        document.addEventListener('touchstart', this.handleCloseMenus);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        this.componentDestructor();

        document.removeEventListener('mousedown', this.handleCloseMenus);
        document.removeEventListener('touchstart', this.handleCloseMenus);
    };

    /**
     * @method handleCloseMenus
     * @param {Event} event
     */
    handleCloseMenus = (event) => {
        if (!this.openRef || this.openRef.contains(event.target)) {
            return;
        }

        this.setState({
            showNotifications: false,
        });
    };

    /**
     * @method handleToggleNotifications
     */
    handleToggleNotifications = () => this.setState({
        showNotifications: !this.state.showNotifications,
    });

    /**
     * @method render
     * @return {*}
     */
    render() {
        const { showNotifications, notifications } = this.state;

        return (
            <div className="relative flex-shrink-0 ml-4">
                <button
                    onClick={this.handleToggleNotifications}
                    className="flex-shrink-0 rounded-full p-1 text-app-typography"
                >
                    <FontAwesomeIcon icon={faBell}/>

                    {notifications && notifications.data.filter(n => n.read_at === null).length !== 0 && (
                        <div className="rounded-full w-2 h-2 bg-red-400 absolute top-0 right-0" />
                    )}
                </button>

                <div
                    ref={ref => this.openRef = ref}
                    className={`
                        origin-top-right absolute right-0 max-h-96 overflow-y-scroll
                        mt-2 w-96 rounded-md shadow-lg py-1 bg-white ring-1
                        ring-black ring-opacity-5
                        ${showNotifications ? '' : 'hidden'}
                    `}
                >
                    {this.renderNotifications(notifications)}
                </div>
            </div>
        );
    }

    /**
     * @method renderNotifications
     * @return {JSX.Element}
     */
    renderNotifications = (notifications) => (
        <div className="relative bg-white p-2">
            {notifications && notifications.data.length === 0 && (
                <div className="p-2 text-center text-sm text-gray-500">
                    You currently do not have any notifications
                </div>
            )}

            {notifications && notifications.data.map(notification => (
                <Notification
                    notification={notification}
                    key={notification.id}
                    onRead={this.handleReadNotification}
                    onSwitchToTeam={this.props.onSwitchToTeam}
                />
            ))}
        </div>
    );
}

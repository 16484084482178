import React from 'react';

import AdminLayout from './AdminLayout';

export default class AdminIndex extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Welcome To The Admin Panel">
                <div className="px-6 block lg:hidden">
                    Click on a link above to begin.
                </div>
                <div className="px-6 hidden lg:block">
                    Click on a link on the left side to begin.
                </div>
            </AdminLayout>
        );
    }
}
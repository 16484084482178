import React from 'react';

import {Alert} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {FormHandler, Input, DatePicker, Select, Checkbox} from 'Components/Form';

import CovidTestEntryApi from 'Services/Api/Covid/CovidTestEntry';

class Store extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = this.props.id ? 'Successfully updated.' : 'Successfully added.';

    /**
     * @var results
     * @type {array}
     */
    results = [
        {
            label: 'Negative',
            value: 'Negative'
        },
        {
            label: 'Positive',
            value: 'Positive'
        }
    ];

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.props.id) {
            this.loadData();
        } else {
            this.props.setInitialValues({
                result: false,
                uploaded_to_nhs: false
            });
        }
    }

    /**
     * @method loadData
     */
    loadData = async () => {
        const {id} = this.props;

        const response = await CovidTestEntryApi.get(id);

        this.props.setForm(response.data.data);
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {id} = this.props;

        if (id) {
            return CovidTestEntryApi.patch(id, form);
        } else {
            return CovidTestEntryApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, working, alert, setForm, handleInput, getFieldError, handleSubmit, id} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {id ? 'Update' : 'Create'} Covid Test Entry
                </ModalHeader>

                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, !id)}>
                    <ModalBody>
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid gap-4 mb-4">
                            <Input
                                label="Barcode"
                                value={form.barcode}
                                id="barcode"
                                error={getFieldError('barcode')}
                                onChange={(v) => handleInput('barcode', v)}
                            />

                            <DatePicker 
                                label="Date & Time"
                                selected={form.date_time} 
                                onChange={date => handleInput('date_time', date)}
                                showTimeSelect={true}
                                timeIntervals={5}
                            />

                            <Select
                                label="Result"
                                value={form.result ? 'Positive' : 'Negative'}
                                id="result"
                                error={getFieldError('result')}
                                onChange={(v) => handleInput(
                                    'result',
                                    v === 'Positive' ? true : false
                                )}
                                options={this.results}
                            />

                            <Checkbox
                                label="Uploaded To NHS"
                                value={form.uploaded_to_nhs}
                                id="uploaded_to_nhs"
                                error={getFieldError('uploaded_to_nhs')}
                                onChange={(v) => handleInput('uploaded_to_nhs', v.target.checked)}
                            />
                        </div>
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton
                            text={id ? 'Update' : 'Create'}
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler(Store);

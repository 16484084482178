import React from 'react';
import Dropzone from 'react-dropzone';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';

import {PrimaryButton, SecondaryButton} from 'Components/Button';

import DefaultApiBase from 'Services/Api/DefaultApiBase';

import {translation} from 'Services/TranslationHelpers';

import Label from './Label';

/*
Example...
<FileUpload
    containerClassName="flex flex-row justify-start items-center gap-4"
    value={form.avatar_path}
    onChange={v => handleInput('avatar_path', v)}
    accept="image/*"
    placeholder="Change"
    fallbackImage={getFallbackAvatarUrl(User.data)}
    dropzone={false}
    multiple={false}
    allowDownloadExisting={false}
/>
*/

class FileUpload extends React.Component {
    /**
     * @var inputRef
     * @type {null|HTMLInputElement}
     */
    inputRef = null;

    /**
     * @method handleOpenInput
     */
    handleOpenInput = () => {
        this.inputRef.click();
    };

    /**
     * @method handleFiles
     * @param {file[]} newFiles
     */
    handleFiles = (newFiles) => {
        let value = null

        if (this.props.multiple) {
            value = [];

            for (let i = 0; i < newFiles.length; i++) {
                value.push(newFiles[i]);
            }
        } else {
            value = newFiles[0];
        }

        return this.props.onChange(value);
    };

    /**
     * @method getImageSrc
     * @return {string|null}
     */
    getImageSrc = () => {
        const {value, fallbackImage, multiple} = this.props;

        if (multiple) {
            return null;
        }

        if (value) {
            if (value?.mime?.startsWith("image")) {
                return value.url;
            } else if (value?.type?.startsWith("image")) {
                return URL.createObjectURL(value);
            }
        }

        return fallbackImage ?? null;
    }

    /**
     * @method render
     * @return {*}
     */
    render () {
        const {containerClassName, label, id, instructions, error = null, dropzone = false} = this.props;

        return (
            <div className={containerClassName ?? 'flex flex-col justify-center items-start gap-4'}>
                {label && (
                    <Label 
                        label={label} 
                        htmlFor={id} 
                        instructions={instructions}
                        error={error}
                    />
                )}

                {dropzone && this.renderDropZone()}

                {!dropzone && this.renderFileInput()}
            </div>
        )
    }

    /**
     * @method renderDropZone
     * @return {*}
     */
    renderDropZone() {
        const {value, accept, instructions, error} = this.props;

        return (
            <Dropzone onDrop={this.handleFiles} accept={accept}>
                {({getRootProps, getInputProps, isDragActive}) => (
                    <div {...getRootProps()} className="w-full">
                        <div
                            className={`
                            flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md w-full
                            ${isDragActive ? 'bg-gray-200' : ''}
                            ${error ? 'border-red-800' : 'border-gray-300'} 
                        `}>
                            <div className="flex flex-col justify-center items-center gap-4 text-center">
                                <FontAwesomeIcon 
                                    icon={faUpload} 
                                    size="2x" 
                                    className="mx-auto h-12 w-12 text-gray-400"
                                />

                                <div className="flex justify-center items-center text-sm text-gray-600 text-center">
                                    <label
                                        htmlFor="file-upload"
                                        className={`relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 
                                            hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 
                                            focus-within:ring-offset-2 focus-within:ring-indigo-500`}
                                        instructions={instructions}
                                    >
                                        <span>Upload a file</span>

                                        <input {...getInputProps()} />
                                    </label>

                                    <p className="pl-1">
                                        or drag and drop
                                    </p>
                                </div>

                                {this.renderImages()}

                                {this.renderFileNames()}                                        
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    }

    /**
     * @method renderFileInput
     * @return {*}
     */
    renderFileInput() {
        const {id, value, accept, multiple, placeholder} = this.props;

        return (
            <>
                {this.renderImages()}

                <input
                    id={id}
                    onChange={(e) => {
                        this.handleFiles(e.target.files);
                        e.target.value = null;
                    }}
                    ref={ref => this.inputRef = ref}
                    type="file"
                    className="hidden"
                    accept={accept}
                    multiple={multiple}
                />

                <SecondaryButton
                    text={placeholder ?? translation('misc', 'select_file')}
                    onClick={this.handleOpenInput}
                />

                {this.renderFileNames()}
            </>
        );
    }

    /**
     * @method renderImages
     * @return {*}
     */
    renderImages() {
        const {dropzone} = this.props;

        let image_src = this.getImageSrc();

        if (!image_src) {
            return null;
        }

        return (
            <div>
                <img
                    src={image_src}
                    className="rounded-full h-20 w-20 object-cover cursor-pointer"
                    onClick={!dropzone ? this.handleOpenInput : null}
                />
            </div>
        );
    }

    /**
     * @method renderFileNames
     * @return {*}
     */
    renderFileNames() {
        const {value, multiple, allowDownloadExisting = false} = this.props;

        if (this.getImageSrc()) {
            return null;
        }

        if (!multiple && value?.name) {
            return (
                <div className="text-sm text-gray-600 text-center">
                    {value.name}
                </div>
            );
        }

        if (!multiple && value?.url && allowDownloadExisting) {
            return (
                <PrimaryButton 
                    text="Download Existing File"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        DefaultApiBase.downloadResourceFile(value)
                    }}
                />
            );
        }

        if (multiple && value) {
            return (
                <div className="text-sm text-gray-600 text-center flex flex-col gap-2">
                    {value.map((file, i) => (
                        <div key={i}>
                            {file.name}
                        </div>
                    ))}
                </div>
            );
        }
    }
};

export default FileUpload;

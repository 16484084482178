import React from 'react';

import UnauthenticatedDefault from 'Components/Layouts/Default/Unauthenticated';
import UnauthenticatedTopMenu from 'Components/Layouts/TopMenu/Unauthenticated';
import UnauthenticatedCornerMenu from 'Components/Layouts/CornerMenu/Unauthenticated';
import UnauthenticatedSideMenu from 'Components/Layouts/SideMenu/Unauthenticated';
import UnauthenticatedTTS from 'Components/Layouts/TTS/Unauthenticated';

export default class Unauthenticated extends React.Component {
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        switch(window.base.site_layout) {
            case 'top-menu':
                return (
                    <UnauthenticatedTopMenu
                        {...this.props}
                    />
                );
            case 'corner-menu':
                return (
                    <UnauthenticatedCornerMenu
                        {...this.props}
                    />
                );
            case 'side-menu':
                return (
                    <UnauthenticatedSideMenu
                        {...this.props}
                    />
                );
            case 'tts':
                return (
                    <UnauthenticatedTTS
                        {...this.props}
                    />
                );
            default:
                return (
                    <UnauthenticatedDefault
                        {...this.props}
                    />
                );
        }
    };
}

import React from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard/src";

import { PrimaryButton } from "Components/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "Components/Modal";

import { Toast, User } from "Services";
import { translation } from 'Services/TranslationHelpers';

export default class SubscriptionCalendarLink extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {admin} = this.props;

        let timesheetUrl = window.location.protocol + "//" + window.location.hostname;

        if (admin) {
            timesheetUrl += "/timesheets-subscription-calendar/" + User.id + '?admin=true';
        } else {
            timesheetUrl += "/timesheets-subscription-calendar/" + User.id;
        }

        return (
                <Modal size="max-w-screen-lg">
                    <ModalHeader onClose={this.props.onClose}>
                        {translation('subscription-calendar-link', admin ? 'title-admin' : 'title-my')}
                    </ModalHeader>

                    <ModalBody>
                        <div className="flex flex-col justify-center items-center text-center gap-4 mb-4">
                            <p>
                                Add a subscription calendar to your phone with the below url...
                            </p>

                            <p className="font-bold">
                                {timesheetUrl}
                            </p>

                            <div>
                                <CopyToClipboard
                                    text={timesheetUrl}
                                    onCopy={() => Toast.success('Link has been copied.')}
                                >
                                    <PrimaryButton
                                        text="Copy"
                                    />
                                </CopyToClipboard>
                            </div>
                        </div>

                        <details className="mb-4">
                            <summary>
                                iOS Instruction
                            </summary>

                            <ol className="list-decimal px-10">
                                <li>
                                     Open the Calendar App.
                                </li>

                                <li>
                                     Tap <strong>Calendars</strong> at the bottom of the screen and a menu will appear.
                                </li>

                                <li>
                                    Tap on <strong>Add Calendar -> Add Subscription Calendar</strong> and paste in the url above.
                                </li>

                                <li>
                                    Tap on <strong>Subscribe</strong> and ensure that you can see your records.
                                </li>

                                <li>
                                     Finally, Tap on <strong>Add</strong> in the top right corner. 
                                     A few moments later the entries should start appearing in your calendar.
                                </li>
                            </ol>
                        </details>

                        <details>
                            <summary>
                                Android Instructions
                            </summary>

                            <ol className="list-decimal px-10">
                                <li>
                                     Open the Calendar App.
                                </li>

                                <li>
                                     Tap the menu at the top.
                                </li>

                                <li>
                                     Tap on <strong>Settings</strong>.
                                </li>

                                <li>
                                     Tap on <strong>Add Calendar</strong>, this will open a menu.
                                </li>

                                <li>
                                     Tap on <strong>From URL</strong> and paste in the url above.
                                </li>

                                <li>
                                    Tap on <strong>Add Calendar</strong> and wait for it to load.
                                </li>

                                <li>
                                     Finally, tap on <strong>Add</strong> in the top right corner.
                                </li>
                            </ol>
                        </details>
                    </ModalBody>
            </Modal>
        );
    }
}
import CovidIndex from 'Pages/Covid';

import AdminCovidIndex from 'Pages/Admin/Covid';
import AdminCovidMissingIndex from 'Pages/Admin/Covid/MissingIndex';

export default [
    { path: '/covid', component: CovidIndex, guards: ['auth'] },

    { path: '/admin/covid', component: AdminCovidIndex, guards: ['auth', 'has-scope:hr-admin'] },
    { path: '/admin/covid/missing', component: AdminCovidMissingIndex, guards: ['auth', 'has-scope:hr-admin'] },
];

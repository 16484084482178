// import Splash from 'Pages/Splash';
import SecureDownload from 'Pages/SecureDownload';

import Surveys from "Pages/Surveys";

export default [
    // { path: '/', component: Splash },
    { path: '/secure-download/:id', component: SecureDownload },
    { path: "/surveys/:survey/:section?", component: Surveys }
];

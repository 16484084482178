import React, {useRef} from 'react';

import {useDrag, useDrop} from 'react-dnd';

// https://react-dnd.github.io/react-dnd/examples/sortable/simple

const DraggableRow = ({ id, field, index, moveField, disabled, children, rowClassName }) => {
    const ref = useRef(null);
    
    const [{ handlerId }, drop] = useDrop({
        accept: 'item',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (disabled || !ref.current) {
                return;
            }

            const dragIndex = item.index;

            const hoverIndex = index;
            
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            
            // Time to actually perform the action
            moveField(dragIndex, hoverIndex, item.id);
            
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    
    const [{ isDragging }, drag] = useDrag({
        type: 'item',
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    
    const opacity = isDragging ? 0 : 1;

    if (!disabled) {
        drag(drop(ref));
    }

    return (
        <tr 
            ref={ref} 
            data-handler-id={handlerId} 
            style={!disabled ? {
                    cursor: 'move',
                    opacity
                } : {}}
            className={rowClassName}
        >
            {children}
        </tr>
    );
};

export default DraggableRow;
import React from "react";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons/faPoundSign";
import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";

import AdminLayout from 'Pages/Admin/AdminLayout';

import { Loading } from "Components/Partials";
import { DateTimeFilter } from "Components/Filters";
import { PrimaryButton } from "Components/Button";
import FilterModal from "Components/Utilities/FilterModal";

import AdminTimesheetsApi from "Services/Api/Admin/HumanResources/Timesheets";
import {Modal, User} from "Services";

class PaySummary extends React.Component {
    /**
     * @var state
     * @type {{filters: {"end_date_time[afterDateTime]": Date, "start_date_time[beforeDateTime]": Date}}}
     */
    state = {
        loading: false,
        filters: {
            'end_date_time[afterDateTime]': new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, User.data.active_team?.configs?.pay_period?.start ?? 26)), // Default to 26th of last month.
            'start_date_time[beforeDateTime]': new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), User.data.active_team?.configs?.pay_period?.end ?? 25)) // Default to 25th of current month.
        },
        records: [],
    };

    /**
     * @var filterColumns
     * @type {[{label: string, filters: [{component: function({onChange: Function, filters: Object, column: string, label: string, defaultMethod?: boolean, props: Object}): (undefined|*), column: string, label: string, props: {type: string, direction: string}},{component: function({onChange: Function, filters: Object, column: string, label: string, defaultMethod?: boolean, props: Object}): (undefined|*), column: string, label: string, props: {type: string, direction: string}}]}]}
     */
    filterColumns = [
        {
            label: 'Date',
            filters: [
                {
                    column: 'end_date_time',
                    label: 'After',
                    component: DateTimeFilter,
                    props: {
                        type: 'date',
                        direction: 'after'
                    }
                },
                {
                    column: 'start_date_time',
                    label: 'Before',
                    component: DateTimeFilter,
                    props: {
                        type: 'date',
                        direction: 'before'
                    }
                }
            ]
        }
    ];

    /**
     * @method componentDidMount
     * @return {Promise<void>}
     */
    componentDidMount = async () => {
        this.fetchData();
    };

    /**
     * @method componentDidUpdate
     * @param {object} prevState
     */
    componentDidUpdate(_, prevState) {
        if (!isEqual(prevState.filters, this.state.filters)) {
            this.fetchData();
        }
    }

    /**
     * @method fetchData
     * @return {Promise<void>}
     */
    async fetchData() {
        const { filters } = this.state;

        const fromDate = DateTime.fromJSDate(filters['end_date_time[afterDateTime]']).startOf('day');
        const toDate = DateTime.fromJSDate(filters['start_date_time[beforeDateTime]']);

        this.setState({ loading: true });

        const endDate = new Date(filters['start_date_time[beforeDateTime]']);
        endDate.setHours(23, 59, 59);

        var response = await AdminTimesheetsApi.getTimesheetSummaryByEmployee({
            ...filters,
            'start_date_time[beforeDateTime]': endDate.toISOString(),
        });

        if (response.success) {
            const records = [];

            response.data.data.forEach((employee) => {
                const hoursData = [];
                const weekdayHours = { hours: 0, mins: 0 };
                const weekendHours = { hours: 0, mins: 0 };

                [...employee.timesheets].sort((a, b) => (a.hours?.hours ?? 0) < (b.hours?.hours ?? 0) ? 1 : -1).forEach((r) => {
                    if (r.type === 'time_worked') {
                        const start = DateTime.fromISO(r.start_date_time.iso_string);
                        const end = DateTime.fromISO(r.end_date_time.iso_string);

                        // Calculate weekday and weekend hours
                        let current = start;
                        while (current < end) {
                            const next = current.plus({ days: 1 }).startOf('day');
                            const segmentEnd = next < end ? next : end;

                            if (current.weekday >= 1 && current.weekday <= 5) {
                                const diff = segmentEnd.diff(current, ['hours', 'minutes']);
                                weekdayHours.hours += diff.hours;
                                weekdayHours.mins += diff.minutes;
                            } else {
                                const diff = segmentEnd.diff(current, ['hours', 'minutes']);
                                weekendHours.hours += diff.hours;
                                weekendHours.mins += diff.minutes;
                            }

                            current = segmentEnd;
                        }

                        hoursData.push(r);
                    }
                });

                // Normalize minutes to hours
                if (weekdayHours.mins > 59) {
                    let hours_to_add = Math.floor(weekdayHours.mins / 60);
                    weekdayHours.hours += hours_to_add;
                    weekdayHours.mins -= hours_to_add * 60;
                }

                if (weekendHours.mins > 59) {
                    let hours_to_add = Math.floor(weekendHours.mins / 60);
                    weekendHours.hours += hours_to_add;
                    weekendHours.mins -= hours_to_add * 60;
                }

                records.push({
                    name: `${employee.first_name} ${employee.last_name}`,
                    weekdayHours,
                    weekendHours,
                    miles: employee.timesheets.reduce((mileage, record) => mileage + Number(record.mileage ?? 0), 0),
                    sleepIns: employee.timesheets.reduce((sleepsIn, record) => sleepsIn + Number(record.sleeps_in ?? 0), 0),
                    annualLeave: employee.timesheets?.filter(row => row.type === 'holiday').reduce((leave, row) => {
                        row.days_breakdown?.forEach(day => {
                            const d = DateTime.fromSQL(day);
                            if (d >= fromDate && d <= toDate) {
                                leave += Number(employee.hours_per_day);
                            }
                        });
                        return leave;
                    }, 0)?.toFixed(2),
                });
            });

            this.setState({
                apiData: response.data.data,
                records,
                loading: false,
            });
        }
    }    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { filters, loading, records } = this.state;

        return (
            <AdminLayout title="Pay Summary" titleIcon={faPoundSign}>
            <div className="px-4">
                {loading && (
                    <Loading />
                )}

                {!loading && (
                    <>
                        <div className="flex justify-end gap-2 mb-6">
                            <div className="flex flex-row gap-2">
                                <PrimaryButton
                                    onClick={() => {
                                        Modal.open({
                                            component: FilterModal,
                                            props: {
                                                columns: this.filterColumns,
                                                filters,
                                                handleFiltersCallback: (filters) => this.setState({filters})
                                            }
                                        });
                                    }}
                                    text="Filter"
                                />
                            </div>
                        </div>

                        <div className="bg-gray-200 rounded-lg my-6 p-2">
                            <table className="table-auto mx-auto">
                                <thead>
                                <tr className="bg-gray-300">
                                    <th className="px-4 py-2 border border-app-leading text-center">Name</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Weekday Hours</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Weekend Hours</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Miles</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Sleep In</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Annual Leave</th>
                                </tr>
                                </thead>

                                <tbody>
                                {records?.map((record, key) => (
                                    <tr className="bg-white" key={key}>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            {record.name}
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            {record.weekdayHours.hours}h {record.weekdayHours.mins}m
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            {record.weekendHours.hours}h {record.weekendHours.mins}m
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            {Number(record.miles).toFixed(2)}
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            {record.sleepIns}
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            {record.annualLeave}h
                                        </td>
                                    </tr>
                                ))}

                                {this.renderTotals()}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </AdminLayout>
        );
    }
    /**
     * @method renderTotals
     * @return {JSX.Element|null}
     */
    renderTotals = () => {
        const { records } = this.state;

        // Calculate total weekday hours and minutes
        let totalWeekdayHours = records.reduce((total, record) => {
            total.hours += record.weekdayHours.hours;
            total.mins += record.weekdayHours.mins;
            return total;
        }, { hours: 0, mins: 0 });

        // Normalize weekday minutes to hours
        if (totalWeekdayHours.mins > 59) {
            let hoursToAdd = Math.floor(totalWeekdayHours.mins / 60);
            totalWeekdayHours.hours += hoursToAdd;
            totalWeekdayHours.mins -= hoursToAdd * 60;
        }

        // Calculate total weekend hours and minutes
        let totalWeekendHours = records.reduce((total, record) => {
            total.hours += record.weekendHours.hours;
            total.mins += record.weekendHours.mins;
            return total;
        }, { hours: 0, mins: 0 });

        // Normalize weekend minutes to hours
        if (totalWeekendHours.mins > 59) {
            let hoursToAdd = Math.floor(totalWeekendHours.mins / 60);
            totalWeekendHours.hours += hoursToAdd;
            totalWeekendHours.mins -= hoursToAdd * 60;
        }

        return (
            <tr>
            <td></td>
            <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                {totalWeekdayHours.hours}h {totalWeekdayHours.mins}m
            </td>
            <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                {totalWeekendHours.hours}h {totalWeekendHours.mins}m
            </td>
            <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                {records.reduce((miles, record) => miles + Number(record.miles ?? 0), 0)?.toFixed(2)}
            </td>
            <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                {records.reduce((sleepIns, record) => sleepIns + Number(record.sleepIns ?? 0), 0)}
            </td>
            <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                {records.reduce((annualLeave, record) => annualLeave + Number(record.annualLeave ?? 0), 0)?.toFixed(2)}
            </td>
        </tr>
        );
    };}

export default PaySummary;

import React from "react";

const Section = ({ children }) => {
    return (
        <div className="p-6 border border-gray-300 sm:rounded-md">
            { children }
        </div>
    );
};

export default Section;

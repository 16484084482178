import React from 'react';
import {DateTime} from 'luxon';

import {User, Socket} from 'Services';
import NotificationsApi from 'Services/Api/Comms/Notifications';

export default class Notifications extends React.Component {
    /**
     * @var state
     * @type {{notifications: []}}
     */
    state = {
        working: true,
        notifications: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.componentConstructor();
    };

    /**
     * @method componentConstructor
     */
    componentConstructor = () => {
        this.fetchNotifications();

        Socket.getConnection()
            .private(`App.Models.User.${User.id}`)
            .notification((notification) => {
                const {notifications} = this.state;

                notifications.data.unshift(notification);

                this.setState({
                    notifications
                });
            });
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        this.componentDestructor();
    };

    /**
     * @method componentDestructor
     */
    componentDestructor = () => {
        Socket.getConnection().leave(`App.Models.User.${User.id}`);
    };

    /**
     * @method fetchNotifications
     * @param {number} page
     * @return {Promise<void>}
     */
    fetchNotifications = async (page = 1) => {
        this.setState({working: true});

        const request = await NotificationsApi.get(null, {
            page
        });

        if (request.success) {
            this.setState({
                notifications: request.data,
                meta: request.data.meta,
                working: false
            });
        }
    };

    /**
     * @method handleReadNotification
     * @param {string} id
     */
    handleReadNotification = (id) => {
        const {notifications} = this.state;

        const i = notifications.data.map(n => n.id).indexOf(id);

        if (i !== -1) {
            notifications.data[i].read_at = DateTime.local();

            this.setState({
                notifications
            });
        }
    };
}

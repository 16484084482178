import React from 'react';

import SettingsPage from 'Pages/Settings/SettingsPage';

import Table from 'Components/HumanResources/Timesheets/Table';

export default class Absences extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <SettingsPage>
                <Table
                    type='sick'
                    title={'My HR ' + window.base.hr_timesheet_types['sick']?.label + 's'}
                    admin={false}
                />
            </SettingsPage>
        );
    }
}

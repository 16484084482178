import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, SecondaryButton} from 'Components/Button';

import {translation} from 'Services/TranslationHelpers';
import { Select } from "Components/Form/index";
import AdminUsersApi from "Services/Api/Admin/HumanResources/Users";

export default class FilterModal extends React.Component {
    /**
     * @var state
     */
    state = {
        filters: this.props.filters || {}, // Ensure filters is initialized
    }

    /**
     * @method handleFiltersKey
     * @param {string} key
     * @param {string} value
     */
    handleFiltersKey = (key, value) => {
        const {filters} = this.state;

        this.setState({
            filters: {
                ...filters,
                [key]: value
            }
        });
    };

    /**
     * @method handleUpdate
     */
    handleUpdate = () => {
        const {onClose, handleFiltersCallback} = this.props;
        const {filters} = this.state;

        handleFiltersCallback(filters);

        onClose();
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {onClose, columns} = this.props;
        const {filters} = this.state;

        return (
            <Modal>
                <ModalHeader onClose={onClose}>
                    Filter
                </ModalHeader>

                <ModalBody>
                    {columns.map((column, i) =>
                        column.filters && column.filters.map((filter, j) => {
                            let Filter = filter.component;

                            return (
                                <div className="mb-4 text-black" key={j+'_'+i}>
                                    <Filter
                                        containerClassName="w-full"
                                        onChange={(column, value) => this.handleFiltersKey(column, value)}
                                        filters={filters}
                                        column={filter.column}
                                        props={filter.props}
                                        label={filter.label}
                                        defaultMethod={filter.defaultMethod}
                                    />
                                </div>
                            );
                        })
                    )}

                    <Select
                        containerClassName="mb-4"
                        label="Staff Member"
                        id="employee_id"
                        value={filters.employee_id}
                        onChange={(v) => this.handleFiltersKey('employee_id[int]', v)}
                        isAsync
                        searchCallback={(data) => AdminUsersApi.get(null, data)}
                        searchLabelKey="full_name"
                        allowNull={true}
                    />

                    <div className="flex justify-center items-center mt-4">
                        <PrimaryButton
                            text={translation('misc', 'update')}
                            onClick={this.handleUpdate}
                        />
                    </div>
                </ModalBody>

                <ModalFooter alignment="center">
                    <SecondaryButton
                        text={translation('misc', 'cancel')}
                        onClick={onClose}
                    />
                </ModalFooter>
            </Modal>
        )
    }
};

import React from 'react';

// TODO work out a more efficient way of doing this than importing all layouts.

import AuthenticatedDefault from 'Components/Layouts/Default/Authenticated';
import AuthenticatedTopMenu from 'Components/Layouts/TopMenu/Authenticated';
import AuthenticatedCornerMenu from 'Components/Layouts/CornerMenu/Authenticated';
import AuthenticatedSideMenu from 'Components/Layouts/SideMenu/Authenticated';
import AuthenticatedTTS from 'Components/Layouts/TTS/Authenticated';

export default class Authenticated extends React.Component {
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        switch(window.base.site_layout) {
            case 'top-menu':
                return (
                    <AuthenticatedTopMenu
                        {...this.props}
                    />
                );
            case 'corner-menu':
                return (
                    <AuthenticatedCornerMenu
                        {...this.props}
                    />
                );
            case 'side-menu':
                return (
                    <AuthenticatedSideMenu
                        {...this.props}
                    />
                );
            case 'tts':
                return (
                    <AuthenticatedTTS
                        {...this.props}
                    />
                );
            default:
                return (
                    <AuthenticatedDefault
                        {...this.props}
                    />
                );
        }
    };
}

import React from 'react';

import {ActiveUsersService} from 'Components/Networking';

import {Event} from 'Services';
import {getAvatarUrl} from 'Services/AuthHelpers';

export default class ProfilePic extends React.Component {
    /**
     * @var state
     * @type {{activeUserIds: array}}
     */
    state = {
        activeUserIds: []
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        this.handleActiveUsers();
    }

    /**
     * @method activeUserIds
     */
    handleActiveUsers = () => {
        this.setState({
            activeUserIds: ActiveUsersService.getActiveUserIds()
        });

        Event.on('networking-active-users', () => {
            this.setState({
                activeUserIds: ActiveUsersService.getActiveUserIds()
            });
        });
    }

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {activeUserIds} = this.state;
        const {user, hide_active_status = false, size = 'regular'} = this.props;

        let styleSize = '3rem';
        if (size === 'small') {
            styleSize = '2.5rem';
        }

        return (
            <div className="relative inline-block">
                <img
                    src={getAvatarUrl(user)}
                    className="profile-pic object-cover rounded-3xl"
                    style={{
                        minWidth: styleSize,
                        minHeight: styleSize,
                        maxWidth: styleSize,
                        maxHeight: styleSize
                    }}
                />

                {user.type === 'App\\Models\\User' &&
                    <span className={`
                        absolute bottom-0 left-9 text-center text-networking-primary-text p-1.5 rounded-lg
                        ${hide_active_status === true ?
                            '' :
                            (activeUserIds.indexOf(user.id) > -1 ? 'bg-networking-active' : 'bg-networking-inactive')}
                    `}/>
                }
            </div>
        );
    }
}

import React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import luxonTimezonePlugin from "@fullcalendar/luxon";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import { DateTime } from "luxon";

import { Card, CardBody } from "Components/Card";

import SettingsPage from 'Pages/Settings/SettingsPage';

import { Settings, Toast } from 'Services';
import HolidayCalendarApi from "Services/Api/HumanResources/HolidayCalendar";


export default class AnnualLeaveCalendar extends React.Component {
    /**
     * @var state
     * @type {{events: []}}
     */
    state = {
        events: [],
        calendarKey: 0,
        calendarView: {
            view: 'timeGridWeek',
            date: new Date,
        },
    }

    /**
     * @method fetchTimesheets
     * @param {object} [data]
     * @return {Promise<array>}
     */
    fetchTimesheets = async (data) => {
        const {admin} = this.props;
        const {filters, user_ids} = this.state;

        if (!data) {
            data = {
                calendar: DateTime.now().toUTC().toFormat('yyyy-MM-dd'),
            };
        }

        const response = await HolidayCalendarApi.get(null, {
            ...data,
            ...filters,
            types: 'holiday',
        });

        if (response.success) {
            return response.data.data.map((timesheet) => {
                const {id, type, start_date_time, end_date_time, all_day, employee, status} = timesheet;
                let end = null;

                if (end_date_time && type === 'holiday') {
                    const endDateTime = DateTime.fromISO(end_date_time.iso_string);
                    if (endDateTime.hour === 23 && endDateTime.minute === 59 && endDateTime.second === 59) {
                        end = endDateTime.plus({day: 1}).startOf('day').toISO();
                    } else {
                        end = end_date_time.iso_string;
                    }
                } else {
                    end = end_date_time?.iso_string;
                }
                return {
                    id,
                    title: this.getEventTitle(timesheet),
                    start: start_date_time.iso_string,
                    end,
                    display: 'block',
                    allDay: true,
                    constraint: admin ? null : 'blocked',
                    timesheet,
                    backgroundColor: type === 'sick' ? '#808080' : (employee?.colour ?? status.colour)
                };
            });
        }

        Toast.error();

        return [];
    };

    /**
     * @method componentDidMount
     * @param timesheet
     * @returns {*}
     */
    getEventTitle = (timesheet) => {
        return timesheet.title;

        const {employee, service_user, type, description, sleeps_in} = timesheet;

        let eventTitle = window.base.hr_timesheet_types[type]?.label;

        if (employee) {
            eventTitle += ' - ' + employee.full_name;
        }

        if (service_user) {
            eventTitle += ' - ' + service_user.full_name;
        }

        if (sleeps_in) {
            eventTitle += ' Sleep in';
        }

        if (description) {
            eventTitle += ' <i>(' + description + ')</i>';
        }

        return eventTitle;
    }

    /**
     * @method getDiaryData
     */
    fetchHolidayEvents = async (info, successCallback) => {
        const timesheets = await this.fetchTimesheets({
            after: info.start,
            before: info.end,
        });

        successCallback(timesheets);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { calendarView, calendarKey } = this.state;

        let locale = Settings.data.language;

        if (locale === 'en') {
            locale = 'en-gb';
        }

        return (
            <SettingsPage title="Annual Leave Calendar" titleIcon={ faCalendar }>
                <Card>
                    <CardBody>
                        <FullCalendar
                            headerToolbar={{
                                start: 'prev next',
                                center: 'title',
                                end: 'today timeGridWeek dayGridMonth',
                            }}
                            initialView={calendarView.view}
                            initialDate={calendarView.date}
                            plugins={[luxonTimezonePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            events={this.fetchHolidayEvents}
                            datesRender={this.getDiaryData}
                            eventTimeFormat={{
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }}
                            firstDay={1}
                            locales={allLocales}
                            locale={locale}
                            timeZone={Settings.data.timezone}
                            key={calendarKey}
                            datesSet={(arg) => {
                                this.setState({
                                    calendarView: {
                                        view: arg.view.type,
                                        date: arg.startStr,
                                    },
                                });
                            }}
                        />
                    </CardBody>
                </Card>
            </SettingsPage>
        );
    }
}

import React from 'react';
import {faBusinessTime} from '@fortawesome/free-solid-svg-icons/faBusinessTime';

import {Select} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';
import CreateTimesheetModal from 'Components/HumanResources/Timesheets/CreateTimesheetModal';
import FilterModal from 'Components/Utilities/FilterModal';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {Modal, User} from 'Services';
import {getColumns} from 'Services/HrHelpers';
import AdminUsersApi from 'Services/Api/Admin/HumanResources/Users';

import IndexUser from './IndexUser';

export default class TimeSheets extends React.Component {
    /**
     * @var data_types
     * @type {array}
     */
    data_types = [
        {
            label: 'Records',
            value: 'records'
        },
        {
            label: 'Rates',
            value: 'rates'
        }
    ];

    /**
     * @var state
     */
    state = {
        user_ids: null,
        data_type: 'records',
        filters: {
            'end_date_time[afterDateTime]': new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, User.data.active_team?.configs?.pay_period?.start ?? 26)), // Default to 26th of last month.
            'start_date_time[beforeDateTime]': new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), User.data.active_team?.configs?.pay_period?.end ?? 25)) // Default to 25th of current month.
        },
        columns: getColumns(true).filter((c) => c.label !== 'Employee')
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {user_ids, data_type, filters, columns} = this.state;

        return (
            <AdminLayout title="HR Timesheets" titleIcon={faBusinessTime}>
                <div className="px-4">
                    <div className="flex justify-between gap-2 mb-6">
                        {window.base.features.rates &&
                            <Select
                                containerClassName="w-48"
                                id="data_type"
                                value={data_type}
                                onChange={(v) => this.setState({data_type: v})}
                                options={this.data_types}
                            />
                        }

                        <div className="flex flex-row gap-2">
                            <PrimaryButton
                                onClick={() => {
                                    Modal.open({
                                        component: FilterModal,
                                        props: {
                                            columns,
                                            filters,
                                            handleFiltersCallback: (filters) => this.setState({filters})
                                        }
                                    });
                                }}
                                text="Filter"
                            />

                            <PrimaryButton
                                onClick={() => {
                                    Modal.open({
                                        component: CreateTimesheetModal,
                                        props: {
                                            type: 'time_worked',
                                            admin: true,
                                        }
                                    });
                                }}
                                text="Create"
                            />
                        </div>
                    </div>

                    <Select
                        containerClassName="mb-6"
                        id="user_ids"
                        value={user_ids}
                        onChange={(v) => this.setState({user_ids: v})}
                        isAsync
                        isMulti
                        searchCallback={(data) => AdminUsersApi.get(null, data)}
                        searchLabelKey="full_name"
                        allowNull={false}
                        placeholder="Select a user ..."
                        colouredOptions={true}
                    />

                    {user_ids && user_ids.map((user_id, i) => (
                        <div className="bg-gray-200 rounded-lg my-6 p-2">
                            <IndexUser
                                userId={user_id}
                                filters={filters}
                                dataType={data_type}
                                columns={columns}
                                key={user_id}
                            />
                        </div>
                    ))}

                    {!user_ids &&
                        <p className="text-center font-bold my-8">
                            Select a user above
                        </p>
                    }
                </div>
            </AdminLayout>
        );
    }
}

import Settings from 'Services/Settings';

/**
 * @method translationFromJson
 * @param {object} data
 * @return {string}
 */
const translationFromJson = (data) => {
    if (!data) {
        console.error(
            "Invalid translation data"
        );
        return;
    }

    if (data[Settings.data.language]) {
        return data[Settings.data.language];
    }

    // Fallback to another language.
    for (const language in window.base.languages) {
        if (data[language]) {
            return data[language];
        }
    }

    console.error(
        "No translation value found in json data for any language"
    );
}

export {
    translationFromJson
}

import React from 'react';

import {Alert, Loading} from 'Components/Partials';
import {Toggle} from 'Components/Form';
import {PrimaryButton, DangerButton} from 'Components/Button';

import NotificationSettingsApi from 'Services/Api/Comms/NotificationSettings';
import AdminNotificationSettingsApi from 'Services/Api/Admin/Comms/NotificationSettings';

export default class NotificationSettings extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        alert: null,
        channels: null,
        settings: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.loadChannels();
        await this.loadSettings();
    }

    /**
     * @method loadChannels
     */
    loadChannels = async () => {
        const response = await NotificationSettingsApi.getAvailableChannels();

        this.setState({
            channels: response.data,
        });
    }

    /**
     * @method loadSettings
     */
    loadSettings = async () => {
        const {admin, userId} = this.props;

        if (admin) {
            var response = await AdminNotificationSettingsApi.get(null, {
                user_id: userId
            });
        } else {
            var response = await NotificationSettingsApi.get(null);
        }

        this.setState({
            settings: response.data.data,
            working: false,
        });
    }

    /**
     * @method handleUpdate
     * @param {object} setting
     * @param {boolean} active
     */
    handleUpdate = async (setting, active) => {
        const {settings} = this.state;

        this.setState({
            working: true,
            alert: null,
        });

        const response = await NotificationSettingsApi.patch(setting.id, {
            active
        });

        if (response.status !== 200) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message
                },
                working: false
            });
            return;
        }

        this.setState({
            settings: settings.map((o) => {
                if (o.id === setting.id) {
                    o.active = active;
                }

                return o;
            }),
            working: false,
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, alert, channels, settings} = this.state;

        return (
            <div>
                {working && (<Loading />)}

                {alert !== null && (<Alert {...alert} />)}

                {!working &&
                    <>
                        {Object.entries(channels).map((channel, i) => (
                            this.renderChannel(channel, i)
                        ))}
                    </>
                }
            </div>
        );
    }

    /**
     * @method renderChannel
     * @param {object} channel
     * @param {integer} key
     * @return {JSX.Element}
     */
    renderChannel(channel, key) {
        const {admin} = this.props;
        const {settings} = this.state;

        let channel_key = channel[0];
        let channel_name = channel[1].name;

        let channelSettings = settings.filter(o => o.channel === channel_key);

        return (
            <div className="mb-8" key={key}>
                <p className="capitalize font-bold mb-4">
                    {channel_name}
                </p>

                {!channelSettings || channelSettings.length === 0 &&
                    <p>
                        There are no notification settings for the {channel_name} channel.
                    </p>
                }

                {channelSettings && channelSettings.length > 0 && channelSettings.map((setting, j) => (
                    <div className="flex flex-row gap-4" key={j}>
                        {setting.setting_name}

                        <Toggle
                            value={setting.active}
                            onChange={(v) => this.handleUpdate(setting, v)}
                            disabled={setting.locked || admin}
                        />
                    </div>
                ))}
            </div>
        );
    }
}
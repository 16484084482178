import React from 'react';

import {Loading, Alert} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {FormHandler, Select, Toggle, DatePicker, Textarea} from 'Components/Form';

import StoreMedicationDetails from 'Pages/ServiceUsers/MedicationDetails/store';

import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';
import MedicationsApi from 'Services/Api/ServiceUsers/ServiceUserMedications';
import MedicationsTakenApi from 'Services/Api/ServiceUsers/ServiceUserMedicationsTaken';

import {translation} from 'Services/TranslationHelpers';

import MedicationInfo from './MedicationInfo';
import { DateTime } from "luxon";

class Store extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Successfully updated.';

    /**
     * @var state
     */
    state = {
        working: true,
        service_user_id: null,
        medications: null,
        nullMessage: false,
        showPrn: false,
    };

    marsStatus = [
        {
            label: 'Administered',
            value: 'administered',
        },
        {
            label: 'Family',
            value: 'family',
        },
        {
            label: 'Absent',
            value: 'absent',
        },
        {
            label: 'Taken Independently',
            value: 'taken-independently',
        },
        {
            label: 'Refused',
            value: 'refused',
        }
    ];

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.props.service_user_id) {
            this.handleUpdateServiceUser(this.props.service_user_id);
        } else {
            this.setState({
                working: false
            });
        }
    }

    /**
     * @method componentDidUpdate
     */
    handleTogglePrn = () => this.setState({showPrn: !this.state.showPrn});

    /**
     * @method handleUpdateServiceUser
     * @param {string} service_user_id
     */
    handleUpdateServiceUser = async (service_user_id) => {
        const {setForm} = this.props;

        this.setState({
            working: true
        });

        // Get medications for this service user.
        const response = await MedicationsApi.get(null, {
            service_user_id,
            paginate: false,
            date: this.getDateString()
        });

        if (response.data.data.length === 0) {
            // If no medications then set the form to null.
            setForm(null, true);
        } else {
            // Set the medications in the form.
            setForm(response.data.data.map((medication) => ({
                medication: medication,
                service_user_medication_id: medication.id,
                service_user_medication_taken_id: medication.taken?.id ?? null,
                taken: medication.taken ? true : false,
                date_time: medication.taken?.date_time ?? this.getDateTime(medication),
                notes: medication.taken?.notes ?? null,
                mars_version: medication.taken?.mars_version ?? 2,
                status: medication.taken?.status,
            })), true);
        }

        this.setState({
            working: false,
            service_user_id,
            nullMessage: response.data.data.length === 0
        });
    }

    /**
     * @method getDateString
     * @return {string}
     */
    getDateString = () => {
        return this.props.date.toISOString().split('T')[0];
    }

    /**
     * @method getDateTime
     * @param {object} medication
     * @return {Date}
     */
    getDateTime = (medication) => {
        if (medication?.medication_time) {
            const split = medication.medication_time.split(':');

            return DateTime
                .fromJSDate(this.props.date)
                .set({hour: split[0], minute: split[1]})
                .toJSDate();
        }

        const {date} = this.props;

        let datetime = new Date(date.getTime());

        switch(medication.time) {
            case 'morning':
                datetime.setHours(9);
                break;
            case 'afternoon':
                datetime.setHours(12);
                break;
            case 'evening':
                datetime.setHours(17);
                break;
        }

        datetime.setMinutes(0);

        return datetime;
    }

    /**
     * @method handleInput
     * @param {object} form
     * @return {Promise<void>}
     */
    handleInput = (medication_id, key, value) => {
        let {form, setForm} = this.props;

        Object.entries(form).forEach((object, i) => {
            if (object[1].service_user_medication_id === medication_id) {
                form[i][key] = value;
            }
        });

        setForm(form);
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<void>}
     */
    handleSubmit = (form) => {
        return MedicationsTakenApi.post(null, form);
    };

    /**
     * @method handleSuccess
     */
    handleSuccess = () => {
        this.props.onClose();
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {service_user_id, nullMessage, showPrn} = this.state;
        const {form, handleInput, handleSubmit, working, alert, date} = this.props;

        let thClassName = 'px-4 py-2 border border-app-leading text-center';
        let tdClassName = 'px-4 py-2 border border-app-leading text-center';

        return (
            <Modal size="max-w-screen-xl">
                <ModalHeader onClose={this.props.onClose}>
                    {translation('service_users', 'record_medications_taken')} - {date.toLocaleDateString()}
                </ModalHeader>

                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.handleSuccess)}>
                    <ModalBody>
                        {working && (<Loading />)}

                        {alert !== null && (<Alert {...alert} />)}

                        {!working &&
                            <>
                                <div className="flex items-center space-x-4">
                                    <div className="flex-1">
                                        <Select
                                            containerClassName="mb-8"
                                            id="service_user_id"
                                            value={service_user_id}
                                            onChange={(v) => this.handleUpdateServiceUser(v)}
                                            isAsync
                                            searchCallback={(data) => ServiceUsersApi.get(null, {
                                                ...data,
                                                enabled: true
                                            })}
                                            searchLabelKey="full_name"
                                            allowNull={false}
                                        />
                                    </div>

                                    <PrimaryButton
                                        onClick={this.handleTogglePrn}
                                        text={showPrn ? 'Hide PRN' : 'Show PRN'}
                                    />
                                </div>

                                {nullMessage &&
                                    <p className="font-bold text-center mt-12 mb-4">
                                        There are no medications listed for this service user.
                                    </p>
                                }

                                {form && Object.keys(form).length !== 0 &&
                                    <div className="overflow-x-auto">
                                        <table className="table-auto mx-auto">
                                            <thead>
                                                <tr>
                                                    <th/>
                                                    <th className={`${thClassName} min-w-60`}>
                                                        Taken
                                                    </th>
                                                    <th className={thClassName}>
                                                        Time
                                                    </th>
                                                    <th className={thClassName}>
                                                        Notes
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(form)
                                                    .filter((item) => (!item[1]?.medication?.prn && !item[1]?.medication?.non_prescription) || showPrn)
                                                    .map((object, i) => {
                                                        let medication = object[1];
                                                    let id = medication.service_user_medication_id;

                                                    return (
                                                        <tr key={i}>
                                                            <td className={tdClassName}>
                                                                <MedicationInfo
                                                                    medication={medication}
                                                                />
                                                            </td>
                                                            <td className={`${tdClassName} min-w-60`}>
                                                                <div className="flex justify-center">
                                                                    {medication.mars_version === 1 && (
                                                                        <Toggle
                                                                            containerClassName="text-left"
                                                                            value={medication.taken}
                                                                            onChange={v => this.handleInput(id, 'taken', v)}
                                                                        />
                                                                    )}

                                                                    {medication.mars_version === 2 && (
                                                                        <Select
                                                                            options={this.marsStatus}
                                                                            containerClassName="text-left w-full"
                                                                            value={medication.status}
                                                                            onChange={async v => {
                                                                                await this.handleInput(id, 'status', v);
                                                                                await this.handleInput(id, 'taken', v !== null);
                                                                            }}
                                                                            isClearable={true}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className={tdClassName}>
                                                                <DatePicker
                                                                    selected={medication.date_time}
                                                                    onChange={v => this.handleInput(id, 'date_time', v)}
                                                                    showTimeSelect={true}
                                                                    showTimeSelectOnly={true}
                                                                    timeIntervals={5}
                                                                />
                                                            </td>
                                                            <td className={tdClassName}>
                                                                <Textarea
                                                                    value={medication.notes}
                                                                    onChange={v => this.handleInput(id, 'notes', v)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {service_user_id &&
                                    <StoreMedicationDetails
                                        service_user_id={service_user_id}
                                        readOnly={true}
                                        key={service_user_id}
                                    />
                                }
                            </>
                        }
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton
                            text="Submit"
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler(Store);

import React from 'react';
import { DateTime } from "luxon";

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton} from 'Components/Button';
import {Alert, Loading} from 'Components/Partials';
import {DatePicker, FormHandler, Input, Label} from 'Components/Form';
import {HrTitle} from 'Components/HumanResources/AdminComponents';

import UserSettingsApi from 'Services/Api/Admin/HumanResources/UserSettings';

import Base from '../Base';

class UserSettings extends Base {
    /**
     * @var success
     * @type {string}
     */
    success = 'Successfully updated.';

    /**
     * @var state
     */
    state = {
        loading: true,
        user: null,
    };

    /**
     * @method componentDidMount
     */
    async componentDidMount() {
        await this.setUser();
        await this.loadSettings();
    };

    /**
     * @method loadSettings
     */
    loadSettings = async () => {
        const {setForm} = this.props;

        this.setState({
            loading: true,
        });

        const response = await UserSettingsApi.get({
            user: this.state.user.id
        });

        if (response.success) {
            let data = response.data.data;

            setForm({
                id: data.id,
                holiday_allowed: data.holiday_allowed ?? 0,
                holiday_needs_approval: data.holiday_needs_approval ?? false,
                sick_days_allowed: data.sick_days_allowed ?? 0,
                sick_days_needs_approval: data.sick_days_needs_approval ?? false,
                default_working_minutes: data.default_working_minutes ?? 0,
                days_per_week: data.days_per_week ?? 0,
                hours_per_day: data.hours_per_day ?? 0,
                working_days: data.working_days ?? [],
                ignore_bank_holidays: data.ignore_bank_holidays ?? false,
                time_worked_needs_approval: data.time_worked_needs_approval ?? false,
                user_cannot_edit_submitted_timesheet: data.user_cannot_edit_submitted_timesheet ?? false,
                start_date: data.start_date ? DateTime.fromISO(data.start_date).toJSDate() : null,
            });

            this.setState({
                loading: false,
            });
        }
    };

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<void>}
     */
    handleSubmit = (form) => {
        return UserSettingsApi.patch({
            user: this.state.user.id,
            userSetting: form.id,
        }, form);
    };

    /**
     * @method handleSuccess
     * @param response
     * @returns {Promise<void>}
     */
    handleSuccess = async (response) => {
        this.loadSettings();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {user, loading} = this.state;
        const {form, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <AdminLayout>
                {loading && (<Loading/>)}

                {!loading &&
                    <div className="divide-y divide-gray-200">
                        <div className="p-6">
                            <HrTitle user={user} title="Settings" />
                        </div>

                        <div className="p-6">
                            <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.handleSuccess, false)}>
                                {alert !== null && (<Alert {...alert} />)}

                                <div className="grid grid-cols-1 gap-4 mb-4">
                                    <Input
                                        label="Annual Sickness Allowance"
                                        type="number"
                                        value={form.sick_days_allowed}
                                        onChange={v => handleInput('sick_days_allowed', v)}
                                    />

                                    <div className="grid grid-cols-4 gap-4">
                                        <Input
                                            label="Annual Holiday Allowance"
                                            type="number"
                                            value={form.holiday_allowed}
                                            onChange={v => handleInput('holiday_allowed', v)}
                                        />

                                        <Input
                                            label="Days Per Week"
                                            type="number"
                                            value={form.days_per_week}
                                            onChange={v => handleInput('days_per_week', v)}
                                        />

                                        <Input
                                            label="Hours Per Day"
                                            type="number"
                                            value={form.hours_per_day}
                                            readOnly={true}
                                            className="!bg-opacity-5"
                                        />

                                        <DatePicker
                                            isClearable
                                            instructions="Leave blank to calculate averages based on last 12 months"
                                            label="Start Date"
                                            selected={form.start_date}
                                            onChange={(d) => handleInput('start_date', d)}
                                        />
                                    </div>

                                    <div className="flex items-center">
                                        <Label label="User Cannot Edit Submitted Timesheet" className="mb-2.5 mt-auto mr-4"/>

                                        <input
                                            type="checkbox"
                                            checked={form.user_cannot_edit_submitted_timesheet}
                                            onChange={v => handleInput('user_cannot_edit_submitted_timesheet', v.target.checked)}
                                            className={
                                                'h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ' +
                                                'appearance-none rounded-md border-gray-500 border mb-2.5 mt-auto ' +
                                                'hover:border-gray-600 focus:border-indigo-500'
                                            }
                                        />
                                    </div>

                                    <div className="flex items-center">
                                        <Label
                                            label={window.base.hr_timesheet_types['holiday']?.label + " Needs Approval"}
                                            className="mb-2.5 mt-auto mr-4"
                                        />

                                        <input
                                            type="checkbox"
                                            checked={form.holiday_needs_approval}
                                            onChange={v => handleInput('holiday_needs_approval', v.target.checked)}
                                            className={
                                                'h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ' +
                                                'appearance-none rounded-md border-gray-500 border mb-2.5 mt-auto ' +
                                                'hover:border-gray-600 focus:border-indigo-500'
                                            }
                                        />
                                    </div>

                                    <div className="flex items-center">
                                        <Label
                                            label={window.base.hr_timesheet_types['sick']?.label + " Needs Approval"}
                                            className="mb-2.5 mt-auto mr-4"
                                        />

                                        <input
                                            type="checkbox"
                                            checked={form.sick_days_needs_approval }
                                            onChange={v => handleInput('sick_days_needs_approval', v.target.checked)}
                                            className={
                                                'h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ' +
                                                'appearance-none rounded-md border-gray-500 border mb-2.5 mt-auto ' +
                                                'hover:border-gray-600 focus:border-indigo-500'
                                            }
                                        />
                                    </div>

                                    {/*
                                    TODO Commented out as not yet being implemented
                                    <Input
                                        label="Typical Working day (minutes)"
                                        type="number"
                                        value={form.default_working_minutes}
                                        onChange={v => handleInput('default_working_minutes', v)}
                                    />
                                    */}

                                    <div className="flex items-center">
                                        <Label
                                            label={window.base.hr_timesheet_types['time_worked']?.label + " Needs Approval"}
                                            className="mb-2.5 mt-auto mr-4"
                                        />

                                        <input
                                            type="checkbox"
                                            checked={form.time_worked_needs_approval}
                                            onChange={v => handleInput('time_worked_needs_approval', v.target.checked)}
                                            className={
                                                'h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ' +
                                                'appearance-none rounded-md border-gray-500 border mb-2.5 mt-auto ' +
                                                'hover:border-gray-600 focus:border-indigo-500'
                                            }
                                        />
                                    </div>

                                    <div className="flex items-center">
                                        <Label label="Ignore UK bank holidays" className="mb-2.5 mt-auto mr-4"/>

                                        <input
                                            type="checkbox"
                                            checked={form.ignore_bank_holidays}
                                            onChange={v => handleInput('ignore_bank_holidays', v.target.checked)}
                                            className={
                                                'h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ' +
                                                'appearance-none rounded-md border-gray-500 border mb-2.5 mt-auto ' +
                                                'hover:border-gray-600 focus:border-indigo-500'
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="w-full flex">
                                    <PrimaryButton
                                        text="Save"
                                        className="ml-auto"
                                        disabled={working}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </AdminLayout>
        );
    }
}

export default FormHandler(UserSettings);

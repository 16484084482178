import React from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';

import Authenticated from 'Components/Layouts/Authenticated';
import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';
import {BooleanFilter, DateTimeFilter} from 'Components/Filters';

import CovidTestEntryApi from 'Services/Api/Covid/CovidTestEntry';

import Store from './store';

export default class Index extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Barcode',
            value: 'barcode',
            type: 'string',
            orderable: 'barcode'
        },
        {
            label: 'Date & Time',
            value: 'date_time',
            type: 'datetime',
            orderable: 'date_time',
            filters: [
                {
                    label: 'Date & Time Before',
                    column: 'date_time',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'before'
                    }
                },
                {
                    label: 'Date & Time After',
                    column: 'date_time',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'after'
                    }
                }
            ]
        },
        {
            label: 'Result',
            value: 'result',
            type: 'boolean_fa',
            orderable: 'result',
            filters: [
                {
                    label: 'Result',
                    column: 'result',
                    component: BooleanFilter
                }
            ]
        },
        {
            label: 'Uploaded To NHS',
            value: 'uploaded_to_nhs',
            type: 'boolean_fa',
            orderable: 'uploaded_to_nhs',
            filters: [
                {
                    label: 'Uploaded To NHS',
                    column: 'uploaded_to_nhs',
                    component: BooleanFilter
                }
            ]
        },
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        return [
            {
                type: "modalTrigger",
                component: Store,
                props: {
                    id: item.id
                },
                text: 'Update'
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'covid test entry',
                    itemId: item.id,
                    onDeleteApi: CovidTestEntryApi.delete
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <Authenticated title="My Covid Test Entries" titleIcon={faCheckCircle}>
                <IndexTable
                    columns={this.columns}
                    storeModal={{
                        component: Store
                    }}
                    loadDataCallback={(data) => CovidTestEntryApi.get(null, data)}
                    rowActions={this.getRowActions}
                    eventApi={CovidTestEntryApi}
                />
            </Authenticated>
        )
    }
}

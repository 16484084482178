import React from 'react';

import {Loading} from 'Components/Partials';

import AdminTimesheetsRatesApi from 'Services/Api/Admin/HumanResources/TimesheetRates';

export default class RatesBreakdownTable extends React.Component {
	/**
     * @var state
     */
    state = {
    	working: true,
        data: null,
        mileage: null,
        mileage_rate: null,
        total: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
    	this.loadTimesheetsRates();
    }

    /**
     * @method loadTimesheetsRates
     */
    loadTimesheetsRates = async () => {
        const response = await AdminTimesheetsRatesApi.get(null, {
        	timesheetIds: this.props.timesheetIds.join(',')
        });

        this.setState({
            working: false,
            data: response.data.data,
            mileage: response.data.mileage,
	        mileage_rate: response.data.mileage_rate,
	        total: response.data.total,
        });
    }

	/**
     * @method render
     * @return {JSX.Element}
     */
    render() {
    	const {working, data, mileage, mileage_rate, total} = this.state;

    	let thClass = 'text-left p-2';
    	let tdClass = 'border border-gray-500 border-collapse px-4 py-2 whitespace-nowrap';

    	return (
    		<div className="overflow-x-auto">
    			{working && (<Loading />)}

				{!working &&
	    			<table className="table-auto">
	                    <thead>
	                        <tr>
	                            <th className={thClass}>
	                                Rate Name
	                            </th>
	                            <th className={thClass}>
	                                Value Per Hour
	                            </th>
	                            <th className={thClass}>
	                                Total Hours
	                            </th>
	                            <th className={thClass}>
	                                Total Value
	                            </th>
	                            <th className={thClass}>
	                                Description
	                            </th>
	                        </tr>
	                    </thead>
	                    <tbody>
	                        {data.map((datum, i) => {
	                            return (
	                                <tr key={i}>
	                                    <td className={tdClass}>
	                                        {datum.rate_name}
	                                    </td>
	                                    <td className={tdClass}>
	                                        £{datum.value_per_hour}
	                                    </td>
	                                    <td className={tdClass}>
	                                        {datum.total_hours}
	                                    </td>
	                                    <td className={tdClass}>
	                                        £{datum.total_value.toFixed(2)}
	                                    </td>
	                                    <td className={tdClass}>
	                                        {datum.description.join(' ')}
	                                    </td>
	                                </tr>
	                            );
	                        })}

	                        <tr>
                                <td className={tdClass}>
                                    Mileage
                                </td>
                                <td className={tdClass}>
                                    
                                </td>
                                <td className={tdClass}>
                                    {mileage} miles
                                </td>
                                <td className={tdClass}>
                                    £{mileage_rate}
                                </td>
                            </tr>

                            <tr>
                            	<td></td>
                            	<td></td>
                            	<td></td>
                                <td className={`${tdClass} font-bold`}>
                                    £{total.toFixed(2)}
                                </td>
                            </tr>
	                    </tbody>
	                </table>
	            }
    		</div>
    	);
    }
}
import React from 'react';

import Unauthenticated from 'Components/Layouts/Unauthenticated';
import AuthLayout from 'Components/Layouts/AuthLayout';
import {FormHandler, Input} from 'Components/Form';
import {Link} from 'Components/Utilities';

import Auth from 'Services/Api/Auth/Auth';
import {translation} from 'Services/TranslationHelpers';

class Login extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, handleInput, getFieldError, handleSubmit, working, alert} = this.props;

        return (
            <Unauthenticated containerClassName="space-y-8 md:space-y-0 flex flex-col md:flex-row h-full md:justify-between p-8 md:px-24">
                <AuthLayout
                    title={translation('auth_frontend', 'login')}
                    backLink={window.base.features.allow_password_resets ? "/password/request" : null}
                    backLinkText={translation('auth_frontend', 'forgot_your_password')}
                    submitText={translation('auth_frontend', 'login')}
                    onSubmit={(e) => handleSubmit(e, Auth.login)}
                    working={working}
                    alert={alert}
                >
                    <Input
                        labelTextColor="text-app-backup"
                        label="Email or Username"
                        value={form.email}
                        error={getFieldError('email')}
                        onChange={v => handleInput('email', v)}
                    />

                    <Input
                        labelTextColor="text-app-backup"
                        label={translation('auth_frontend', 'password')}
                        type="password"
                        value={form.password}
                        error={getFieldError('password')}
                        onChange={v => handleInput('password', v)}
                    />
                </AuthLayout>

                {window.base.features.allow_registrations && (
                    <p className="text-center mt-4">
                        <Link to="/register">
                            {translation('auth_frontend', 'register_here')}
                        </Link>
                    </p>
                )}
            </Unauthenticated>
        );
    }
}

export default FormHandler(Login);
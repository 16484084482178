import React from 'react';
import Unauthenticated from 'Components/Layouts/Unauthenticated';

import {Card, CardBody, CardFooter, CardHeader} from 'Components/Card';
import {FormHandler, Input} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';
import {Alert, Loading} from 'Components/Partials';

import SecureDownloadApi from 'Services/Api/SecureDownload/SecureDownload';
import {translation} from 'Services/TranslationHelpers';

class SecureDownload extends React.Component {
    /**
     * @var state
     * @type {{success: boolean}}
     */
    state = {
        downloading: false,
        indexes: null,
        currentIndex: null,
        success: false,
    };

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const id = this.props.match?.params?.id ?? null;

        return SecureDownloadApi.show(id, form);
    };

    /**
     * @method handleSuccess
     * @param {object} response
     */
    handleSuccess = async (response) => {
        const {form} = this.props;
        const id = this.props.match?.params?.id ?? null;
        let {title, indexes} = response.data;

        title = title.replace(/ /g, "_");
        
        for (var i=0; i<indexes; i++) {
            let currentIndex = i + 1; // 0 indexed
            let fileName = title + (indexes === 1 ? '' : '_' + currentIndex) + '.zip';

            this.setState({
                downloading: true,
                indexes: indexes,
                currentIndex: currentIndex
            });

            await SecureDownloadApi.download(id, {
                ...form,
                index: i
            }, fileName);
        }

        this.setState({
            downloading: false,
            success: true
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, handleInput, handleSubmit, working, alert} = this.props;
        const {downloading, indexes, currentIndex, success} = this.state;

        return (
            <div className="w-screen h-screen flex justify-center items-center bg-light-blue-700">
                <div className="z-10 w-full lg:w-1/3 px-6 lg:px-0">
                    <Card>
                        <CardHeader>
                            {translation('misc', 'secure_download')}
                        </CardHeader>

                        {!success && !downloading &&
                            <form autoComplete="off" onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.handleSuccess)}>
                                <CardBody>
                                    {alert !== null && (<Alert {...alert} />)}

                                    <Input
                                        label={translation('auth_frontend', 'password')}
                                        type="password"
                                        value={form.password}
                                        onChange={v => handleInput('password', v)}
                                    />
                                </CardBody>

                                <CardFooter>
                                    <PrimaryButton
                                        text={translation('misc', 'submit')}
                                        working={working}
                                    />
                                </CardFooter>
                            </form>
                        }

                        {downloading &&
                            <CardBody>
                                <p>
                                    Downloading {currentIndex} of {indexes}.
                                </p>

                                <Loading />
                            </CardBody>
                        }

                        {success &&
                            <CardBody>
                                <p>
                                    Your files have downloaded.
                                </p>
                            </CardBody>
                        }
                    </Card>
                </div>
            </div>
        );
    }
}

export default FormHandler(SecureDownload);

import React from 'react';
import {Link} from "react-router-dom";

import {PrimaryButton} from 'Components/Button';

import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';

import { translation } from 'Services/TranslationHelpers';

export default class ActiveServiceUserButton extends React.Component {
    /**
     * @var state
     */
    state = {
        service_user: null
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.fetchActiveServiceUser();
    }

    /**
     * @method fetchActiveServiceUser
     */
    fetchActiveServiceUser = async () => {
        const {admin, timesheet_id} = this.props;

        let response = await TimesheetsApi.get(null, {
            active: true
        });

        if (response?.data?.data && response.data.data[0] && response.data.data[0].service_user_id) {
            this.setState({
                service_user: response.data.data[0].service_user
            });
        }
    }

    /**
     * @method render
     * @return {*}
     */
    render () {
        const {service_user} = this.state;

        if (!service_user) {
            return null;
        }

        return (
            <Link to={`/service-users/show/${service_user.id}`}>
                <PrimaryButton
                    className="font-league-spartan font-bold text-xl"
                    text={`Active Service User: ${service_user.full_name}`}
                />
            </Link>
        );
    }
} 
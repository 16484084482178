import ApiBase from '../ApiBase';

import {downloadFileFromApi} from 'Services/BaseHelpers';

class SecureDownload extends ApiBase {
    route = '/secure-download';

    /**
     * @method show
     * @param {string} id
     * @param {object} data
     * @return {Promise<boolean|{status: number}>}
     */
    show = async (id, data) => {
        return this.get(
            this.route + '/' + id,
            data,
        );
    };

    /**
     * @method download
     * @param {string} id
     * @param {object} data
     * @param {string} fileName
     * @return {Promise<boolean|{status: number}>}
     */
    download = async (id, data, fileName) => {
        return this.downloadFile(
            this.route + '/download/' + id,
            data,
            fileName
        );
    };
}

export default new SecureDownload();

import Profile from 'Pages/Settings/HrProfile';
import Documents from 'Pages/Settings/HrDocuments';
import Timesheets from 'Pages/Settings/HrTimesheets';
import MyCalendar from 'Pages/Settings/HrTimesheets/Calendar';
import AnnualLeaveCalendar from 'Pages/Settings/HrTimesheets/AnnualLeaveCalendar';
import AppointmentsCalendar from 'Pages/Settings/HrTimesheets/AppointmentsCalendar';
import Absences from 'Pages/Settings/HrTimesheets/Absences';
// import Holidays from 'Pages/Settings/HrTimesheets/Holidays';
import TimeWorked from 'Pages/Settings/HrTimesheets/TimeWorked';
import MyHolidays from 'Pages/Settings/Holidays';

import AdminIndex from 'Pages/Admin/HumanResources';
import AdminProfiles from 'Pages/Admin/HumanResources/Profiles';
import AdminUserSettings from 'Pages/Admin/HumanResources/UserSettings';
import AdminUserRates from 'Pages/Admin/HumanResources/UserRates';
import AdminTimesheets from 'Pages/Admin/HumanResources/Timesheets';
import AdminCalendar from 'Pages/Admin/HumanResources/Timesheets/Calendar';
import AdminAbsences from 'Pages/Admin/HumanResources/Timesheets/Absences';
import AdminHolidays from 'Pages/Admin/HumanResources/Timesheets/Holidays';
import AdminTimeWorked from 'Pages/Admin/HumanResources/Timesheets/TimeWorked';
import AdminDocuments from 'Pages/Admin/HumanResources/Documents';
import AdminPaySummary from 'Pages/Admin/HumanResources/PaySummary';

import AdminHolidayApprovals from 'Pages/Admin/HumanResources/Holiday/Approvals';
import AdminHolidayDashboard from 'Pages/Admin/HumanResources/Holiday/Dashboard';

import AdminRatesIndex from 'Pages/Admin/HumanResourcesRates';
import AdminRatesStore from 'Pages/Admin/HumanResourcesRates/store';

export default [
    {path: '/settings/human-resources/profile', component: Profile, guards: ['auth', 'two-factor-verified', 'group:Staff']},
    {path: '/settings/human-resources/documents', component: Documents, guards: ['auth', 'group:Staff']},
    {path: '/settings/human-resources/timesheets', component: Timesheets, guards: ['auth', 'group:Staff']},
    {path: '/settings/human-resources/my-calendar', component: MyCalendar, guards: ['auth', 'group:Staff']},
    {path: '/settings/human-resources/annual-leave-calendar', component: AnnualLeaveCalendar, guards: ['auth', 'group:Staff']},
    {path: '/settings/human-resources/appointment-calendar', component: AppointmentsCalendar, guards: ['auth', 'group:Staff']},
    {path: '/settings/human-resources/absences', component: Absences, guards: ['auth', 'group:Staff']},
    // {path: '/settings/human-resources/holidays', component: Holidays, guards: ['auth', 'group:Staff']},
    {path: '/settings/human-resources/time-worked', component: TimeWorked, guards: ['auth', 'group:Staff']},
    {path: '/settings/human-resources/holidays', component: MyHolidays, guards: ['auth', 'group:Staff']},

    {path: '/admin/human-resources', component: AdminIndex, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/profile/:user', component: AdminProfiles, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/settings/:user', component: AdminUserSettings, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/user-rates/:user', component: AdminUserRates, feature: 'rates', guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/timesheets', component: AdminTimesheets, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/calendar/:user?', component: AdminCalendar, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/absences/:user?', component: AdminAbsences, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/holidays/:user?', component: AdminHolidays, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/time-worked/:user?', component: AdminTimeWorked, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/documents/:user', component: AdminDocuments, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/pay-summary', component: AdminPaySummary, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/holiday-approvals', component: AdminHolidayApprovals, guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/holiday-dashboard', component: AdminHolidayDashboard, guards: ['has-scope:hr-admin']},

    {path: '/admin/human-resources/rates', component: AdminRatesIndex, feature: 'rates', guards: ['has-scope:hr-admin']},
    {path: '/admin/human-resources/rates/store/:rate?', component: AdminRatesStore, feature: 'rates', guards: ['has-scope:hr-admin']},
];

import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";

import AdminLayout from "Pages/Admin/AdminLayout";

import Table from "Components/HumanResources/Timesheets/Table";

import { User } from "Services";
import AdminTimesheetsApi from "Services/Api/Admin/HumanResources/Timesheets";

class Approvals extends React.Component {
    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        AdminTimesheetsApi.on('updated', User.refresh);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        AdminTimesheetsApi.removeListener('updated', User.refresh);
    };

    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        return (
            <AdminLayout title="Holiday Approvals" titleIcon={faCheck}>
                <div className="px-4">
                    <Table
                        showTotal={false}
                        defaultParams={{
                            pending: true,
                        }}
                        type='holiday'
                        title="Holiday"
                        admin={true}
                    />
                </div>
            </AdminLayout>
        );
    }
}

export default Approvals;

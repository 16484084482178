import React from 'react';
import {Link} from "react-router-dom";

import {faUsers} from '@fortawesome/free-solid-svg-icons/faUsers';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {Loading} from 'Components/Partials';
import {PrimaryButton, SecondaryButton} from 'Components/Button';

import AdminUsersApi from 'Services/Api/Admin/Users';

export default class UsersShow extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        user_id: this.props.match?.params?.user ?? '',
        user: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadUser();
    }

    /**
     * @method loadUser
     * @param {integer} page
     */
    loadUser = async (page = 1) => {
        const {user_id} = this.state;

        this.setState({ working: true });

        const response = await AdminUsersApi.get(user_id);

        this.setState({
            working: false,
            user: response.data.data,
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, user_id, user} = this.state;

        let thStyle = 'border px-4 py-2 bg-gray-300';
        let tdStyle = 'border px-4 py-2';

        return (
            <AdminLayout title="User" titleIcon={faUsers}>
                <div className="mx-6">
                    {working && (<Loading />)}

                    {!working &&
                        <table className="table-fixed mx-auto">
                            <tbody>
                                <tr>
                                    <th className={thStyle}>First Name</th>
                                    <td className={tdStyle}>{user.first_name}</td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Last Name</th>
                                    <td className={tdStyle}>{user.last_name}</td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Email</th>
                                    <td className={tdStyle}>{user.email}</td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Username</th>
                                    <td className={tdStyle}>{user.username}</td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Colour</th>
                                    <td className={tdStyle}>
                                        <div
                                            className="w-10 h-10 border"
                                            style={{backgroundColor: user.colour}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Enabled</th>
                                    <td className={tdStyle}>{user.enabled ? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Admin</th>
                                    <td className={tdStyle}>{user.is_admin ? 'Yes' : 'No'}</td>
                                </tr>
                                {window.base.features.groups &&
                                    <tr>
                                        <th className={thStyle}>Group</th>
                                        <td className={tdStyle}>
                                            {user.groups?.map((group) => (<p>{group.name}</p>))}
                                        </td>
                                    </tr>
                                }
                                {user.connected_service_user_id &&
                                    <tr>
                                        <th className={thStyle}>Connected Service User</th>
                                        <td className={tdStyle}>{user.connected_service_user.full_name}</td>
                                    </tr>
                                }

                                {user.relationship_to_connected_service_user &&
                                    <tr>
                                        <th className={thStyle}>Relationship To Service User</th>
                                        <td className={tdStyle}>{user.relationship_to_connected_service_user}</td>
                                    </tr>
                                }
                                <tr>
                                    <th className={thStyle}>Email Verified</th>
                                    <td className={tdStyle}>
                                        {user.email_verified_at?.date_time_timezone ?? (user.must_verify_email ? 'No' : 'N/A')}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Last Login Date</th>
                                    <td className={tdStyle}>{user.last_login_date?.date_time_timezone}</td>
                                </tr>
                                {user.settings && Object.entries(user.settings).map((value, key) => (
                                    <tr key={key}>
                                        <th className={thStyle}>
                                            {value[0]}
                                        </th>
                                        <td className={tdStyle}>
                                            {value[0] === 'language' ? window.base.languages[value[1]] : value[1]}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <th className={thStyle}>Notes</th>
                                    <td className={tdStyle}>{user.notes}</td>
                                </tr>
                                <tr>
                                    <th className={thStyle}>Created At</th>
                                    <td className={tdStyle}>{user.created_at.date_time_timezone}</td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>

                <div className="p-6 flex justify-center gap-4">
                    <Link to="/admin/users">
                        <SecondaryButton type="button" text="Back" />
                    </Link>

                    <Link to={"/admin/users/store/" + user_id}>
                        <PrimaryButton
                            text="Update"
                        />
                    </Link>
                </div>
            </AdminLayout>
        )
    }
}
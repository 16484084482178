import React from 'react';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, FileUpload} from 'Components/Form';
import {Alert} from 'Components/Partials';

import AdminTestsApi from 'Services/Api/Admin/Tests';

class StorageTest extends React.Component {
    /**
     * @var state
     */
    state = {
        url: null
    };

    /**
     * @method handleSuccess
     */
    handleSuccess = async (response) => {
        if (response.status === 200) {
            this.setState({
                url: response.url
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, alert, form, handleInput, handleSubmit} = this.props;
        const {url} = this.state;

        return (
            <div className="p-4 bg-gray-300 rounded">
                <p className="font-bold flex flex-row items-center gap-4 mb-4">
                    Storage Test
                </p>

                <form
                    autoComplete="off"
                    onSubmit={(e) => handleSubmit(e, AdminTestsApi.storage, this.handleSuccess, true)}
                >
                    {alert !== null && (<Alert {...alert} />)}

                    <FileUpload
                        value={form.file}
                        onChange={v => handleInput('file', v)}
                        accept="image/*"
                        placeholder="Select image"
                    />

                    <PrimaryButton
                        className="mt-4"
                        text={url ? "Uploaded - Image should show below" :  "Upload"}
                        working={working}
                    />
                </form>

                {url &&
                    <img
                        src={url}
                        className="h-48 mt-4"
                    />
                }
            </div>
        )
    }
}

export default FormHandler(StorageTest);

import React from 'react';
import Axios from 'axios';
import {DateTime} from 'luxon';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin from '@fullcalendar/interaction';
import luxonTimezonePlugin from '@fullcalendar/luxon'; // For https://fullcalendar.io/docs/timeZone

import {Loading} from 'Components/Partials';
import ShowTimesheetModal from 'Components/HumanResources/Timesheets/ShowTimesheetModal';
import {DisplayTimeZone} from 'Components/Settings';
import {PrimaryButton} from 'Components/Button';
import CreateTimesheetModal from 'Components/HumanResources/Timesheets/CreateTimesheetModal';
import FilterModal from 'Components/Utilities/FilterModal';
import {HrTitle} from 'Components/HumanResources/AdminComponents';
import {Select} from 'Components/Form';
import SubscriptionCalendarLink from "Components/HumanResources/Timesheets/SubscriptionCalendarLink";

import {Modal, Toast, Settings} from 'Services';
import {translation} from 'Services/TranslationHelpers';
import {getColumns} from 'Services/HrHelpers';
import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';
import AdminUsersApi from 'Services/Api/Admin/HumanResources/Users';

import Base from './Base';

export default class TimesheetsCalendar extends Base {
    /**
     * @var bankHolidays
     * @type {array|null}
     */
    bankHolidays = null;

    /**
     * @var drag
     * @type {null|object}
     */
    drag = null;

    /**
     * @var state
     */
    state = {
        loading: true,
        events: [],
        settings: null,
        calendarKey: 0,
        user_ids: this.props.userId ? [this.props.userId] : null,
        calendarView: {
            view: this.props.layout === 'dashboard' ? 'timeGridDay' : 'timeGridWeek',
            date: new Date,
        },
        filters: null,
        isMobile: false,
    };

    /**
     * @constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.calendarRef = React.createRef();
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        this.setState({loading: true});

        if (this.props.admin && !this.props.userId) {
            await this.autoSelectAllUsers();
        } else if (!this.props.admin) {
            await this.fetchUserSettings();
        }

        this.setState({loading: false});

        if (this.props.admin) {
            AdminTimesheetsApi.on('updated', this.refreshCalendar);
        } else {
            TimesheetsApi.on('updated', this.refreshCalendar);
        }

        await this.handleMobileCalendar();

        window.addEventListener('resize', this.handleMobileCalendar);
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        if (this.props.admin) {
            AdminTimesheetsApi.removeListener('updated', this.refreshCalendar);
        } else {
            TimesheetsApi.removeListener('updated', this.refreshCalendar);
        }

        window.removeEventListener('resize', this.handleMobileCalendar);
    };

    /**
     * @method handleMobileCalendar
     */
    handleMobileCalendar = () => {
        const {defaultView = 'timeGridWeek'} = this.props;

        const mobileTrigger = 1280;
        const isMobile = window.innerWidth < mobileTrigger;
        const view = isMobile ? 'timeGridDay' : (this.props.layout === 'dashboard' ? 'timeGridDay' : defaultView);

        this.calendarRef
            .current
            .getApi()
            .changeView(view);

        this.setState({
            calendarView: {
                ...this.state.calendarView,
                view,
            },
            isMobile,
        });
    };

    /**
     * @method handleFilters
     * @param {object} filters
     */
    handleFilters = (filters) => {
        this.setState({filters}, () => {
            this.refreshCalendar();
        });
    }

    /**
     * @method autoSelectAllUsers
     */
    autoSelectAllUsers = async () => {
        const {displayUserSelect = true} = this.props;

        if (!displayUserSelect) {
            return;
        }

        const response = await AdminUsersApi.get(null, {
            page: 1,
            per_page: 50
        });

        // If more than one page of users then we do not
        // auto-load them as there are too many.
        if (response.data.meta.last_page !== 1) {
            return;
        }

        this.setState({
            user_ids: response.data.data.map((user) => (
                user.id
            ))
        });
    }

    /**
     * @method refreshCalendar
     */
    refreshCalendar = () => {
        const {calendarKey} = this.state;

        this.setState({
            calendarKey: (calendarKey + 1),
        })
    }

    /**
     * @method fetchEvents
     * @param {object} info
     * @param {mixed} successCallback
     */
    fetchEvents = async (info, successCallback) => {
        const bankHolidays = await this.getBankHolidays();

        const timesheets = await this.fetchTimesheets({
            after: info.start,
            before: info.end,
            approved: true,
        });

        successCallback(bankHolidays.concat(timesheets, [{
            groupId: this.props.admin ? null : 'blocked',
            start: DateTime.now().minus({years: 2}).endOf('day').toISO(),
            end: DateTime.now().toISO(),
            display: 'none',
        }, {
            start: DateTime.now().plus({minutes: 1}).toISO(),
            end: DateTime.now().plus({years: 2}).toISO(),
            display: 'background',
            backgroundColor: '#9b9b9b',
        }]));
    }

    /**
     * @method getBankHolidays
     */
    getBankHolidays = async () => {
        if (this.state.settings?.ignore_bank_holidays) {
            return [];
        }

        if (this.bankHolidays) {
            return this.bankHolidays;
        }

        const holidays = await this.fetchBankHolidays();

        const background = holidays.events.map(({date}) => (
            {
                start: date,
                display: 'background',
                backgroundColor: 'rgba(0,0,0,0.2)'
            }
        ));

        const labels = holidays.events.map(({title, date}) => (
            {
                title,
                start: date,
                display: 'block',
                editable: false,
            }
        ));

        this.bankHolidays = background.concat(labels);

        return background.concat(labels);
    }

    /**
     * @method fetchBankHolidays
     */
    fetchBankHolidays = async () => {
        let cache = localStorage.getItem('bank-holidays');

        if (cache) {
            return JSON.parse(cache);
        }

        const response = await Axios.get('https://www.gov.uk/bank-holidays.json');

        localStorage.setItem('bank-holidays', JSON.stringify(response.data['england-and-wales']));
        return response.data['england-and-wales'];
    }

    /**
     * @method fetchTimesheets
     * @param {object} [data]
     * @return {Promise<array>}
     */
    fetchTimesheets = async (data) => {
        const {admin, service_user_id, displayUserSelect = true} = this.props;
        const {filters, user_ids} = this.state;

        if (!data) {
            data = {
                calendar: DateTime.now().toUTC().toFormat('yyyy-MM-dd'),
            };
        }

        if (admin) {
            if (displayUserSelect && !user_ids) {
                return;
            }

            var response = await AdminTimesheetsApi.get(null, {
                ...data,
                ...filters,
                employee_ids: displayUserSelect ? user_ids.join(',')  : null,
                service_user_id: service_user_id ?? null
            });
        } else {
            var response = await TimesheetsApi.get(null, {
                ...data,
                ...filters,
                service_user_id: service_user_id ?? null
            });
        }

        if (response.success) {
            return response.data.data.map((timesheet) => {
                const {id, type, start_date_time, end_date_time, all_day, employee, status} = timesheet;
                let end = null;

                if (end_date_time && type === 'holiday') {
                    const endDateTime = DateTime.fromISO(end_date_time.iso_string);
                    if (endDateTime.hour === 23 && endDateTime.minute === 59 && endDateTime.second === 59) {
                        end = endDateTime.plus({day: 1}).startOf('day').toISO();
                    } else {
                        end = end_date_time.iso_string;
                    }
                } else {
                    end = end_date_time?.iso_string;
                }

                return {
                    id,
                    title: this.getEventTitle(timesheet),
                    start: start_date_time.iso_string,
                    end,
                    display: all_day ? 'block' : 'list-item',
                    allDay: all_day,
                    editable: true,
                    constraint: admin ? null : 'blocked',
                    timesheet,
                    backgroundColor: type === 'sick' ? '#808080' : (employee?.colour ?? status.colour)
                };
            });
        }

        Toast.error();

        return [];
    };

    /**
     * @method getEventTitle
     * @param {object} timesheet
     * @return {string}
     */
    getEventTitle = (timesheet) => {
        if (timesheet.type === 'holiday') {
            return timesheet.title;
        }

        const {admin} = this.props;
        const {employee, service_user, type, description, sleeps_in} = timesheet;

        let eventTitle = window.base.hr_timesheet_types[type]?.label;

        if (admin && employee) {
            eventTitle += ' - ' + employee.full_name;
        }

        if (service_user) {
            eventTitle += ' - ' + service_user.full_name;
        }

        if (sleeps_in) {
            eventTitle += ' Sleep in';
        }

        if (description) {
            eventTitle += ' <i>(' + description + ')</i>';
        }

        return eventTitle;
    }

    /**
     * @method startDrag
     * @param {object}
     */
    startDrag = ({event: {id, start}}) => {
        this.drag = {id, start};
    }

    /**
     * @method endDrag
     * @param {object}
     */
    endDrag = async ({event, event: {id, start, end, allDay, extendedProps: {timesheet}}}) => {
        const { canCreate = true } = this.props;

        if (!canCreate) {
            return;
        }

        if (id !== this.drag.id || start === this.drag.start) {
            return;
        }

        let new_timesheet = {
            ...timesheet,
            allDay,
            start_date_time: start,
            end_date_time: allDay ? null : end,
        }

        if (this.props.admin) {
            await AdminTimesheetsApi.patch({
                record: id
            }, new_timesheet);
        } else {
            await TimesheetsApi.patch(id, new_timesheet);
        }
    }

    /**
     * @method showEvent
     * @param {object} info
     */
    showEvent = (info) => {
        const {admin} = this.props;

        if (info.event.extendedProps.timesheet) {
            info.jsEvent.preventDefault();

            Modal.open({
                component: ShowTimesheetModal,
                props: {
                    timesheet_id: info.event.extendedProps.timesheet.id,
                    admin
                }
            })
        }
    }

    /**
     * @method handleCreateTimesheet
     * @param {object} args
     */
    handleCreateTimesheet = (args) => {
        const {admin} = this.props;

        Modal.open({
            component: CreateTimesheetModal,
            props: {
                type: 'time_worked',
                admin: admin,
                start_date_time: args.start,
                end_date_time: args.end
            }
        });
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {title, admin, layout, displayUserSelect = true, canCreate = true} = this.props;
        const {loading, user_ids, filters} = this.state;

        if (layout === 'dashboard') {
            return this.renderDashboardContent();
        }

        return (
            <div>
                {loading &&
                    <div className="p-6">
                        <Loading/>
                    </div>
                }

                {!loading &&
                    <>
                        <div className="text-lg leading-6 font-medium text-gray-900 flex flex-col xl:flex-row items-center xl:justify-between p-6">
                            {admin &&
                                <HrTitle title={title} />
                            }

                            {!admin &&
                                <h2 className="py-2">
                                    {title}
                                </h2>
                            }

                            <div className="flex flex-row gap-2">
                                <PrimaryButton
                                    onClick={() => {
                                        Modal.open({
                                            component: SubscriptionCalendarLink,
                                            props: {
                                                admin
                                            }
                                        });
                                    }}
                                    text={translation('subscription-calendar-link', admin ? 'title-admin' : 'title-my')}
                                />

                                <PrimaryButton
                                    onClick={() => {
                                        Modal.open({
                                            component: FilterModal,
                                            props: {
                                                columns: getColumns(admin, null, true),
                                                filters,
                                                handleFiltersCallback: this.handleFilters
                                            }
                                        });
                                    }}
                                    text="Filter"
                                />

                                {canCreate && (
                                    <PrimaryButton
                                        onClick={() => {
                                            Modal.open({
                                                component: CreateTimesheetModal,
                                                props: {
                                                    type: null,
                                                    admin
                                                }
                                            });
                                        }}
                                        text="Create"
                                    />
                                )}
                            </div>
                        </div>

                        <div className="px-6">
                            {admin && displayUserSelect &&
                                <Select
                                    containerClassName="relative z-10 p-6 bg-gray-200 rounded-lg mb-6"
                                    id="user_ids"
                                    value={user_ids}
                                    options={[
                                        {value: 'null', label: 'Unassigned'},
                                    ]}
                                    onChange={(v) => this.setState({user_ids: v}, () => {
                                        this.refreshCalendar()
                                    })}
                                    isAsync
                                    isMulti
                                    searchCallback={(data) => AdminUsersApi.get(null, data)}
                                    searchLabelKey="full_name"
                                    allowNull={false}
                                    placeholder="Select a user ..."
                                    colouredOptions={true}
                                />
                            }

                            {this.renderContent()}
                        </div>
                    </>
                }
            </div>
        );
    }

    /**
     * @method renderDashboardContent
     * @return {JSX.Element}
     */
    renderDashboardContent = () => {
        return (
            <div className="p-6">
                { this.renderContent () }
            </div>
        );
    }

    /**
     * @method renderContent
     * @return {JSX.Element}
     */
    renderContent() {
        const {layout, views = 'timeGridWeek dayGridMonth', canCreate = true } = this.props;
        const {calendarKey, loading, calendarView, isMobile} = this.state;

        let locale = Settings.data.language;

        if (locale === 'en') {
            locale = 'en-gb';
        }

        return (
            <div className="mt-2 pb-6">
            <FullCalendar
                ref={this.calendarRef}
                editable={canCreate}
                selectable={canCreate}
                selectMirror={true}
                unselectAuto={true}
                showNonCurrentDates={false}
                initialView={calendarView.view}
                initialDate={calendarView.date}
                select={this.handleCreateTimesheet}
                firstDay={1}
                locales={allLocales}
                locale={locale}
                headerToolbar={{
                    start: 'prev next today',
                    center: 'title',
                    end: layout === 'dashboard' ? 'timeGridDay timeGridWeek dayGridMonth' : views,
                }}
                plugins={[luxonTimezonePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                events={!loading ? this.fetchEvents : []}
                eventContent={(info) => {
                    return {html: info.event.title}; // Enable html chars in event title.
                }}
                eventDragStart={this.startDrag}
                eventResizeStart={this.startDrag}
                eventDrop={this.endDrag}
                eventResize={this.endDrag}
                snapDuration="00:15:00"
                eventClick={this.showEvent}
                key={calendarKey}
                datesSet={(arg) => {
                    this.setState({
                        calendarView: {
                            view: arg.view.type,
                            date: arg.startStr,
                        }
                    })
                }}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
                height={isMobile ? 600 : 1200}
            />
        </div>
        );
    }}

import React from 'react';
import Modal from 'Services/Modal';

export default class Container extends React.Component {
    /**
     * @var state
     */
    state = {
        modal: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        Modal.on('open', this.checkModals);
        Modal.on('close-all', this.handleCloseAll);

        this.checkModals();
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        Modal.removeListener('open', this.checkModals);
        Modal.removeListener('close-all', this.handleCloseAll);
    };

    /**
     * @method checkModals
     */
    checkModals = () => {
        if (Modal.modals.length === 0) {
            return;
        }

        const {data} = Modal.modals[(Modal.modals.length - 1)];

        this.setState({
            modal: data,
        });
    };

    /**
     * @method handleClose
     */
    handleClose = () => {
        let previousComponent = Modal.modals[(Modal.modals.length - 1)]?.data?.component?.name;

        Modal.modals.splice((Modal.modals.length - 1), 1);

        let nextComponent = Modal.modals[(Modal.modals.length - 1)]?.data?.component?.name;

        if (previousComponent === 'Delete' && nextComponent === 'ShowTimesheetModal') {
            Modal.modals.splice((Modal.modals.length - 1), 1);
        }

        this.setState({
            modal: null,
        }, this.checkModals);
    };

    /**
     * @method handleCloseAll
     */
    handleCloseAll = () => {
        Modal.modals = [];

        this.setState({
            modal: null,
        });
    }

    /**
     * @method render
     * @return {null|*}
     */
    render = () => {
        const {modal} = this.state;

        if (modal === null) {
            return null;
        }

        return (
            <div className={`
                modal fixed w-full h-full top-0 left-0 flex items-center 
                justify-center z-50
            `}>
                <div className='modal-overlay absolute w-full h-full bg-gray-900 opacity-50' />

                {this.renderModals(modal)}
            </div>
        );
    }

    /**
     * @method renderModals
     * @param {{}} modal
     * @return {*}
     */
    renderModals = (modal) => {
        if (modal.component) {
            return (
                <modal.component
                    {...modal.props}
                    onClose={() => this.handleClose()}
                />
            );
        }
    };
}

import {EventEmitter} from 'events';

import HttpBase from './HttpBase';
import FileUploader from '../FileUploader';
import Toast from '../Toast';

export default class ApiBase extends EventEmitter {
    /**
     * @var route
     * @type {string}
     */
    route = '';

    /**
     * @constructor
     */
    constructor() {
        super();

        this.client = HttpBase.getClient();
    };

    /**
     * @method get
     * @param {object|string} resource
     * @param {object} data
     * @param {object} config
     * @return {Promise<*>}
     */
    get = async (resource = {}, data = {}, config = {}) => {
        let err = null;

        const response = await this.client.get(
            this.getResourceUrl(resource) + await this.urlParams(data),
            config
        ).catch(
            (error) => (err = error),
        );

        return this.handleResponse(response, err);
    };

    /**
     * @method post
     * @param {string|object} resource
     * @param {object} data
     * @param {string|null} event
     * @param {number} successStatus
     * @param {object} config
     * @return {Promise<*>}
     */
    post = async (resource, data, event = null, successStatus = 201, config = {}) => {
        let err = null;

        const response = await this.client.post(
            this.getResourceUrl(resource),
            await this.handleData(data),
            config
        ).catch(
            (error) => (err = error),
        );

        this.handleEvent(event, successStatus, response, 'post');

        return this.handleResponse(response, err, successStatus);
    };

    /**
     * @method patch
     * @param {string|object} resource
     * @param {object} data
     * @param {string|null} event
     * @param {number} successStatus
     * @param {object} config
     * @return {Promise<boolean|{status: number}>}
     */
    patch = async (resource, data, event = null, successStatus = 200, config = {}) => {
        let err = null;

        const response = await this.client.patch(
            this.getResourceUrl(resource),
            await this.handleData(data),
            config
        ).catch(
            (error) => (err = error),
        );

        this.handleEvent(event, successStatus, response, 'patch');

        return this.handleResponse(response, err, successStatus);
    };

    /**
     * @method put
     * @param {string|object} resource
     * @param {object} data
     * @param {string|null} event
     * @param {number} successStatus
     * @param {object} config
     * @return {Promise<boolean|{status: number}>}
     */
    put = async (resource, data, event = null, successStatus = 200, config = {}) => {
        let err = null;

        const response = await this.client.put(
            this.getResourceUrl(resource),
            await this.handleData(data),
            config
        ).catch(
            (error) => (err = error),
        );

        this.handleEvent(event, successStatus, response, 'put');

        return this.handleResponse(response, err, successStatus);
    };

    /**
     * @method delete
     * @param {string|object} resource
     * @param {string|null} event
     * @param {number} successStatus
     * @param {object} config
     * @return {Promise<boolean|{status: number}>}
     */
    delete = async (resource, event, successStatus = 204, config = {}) => {
        let err = null;

        const response = await this.client.delete(this.getResourceUrl(resource), config).catch(
            (error) => (err = error),
        );

        this.handleEvent(event, successStatus, response, 'delete');

        return this.handleResponse(response, err, successStatus);
    };

    /**
     * @method downloadFile
     * @param {string|object} resource
     * @param {object} data
     * @param {string} filename
     * @param {number} successStatus
     * @param {object} config
     * @return {Promise<*>}
     */
    downloadFile = async (resource = {}, data = {}, filename, successStatus = 200, config = {}) => {
        let err = null;

        config = {
            ...config,
            responseType: 'blob'
        };

        const response = await this.client.get(this.getResourceUrl(resource) + await this.urlParams(data), config)
            .catch(
                (error) => (err = error),
            );

        let result = this.handleResponse(response, err, successStatus);

        if (result.status === successStatus) {
            const fileURL = window.URL.createObjectURL(
                response.data instanceof Blob ? response.data : new Blob([response.data]),
            );

            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();
        }

        return result;
    };

    /**
     * @method downloadResourceFile
     * @param {object} file
     * @param {string|null} name
     * @return {Promise<*>}
     */
    downloadResourceFile = (file, name = null) => {
        if (!name) {
            let spliter = file.value.split('/');

            name = spliter[spliter.length - 1];
        }

        return this.downloadFile(
            '/uploads/download',
            {
                key: file.value,
            },
            name + '.' + file.extension
        );
    }

    /**
     * @method getResourceUrl
     * @param {string|object} resources
     * @return {string}
     */
    getResourceUrl = (resources) => {
        if (resources === null) {
            return this.route;
        }

        if (typeof resources === 'object') {
            let route = this.route;
            const added = [];

            for (let r in resources) {
                const replaceString = `:${r}`;

                if (route.indexOf(replaceString) !== -1) {
                    route = route.replace(replaceString, resources[r]);
                    added.push(resources[r]);
                }
            }

            const resourcesArray = Object.values(resources)
            const lastResource = resourcesArray[resourcesArray.length - 1];

            return `${route}${added.indexOf(lastResource) === -1 ? `/${lastResource}` : ''}`;
        } else {
            if (resources[0] === '/') {
                return resources;
            } else {
                return `${this.route}/${resources}`;
            }
        }
    };

    /**
     * @method getStatus
     * @param {number|number[]} successStatus
     * @return {number[]}
     */
    getStatus = (successStatus) => {
        return !Array.isArray(successStatus) ? [successStatus] : successStatus;
    };

    /**
     * @method urlParams
     * @param {object} params
     * @return {string}
     */
    urlParams = async (params) => {
        if (params === undefined || params.length === 0) {
            return '';
        }

        params = await this.handleData(params);

        const queryStringItems = [];

        for (const field in params) {
            if (params[field] !== undefined && 
                params[field] !== null &&
                params[field].length !== 0) {
                queryStringItems.push(field + '=' + encodeURI(params[field]));
            }
        }

        return '?' + queryStringItems.join('&');
    };

    /**
     * @method handleData
     * @param {object} data
     * @return {object}
     */
    handleData = async (data) => {
        if (data) {
            let new_data = {
                ...data
            };

            for (const datum in new_data) {
                if (new_data[datum]) {
                    if (new_data[datum].constructor === Array) {
                        new_data[datum] = await this.handleData(new_data[datum]);
                    } else {
                        new_data[datum] = await this.handleDatum(new_data[datum]);
                    }
                }
            }

            return new_data;
        }

        return data;
    }

    /**
     * @method handleDatum
     * @param {mixed} value
     * @return {mixed}
     */
    handleDatum = async (value) => {
        // Date object.
        if (typeof value === '[object Date]' || Object.prototype.toString.call(value) === '[object Date]') {
            return value.toISOString();
        }

        // Date serialized.
        if (value?.iso_string) {
            return value.iso_string;
        }

        // File
        if (typeof value === '[object File]' || Object.prototype.toString.call(value) === '[object File]') {
            const upload = await this.uploadFile(value);

            if (upload.status !== 200) {
                Toast.error('Your upload could not be processed.');
                throw 'Your upload could not be processed.';
            }

            return upload.tempKey;
        }

        // File serialized.
        if (value?.url && value?.mime) {
            return value.value;
        }

        // Recursively call this method on objects.
        if (typeof value === 'object') {
            return await this.handleData(value);
        }

        return value;
    }

    /**
     * @method handleResponse
     * @param {object} response
     * @param {object} error
     * @param {number} successStatus
     * @return {boolean|{status: number}}
     */
    handleResponse = (response, error, successStatus = 200) => {
        const status = this.getStatus(successStatus);

        if (status.indexOf(response.status) !== -1) {
            return {
                status: response.status,
                data: response.data,
                success: true
            };
        }

        if (error) {
            if (error.response.status === 403) {
                Toast.error(
                    error.response?.data?.message ?? 'Please contact support if you believe this is an error.',
                    'Access Denied',
                    50000
                );
            }

            if (error.response.status === 413) {
                Toast.error('Your upload is too large.');
            }

            return {status: error.response.status, ...error.response.data, success: false};
        }

        return {status: response.status, ...response.data, success: true};
    };

    /**
     * @method handleEvent
     * @param {string|null} event
     * @param {number} successStatus
     * @param {object} response
     * @param {string} type
     */
    handleEvent = (event, successStatus, response, type) => {
        if (event === undefined || event === null) {
            event = this.events && this.events[type] ? this.events[type] : 'updated';
        }

        if (
            event !== undefined && event !== null &&
            this.getStatus(successStatus).indexOf(response.status) !== -1
        ) {
            this.emit(event);
        }
    }

    /**
     * @method requestUpload
     * @param {string} contentType
     * @param {array} metadata
     * @return {Promise<*>}
     */
    requestUpload = async (contentType, metadata) => {
        const request = await this.post('/uploads', {
            content_type: contentType,
            metadata,
        });

        return request.data.data;
    };

    /**
     * @method uploadFile
     * @param {file|Blob|any} contents
     * @param {string|number} id
     * @param {string|null} type
     * @return {Promise<{host: string, tempKey: *, key: *, status: number}|boolean|{status: number}>}
     */
    uploadFile = async (contents, id = null, type = null) => {
        const data = {};

        if (contents instanceof File || contents instanceof Blob) {
            type = contents.type;

            if (contents instanceof File) {
                data['OriginalName'] = contents.name;
            }
        }

        const upload = await this.requestUpload(type, data);
        const uploadHeaders = upload.headers;
        const headers = {Host: ''};

        for (const h in uploadHeaders) {
            if (!uploadHeaders.hasOwnProperty(h)) continue;

            if (h === 'Content-Type') {
                headers[h] = uploadHeaders[h];
                continue;
            }

            headers[h] = uploadHeaders[h][0] || '';
        }

        let host = '';
        if ('Host' in uploadHeaders) {
            host = headers.Host;
            delete headers.Host;
        }

        let err;

        const request = await FileUploader.startUpload(id ?? upload.uuid, {
            method: "put",
            url: upload.url,
            data: contents,
            headers,
        }).catch((e) => err = e);

        if (request.status !== 200) {
            return this.handleResponse(request, err);
        }

        return {
            success: true,
            status: request.status,
            host,
            key: upload.uuid,
            tempKey: upload.key,
        };
    };
}

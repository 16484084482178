import React from 'react';
import { withRouter } from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import { IndexTable } from "Components/Utilities";
import { PrimaryButton } from "Components/Button";

import AdminSurveyResponseApi from "Services/Api/Admin/SurveyResponse";

class Responses extends React.Component {
    /**
     * @var id
     */
    id = this.props.match.params?.survey ?? null;

    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Name',
            value: 'name',
            type: 'string',
            orderable: false,
        },
        {
            label: 'Position',
            value: 'position',
            type: 'string',
            orderable: false,
        },
        {
            label: 'Date',
            value: 'date',
            type: 'date',
            orderable: false,
        },
    ];

    /**
     * @method getRowActions
     * @param {object} item
     * @returns {[{layout: string, to: `"admin/surveys/${string}`, type: string, title: string}]}
     */
    getRowActions = (item) => [
        {
            type: "link",
            to: `/admin/surveys/${item.survey_id}/responses/${item.id}`,
            text: "View",
            className: "bg-app-backup border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-robot text-white",
        },
    ];

    handleExport = () => {};

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Survey - Responses">
                {/*<div className="flex justify-end">*/}
                {/*    <PrimaryButton*/}
                {/*        text="Export"*/}
                {/*        onClick={this.handleExport}*/}
                {/*    />*/}
                {/*</div>*/}

                <IndexTable
                    columns={this.columns}
                    displaySearchBar={false}
                    loadDataCallback={(data) => AdminSurveyResponseApi.get({
                        survey: this.id,
                    }, data)}
                    rowActionsLayout="row"
                    rowActions={this.getRowActions}
                    eventApi={AdminSurveyResponseApi}
                />
            </AdminLayout>
        );
    }
}

export default withRouter(Responses);

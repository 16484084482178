import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';

import AdminLayout from 'Pages/Admin/AdminLayout';

import AdminGroupsApi from 'Services/Api/Admin/Groups';

export default class GroupsIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Name',
            value: 'name',
            type: 'string',
            orderable: 'name'
        },
        {
            label: 'Scopes',
            value: (record) => record.scopes?.join(', '),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Team',
            value: (record) => record.team?.name,
            type: 'callback',
            orderable: null,
            feature: 'teams'
        },
    ];
    
    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        return [
            {
                type: "link",
                to: "/admin/groups/store/"+item.id,
                text: "Update"
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'group',
                    itemId: item.id,
                    onDeleteApi: AdminGroupsApi.delete
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="User Groups">
                {!window.base.features.allow_groups_update &&
                    <p className="m-6 font-bold text-center">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.allow_groups_update &&
                    <IndexTable
                        columns={this.columns}
                        displaySearchBar={false}
                        storeLink="/admin/groups/store"
                        loadDataCallback={(data) => AdminGroupsApi.get(null, data)}
                        rowActions={this.getRowActions}
                        rowClickRedirect={(item) => "/admin/groups/store/"+item.id}
                        eventApi={AdminGroupsApi}
                    />
                }
            </AdminLayout>
        )
    }
}

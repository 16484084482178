import React from 'react';

import TimesheetsCalendar from 'Components/HumanResources/Timesheets/Calendar';

import {User} from 'Services';

export default class CalendarTab extends React.Component {
	/**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {service_user_id} = this.props;

        // TODO currently this will only show timesheets for the logged in user if you are not an admin.
        // Need to discuss with aimee what is required.

        return (
    		<TimesheetsCalendar
                title='Calendar'
                admin={User.data.is_admin}
                service_user_id={service_user_id}
                displayUserSelect={false}
            />
        );
    }
}
import React from 'react';
import TimeAgo from 'react-timeago'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart, faTrash} from '@fortawesome/free-solid-svg-icons';

import {Modal, ModalHeader, ModalBody} from 'Components/Modal';
import {Loading} from 'Components/Partials';
import {FormHandler, Textarea, Input} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';

import {Toast} from 'Services';
import User from 'Services/User';
import CommentApi from 'Services/Api/Cms/Elements/Comment';
import {translation} from 'Services/TranslationHelpers';

class ViewCmsElementRecordModal extends React.Component {
    /**
     * @var state
     * @type {{item: *, comments: null, loading: boolean}}
     */
    state = {
        item: this.props.item,
        comments: null,
        loading: false
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.fetchComments();

        CommentApi.on('comment', this.fetchComments);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        CommentApi.removeListener('comment', this.fetchComments);
    };

    /**
     * @method fetchComments
     * @return {Promise<void>}
     */
    fetchComments = async () => {
        this.setState({
            working: true
        });

        const request = await CommentApi.get(null, {
            cms_element_record_id: this.state.item.id
        });

        if (request.success) {
            this.props.handleCommentNumberUpdate(this.state.item, request.data.data.length);

            return this.setState({
                comments: request.data,
                loading: false
            });
        }

        Toast.error();
    };

    /**
     * @method createComment
     * @param {object} form
     * @return {Promise<*>}
     */
    createComment = (form) => {
        return CommentApi.post(null, {
            ...form,
            cms_element_record_id: this.state.item.id
        }, 'comment');
    };

    /**
     * @method deleteComment
     * @param {string} comment_id
     * @return {Promise<*>}
     */
    deleteComment = async (comment_id) => {
        await CommentApi.delete(comment_id, 'comment');
    };

    /**
     * @method handleToggleLike
     * @return {Promise<void>}
     */
    handleToggleLike = async () => {
        this.setState({
            item: await this.props.handleToggleLike(this.state.item)
        });
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {item, loading, comments} = this.state;
        const {title, body, content} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {title}
                </ModalHeader>

                <ModalBody>
                    {body(item, 'modal')}

                    <div className={"flex justify-between p-2"}>
                        <div>
                            <p className={"light"}>
                                {content}
                            </p>
                        </div>
                        <div>
                            {item.allow_likes && (
                                <FontAwesomeIcon
                                    icon={faHeart}
                                    size="1x"
                                    className={`mx-1 cursor-pointer ${item.user_has_liked ? 'text-red-500' : 'text-black'}`}
                                    onClick={() => this.handleToggleLike(item)}
                                />
                            )}
                        </div>
                    </div>

                    {loading && (
                        <Loading />
                    )}

                    {!loading && item.allow_comments && (
                        <React.Fragment>
                            <div className="mx-2 px-2 max-h-40 overflow-y-auto">
                                {comments && comments.data.map(this.renderComment).reverse()}
                            </div>

                            {this.renderCommentForm()}
                        </React.Fragment>
                    )}
                </ModalBody>
            </Modal>
        );
    }

    /**
     * @method renderComment
     * @param {object} comment
     * @return {JSX.Element}
     */
    renderComment = (comment) => {
        return (
            <div key={comment.id} className="w-max mx-auto my-2 px-4 py-2 border-2 rounded text-center right">
                <p>{comment.message}</p>

                <p className="text-small mt-2">
                    {comment.user.full_name} - <TimeAgo date={comment.created_at.iso_string} />

                    {comment.user.id === User.data.id &&
                        <FontAwesomeIcon
                            onClick={() => this.deleteComment(comment.id)}
                            icon={faTrash}
                            size="1x"
                            title="Delete comment"
                            className="ml-2 cursor-pointer"
                        />
                    }
                </p>
            </div>
        );
    };

    /**
     * @method renderCommentForm
     * @return {JSX.Element}
     */
    renderCommentForm = () => {
        const {handleInput, handleSubmit, form} = this.props;

        return (
            <form onSubmit={(e) => handleSubmit(e, this.createComment, undefined, true)}>
                <div className="mt-4 text-center">
                    <Input
                        type="text"
                        id={"message"}
                        onChange={(v) => handleInput('message', v)}
                        value={form.message ?? ''}
                        placeholder={translation('cms', 'write_comment_here')}
                        required
                    />

                    <div className="w-full mt-2">
                        <PrimaryButton
                            disabled={!form.message || form.message.length === 0}
                            text="Submit"
                            className="transition duration-200 transform hover:scale-110 mt-2"
                        />
                    </div>
                </div>
            </form>
        );
    };
}

export default FormHandler(ViewCmsElementRecordModal);

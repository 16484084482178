import React from 'react';

import {Toast} from 'Services';
import UsersApi from 'Services/Api/Admin/HumanResources/Users';

export default class Base extends React.Component {
    /**
     * @method fetchUser
     * @param {boolean} setForm
     * @param {boolean} force
     */
    setUser = async (setForm = false, force = false) => {
        let userData = localStorage.getItem('_user');
        userData = userData ? JSON.parse(userData) : {};

        if (this.props.match.params.user !== userData.id || force) {
            userData = await this.fetchUser();
        } else {
            this.setState({
                user: userData
            });
        }

        if (setForm) {
            this.props.setForm({
                ...userData,
                date_of_birth: userData.date_of_birth,
            });
        }
    };

    /**
     * @method fetchUser
     * @return {Promise<void>}
     */
    fetchUser = async () => {
        this.setState({ loading: true });

        const request = await UsersApi.get({
            user: this.props.match.params.user
        });

        if (request.success) {
            localStorage.setItem('_user', JSON.stringify(request.data.data));

            this.setState({
                loading: false,
                user: request.data.data
            });

            return request.data.data;
        }

        Toast.error();
    };
}

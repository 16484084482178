import {User} from 'Services';

/**
 * @method handleMessagePreview
 * @param {string} message
 * @return {string}
 */
const handleMessagePreview = (message) => {
    if (message.length <= 40) {
        return message;
    }

    return message.slice(0, 40) + ' ...';
}

/**
 * @method isSameUser
 * @param {object} user_a
 * @param {object} user_b
 * @return {boolean}
 */
const isSameUser = (user_a, user_b) => {
    return (user_a.type === user_b.type && user_a.id === user_b.id);
}

/**
 * @method isSameUserCheck
 * @return {object}
 */
const isSameUserCheck = () => {
    return (o, object) => { return isSameUser(o, object) };
}

/**
 * @method isSameUser
 * @param {object} user
 * @return {boolean}
 */
const isThisUser = (user) => {
    return (user.type === 'App\\Models\\User' && user.id === User.id);
}

export {
    handleMessagePreview,
    isSameUser,
    isSameUserCheck,
    isThisUser,
}

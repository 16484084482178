import React from 'react';

import {DatePicker} from 'Components/Form';

/**
 * @function DateTimeFilter
 * @param {function} onChange
 * @param {object} filters
 * @param {string} column
 * @param {string} label
 * @param {boolean} defaultMethod
 * @param {object} props
 * @constructor
 */
const DateTimeFilter = ({onChange, filters, column, label, defaultMethod = true, props}) => {
    let type = props.type;
    let direction = props.direction;

    if (direction !== 'before' && direction !== 'after') {
        console.error('Invalid DateTimeFilter direction.');
        return;
    }

    // defaultMethod => DualityStudio\Base\Filter\QueryFilter.php -> beforeDateTime/afterDateTime().
    // !defaultMethod => Custom filter "column" on the request.
    let column_key = defaultMethod ? column + "[" + direction + "DateTime]" : column;

    let value = filters && filters[column_key] ? filters[column_key] : null;

    let onchange = (v) => onChange(column_key, v);

    let placeholder = "Filter " + direction + "...";

    if (type === 'datetime') {
        return (
            <DatePicker
                label={label}
                placeholder={placeholder}
                selected={value}
                onChange={onchange}
                showTimeSelect={true}
                timeIntervals={5}
            />
        );
    }

    if (type === 'date') {
        return (
            <DatePicker
                label={label}
                placeholder={placeholder}
                selected={value}
                onChange={onchange}
            />
        );
    }

    if (type === 'time') {
        return (
            <DatePicker
                label={label}
                placeholder={placeholder}
                selected={value} 
                onChange={onchange}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={5}
            />
        );
    }
};

export default DateTimeFilter;

import React from 'react';

import {PrimaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import { Input, FormHandler, Toggle, FileUpload, Select } from 'Components/Form';
import HrCategoryField from 'Components/Form/HrCategoryField';

import DocumentsApi from "Services/Api/Admin/ServiceUsers/Documents";

class ServiceDocumentCreateModal extends React.Component {
    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        const { document } = this.props;

        if (document) {
            this.props.setInitialValues({
                ...document,
            });
        } else {
            this.props.setInitialValues({
                visible_to_user: false
            });
        }
    };

    /**
     * @method request
     * @param {object} data
     * @return {*}
     */
    request = async ( data ) => {
        // add is_visible_to_user to data and set to false

        data.visible_to_user = false;

        if ( this.props.document ) {
            return DocumentsApi.patch ( {
                user: this.props.user.id,
                document: this.props.document.id
            }, data, 'update' );
        } else {
            return DocumentsApi.post ( {
                user: this.props.user.id
            }, data, 'create' );
        }
    };

    /**
     * @method handleFile
     * @param {File} file
     * @return {Promise<void>}
     */
    handleFile = async ( file ) => {
        if ( !this.props.form.name ) {
            await this.props.handleInput ( 'name', file.name );
        }

        this.props.handleInput ( 'file', file );
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { working, form, alert, handleInput, getFieldError, handleSubmit } = this.props;

        const categoryOptions = [
            { value: 'Care planning docs', label: 'Care planning docs' },
            { value: 'Grab Sheets', label: 'Grab Sheets' },
            { value: 'Medical', label: 'Medical' },
            { value: 'Risk Assessments', label: 'Risk Assessments' },
        ];

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {this.props.document ? 'Update' : 'Create'} Document
                </ModalHeader>

                <form
                    onSubmit={ ( e ) => handleSubmit ( e, this.request, this.props.onClose ) }
                >
                    <ModalBody>
                        { alert && <Alert { ...alert } /> }
                        <FileUpload
                            containerClassName="mb-4 flex flex-col justify-center items-center gap-4"
                            value={ form.file }
                            error={ getFieldError ( 'file' ) }
                            onChange={ this.handleFile }
                            dropzone={ true }
                            accept="application/pdf"
                        />

                        <Select
                            label="Category"
                            value={form.category}
                            error={getFieldError('category')}
                            onChange={(v) => handleInput('category', v)}
                            options={categoryOptions}
                            containerClassName="mb-4"
                            isMulti
                        />
                    </ModalBody>

                    <ModalFooter>
                        <PrimaryButton
                            text={ this.props.document ? 'Update' : 'Create' }
                            working={ working }
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler ( ServiceDocumentCreateModal );

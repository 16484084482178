import React from 'react';

import CreateCmsElementRecord from 'Components/Utilities/CreateCmsElementRecord';

import AdminLayout from 'Pages/Admin/AdminLayout';

export default class AdminCmsElementsRecordsStore extends React.Component {
    /**
     * @var state
     */
    state = {
        title: null,
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {title} = this.state;

        return (
            <AdminLayout title={'CMS Elements - ' + title}>
                <div className="m-4">
                    <CreateCmsElementRecord
                        showElementsDropdown={this.props.match?.params?.record ? false : true}
                        element_id={this.props.match?.params?.element ?? null}
                        element_record_id={this.props.match?.params?.record ?? null}
                        setTitleCallback={(v) => this.setState({title: v})}
                    />
                </div>
            </AdminLayout>
        );
    }
}
import React from 'react'

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, DangerButton, SecondaryButton} from 'Components/Button';
import {Loading, Alert} from 'Components/Partials';
import {Select, FileUpload} from 'Components/Form';

import AdminImportsApi from 'Services/Api/Admin/Imports';
import AdminCmsElementApi from 'Services/Api/Admin/Cms/Elements/Element';

import {translationFromJson} from 'Services/TranslationHelpers2';

export default class AdminRunImportModal extends React.Component  {
    /**
     * @var state
     */
    state = {
        alert: null,
        working: false,
        file: false,
        showTemplate: false,
        params: null,
        cms_element_options: null,
    };

    /**
     * @method componentDidUpdate
     */
    async componentDidMount() {
        if (this.props.selectedImport.import === 'CmsElementRecords') {
            await this.getCmsElements();
        }
    }

    /**
     * @method getCmsElements
     */
    getCmsElements = async () => {
        this.setState({working: true});

        const response = await AdminCmsElementApi.get(null);

        this.setState({
            working: false,
            cms_element_options: response.data.data.map((element) => (
                {
                    label: translationFromJson(element.label),
                    value: element.identifier
                }
            )),
        });
    }

    /**
     * @method handleParams
     * @param {string} key
     * @param {string} value
     */
    handleParams = (key, value) => {
        const {params} = this.state;

        this.setState({
            params: {
                ...params,
                [key]: value
            }
        });
    };

    /**
     * @method handleSubmit
     */
    handleSubmit = async () => {
        this.setState({
            alert: null,
            working: true
        });

        const response = await AdminImportsApi.post(null, {
            type: this.props.selectedImport.import,
            import_file: this.state.file,
            import_file_name: this.state.file.name,
            params: this.state.params
        });

        if (response.status !== 201) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message,
                    errors: response.errors
                },
                working: false
            });
            return;
        }

        this.props.callbackOnSubmit(1);

        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {alert, working, file, showTemplate} = this.state;
        const {selectedImport} = this.props;

        return (
            <Modal>
                <ModalBody>
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="my-2 text-center black text-2xl leading-9 font-extrabold">
                            {/* https://stackoverflow.com/questions/5582228/insert-space-before-capital-letters */}
                            { "Run " + selectedImport.import.replace(/([A-Z])/g, ' $1').trim() + " Import" }
                        </h2>

                        {alert !== null && (<Alert {...alert} />)}

                        {selectedImport.available_columns && 
                            <div className="text-center my-4 p-2">
                                <PrimaryButton
                                    text={(showTemplate ? "Hide" : "Show") + " Template"}
                                    onClick={() => this.setState({showTemplate: !showTemplate})}
                                />

                                {showTemplate && 
                                    <table className="table-fixed mx-auto mt-2">
                                        <thead>
                                            <tr>
                                                <th className="px-4 py-2">Columns</th>
                                                <th className="px-4 py-2">Rules</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(selectedImport.available_columns).map((column, i) => {
                                                return (
                                                    <tr key={i}>
                                                    <td className="px-4 py-2 border">{column[0]}</td>
                                                    <td className="px-4 py-2 border">{column[1]}</td>
                                                    </tr>
                                                ); 
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        }

                        <FileUpload
                            containerClassName="mt-4 flex flex-col justify-center items-center gap-4"
                            value={file}
                            onChange={v => this.setState({'file': v})}
                            accept=".csv"
                            placeholder="Select file"
                        />
                    </div>

                    {this.renderParams()}
                </ModalBody>

                <ModalFooter alignment="center">
                    {working && (<Loading color="text-black"/>)}

                    {!working &&
                        <div className="flex justify-around space-between">
                            <PrimaryButton className="mr-2" text="Cancel" onClick={this.props.onClose} />
                            <DangerButton onClick={this.handleSubmit} text="Run" />
                        </div>
                    }
                </ModalFooter>
            </Modal>
        )
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    renderParams() {
        const {params, cms_element_options} = this.state;

        if (this.props.selectedImport.import === 'CmsElementRecords') {
            return (
                <div>
                    <Select
                        label="Cms Element"
                        value={params?.cms_element_identifier}
                        onChange={(v) => this.handleParams('cms_element_identifier', v)}
                        options={cms_element_options}
                    />
                </div>
            );
        }

        return null;
    }
}

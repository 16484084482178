import React from 'react';

import {Modal, ModalHeader, ModalBody} from 'Components/Modal';

class ViewServiceDocumentModal extends React.Component {
    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { pdfUrl, title } = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {title ?? ''}
                </ModalHeader>
                    <ModalBody>
                        {pdfUrl &&
                            <iframe
                                src={ pdfUrl + "#toolbar=0" }
                                className="w-full max-w-4xl mx-auto h-[60rem] my-6"
                            />
                        }
                    </ModalBody>
            </Modal>
        );
    }
}

export default ViewServiceDocumentModal ;

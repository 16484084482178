import React from 'react';

import IndexTable from 'Components/HumanResources/Timesheets/IndexTable';

import { getRecordActions } from 'Services/HrHelpers';
import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';

import Base from '../HrTimesheets/';
import CreateHoliday from './CreateHoliday';
import { BooleanFilter, DateTimeFilter } from "Components/Filters";

export default class Table extends Base {
    /**
     * @var columns
     * @type {[{orderable: null, label: string, filters: [{component: function({onChange: Function, filters: Object, column: string, label: string, defaultMethod?: boolean, props: Object}): (undefined|*), column: string, label: string, props: {type: string, direction: string}},{component: function({onChange: Function, filters: Object, column: string, label: string, defaultMethod?: boolean, props: Object}): (undefined|*), column: string, label: string, props: {type: string, direction: string}}], type: string, value: function(*): string},{orderable: null, label: string, type: string, value: function(*): *},{orderable: null, label: string, type: string, value: function(*): *},{valueClassNameOverwrite: boolean, orderable: null, label: string, filters: [{component: function({containerClassName: (string|null), onChange: Function, filters: Object, column: string, label: string, defaultMethod?: boolean}): *, column: string, label: string}], type: string, value: function(*): *, valueClassName: string},{orderable: null, label: string, type: string, value: string, hideByDefault: boolean}]}
     */
    columns = [
        {
            label: 'Date',
            value: (record) => record.start_date_time.date_utc + ' - ' + record.end_date_time.date_utc,
            type: 'callback',
            orderable: null,
            filters: [
                {
                    column: 'end_date_time',
                    label: 'After',
                    component: DateTimeFilter,
                    props: {
                        type: 'date',
                        direction: 'after'
                    }
                },
                {
                    column: 'start_date_time',
                    label: 'Before',
                    component: DateTimeFilter,
                    props: {
                        type: 'date',
                        direction: 'before'
                    }
                }
            ]
        },
        {
            label: 'Days',
            value: (record) => record.days_cost,
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Hours',
            value: (record) => record.hours_cost,
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Status',
            value: (record) => (
                <div className="px-4 py-2" style={{ backgroundColor: record.approved_by ? 'rgb(34, 140, 34)' : 'rgb(255, 0, 0)' }}>
                    ({record.approved_by ? `Approved by ${record.approved_by.full_name}` : 'Awaiting approval'})
                </div>
            ),
            type: 'callback',
            orderable: null,
            filters: [
                {
                    column: 'submitted',
                    label: 'Submitted',
                    component: BooleanFilter,
                }
            ],
            valueClassName: "border border-app-leading",
            valueClassNameOverwrite: true
        },
        {
            label: 'Notes',
            value: 'description',
            type: 'string',
            orderable: null,
            hideByDefault: true
        }
    ]

    /**
     * @var state
     */
    state = {
        user: null,
        defaultFilters: {
            'employee_id[integer]': null,
        }

    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { service_user_id } = this.props;

        const callback = (data) => TimesheetsApi.get(null, {
            ...data,
            paginate: true,
            service_user_id,
            type: 'holiday',
        });

        return (
            <div>
                <IndexTable
                    showTotal={false}
                    columns={ this.columns }
                    displaySearchBar={ false }
                    storeModal={ {
                        component: CreateHoliday,
                    } }
                    actionsJustify={ 'center' }
                    loadDataCallback={ callback }
                    rowActions={ (record) => getRecordActions(record, false) }
                    rowActionsLayout="row"
                    eventApi={ TimesheetsApi }
                    defaultFilters={ this.state.defaultFilters }
                />
            </div>
        );
    }
}

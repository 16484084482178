import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

/**
 * @function Label
 * @param {string} label
 * @param {string} htmlFor
 * @param {string} className
 * @param {string} textColor
 * @param {string} instructions
 * @param {null|array} error
 * @return {JSX.Element}
 * @constructor
 */
const Label = ({label, htmlFor, className, textColor, instructions, error = null}) => {
    let classes = `
        block text-sm font-medium leading-5 font-roboto
        ${error ? 'text-red-800' : (textColor ?? 'text-gray-700')} 
        ${className && className.includes('mb-') ? '' : 'mb-2'} 
        ${className}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }
    
    return (
        <div className={`${classes} flex flex-row items-center`}>
            <label htmlFor={htmlFor}>
                {label}

                {error &&
                    <>
                        {" - " + error.join(' ')}
                    </>
                }
            </label>

            {instructions &&
                <div className="tooltip relative inline-block ml-1">
                    <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="1x"
                    />

                    <span className="tooltiptext w-56 bg-gray-700 text-white text-center p-3 rounded z-10 absolute left-5 -top-4">
                        {instructions}
                    </span>
                </div>
            }
        </div>
    )
};

export default Label;

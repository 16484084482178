import React from 'react';

import {Container} from 'Components/Utilities';

import Header from './Header';

export default class Unauthenticated extends React.Component {
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        const {children, pageTitle} = this.props;

        return (
            <div className="min-h-screen">
                <Header />

                <Container>
                    {children}
                </Container>
            </div>
        )
    };
}

import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/free-solid-svg-icons';

import {getRecordValue} from 'Services/CmsHelpers';

export default class OnDemandItem extends React.Component {
	/**
     * @var state
     */
    state = {
    	preview: true,
    }

	/**
     * @method render
     * @return {JSX.Element}
     */
    render = () => {
    	const {item, useTopVideo, setTopVideo, renderItemBar, renderItemBody} = this.props;
    	const {preview} = this.state;

    	let item_thumbnail = getRecordValue(item, 'thumbnail', 'file');

    	// Default to just displaying the thumbnail for performance reasons.
        return (
            <div
                className="min-h-60 video rounded-sm bg-gray-200 flex flex-col justify-between"
                key={item.id}
            >
	            {preview &&
	                <div 
	                    className="h-full flex justify-center items-center"
	                    onClick={()=> useTopVideo ? setTopVideo(item) : this.setState({preview: false})}
	                >
	                    {!item_thumbnail &&
	                        <div className="h-48 w-full flex justify-center items-center">
	                            <FontAwesomeIcon
	                                icon={faPlay}
	                                size="2x"
	                                className="h-16 w-16 self-center"
	                            />
	                        </div>
	                    }
	                    {item_thumbnail &&
	                        <img src={item_thumbnail} className="h-48 w-full" />
	                    }
	                </div>
	            }

	            {!preview &&
	            	<div className="h-full flex justify-center items-center">
	            		{renderItemBody(item)}
	            	</div>
	            }

                {renderItemBar(item)}
            </div>
        );
    };
}
import React from "react";

const Textarea = ({ onChange, className, ...rest }) => {
    return (
        <textarea
            className={`border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full sm:text-sm border-gray-300 ${className}`}
            onChange={(e) => onChange(e.target.value)}
            {...rest}
        />
    );
};

export default Textarea;

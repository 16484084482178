import React from "react";

const Nav = ({ sectionsCompleted, sections, activeSection, handleSelectSection }) => {
    const nextSection = sectionsCompleted.length > 0 ? sections[sectionsCompleted.length] : sections[0];

    return (
        <nav className="flex flex-col">
            <ul role="list" className="-mx-2 space-y-1">
                {sections.map((section) => {
                    const canClick = sectionsCompleted.includes(section?.slug) || nextSection?.slug === section?.slug;

                    return (
                        <li key={section.slug}>
                            <div
                                onClick={() => canClick ? handleSelectSection(section) : false}
                                className={`
                                    ${sectionsCompleted.includes(section?.slug) && 'text-indigo-600 line-through'}
                                    ${activeSection?.slug === section?.slug && 'bg-gray-50 text-indigo-600'}
                                    ${canClick && 'cursor-pointer hover:bg-gray-50'}
                                    group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold
                                `}
                            >
                                {section.name}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Nav;

//${activeSection?.slug === section?.slug ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 cursor-pointer'}

import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import TranslationUpdate from './TranslationUpdate';

import {Loading} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {Select, Toggle, Input} from 'Components/Form';

import AdminTranslationsApi from 'Services/Api/Admin/Translations';

export default class TranslationsIndex extends React.Component {
    /**
     * @var state
     */
    state = {
        working1: true,
        working2: false,
        groups: null,
        selected_group: null,
        selected_languages: null,
        display_only_empty: false,
        translations: null,
        search: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.selectAllLanguages();
        this.loadGroups();
    }

    /**
     * @method selectAllLanguages
     */
    selectAllLanguages = () => {
        // Select all languages by default
        this.setState({
            selected_languages: Object.entries(window.base.languages).map((value, key) => {
                return value[0];
            })
        });
    }

    /**
     * @method loadGroups
     */
    loadGroups = async () => {
        this.setState({ 
            working1: true,
        });

        const response = await AdminTranslationsApi.getGroups();

        let groups = response.data.groups.map((value, key) => {
            return {
                label: value,
                value: value
            };
        });

        groups.unshift({
            label: 'All',
            value: ''
        });

        this.setState({
            working1: false,
            groups,
        });
    }

    /**
     * @method loadTranslations
     * @param {string} group
     */
    loadTranslations = async (group) => {
        this.setState({ 
            working2: true,
            selected_group: group,
        });

        const response = await AdminTranslationsApi.get(null, {group});

        this.setState({
            working2: false,
            translations: response.data.data,
        });
    }

    /**
     * @method handleFilter
     * @param {object} item
     */
    handleFilter = (item) => {
        let translation = item[1];

        let {selected_languages, display_only_empty, search} = this.state;

        if (!search) {
            return true;
        }

        search = search.toLowerCase();

        if (translation.key.toLowerCase().includes(search)) {
            return true;
        }

        for (const language of selected_languages) {
            if (translation[language] && translation[language].toLowerCase().includes(search)) {
                return true;
            }
        }

        return false;
    }

    /**
     * @method updateValue
     * @param {string} group
     * @param {string} key
     * @param {string} language
     * @param {string} value
     */
    updateValue = (group, key, language, value) => {
        let {translations} = this.state;

        translations = Object.entries(translations).map((translation, i) => {
            if (translation[1].group === group && translation[1].key === key) {
                translation[1][language] = value;
            }

            return translation[1];
        });

        this.setState({
            translations,
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working1, working2, groups, selected_group, selected_languages, display_only_empty} = this.state;

        let languages = Object.entries(window.base.languages).map((value, key) => {
            return {
                label: value[1],
                value: value[0]
            };
        });

        return (
            <AdminLayout title="Site Translations">
                <Link to="/admin/translations/wysiwyg-example" className="flex justify-center items-center mb-6">
                    <PrimaryButton
                        text="WYSIWYG Example"
                        className="mx-auto"
                    />
                </Link>

                {!window.base.features.database_translations &&
                    <p className="m-6 font-bold text-center">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.database_translations &&
                    <div className="m-4">
                        {working1 && (<Loading />)}

                        {!working1 &&
                            <>
                                <p className="mb-6 text-center font-bold">
                                    Select a group below and then click on a value to update it.
                                </p>

                                <p className="mb-6 flex justify-center items-center">
                                    <p className="mr-2">
                                        Display only records with empty values
                                    </p>

                                    <Toggle
                                        value={display_only_empty}
                                        onChange={v => this.setState({'display_only_empty': v})}
                                    />
                                </p>

                                <Select
                                    containerClassName="mb-6"
                                    placeholder="Select a Group..."
                                    value={selected_group}
                                    onChange={v => this.loadTranslations(v)}
                                    options={groups}
                                />

                                <Select
                                    containerClassName="mb-6"
                                    placeholder="Languages"
                                    value={selected_languages}
                                    onChange={v => this.setState({selected_languages: v})}
                                    options={languages}
                                    isMulti
                                />

                                {working2 && (<Loading />)}

                                {!working2 && this.renderTranslations()}
                            </>
                        }
                    </div>
                }
            </AdminLayout>
        )
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    renderTranslations() {
        const {selected_languages, translations, search} = this.state;

        return (
            <>
                {translations && translations.length === 0 &&
                    <p>This group is empty.</p>
                }

                {translations && translations.length !== 0 &&
                    <>
                        <Input
                            containerClassName="mb-6"
                            type="text"
                            id="search"
                            onChange={v => this.setState({search: v})}
                            value={search ?? ''}
                            placeholder="Search ..."
                        />

                        <div className="overflow-x-auto">
                            <table className="table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2">Group</th>
                                        <th className="px-4 py-2">Key</th>

                                        {selected_languages && selected_languages.map((language, i) => (
                                            <th className="px-4 py-2" key={language}>
                                                {window.base.languages[language]}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(translations).filter(this.handleFilter).map((translation, i) => {
                                        return this.renderTranslation(translation[1], i)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </>
        );
    }

    /**
     * @method render
     * @param {object} translation
     * @param {string} i
     * @return {JSX.Element}
     */
    renderTranslation(translation, i) {
        const {selected_languages, display_only_empty} = this.state;

        if (selected_languages && display_only_empty) {
            if (selected_languages.filter(language => {return (!translation[language])}).length === 0) {
                return null;
            }
        }

        return (
            <tr key={translation.group + '_' + translation.key}>
                <td className="border px-4 py-2">
                    {translation.group}
                </td>

                <td className="border px-4 py-2">
                    <Link to={"/admin/translations/show/" + translation.group + '/' + translation.key}>
                        {translation.key}
                    </Link>
                </td>

                {selected_languages && selected_languages.map((language, j) => (
                    <td className="border px-4 py-2" key={translation.group + '_' + translation.key + '_' + language}>
                        <TranslationUpdate 
                            group={translation.group}
                            the_key={translation.key}
                            language={language}
                            value={translation[language]}
                            updateValueCallback={this.updateValue}
                        />
                    </td>
                ))}
            </tr>
        );
    }
}
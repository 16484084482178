import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, DangerButton} from 'Components/Button';
import {RenderWYSIWYG} from 'Components/Form';

import User from 'Services/User';
import {translation} from 'Services/TranslationHelpers';
import {translationFromJson} from 'Services/TranslationHelpers2';

export default class PushNotificationModal extends React.Component {
    /**
     * @method handleDismiss
     */
    handleDismiss = () => {
        const {pushNotification, preview} = this.props;

        if (!preview) {
            let item_name = 'push_notification_' + pushNotification.id + '_' + User.id;

            localStorage.setItem(item_name, new Date());
        }

        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {pushNotification} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {translationFromJson(pushNotification.title)}
                </ModalHeader>

                {pushNotification.content &&
                    <ModalBody>
                        <RenderWYSIWYG content={translationFromJson(pushNotification.content)} />
                    </ModalBody>
                }

                <ModalFooter alignment="center">
                    <PrimaryButton text={translation('misc', 'close')} onClick={this.handleDismiss} />
                </ModalFooter>
            </Modal>
        )
    }
};

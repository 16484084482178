import React from 'react';
import YouTube from 'react-youtube';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart, faComment, faDownload, faSearch, faPlay} from '@fortawesome/free-solid-svg-icons';

import Authenticated from 'Components/Layouts/Authenticated';
import CmsElement from 'Components/Utilities/CmsElement';
import {Loading, PaginationBar} from 'Components/Partials';
import {ModalTrigger} from 'Components/Modal';
import ViewCmsElementRecordModal from 'Components/Partials/Modals/ViewCmsElementRecordModal';

import User from 'Services/User';
import {translation} from 'Services/TranslationHelpers';
import {getRecordValue} from 'Services/CmsHelpers';

import OnDemandItem from './OnDemandItem';

export default class OnDemand extends CmsElement {
    /**
     * @constructor
     */
    constructor() {
        super();
        this.topVideoRef = React.createRef();
    }

    /**
     * @var cmsElementIdentifier
     * @type {string}
     */
    cmsElementIdentifier = 'on-demand';

    /**
     * @var paginate
     * @type {boolean}
     */
    paginate = false;

    /**
     * @var useHeaders
     * @type {boolean}
     */
    useHeaders = true;

    /**
     * @var useTopVideo
     * @type {boolean}
     */
    useTopVideo = true;

    /**
     * @var state
     */
    state = {
        working: true,
        topVideo: null,
    };

    /**
     * @method setTopVideo
     * @param {object} item
     */
    setTopVideo = (item) => {
        if (!getRecordValue(item, 'video_src')) {
            if (getRecordValue(item, 'download_file', 'file')) {
                alert(translation('cms', 'click_download_icon'));
            }

            return;
        }

        this.setState({
            topVideo: item
        });

        if (this.topVideoRef.current !== null) {
            this.topVideoRef.current.scrollIntoView();
        }
    }
    /**
     * @method render
     * @return {*}
     */
    render () {
        const {working, records} = this.state;

        let headers = [];

        if (this.useHeaders && records && records.data) {
            headers = [...new Set(
                records.data.map(item => getRecordValue(item, 'header'))
            )];
        }

        return (
            <Authenticated>
                <div className="p-4 text-center">
                    <h1 className={"h1 mt-4 mb-8 font-bold"}>
                        {translation('cms', 'on_demand')}
                    </h1>

                    <div className="w-3/4 mx-auto">
                        <div className="relative w-full md:w-3/4 lg:w-1/2 mx-auto">
                            {this.renderSearchBar()}
                            {this.useTopVideo && this.renderTopBox()}
                        </div>

                        {working && <Loading />}

                        {!working &&
                            <div className="relative z-10">
                                {records.data !== null && (!records.data || records.data.length === 0) && (
                                    <p className="text-center">
                                        {translation('misc', 'no_items', {
                                            item: translation('cms', 'on_demand')
                                        })}
                                    </p>
                                )}

                                {records.data && this.useHeaders && headers.map((header, k) => {
                                    const items = records.data.filter(o => getRecordValue(o, 'header') === header);

                                    return (
                                        <React.Fragment key={k}>
                                            <div className="my-14" key={`header-${k}`}>
                                                <p className="font-bold text-2xl mb-3 text-center md:text-left">
                                                    {header}
                                                </p>

                                                {items.length === 0 && (
                                                    <p className="mb-3 text-center md:text-left text-white">
                                                        {translation('misc', 'no_items', {
                                                            item: translation('cms', 'on_demand')
                                                        })}
                                                    </p>
                                                )}

                                                {items.length > 0 && (
                                                    <div className={'video-grid grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-2 mb-8 gap-4'}>
                                                        {items.map(this.renderItem)}
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )
                                })}

                                {records.data && !this.useHeaders && 
                                    <div className={'video-grid grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-2 mb-8 gap-4'}>
                                        {records.data.map(this.renderItem)}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Authenticated>
        );
    }

    /**
     * @method renderSearchBar
     * @return {JSX.Element}
     */
    renderSearchBar = () => {
        const {filters} = this.state

        let search = filters?.search ?? null;

        return (
            <div className="mx-auto flex justify-center relative z-10 mb-16" ref={this.topVideoRef}>
                <input
                    type="text"
                    className={`w-11/12 mt-6 border-0 bg-blue-500 rounded-full text-white
                        outline-none focus:outline-none focus:border-0 focus:ring-0 font-bold py-3 placeholder-white pl-10`}
                    placeholder={translation('misc', 'search')}
                    onChange={(e) => this.setFilter('on_demand_search', e.target.value)}
                    value={search}
                />

                <FontAwesomeIcon
                    icon={faSearch}
                    size="1x"
                    className="h-6 w-6 absolute top-9 right-16 text-white"
                />
            </div>
        );
    }

    /**
     * @method renderTopBox
     * @return {JSX.Element}
     */
    renderTopBox = () => {
        let {topVideo} = this.state;

        return (
            <div className="mb-24 relative z-10">
                <div className="mx-auto rounded-sm bg-app-lightgrey flex flex-col justify-between border border-blue-500">
                    {!topVideo &&
                        <div className="h-80 flex justify-center">
                            <FontAwesomeIcon
                                icon={faPlay}
                                size="2x"
                                className="h-16 w-16 self-center"
                            />
                        </div>
                    }

                    {topVideo &&
                        this.renderItemBody(topVideo, 'index')
                    }

                    {this.renderItemBar(topVideo)}
                </div>
            </div>
        );
    }

    /**
     * @method renderItem
     * @param {object} item
     * @param {string} key
     * @return {JSX.Element}
     */
    renderItem = (item, key) => {
        return (<OnDemandItem 
            item={item}
            useTopVideo={this.useTopVideo}
            setTopVideo={this.setTopVideo}
            renderItemBar={this.renderItemBar}
            renderItemBody={this.renderItemBody}
            key={key}
        />);
    };

    /**
     * @method renderItemBar
     * @param {object} item
     * @return {JSX.Element}
     */
    renderItemBar = (item) => {
        let item_title = item ? getRecordValue(item, 'title') : null;
        let item_download_file = item ? getRecordValue(item, 'download_file', 'file') : null;

        return (
            <div className="flex justify-between p-2 bg-blue-600 text-white min-h-12 h-full">
                <div className="ml-2 flex">
                    <p className={"self-center"}>
                        {item ? item_title : translation('cms', 'click_video_to_begin')}
                    </p>
                </div>

                {item && 
                    <div className="flex items-center select-none">
                        {item_download_file &&
                            <a href={item_download_file} download target="_blank" className="mr-2">
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    size="1x"
                                    className={"mx-1 cursor-pointer"}
                                />
                            </a>
                        }

                        {item.allow_likes && (
                            <div className="flex mr-2">
                                <FontAwesomeIcon
                                    icon={faHeart}
                                    size="1x"
                                    className={`mx-1 cursor-pointer ${item.user_has_liked ? 'text-red-500' : 'text-white'}`}
                                    onClick={() => this.handleToggleLike(item)}
                                />

                                {(item.total_likes !== undefined && item.total_likes > 0) &&
                                    <div className={"text-xs text-white"}>
                                        {item.total_likes}
                                    </div>
                                }
                            </div>
                        )}

                        {item.allow_comments && (
                            <ModalTrigger component={ViewCmsElementRecordModal} props={{
                                item,
                                handleToggleLike: this.handleToggleLike,
                                handleView: this.handleView,
                                handleCommentNumberUpdate: this.handleCommentNumberUpdate,
                                title: item_title,
                                body: this.renderItemBody,
                                content: null,
                            }}>
                                <div className="flex">
                                    <FontAwesomeIcon
                                        icon={faComment}
                                        size="1x"
                                        className="mx-1"
                                    />

                                    {item.total_comments !== undefined && item.total_comments > 0 &&
                                        <div className={"text-xs text-white"}>
                                            {item.total_comments}
                                        </div>
                                    }
                                </div>
                            </ModalTrigger>
                        )}
                    </div>
                }
            </div>
        );
    }

    /**
     * @method renderItemBody
     * @param {object} item
     * @param {string} source
     * @return {JSX.Element}
     */
    renderItemBody = (item, source) => {
        // Iframe responsive; https://www.w3schools.com/howto/howto_css_responsive_iframes.asp
        
        let item_video_type = getRecordValue(item, 'video_type');
        let item_video_src = getRecordValue(item, 'video_src');
        
        return (
            <div className="h-full w-full">
                {item_video_type === 'youtube' && (
                    <YouTube
                        videoId={item_video_src}
                        id="2g811Eo7K8U"
                        className="w-full h-full"
                        containerClassName="w-full h-80"
                        onPlay={() => this.handleView(item)}
                    />
                )}
                {item_video_type === 'vimeo' && (
                    <div className="relative h-full w-full" style={{paddingTop: '56.25%'}}>
                        <iframe src={item_video_src}
                            className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                        </iframe>
                    </div>
                )}

                {(item_video_type !== 'youtube' && item_video_type !== 'vimeo') && (
                    <video className="w-full h-full" controls>
                        <source src={item_video_src}/>
                    </video>
                )}
            </div>
        );
    }
}

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import GoogleAnalytics from 'Services/GoogleAnalytics';

class RouterHistory extends Component {
    componentDidMount() {
        this.reportView();
    }

    componentDidUpdate() {
        this.reportView();
    }

    reportView() {
        GoogleAnalytics.pageView(window.location.pathname + window.location.search);
    }

    render() {
        return null;
    }
}

export default withRouter(RouterHistory);

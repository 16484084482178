import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';

import AdminLayout from 'Pages/Admin/AdminLayout';

import ElementApi from 'Services/Api/Admin/Cms/Elements/Element';
import {translationFromJson} from 'Services/TranslationHelpers2';

export default class AdminCmsElementsIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Label',
            value: (record) => translationFromJson(record.label),
            type: 'callback',
            orderable: null
        },
        {
            label: 'Attach To Service User (Narrative)',
            value: 'attach_to_service_user',
            type: 'boolean',
            orderable: 'attach_to_service_user'
        },
        {
            label: 'Health & Safety',
            value: 'health_and_safety',
            type: 'boolean',
            orderable: 'health_and_safety'
        },
        {
            label: 'Quality Assurance',
            value: 'quality_assurance',
            type: 'boolean',
            orderable: 'quality_assurance'
        },
        {
            label: 'Medication Management',
            value: 'medication_management',
            type: 'boolean',
            orderable: 'medication_management'
        },
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        return [
            {
                type: "link",
                to: "/admin/cms/elements/show/"+item.id,
                text: "Manage"
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'element',
                    itemId: item.id,
                    onDeleteApi: ElementApi.delete
                },
                text: 'Delete'
            },
            {
                type: "link",
                to: "/admin/cms/elements/records/"+item.id,
                text: "View Records"
            },
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Forms">
                <IndexTable
                    columns={this.columns}
                    displaySearchBar={false}
                    storeLink="/admin/cms/elements/store"
                    loadDataCallback={(data) => ElementApi.get(null, {
                        ...data,
                        paginate: true
                    })}
                    rowActions={this.getRowActions}
                    rowClickRedirect={(item) => "/admin/cms/elements/show/"+item.id}
                    eventApi={ElementApi}
                />
            </AdminLayout>
        );
    }
}

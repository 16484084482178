import React from 'react';
import EventEmitter from 'events';
import {toast} from 'react-toastify';

import {Error, Success} from 'Components/Toast';

class Toast extends EventEmitter {
    /**
     * @method success
     * @param {string} body
     * @param {string|null} title
     */
    success = (body, title = null, autoClose = 5000) => {
        let ref = toast(
            <Success body={body} title={title} />,
            { autoClose }
        );

        this.emit('toast-open', {
            type: 'success'
        });

        return ref;
    };

    /**
     * @method error
     * @param {string|null} body
     * @param {string|null} title
     */
    error = (body = null, title = null, autoClose = 5000) => {
        let ref = toast(
            <Error body={body} title={title} />,
            { autoClose }
        );

        this.emit('toast-open', {
            type: 'success'
        });

        return ref;
    };
}

export default new Toast();

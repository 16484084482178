import React from 'react';

import SettingsPage from 'Pages/Settings/SettingsPage';

import {Loading, PaginationBar} from 'Components/Partials';
import {Input} from 'Components/Form';
import HrCategoryField from 'Components/Form/HrCategoryField';

import {Toast} from 'Services';
import DocumentsApi from 'Services/Api/HumanResources/Documents';

export default class MyDocuments extends React.Component {
    /**
     * @var state
     * @type {{myDocuments: null, loading: boolean}}
     */
    state = {
        loading: false,
        myDocuments: null,
        filters: {
            categories: [],
            search: ''
        }
    };

    /**
     * @method componentDidMount
     */
    async componentDidMount() {
        await this.fetchMyDocuments();
    };

    /**
     * @method fetchMyDocuments
     * @param {number} page
     * @return {Promise<void>}
     */
    fetchMyDocuments = async (page = 1) => {
        this.setState({ loading: true });

        const request = await DocumentsApi.get(null, {
            page,
            ...this.state.filters
        });

        if (request.success) {
            return this.setState({
                loading: false,
                myDocuments: request.data
            });
        }

        Toast.error();
    };

    /**
     * @method handleDownload
     * @param {object} document
     */
    handleDownload = (document) => {
        DocumentsApi.downloadFile(
            `/human-resources/documents/${document.id}/download`,
            {},
            `document-${document.id}.pdf`
        );
    };

    /***
     * @method handleFilters
     * @param {string} field
     * @param {string|string[]} value
     */
    handleFilters = (field, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [field]: value
            }
        }, this.fetchMyDocuments);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { loading, myDocuments, filters } = this.state;

        return (
            <SettingsPage>
                <div className="p-6 border-b border-gray-20">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                        HR Documents
                    </h2>
                </div>

                <div className="p-6">
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            label="Search"
                            value={filters.search}
                            onChange={(v) => this.handleFilters('search', v)}
                        />

                        <HrCategoryField
                            isCreatable={false}
                            value={filters.categories}
                            onChange={(v) => this.handleFilters('categories', v)}
                        />
                    </div>
                </div>

                {loading && (
                    <div className="p-6">
                        <Loading />
                    </div>
                )}

                {!loading && myDocuments && (
                    <React.Fragment>
                        <div className="p-6">
                            {myDocuments.meta.total === 0 ? (
                                <p className="text-center">
                                    You currently have no documents available
                                </p>
                            ) : (
                                <ul className="mt-2 divide-y divide-gray-200">
                                    {myDocuments.data.map(document => (
                                        <li className="py-4 flex items-center justify-between" key={document.id}>
                                            <div className="flex flex-col cursor-pointer" onClick={() => this.handleDownload(document)}>
                                                <p className="text-sm font-medium text-gray-900">
                                                    {document.name}
                                                </p>

                                                <div className="flex mt-1">
                                                    {document.categories.map(category => (
                                                        <div className="mr-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">{category.name}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {myDocuments.meta.total > 0 && (
                            <div className="p-6">
                                <PaginationBar
                                    total={myDocuments.meta.total}
                                    pageCount={myDocuments.meta.last_page}
                                    page={myDocuments.meta.current_page}
                                    goToPage={this.fetchMyDocuments}
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </SettingsPage>
        );
    }
}

import {faIdCard,
    faCalendar,
    faBusinessTime,
    faUmbrellaBeach,
    faThermometerThreeQuarters,
    faDollarSign} from '@fortawesome/free-solid-svg-icons';

export default [
    {
        icon: faIdCard,
        title: "Staff HR",
        admin: true,
        sub: [
            {
                scope: "hr-admin",
                to: "/admin/human-resources/calendar",
                icon: faCalendar,
                title: "Calendar",
            },
            {
                scope: "hr-admin",
                to: "/admin/human-resources/timesheets",
                icon: faBusinessTime,
                title: "Timesheets",
            },
            {
                scope: "hr-admin",
                to: "/admin/human-resources/holidays",
                icon: faUmbrellaBeach,
                title: window.base.hr_timesheet_types['holiday']?.label,
            },
            {
                scope: "hr-admin",
                to: "/admin/human-resources/absences",
                icon: faThermometerThreeQuarters,
                title: window.base.hr_timesheet_types['sick']?.label,
            },
            {
                scope: "hr-admin",
                to: "/admin/human-resources/time-worked",
                icon: faBusinessTime,
                title: window.base.hr_timesheet_types['time_worked']?.label,
            },
            {
                scope: "hr-admin",
                to: "/admin/human-resources",
                icon: faIdCard,
                title: "Staff",
            },
        ],
    },
    {
        feature: "rates",
        scope: "hr-admin",
        admin: true,
        to: "/admin/human-resources/rates",
        icon: faDollarSign,
        title: "HR Rates",
    },
];

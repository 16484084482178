import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import Base from './Base';

/**
 * @function PrimaryButton
 * @param {function} onClick
 * @param {string} className
 * @param {string} text
 * @param {boolean} working
 * @param {boolean} disabled
 * @param {string} [type]
 * @return {JSX.Element}
 * @constructor
 */
const PrimaryButton = ({ onClick, className, text, working, disabled, type }) => {
    let classes = `
        bg-app-supporting-yellow border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex
        justify-center items-center text-sm font-robot text-white
        ${
            !disabled && !working
                ? 'hover:animate-pulse focus:outline-none'
                : 'opacity-50'
        }
        ${className}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <Base
            className={classes}
            type={type ?? (onClick !== undefined ? 'button' : 'submit')}
            onClick={onClick}
            disabled={disabled}
            working={working}
            text={text}
        />
    );
};

export default PrimaryButton;

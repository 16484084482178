import React from 'react';

import {Select} from 'Components/Form';

/**
 * @function MultipleChoiceFilter
 * @param {string|null} containerClassName
 * @param {function} onChange
 * @param {object} filters
 * @param {string} column
 * @param {string} label
 * @param {boolean} defaultMethod
 * @param {object} props
 * @constructor
 */
const MultipleChoiceFilter = ({containerClassName, onChange, filters, column, label, defaultMethod = true, props}) => {
    // defaultMethod => DualityStudio\Base\Filter\QueryFilter.php -> equals().
    // !defaultMethod => Custom filter "column" on the request.
    let column_key = defaultMethod ? column + "[equals]" : column;

    return (
        <Select
            containerClassName={containerClassName ?? "w-48"}
            label={label}
            placeholder="Filter..."
            onChange={(v) => onChange(column_key, v)}
            value={filters && filters[column_key] ? filters[column_key] : null}
            options={props.options}
            isClearable={true}
        />
    );
};

export default MultipleChoiceFilter;

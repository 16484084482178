import ApiBase from '../ApiBase';

class Chats extends ApiBase {
    route = '/networking/chats';

    /**
     * @returns {Promise<*>}
     */
    getNumberUnread = () => {
        return this.get(this.route + '/number-unread');
    };

    /**
     * @param {string} message_to_type
     * @param {string} message_to_id
     * @param {string} message
     * @param {string} additional_key
     * @returns {Promise<*>}
     */
    postMessage = (message_to_type, message_to_id, message, additional_key) => {
        return this.post(this.route, {
            message_to_type,
            message_to_id,
            message,
            additional_key
        });
    };

    /**
     * @param {string} message_to_type
     * @param {string} message_to_id
     * @param {integer} page
     * @param {string} additional_key
     * @returns {Promise<*>}
     */
    getChat = (message_to_type, message_to_id, page, additional_key) => {
        return this.get(this.route + '/' + message_to_id, {
            page,
            message_to_type,
            additional_key
        });
    };
}

export default new Chats;

import ApiBase from '../ApiBase';

class NotificationSettings extends ApiBase {
    route = '/comms/notification-settings';

    /**
     * @method getAvailableChannels
     * @return {Promise<*>}
     */
    getAvailableChannels = (id) => {
        return this.get(this.route + '/available-channels');
    };

    /**
     * @method unsubscribeAll
     * @param {string} channel
     * @return {Promise<*>}
     */
    unsubscribeAll = (channel) => {
        return this.patch(this.route + '/unsubscribeAll', {
            channel
        });
    };
}

export default new NotificationSettings();

import React from 'react';

import AdminUsersApi from 'Services/Api/Admin/HumanResources/Users';
import MeSettingsApi from 'Services/Api/HumanResources/MeSettings';
import AdminUserSettingsApi from 'Services/Api/Admin/HumanResources/UserSettings';

export default class TimesheetsBase extends React.Component {
    /**
     * @method fetchUser
     * @return {Promise<void>}
     */
    fetchUser = async () => {
        const request = await AdminUsersApi.get(this.props.userId);

        if (request.success) {
            this.setState({
                user: request.data.data
            });
        }
    };

    /**
     * @method fetchUserSettings
     */
    fetchUserSettings = async () => {
        if (this.props.admin) {
            var response = await AdminUserSettingsApi.get({
                user: this.props.userId
            });
        } else {
            var response = await MeSettingsApi.get(null);
        }

        if (response.success) {
            this.setState({
                settings: response.data.data,
            })
        }
    };
}

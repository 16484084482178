import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {Select} from 'Components/Form';

import {translation} from 'Services/TranslationHelpers';

export default class ColumnsSelectModal extends React.Component {
    /**
     * @var state
     */
    state = {
        activeColumns: this.props.activeColumns,
    }

    /**
     * @method handleUpdate
     */
    handleUpdate = () => {
        const {onClose, callback} = this.props;
        const {activeColumns} = this.state;

        callback(activeColumns);

        onClose();
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {activeColumns} = this.state;
        const {onClose, columns} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={onClose}>
                    Columns
                </ModalHeader>

                <ModalBody>
                    <Select
                        placeholder="Select columns..."
                        value={activeColumns}
                        onChange={(v) => this.setState({activeColumns: v})}
                        options={columns}
                        isMulti={true}
                    />

                    <div className="flex justify-center items-center mt-4">
                        <PrimaryButton 
                            text={translation('misc', 'update')} 
                            onClick={this.handleUpdate}
                        />
                    </div>
                </ModalBody>

                <ModalFooter alignment="center">
                    <SecondaryButton 
                        text={translation('misc', 'cancel')} 
                        onClick={onClose}
                    />
                </ModalFooter>
            </Modal>
        )
    }
};

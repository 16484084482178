import React from 'react';

import { PrimaryButton, SecondaryButton } from 'Components/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'Components/Modal';
import { FormHandler, Textarea, Toggle } from 'Components/Form';
import { Alert } from 'Components/Partials';
import { Networking } from 'Components/Networking';

import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';

import { Toast } from 'Services';

class QueryTimesheetModal extends React.Component {
    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        const {timesheet, setForm} = this.props;

        if (timesheet) {
            setForm({
                query_resolved: timesheet.query_resolved ?? false
            });
        }
    }

    /**
     * @method handleSubmitTimesheet
     */
    handleRequest = async (form) => {
        const {admin, timesheet} = this.props;

        let data = {
            ...timesheet,
            ...form
        };

        if (admin) {
            return AdminTimesheetsApi.patch({
                timesheet: timesheet.id
            }, data);
        } else {
            return TimesheetsApi.patch(timesheet.id, data);
        }
    };

    /**
     * @method handleSuccess
     * @return {*}
     */
    handleSuccess = () => {
        Toast.success('Query has been submitted');

        return this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { timesheet, form, alert, working, getFieldError, handleInput, handleSubmit } = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    Query {timesheet?.type === 'holiday' ? 'Holiday' : 'Timesheet'}
                </ModalHeader>

                <ModalBody>
                    <Networking
                        displayUsers={false}
                        displayChats={false}
                        displayChat={true}
                        fullScreen={false}
                        useGroups={false}
                        selectedMessageToType='App\Models\HrTimesheet'
                        selectedMessageToId={timesheet.id}
                    />

                    {alert && <Alert {...alert} />}

                    <div className="bg-gray-400 p-4 rounded-lg mt-4 flex justify-center items-center gap-4">
                        <p>
                            Query Resolved
                        </p>

                        <Toggle
                            value={form.query_resolved}
                            onChange={v => handleInput('query_resolved', v)}
                        />
                    </div>
                </ModalBody>

                <form onSubmit={(e) => handleSubmit(e, this.handleRequest, this.handleSuccess)}>
                    <ModalFooter alignment="center">
                        <div className="space-x-4">
                            <SecondaryButton
                                text="Cancel"
                                onClick={this.props.onClose}
                            />

                            <PrimaryButton
                                text="Submit"
                                working={working}
                            />
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
}

export default FormHandler(QueryTimesheetModal);

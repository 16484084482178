import React from 'react';
import { toast } from 'react-toastify';
import { EventEmitter } from 'events';

import Echo from 'laravel-echo';
import Toast from 'Services/Toast';

window.io = require('socket.io-client');
window.Pusher = require('pusher-js')

class Sockets extends EventEmitter {
    /**
     * @constructor
     */
    constructor() {
        super();

        this.toastIdRef = React.createRef();
    }

    /**
     * @var echo
     * @type {null}
     */
    echo = null;

    /**
     * @var connected
     * @type {boolean}
     */
    connected = false;

    /**
     * @var hasBeenConnected
     * @type {boolean}
     */
    hasBeenConnected = false;

    /**
     * @method getConnection
     * @return {Echo}
     */
    getConnection = () => {
        if (this.echo === null && typeof io !== 'undefined') {
            if (process.env.MIX_ECHO_DRIVER === 'soketi') {
                this.echo = new Echo({
                    broadcaster: 'pusher',
                    key: process.env.MIX_PUSHER_APP_KEY,
                    wsHost: process.env.MIX_PUSHER_HOST,
                    wsPort: process.env.MIX_PUSHER_PORT,
                    wssPort: process.env.MIX_PUSHER_PORT,
                    forceTLS: process.env.MIX_PUSHER_SCHEME === 'https',
                    encrypted: true,
                    disableStats: true,
                    enabledTransports: ['ws', 'wss'],
                });

                this.handleSoketiConnectionEvents();
            } else {
                this.echo = new Echo({
                    client: io,
                    key: process.env.MIX_ECHO_KEY,
                    broadcaster: 'socket.io',
                    host: `${process.env.MIX_ECHO_HOST ?? window.location.hostname}`,
                    authEndpoint: `/broadcasting/auth`,
                });

                this.echo.connector.socket.on('connect', () => {
                    this.handleConnectedChange(true);
                });

                this.echo.connector.socket.on('disconnect', () => {
                    this.handleConnectedChange(false);
                });
            }

            // After we first attempt to connect leave it 5 seconds and check if first connected.
            setTimeout(() => this.checkIfConnected(), 5000);
        }

        return this.echo;
    };

    /**
     * @method getConnected
     * @return {boolean}
     */
    getConnected = () => {
        return this.connected;
    }

    /**
     * @method getMessage
     * @return {string}
     */
    getMessage = () => {
        if (this.connected === false) {
            if (this.hasBeenConnected === true) {
                return 'Disconnected from live updates. Please refresh your browser page to re-connect. If the problem persists please contact support.';
            } else {
                return 'Unable to connect to live updates. Please refresh your browser page to re-attempt to connect. If the problem persists please contact support.';
            }
        } else {
            return 'Live Updates Enabled';
        }
    }

    /**
     * @method checkIfConnected
     */
    checkIfConnected = () => {
        if (process.env.MIX_ECHO_DRIVER === 'soketi') {
            this.handleSoketiConnectionEvents();
        } else {
            this.handleConnectedChange(this.echo.connector.socket.connected);
        }
    }

    /**
     * @method handleSoketiConnectionEvents
     */
    handleSoketiConnectionEvents = () => {
        this.echo.connector.pusher.connection.bind('connected', () => {
            this.handleConnectedChange(true);
        });

        this.echo.connector.pusher.connection.bind('disconnected', () => {
            this.handleConnectedChange(false);
        });

        this.echo.connector.pusher.connection.bind('unavailable', () => {
            this.handleConnectedChange(false);
        });

        this.echo.connector.pusher.connection.bind('failed', () => {
            this.handleConnectedChange(false);
        });
    }

    /**
     * @method handleConnectedChange
     */
    handleConnectedChange = (connected) => {
        window.clearTimeout(this.displayErrorTimeout);

        if (connected) {
            this.connected = true;
            this.hasBeenConnected = true;

            this.hideError();
        } else {
            this.connected = false;

            // Only display the error if it's still relevant 10 seconds later
            // to allow the socket to automatically reconnect if possible.
            this.displayErrorTimeout = setTimeout(() => this.displayError(), 10000);
        }

        this.emit('change');
    }

    /**
     * @method displayError
     */
    displayError = () => {
        // If a MIX_ECHO_HOST is set (i.e. broadcasts are supposed to be working) and we're not on local dev then display the error message.
        if (!(!process.env.MIX_ECHO_HOST || process.env.MIX_ECHO_HOST.startsWith(':')) && process.env.MIX_APP_ENV !== 'local') {
            if (this.connected === false && !this.toastIdRef.current) {
                this.toastIdRef.current = Toast.error(
                    this.getMessage(),
                    'Live Updates',
                );
            }
        }
    }

    /**
     * @method hideError
     */
    hideError = () => {
        if (this.toastIdRef.current) {
            toast.dismiss(this.toastIdRef.current);

            this.toastIdRef.current = null;
        }
    }
}

export default new Sockets();

import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {DangerButton, PrimaryButton} from 'Components/Button';

import {Toast} from 'Services';
import DocumentsApi from 'Services/Api/Admin/HumanResources/Documents';

export default class ToggleVisibility extends React.Component {
    /**
     * @var state
     * @type {{working: boolean}}
     */
    state = {
        working: false
    };

    /**
     * @method handleToggleVisibility
     * @return {Promise<*>}
     */
    handleToggleVisibility = async () => {
        this.setState({working: true});

        const request = await DocumentsApi.patch({
            user: this.props.user.id,
            document: this.props.document.id
        }, {
            ...this.props.document,
            visible_to_user: !this.props.document.visible_to_user
        }, 'update');

        if (request.success) {
            Toast.success('Document has been updated');

            return this.props.onClose();
        }

        Toast.error();
    };

    /**
     *
     * @returns {JSX.Element}
     */
    render () {
        const { document, user } = this.props;
        const { working } = this.state;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    Update Document
                </ModalHeader>

                <ModalBody>
                    <p className="text-center">
                        Are you sure you want to make this document {document.visible_to_user ? 'hidden' : 'visible'} for {user.full_name}.
                    </p>
                </ModalBody>

                <ModalFooter alignment="center">
                    <DangerButton
                        text="Cancel"
                        working={working}
                        onClick={this.props.onClose}
                        className="mr-2"
                    />

                    <PrimaryButton
                        text="Continue"
                        working={working}
                        onClick={this.handleToggleVisibility}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {DraggableIndexTable} from 'Components/Utilities';

import FieldApi from 'Services/Api/Admin/Cms/Elements/Field';
import {translationFromJson} from 'Services/TranslationHelpers2';
import {capitalizeFirstLetter} from 'Services/BaseHelpers';

import FieldsStore from './store';

export default class AdminCmsElementFieldsIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Order',
            value: 'order',
            type: 'string',
            orderable: 'order'
        },
        {
            label: 'Label',
            value: (record) => translationFromJson(record.label),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Type',
            value: (record) => capitalizeFirstLetter(record.type.replace(/_/g, ' ')),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Admin Only',
            value: 'admin_only',
            type: 'boolean',
            orderable: null
        },
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        const {element_id} = this.props;

        return [
            {
                type: "modalTrigger",
                component: FieldsStore,
                props: {
                    element_id,
                    field: item
                },
                text: 'Update'
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'field',
                    itemId: item.id,
                    onDeleteApi: FieldApi.delete
                },
                text: 'Delete'
            },
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {element_id} = this.props;

        return (
            <div className="mt-12 bg-gray-200 p-4">
                <h3 className="text-lg my-4 font-bold text-center">
                    Inputs
                </h3>

                <DraggableIndexTable
                    columns={this.columns}
                    defaultOrder={{
                        order_by: 'order',
                        order: 'asc'
                    }}
                    displaySearchBar={false}
                    storeModal={{
                        component: FieldsStore,
                        props:{
                            element_id
                        }
                    }}
                    loadDataCallback={(data) => FieldApi.get(null, {
                        ...data,
                        cms_element_id: element_id
                    })}
                    rowActions={this.getRowActions}
                    eventApi={FieldApi}
                    orderable={true}
                    saveOrderCallback={FieldApi.updateOrder}
                    itemName="fields"
                    actionsJustify="center"
                />
             </div>
        );
    }
}
import React, { useEffect, useState } from "react";

import { PrimaryButton } from "Components/Button";
import { Textarea, Input, Select, DatePicker, FormHandler } from "Components/Form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "Components/Modal";
import { Alert, Loading } from "Components/Partials";

import MarsInformationApi from "Services/Api/ServiceUsers/MarsInformation";

const genderOptions = [
    { label: "Male", value: "Male", },
    { label: "Female", value: "Female", },
];

const EditMarsInformation = ({ handleRefresh, serviceUserId, onClose, form, working, setForm, alert, handleSubmit, getFieldError, handleInput }) => {
    const [loading, setLoading] = useState(true);

    const handleRequest = (data) => MarsInformationApi.patch({
        service_user: serviceUserId,
    }, data);

    const handleSuccess = () => {
        handleRefresh();
        onClose();
    };

    useEffect(() => {
        (async () => {
            const { data, success } = await MarsInformationApi.get({
                service_user: serviceUserId,
            });

            if (success) {
                setForm({
                    ...data.data,
                    dob: data.data.dob ? new Date(data.data.dob) : null,
                    start_date: data.data.start_date ? new Date(data.data.start_date?.iso_string) : null,
                });
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Modal>
            <form onSubmit={(e) => handleSubmit(e, handleRequest, handleSuccess)}>
                <ModalHeader onClose={onClose}>
                    Edit MARS Information
                </ModalHeader>

                {loading && (
                    <Loading />
                )}

                {!loading && (
                    <>
                        <ModalBody>
                            <div className="space-y-4">
                                {alert && (<Alert {...alert} />)}

                                <Textarea
                                    label="Pharmacy"
                                    value={form.pharmacy}
                                    onChange={(e) => handleInput("pharmacy", e)}
                                    error={getFieldError("pharmacy")}
                                />

                                <Input
                                    label="Name"
                                    value={form.name}
                                    onChange={(e) => handleInput("name", e)}
                                    error={getFieldError("name")}
                                />

                                <Input
                                    label="NHS Number"
                                    value={form.nhs_number}
                                    onChange={(e) => handleInput("nhs_number", e)}
                                    error={getFieldError("nhs_number")}
                                />

                                <DatePicker
                                    label="D.O.B"
                                    selected={form.dob}
                                    onChange={(date) => handleInput("dob", date)}
                                    error={getFieldError("dob")}
                                />

                                <Input
                                    label="Allergies/Conditions"
                                    value={form.allergies}
                                    onChange={(e) => handleInput("allergies", e)}
                                    error={getFieldError("allergies")}
                                />

                                <Select
                                    options={genderOptions}
                                    value={form.gender}
                                    label="Gender"
                                    onChange={(e) => handleInput("gender", e)}
                                    error={getFieldError("gender")}
                                />

                                <Input
                                    label="Address"
                                    value={form.address}
                                    onChange={(e) => handleInput("address", e)}
                                    error={getFieldError("address")}
                                />

                                <Input
                                    label="Doctor"
                                    value={form.doctor}
                                    onChange={(e) => handleInput("doctor", e)}
                                    error={getFieldError("doctor")}
                                />

                                {/*<DatePicker*/}
                                {/*    label="Start Date"*/}
                                {/*    selected={form.start_date}*/}
                                {/*    onChange={(date) => handleInput("start_date", date)}*/}
                                {/*    error={getFieldError("start_date")}*/}
                                {/*/>*/}

                                <Input
                                    label="Patient ID"
                                    value={form.patient_id}
                                    onChange={(e) => handleInput("patient_id", e)}
                                    error={getFieldError("patient_id")}
                                />

                                <Input
                                    label="Prescribing Organisation"
                                    value={form.prescribing_organisation}
                                    onChange={(e) => handleInput("prescribing_organisation", e)}
                                    error={getFieldError("prescribing_organisation")}
                                />
                            </div>
                        </ModalBody>

                        <ModalFooter alignment="center">
                            <PrimaryButton
                                working={working}
                                text="Save"
                            />
                        </ModalFooter>
                    </>
                )}
            </form>
        </Modal>
    );
};

export default FormHandler(EditMarsInformation);

import React from 'react';

import {Select} from 'Components/Form';

import {Toast} from 'Services';
import CategoriesApi from 'Services/Api/HumanResources/Categories';

export default class HrCategoryField extends React.Component {
    /**
     * @var state
     * @type {{categories: []}}
     */
    state = {
        categories: [],
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.fetchCategories();
    };

    /**
     * @method fetchCategories
     * @return {Promise<void>}
     */
    fetchCategories = async () => {
        const request = await CategoriesApi.get(null, {
            type: this.props.type
        });

        if (request.success) {
            return this.setState({
                categories: request.data.data.map(category => ({
                    value: category.name,
                    label: category.name,
                })),
            });
        }

        Toast.error();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { categories } = this.state;

        return (
            <Select
                options={categories}
                label="Categories"
                value={this.props.value}
                onChange={this.props.onChange}
                isCreatable={this.props.isCreatable !== undefined ? this.props.isCreatable : true}
                isMulti={this.props.isMulti !== undefined ? this.props.isMulti : true}
            />
        )
    }
}

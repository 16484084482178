import React from 'react';
import {NavLink} from 'react-router-dom';
import { Transition } from '@headlessui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';

import {User} from 'Services';
import {filterItemsByAccess} from 'Services/BaseHelpers';

export default class NavItem extends React.Component {
    /**
     * @var state
     * @type {{dropdown: boolean}}
     */
    state = {
        dropdown: false,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        const {sub} = this.props.item;

        // If one of the sub items is active then default to
        // the menu open.
        if (sub) {
            let dropdown = false;

            sub.map((item, i) => {
                if (item.to === location.pathname) {
                    dropdown = true;
                }
            });

            this.setState({dropdown});
        }
    };

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {dropdown} = this.state;
        const {subNav} = this.props;
        const {to, icon, title, sub, exact = false} = this.props.item;

        let className1 = `p-4 flex items-center text-md text-white select-none font-league-spartan transition duration-200 hover:bg-app-supporting-yellow`;

        let className2 = `flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-app-typography select-none`;

        if (sub) {
            return (
                <div>
                    <div
                        className={`${className1} cursor-pointer`}
                        onClick={() => this.setState({dropdown: !dropdown})}
                    >
                        <FontAwesomeIcon
                            icon={icon}
                            className={className2}
                            fixedWidth={true}
                        />

                        <span className="truncate">
                            {title}
                        </span>

                        <FontAwesomeIcon icon={dropdown ? faCaretUp : faCaretDown} className="ml-2" />
                    </div>

                    <div className="relative overflow-hidden">
                        <Transition
                            show={dropdown}
                            enter="transition ease duration-500 transform"
                            enterFrom="opacity-0 -translate-y-full"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease duration-500 transform"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-full"
                        >
                            <div>
                                {filterItemsByAccess(sub).map((item, i) => (
                                    <NavItem
                                        item={item}
                                        key={i}
                                        subNav={true}
                                    />
                                ))}
                            </div>
                        </Transition>
                    </div>
                </div>
            );
        }

        return (
            <NavLink
                to={to}
                className={`${className1} ${subNav ? 'transition-padding duration-200 group-hover:pl-8' : ''}`}
                activeClassName="bg-app-supporting-yellow"
                exact={exact}
            >
                <FontAwesomeIcon
                    icon={icon}
                    className={className2}
                    fixedWidth={true}
                />

                <span className="truncate">
                    {title}
                </span>
            </NavLink>
        );
    }
}

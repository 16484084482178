import React from 'react';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';

import Authenticated from 'Components/Layouts/Authenticated';
import {IndexTable} from 'Components/Utilities';
import {DatePicker} from 'Components/Form';

import CovidTestEntryStore from 'Pages/Covid/store';

import MissingCovidTestEntriesApi from 'Services/Api/Admin/Covid/MissingCovidTestEntries';

export default class MissingIndex extends React.Component {
    /**
     * @var state
     */
    state = {
        start_date: new Date(new Date().setDate(new Date().getDate() - 7)), // 1 week ago
        end_date: new Date()
    };

    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Date',
            value: 'date',
            type: 'string',
            orderable: null
        },
        {
            label: 'Employee',
            value: (record) => record?.employee?.full_name,
            type: 'callback',
            orderable: null
        }
    ];

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {start_date, end_date} = this.state;

        return (
            <Authenticated title="Missing Covid Test Entries" titleIcon={faTimesCircle}>
                <div className="flex flex-row justify-between items-center gap-4">
                    <DatePicker
                        label="Start"
                        selected={start_date}
                        onChange={date => this.setState({'start_date': date})}
                    />

                    <DatePicker
                        label="End"
                        selected={end_date}
                        onChange={date => this.setState({'end_date': date})}
                    />
                </div>

                <IndexTable
                    columns={this.columns}
                    loadDataCallback={(data) => MissingCovidTestEntriesApi.get(null, {
                        ...data,
                        start_date,
                        end_date
                    })}
                    key={start_date + '-' + end_date}
                />
            </Authenticated>
        )
    }
}

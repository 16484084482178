import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';
import {BooleanFilter, DateTimeFilter} from 'Components/Filters';

import AdminLayout from 'Pages/Admin/AdminLayout';

import AdminPushNotificationsApi from 'Services/Api/Admin/PushNotifications';
import {translationFromJson} from 'Services/TranslationHelpers2';

export default class PushNotificationsIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Type',
            value: 'type',
            type: 'string',
            orderable: 'type'
        },
        {
            label: 'Title',
            value: (record) => translationFromJson(record.title),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Start Date',
            value: 'start_date_time',
            type: 'datetime',
            orderable: 'start_date_time'
        },
        {
            label: 'End Date',
            value: 'end_date_time',
            type: 'datetime',
            orderable: 'end_date_time'
        },
        {
            label: 'Sent',
            value: 'sent',
            type: 'boolean',
            orderable: 'sent',
            filters: [
                {
                    column: 'sent',
                    label: 'Sent',
                    component: BooleanFilter
                }
            ]
        },
        {
            label: 'Created At',
            value: 'created_at',
            type: 'datetime',
            orderable: 'created_at',
            hideByDefault: true,
            filters: [
                {
                    column: 'created_at',
                    label: 'Created At Before',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'before'
                    }
                },
                {
                    column: 'created_at',
                    label: 'Created At After',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'after'
                    }
                }
            ]
        }
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        return [
            {
                type: "link",
                to: "/admin/push-notifications/store/"+item.id,
                text: "Update"
            },
            {
                type: "link",
                to: `/admin/push-notifications/${item.id}/logs`,
                text: "Logs"
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'push notification',
                    itemId: item.id,
                    onDeleteApi: AdminPushNotificationsApi.delete
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Push Notifications">
                {!window.base.features.allow_push_notifications &&
                    <p className="m-6 font-bold text-center">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.allow_push_notifications &&
                    <IndexTable
                        columns={this.columns}
                        displaySearchBar={false}
                        storeLink="/admin/push-notifications/store"
                        loadDataCallback={(data) => AdminPushNotificationsApi.get(null, data)}
                        rowActions={this.getRowActions}
                        rowClickRedirect={(item) => "/admin/push-notifications/store/"+item.id}
                        eventApi={AdminPushNotificationsApi}
                    />
                }
            </AdminLayout>
        )
    }
}

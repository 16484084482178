import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight, faCaretUp, faCaretDown} from '@fortawesome/free-solid-svg-icons';

import {Label, FileUpload, Select, Input} from 'Components/Form';
import {SecondaryButton} from 'Components/Button';

import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';

export default class ImageUpload extends React.Component {
    /**
     * @var state
     */
    state = {
        activeIndex: 0
    };

    /**
     * @method handleNewFile
     * @param {File} file
     */
    handleNewFile(file) {
        let {onChange, value} = this.props;

        if (!value) {
            value = [];
        }

        value.push({
            new_file: file,
            meta: {
                service_user_ids: [],
                location: null
            }
        })

        onChange(value);

        this.setState({
            activeIndex: value.length - 1
        });
    }

    /**
     * @method handleMetaUpdate
     * @param {integer} index
     * @param {string} key
     * @param {mixed} newvalue
     */
    handleMetaUpdate(index, key, newvalue) {
        let {onChange, value} = this.props;

        value[index].meta[key] = newvalue;

        onChange(value);
    }

    /**
     * @method handlePrevious
     */
    handlePrevious = () => {
        const {value} = this.props;
        const {activeIndex} = this.state;

        let newActiveIndex = +activeIndex - 1;

        if (newActiveIndex < 0) {
            newActiveIndex = value.length - 1;
        }

        this.setState({
            activeIndex: newActiveIndex
        });
    };

    /**
     * @method handleNext
     */
    handleNext = () => {
        const {value} = this.props;
        const {activeIndex} = this.state;

        let newActiveIndex = +activeIndex + 1;

        if (newActiveIndex >= value.length) {
            newActiveIndex = 0;
        }

        this.setState({
            activeIndex: newActiveIndex
        });
    };

    /**
     * @method handleRemove
     * @param {integer} index
     */
    handleRemove = (index) => {
        let {onChange, value} = this.props;

        value.splice(index, 1);

        onChange(value);

        this.setState({
            activeIndex: 0
        });
    }

    /**
     * @method render
     * @return {*}
     */
    render () {
        const {containerClassName, label, id, value, accept, placeholder, instructions, error = null} = this.props;
        const {activeIndex} = this.state;

        return (
            <div className={containerClassName ?? 'bg-gray-200 p-4'}>
                {label && (
                    <Label
                        label={label}
                        htmlFor={id}
                        instructions={instructions}
                        error={error}
                    />
                )}

                <div className="flex justify-center items-center">
                    <FileUpload
                        onChange={(file) => this.handleNewFile(file)}
                        accept="image/*, video/*"
                        dropzone={true}
                    />
                </div>

                {value && value.length !== 0 &&
                    <div className={`
                        bg-gray-400 p-4 mt-4 flex flex-row items-center gap-4
                        ${value.length > 1 ? 'justify-between' : 'justify-center'}
                    `}>
                        {value.length > 1 &&
                            <div
                                className="select-none cursor-pointer text-indigo-600"
                                onClick={() => this.handlePrevious()}
                            >
                                <FontAwesomeIcon icon={faCaretLeft} size="3x" className="hidden sm:flex"/>
                                <FontAwesomeIcon icon={faCaretUp} size="3x" className="sm:hidden"/>
                            </div>
                        }

                        <div className="w-full">
                            {this.renderImage()}

                            <p className="text-center mt-4 text-sm text-gray-700">
                                {activeIndex + 1} of {value.length}
                            </p>
                        </div>

                        {value.length > 1 &&
                            <div
                                className="select-none cursor-pointer text-indigo-600"
                                onClick={() => this.handleNext()}
                            >
                                <FontAwesomeIcon icon={faCaretRight} size="3x" className="hidden sm:flex"/>
                                <FontAwesomeIcon icon={faCaretDown} size="3x" className="sm:hidden"/>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

    /**
     * @method renderImage
     * @return {*}
     */
    renderImage() {
        const {value} = this.props;
        const {activeIndex} = this.state;

        let file = value[activeIndex];

        return (
            <div className="flex flex-col items-center w-full">
                <img
                    src={file.new_file ? URL.createObjectURL(file.new_file) : file.file_url}
                    className="w-96"
                />

                <div className="bg-white p-4 border-black mt-4 w-full">
                    <Input
                        containerClassName="mb-4"
                        label="Location"
                        value={file.meta.location}
                        onChange={(v) => this.handleMetaUpdate(activeIndex, 'location', v)}
                    />

                    <Select
                        label="Service Users"
                        value={file.meta.service_user_ids}
                        onChange={(v) => this.handleMetaUpdate(activeIndex, 'service_user_ids', v)}
                        isAsync
                        isMulti
                        searchCallback={(data) => ServiceUsersApi.get(null, {
                            ...data,
                            enabled: true
                        })}
                        searchLabelKey="full_name"
                        allowNull={true}
                    />
                </div>

                <div className="mt-4 space-x-4 flex items-center text-center">
                    <SecondaryButton
                        text="Remove"
                        onClick={() => this.handleRemove(activeIndex)}
                    />

                    {file.file_url &&
                        <div className="text-center">
                            <a
                                href={file.file_url}
                                target="_blank"
                            >
                                <SecondaryButton
                                    text="Download"
                                    onClick={null}
                                />
                            </a>
                        </div>
                    }
                </div>
            </div>
        );
    }
};

import React from 'react';

import {Select} from 'Components/Form';

/**
 * @function IsEmptyFilter
 * @param {string|null} containerClassName
 * @param {function} onChange
 * @param {object} filters
 * @param {string} column
 * @param {string} label
 * @param {boolean} defaultMethod
 * @constructor
 */
const IsEmptyFilter = ({containerClassName, onChange, filters, column, label, defaultMethod = true}) => {
    // defaultMethod => DualityStudio\Base\Filter\QueryFilter.php -> isEmpty().
    // !defaultMethod => Custom filter "column" on the request.
    let column_key = defaultMethod ? column + "[isEmpty]" : column;

    return (
        <Select
            containerClassName={containerClassName ?? "w-32"}
            label={label}
            placeholder="Filter..."
            onChange={(v) => onChange(column_key, v)}
            value={filters && filters[column_key] ? filters[column_key] : null}
            options={[
                {
                    label: 'Is Empty',
                    value: 'is_empty'
                },
                {
                    label: 'Is Not Empty',
                    value: 'is_not_empty'
                }
            ]}
            isClearable={true}
        />
    );
};

export default IsEmptyFilter;

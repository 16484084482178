import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {FormHandler, Input, Select} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import AdminGroupsApi from 'Services/Api/Admin/Groups';

class GroupsStore extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Group ' + (this.props.match?.params?.group ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     * @type {{group_id: string}}
     */
    state = {
        group_id: this.props.match?.params?.group ?? '',
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {        
        if (this.state.group_id) {
            this.loadGroup();
        }
    }

    /**
     * @method loadGroup
     */
    loadGroup = async () => {
        const {group_id} = this.state;

        const response = await AdminGroupsApi.get(group_id);

        let group = response.data.data;

        this.props.setForm({
            name: group.name,
            scopes: group.scopes,
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {group_id} = this.state;

        if (group_id) {
            return AdminGroupsApi.patch(group_id, form);
        } else {
            return AdminGroupsApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() { 
        const {group_id} = this.state;
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        let scopes = window.base.scopes.map((scope, key) => {
            return {
                label: scope,
                value: scope
            };
        });

        return (
            <AdminLayout title={'User Groups - ' + (group_id ? 'Update' : 'Create') + ' Group'}>
                {!window.base.features.allow_groups_update &&
                    <p className="m-6 font-bold text-center">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.allow_groups_update &&
                    <form
                        className="divide-y divide-gray-200"
                        onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (group_id ? false : true))}
                    >
                        <div className="mx-6">
                            {alert !== null && (<Alert {...alert} />)}

                            <div className="grid grid-cols-2 gap-4 mb-4 border-0">
                                <div>
                                    <Input
                                        label="Name"
                                        value={form.name}
                                        id="name"
                                        error={getFieldError('name')}
                                        onChange={(v) => handleInput('name', v)}
                                    />
                                </div>

                                <div>
                                    <Select
                                        label="Scopes"
                                        value={form.scopes}
                                        error={getFieldError('scopes')}
                                        onChange={(v) => handleInput('scopes', v)}
                                        options={scopes}
                                        isMulti
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="px-6 pt-6 flex justify-end gap-4">
                            <Link to="/admin/groups">
                                <SecondaryButton type="button" text="Back" />
                            </Link>

                            <PrimaryButton
                                text="Save"
                                working={working}
                            />
                        </div>
                    </form>
                }
            </AdminLayout>
        )
    }
}

export default FormHandler(GroupsStore);
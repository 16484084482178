import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

import {RenderWYSIWYG} from 'Components/Form/WYSIWYG';

import User from 'Services/User';

// publicPath
let publicPath = window.asset_url ?? '/';
if (!publicPath.endsWith('/')) {
    publicPath = publicPath + '/';
}

/**
 * @method asset
 * @param {string} path
 * @return {string}
 */
const asset = (path) => {
    if (path.startsWith('/')) {
        path = path.substring(1);
    }

    return publicPath + path;
}

/**
 * @method isAdminPage
 * @return {boolean}
 */
const isAdminPage = () => {
    return location.pathname.startsWith('/admin');
}

/**
 * @method mergeObjectsIntoPaginationData
 * @param {object} data
 * @param {array} objects
 * @param {boolean} reverse
 * @param {object} sameObjectCheck
 * @return {object}
 */
const mergeObjectsIntoPaginationData = (data, objects, reverse = false, sameObjectCheck = null) => {
    // Fallback to just checking on id.
    if (!sameObjectCheck) {
        sameObjectCheck = (o, object) => { return o.id === object.id };
    }

    if (!objects) {
        return data;
    }

    if (!data) {
        return {
            data: objects
        }
    }

    if (reverse) {
        objects.reverse().forEach(function(object) {
            data = mergeObjectIntoPaginationData(data, object, reverse, sameObjectCheck);
        })
    } else {
        objects.forEach(function(object) {
            data = mergeObjectIntoPaginationData(data, object, reverse, sameObjectCheck);
        })
    }

    return data;
}

/**
 * @method mergeObjectIntoPaginationData
 * @param {object} data
 * @param {object} object
 * @param {boolean} reverse
 * @param {object} sameObjectCheck
 * @return {object}
 */
const mergeObjectIntoPaginationData = (data, object, reverse, sameObjectCheck) => {
    if (data.data.filter(o => sameObjectCheck(o, object)).length === 0) {
        data = {
            data: reverse ? [
                object,
                ...data.data
            ] : [
                ...data.data,
                object
            ],
            meta: data.meta
        };
    }

    return data;
}

/**
 * @method capitalizeFirstLetter
 * @param {string} string
 * @return {string}
 */
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * @method convertValue
 * @param {object} column
 * @param {object} record
 * @return {string}
 */
const convertValue = (column, record) => {
    switch(column.type){
        case 'string':
            return record[column.value];
        case 'boolean':
            return record[column.value] ? 'Yes' : 'No';
        case 'boolean_fa':
            return <FontAwesomeIcon
                icon={record[column.value] ? faCheckCircle : faTimesCircle}
                className={`text-2xl ${record[column.value] ? 'text-green-400' : 'text-red-400'}`}
            />;
        case 'callback':
            return column.value(record);
        case 'datetime':
            return record[column.value] ? record[column.value].date_time_timezone : null;
        case 'date':
            return record[column.value] ? (record[column.value]?.date_timezone ?? (record[column.value] ?? null)) : null;
        case 'time':
            return record[column.value] ? record[column.value].time_timezone : null;
        case 'currency_gbp':
            return '£' + record[column.value];
        case 'wysiwyg':
            return <RenderWYSIWYG content={record[column.value]} />;
        case 'address':
            return renderAddress(record[column.value]);
        default:
            return record[column.value];
    }
}

/**
 * @method renderAddress
 * @param {object} value
 * @return {JSX.Element}
 */
const renderAddress = (value) => {
    if (!value) {
        return null;
    }

    return (
        <>
            {value.line_1}
            <br/>
            {value.line_2 &&
                <>
                    {value.line_2}
                    <br/>
                </>
            }
            {value.city}, {value.county}
            <br/>
            {value.country}
            <br/>
            {value.postcode}
        </>
    );
}

/**
 * @method checkAccess
 * @param {object} item
 * @return {boolean}
 */
const checkAccess = (item) => {
    if (item.is_admin && !User.data.is_admin) {
        return false;
    }

    if (item.is_not_admin && User.data.is_admin) {
        return false;
    }

    if (item.feature && !window.base.features[item.feature]) {
        return false;
    }

    if (item.team && User.data.active_team.name !== item.team) {
        return false;
    }

    if (item.group) {
        if (Array.isArray(item.group)) {
            if (!item.group.some(group => User.belongsToGroup(group))) {
                return false;
            }
        } else if (!User.belongsToGroup(item.group)) {
            return false;
        }
    }

    if (item.scope && !User.hasScope(item.scope)) {
        return false;
    }

    return true;
}

/**
 * @method filterItemsByAccess
 * @param {array} items
 * @return {array}
 */
const filterItemsByAccess = (items) => {
    return items.filter(item => checkAccess(item));
};

const convertDecimalToTime = (decimalHours) => {
    // Extract the whole number part (hours) and the decimal part (minutes)
    const hours = Math.floor(decimalHours);
    const minutesDecimal = (decimalHours - hours) * 60;
    const minutes = Math.round(minutesDecimal);

    // Construct the formatted time string
    return `${hours} hrs ${minutes} mins`;
};

export {
    asset,
    isAdminPage,
    mergeObjectsIntoPaginationData,
    capitalizeFirstLetter,
    convertValue,
    checkAccess,
    filterItemsByAccess,
    convertDecimalToTime,
};

import {EventEmitter} from 'events';
import axios, {AxiosRequestConfig} from 'axios';

class FileUploader extends EventEmitter {
    uploads = {};

    /**
     * @method startUpload
     * @desc Upload files in the background of the website
     * @param {string} uploadId
     * @param {AxiosRequestConfig} config
     * @param {boolean} [emits]
     * @param {boolean} [genericEmit]
     * @emits uploaded
     * @emits uploaded-{uploadId}
     * @emits aborted
     * @emits aborted-{uploadId}
     * @emits started
     * @emits started-{uploadId}
     * @emits progress-{uploadId}
     */
    startUpload = (uploadId, config, emits = true, genericEmit = true) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        config.cancelToken = source.token;

        if (emits) {
            config.onUploadProgress = (progressEvent) => {
                if (!this.uploads[uploadId]) return;

                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                this.emit('progress-' + uploadId, progress);

                if (genericEmit) {
                    this.emit('progress', {
                        uploadId,
                        progress
                    });
                }
            };
        }

        const request = axios(config).then((r) => {
            if (emits) {
                if (genericEmit) {
                    this.emit('uploaded', uploadId, r);
                }

                this.emit(`uploaded-${uploadId}`, r);
            }

            delete this.uploads[uploadId];

            return r;
        }).catch((error) => {
            if (!axios.isCancel(error)) {
                throw error;
            }

            if (genericEmit) {
                this.emit('aborted', uploadId, error.message);
            }
            this.emit(`aborted-${uploadId}`, error.message);
        });

        this.uploads[uploadId] = {
            request,
            cancel: source.cancel,
            progress: [],
        };

        if (genericEmit) {
            this.emit('started', uploadId, request);
        }

        this.emit(`started-${uploadId}`, request);

        return request;
    };

    /**
     * @method abortUpload
     * @param {string} uploadId
     * @param {string} [reason]
     */
    abortUpload = (uploadId, reason) => {
        if (!this.uploads[uploadId]) return;

        this.uploads[uploadId].cancel(reason);
    }
}

export default new FileUploader();
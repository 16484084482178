import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown } from "@fortawesome/free-solid-svg-icons/faFaceFrown";
import { faFaceMeh } from "@fortawesome/free-solid-svg-icons/faFaceMeh";
import { faFaceSmile } from "@fortawesome/free-solid-svg-icons/faFaceSmile";
import { faFaceSmileBeam } from "@fortawesome/free-solid-svg-icons/faFaceSmileBeam";

import { Alert } from "Components/Partials";
import { PrimaryButton } from "Components/Button";

import Question from "./Question";

const SectionQuestions = ({ alert, questions, section, form, handleInput, handleNext, isLast }) => {
    const [canSubmit, setCanSubmit] = useState(false);

    const answerableQuestions = questions.filter((question) => question?.type !== 'heading' && question?.type !== 'paragraph');

    useEffect(() => {
        // Get all of the questions that are required and make sure there is a value for each of them.
        // Enable the submit button if there is.
        const requiredQuestions = questions.filter((question) => question?.required);
        const requiredQuestionsWithValues = requiredQuestions.filter((question) => !!form[question?.id]);

        setCanSubmit(requiredQuestions.length === requiredQuestionsWithValues.length);
    }, [form, questions]);

    return (
        <div>
            <div>
                <div className="flex justify-between items-center mb-2 pb-2 border-b border-gray-200">
                    <div className="flex items-center">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            { section?.name }
                        </h2>
                    </div>
                </div>

                {alert && (
                    <Alert {...alert} />
                )}

                {questions.filter((question) => question?.type === 'rating').length > 0 && (
                    <div className="flex justify-between items-center mb-6">
                        <div className="flex items-center">
                            <div className="w-10 h-10 bg-[#f00100] mr-4">
                                <div className="h-6 flex items-center justify-center">
                                    <FontAwesomeIcon icon={faFaceFrown} className="text-white" />
                                </div>

                                <div className="bg-black text-center text-white text-xs h-4">
                                    1
                                </div>
                            </div>

                            <div className="text-sm">
                                <p className="leading-tight"><strong>Inadequate</strong></p>
                                <p className="leading-tight">1 Score</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-10 h-10 bg-[#fe7904] mr-4">
                                <div className="h-6 flex items-center justify-center">
                                    <FontAwesomeIcon icon={faFaceMeh} className="text-white" />
                                </div>

                                <div className="bg-black text-center text-white text-xs h-4">
                                    2
                                </div>
                            </div>

                            <div className="text-sm">
                                <p className="leading-tight"><strong>Requires Improvement</strong></p>
                                <p className="leading-tight">2 Score</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-10 h-10 bg-[#ffc705] mr-4">
                                <div className="h-6 flex items-center justify-center">
                                    <FontAwesomeIcon icon={faFaceSmile} className="text-white" />
                                </div>

                                <div className="bg-black text-center text-white text-xs h-4">
                                    3
                                </div>
                            </div>

                            <div className="text-sm">
                                <p className="leading-tight"><strong>Good</strong></p>
                                <p className="leading-tight">3 Score</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-10 h-10 bg-[#18bb00] mr-4">
                                <div className="h-6 flex items-center justify-center">
                                    <FontAwesomeIcon icon={faFaceSmileBeam} className="text-white" />
                                </div>

                                <div className="bg-black text-center text-white text-xs h-4">
                                    4
                                </div>
                            </div>

                            <div className="text-sm">
                                <p className="leading-tight"><strong>Outstanding</strong></p>
                                <p className="leading-tight">4 Score</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="space-y-4">
                    {questions && questions?.map((question) => (
                        <Question
                            form={form}
                            handleInput={handleInput}
                            key={question.id}
                            question={question}
                        />
                    ))}

                    <div className="flex justify-between flex-between">
                        <p className="text-sm text-gray-500">
                            { answerableQuestions.filter(q => !!form[q.id]).length } out of {answerableQuestions.length} ({answerableQuestions.filter(q => q.required).length} required) questions answered
                        </p>

                        <PrimaryButton
                            disabled={!canSubmit}
                            text={isLast ? "Submit" : "Next"}
                            onClick={( ) => !isLast ? handleNext() : false}
                            type={isLast ? "submit" : "button"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionQuestions;

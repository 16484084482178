import React from 'react';

import {Toast} from 'Services';
import ElementApi from 'Services/Api/Cms/Elements/Element';
import RecordApi from 'Services/Api/Cms/Elements/Record';
import TagApi from 'Services/Api/Cms/Elements/Tag';
import LikeApi from 'Services/Api/Cms/Elements/Like';
import ViewApi from 'Services/Api/Cms/Elements/View';

export default class CmsElement extends React.Component {
    /**
     * @method componentDidMount
     */
    componentDidMount = async() => {
        await this.fetchElement();
        await this.fetchRecords();
        this.fetchTags();

        RecordApi.on('updated', this.fetchRecords);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        RecordApi.removeListener('updated', this.fetchRecords);
    };

    /**
     * @method fetchRecords
     * @return {Promise<void>}
     */
    fetchElement = async (page = 1) => {
        this.setState({
            working: true
        });

        const request = await ElementApi.get(null, {
            identifier: this.cmsElementIdentifier
        });

        if (request.success) {
            return this.setState({
                element: request.data.data[0]
            });
        }

        Toast.error();
    };

    /**
     * @method fetchRecords
     * @return {Promise<void>}
     */
    fetchRecords = async (page = 1) => {
        const {element} = this.state;

        this.setState({
            working: true
        });

        const request = await RecordApi.get(null, {
            cms_element_id: element.id,
            paginate: this.paginate,
            ...this.state.filters,
            page
        });

        if (request.success) {
            return this.setState({
                working: false,
                records: request.data
            });
        }

        Toast.error();
    };

    /**
     * @method fetchTags
     * @return {Promise<void>}
     */
    fetchTags = async () => {
        if (this.useTags) {
            const {element} = this.state;

            this.setState({
                working: true
            });

            const request = await TagApi.get(null, {
                cms_element_id: element.id,
            });

            if (request.success) {
                return this.setState({
                    working: false,
                    tags: request.data.tags,
                });
            }

            Toast.error();
        }
    };

    /**
     * @method setFilter
     * @param {string} key
     * @param {mixed} value
     */
    setFilter = (key, value) => {
        const {filters} = this.state;

        this.setState({
            filters: {
                ...filters,
                [key]: value
            }
        }, () => this.fetchRecords());
    }

    /**
     * @method handleRecordDelete
     * @param {object} item
     */
    handleRecordDelete = async (item) => {
        const request = await RecordApi.delete(item.id);

        if (request.success) {
            const {records} = this.state;

            records.data = this.state.records.data.filter(item2 => item2.id !== item.id);

            this.setState({
                records
            });
        }
    }

    /**
     * @method handleToggleLike
     * @param {object} item
     * @return {Promise<void>}
     */
    handleToggleLike = async (item) => {
        let request = null;

        if (item.user_has_liked) {
            request = await LikeApi.delete(item.id);
        } else {
            request = await LikeApi.post(null, {
                cms_element_record_id: item.id
            });
        }

        if (request.success) {
            const {records} = this.state;
            const i = records.data.map(c => c.id).indexOf(item.id);

            if (i !== -1) {
                records.data[i].user_has_liked = !records.data[i].user_has_liked;

                if (item.user_has_liked) {
                    records.data[i].total_likes = +records.data[i].total_likes + 1;
                } else {
                    records.data[i].total_likes = +records.data[i].total_likes - 1;
                }

                this.setState({
                    records
                });

                return records.data[i];
            }
        }

        Toast.error();
    };

    /**
     * @method handleView
     * @param {object} item
     * @return {Promise<*>}
     */
    handleView = (item) => {
        return ViewApi.post(null, {
            cms_element_record_id: item.id
        });
    };

    /**
     * @method handleView
     * @param {object} item
     * @return {Promise<*>}
     */
    handleCommentNumberUpdate = (item, number) => {
        const {records} = this.state;
        const i = records.data.map(c => c.id).indexOf(item.id);

        if (i !== -1) {
            records.data[i].total_comments = number;

            this.setState({
                records
            });
        }
    }
}

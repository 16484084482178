import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import TranslationUpdate from './TranslationUpdate';

import {Loading} from 'Components/Partials';

import AdminTranslationsApi from 'Services/Api/Admin/Translations';

export default class TranslationsShow extends React.Component {
    /**
     * @var state
     */
    state = {
        loading: true,
        group: this.props.match?.params?.group,
        key: this.props.match?.params?.key,
        translations: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadTranslation();
    }

    /**
     * @method loadTranslation
     * @param {int} page
     */
    loadTranslation = async (page = 1) => {
        const {group, key} = this.state;

        this.setState({ loading: true });

        const response = await AdminTranslationsApi.get(key, {group});

        this.setState({
            loading: false,
            translations: response.data,
        });
    }

    /**
     * @method updateValue
     * @param {string} group
     * @param {string} key
     * @param {string} language
     * @param {string} value
     */
    updateValue = (group, key, language, value) => {
        this.loadTranslation();
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {loading, group, key, translations} = this.state;

        return (
            <AdminLayout title={'Site Translations - ' + group + ' : ' + key}>
                <div className="m-4">
                    {loading && (<Loading />)}

                    {!loading &&
                        <>
                            {translations?.meta?.total == 0 &&
                                <p>Translations will appear here.</p>
                            }

                            {translations?.meta?.total != 0 &&
                                <>
                                    <div className="overflow-x-auto">
                                        <table className="table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="text-ccenter p-2">
                                                        Language
                                                    </th>
                                                    <th className="text-center p-2">
                                                        Value
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {translations.data.map((translation, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="border px-4 py-2 text-center">
                                                                {window.base.languages[translation.language]}
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                <TranslationUpdate 
                                                                    group={translation.group}
                                                                    the_key={translation.key}
                                                                    language={translation.language}
                                                                    value={translation.value}
                                                                    updateValueCallback={this.updateValue}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </AdminLayout>
        )
    }
}
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import Header from './Header';
import Footer from './Footer';
import Nav from './Nav';

export default class Authenticated extends React.Component {
    /**
     * @var state
     */
    state = {
        displayNav: false,
    };

    /**
     * @method handleToggleDisplayNav
     */
    handleToggleDisplayNav = () => this.setState({
        displayNav: !this.state.displayNav,
    });

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { children, title, titleIcon } = this.props;
        const { displayNav } = this.state;

        return (
            <div className="bg-app-supporting-grey">
                <Header />

                <div className="min-h-screen flex">
                    <div
                        className={`
                            absolute md:static z-50 h-full md:h-auto transition-width duration-500 ease-in-out
                            bg-app-leading overflow-x-hidden group
                            ${displayNav ? 'w-full' : 'w-12 hover:w-80'}
                        `}
                    >
                        <div
                            className={`
                                md:hidden p-4 w-full text-app-typography cursor-pointer
                                ${displayNav ? 'flex justify-end' : ''}
                            `}
                            onClick={this.handleToggleDisplayNav}
                        >
                            <FontAwesomeIcon icon={displayNav ? faTimes : faBars} />
                        </div>

                        <Nav />
                    </div>

                    <div className="flex flex-col w-full">
                        {title && (
                            <div className="w-full pl-16 md:pl-6 p-4 md:p-6 bg-app-supporting-yellow text-white font-league-spartan text-2xl">
                                {titleIcon && (
                                    <FontAwesomeIcon icon={titleIcon} className="text-app-typography mr-4" />
                                )}
                                {title}
                            </div>
                        )}

                        <div className="h-full pl-16 md:pl-6 flex-1 pr-4 md:pr-6 py-4 md:py-6">
                            {children}
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

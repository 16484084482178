import React from 'react';

export default class MedicationInfo extends React.Component {
    /**
     * @var state
     */
    state = {
        full: false
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {medication} = this.props;
        const {full} = this.state;

        return (
            <div 
                className="cursor-pointer"
                onClick={() => this.setState({full: !full})}
            >
                {medication.medication.name}
                
                <br/>
                
                ({medication.medication.dosage} @ <span className="capitalize">{medication.medication.time})</span>
                
                {full &&
                    <div className="mt-2">
                        Method: {medication.method ?? '-'}

                        <br/>

                        What is it for: {medication.what_is_it_for ?? '-'}

                        <br/>

                        Side Effects: {medication.side_effects ?? '-'}

                        <br/>

                        Specific Medication Requirements: {medication.specific_requirements ?? '-'}
                    </div>
                }
            </div>
        );
    }
}
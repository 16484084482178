import ApiBase from '../ApiBase';

class Users extends ApiBase {
    route = '/admin/users';

    /**
     * @method getHardPassword
     * @return {Promise<*>}
     */
    getHardPassword = () => {
        return this.get('/admin/users/get-hard-password');
    }

    /**
     * @method updatePassword
     * @param {string} id
     * @param {object} data
     * @return {Promise<boolean|{status: number}>}
     */
    updatePassword = (id, data) => {
        return this.patch('/admin/users/update-password/'+id, data);
    };

    /**
     * @method sendPasswordResetLink
     * @param {string} id
     * @return {Promise<boolean|{status: number}>}
     */
    sendPasswordResetLink = (id) => {
        return this.patch('/admin/users/send-password-reset-link/'+id);
    };

    /**
     * @method sendUserInvite
     * @param {string} id
     * @param {object} data
     * @return {Promise<boolean|{status: number}>}
     */
    sendUserInvite = (id, data) => {
        return this.patch('/admin/users/send-user-invite/'+id, data);
    };

    /**
     * @method updatePatch
     * @param id
     * @param archived
     */
    archiveToggle = (id, archived = true) => {
        return this.patch(`/admin/archive-user/${id}`, {archived});
    };
}

export default new Users();

import React from 'react';
import {Redirect} from 'react-router-dom';

import User from 'Services/User';

/**
 * @function Registered
 * @param {*|JSX.Element} component
 * @return {*|JSX.Element}
 * @constructor
 */
export default ({component}) => {
    /**
     * @var User
     * @type {{loggedIn: boolean, data: {hr_registered: boolean}}}
     */

    /** @var auth */
    const auth = User.loggedIn;

    return auth && User.data.hr_registered ? component : <Redirect to="/settings/human-resources" />;
};

import React from 'react';
import {Grid, GridItem} from 'react-masonry-grid';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart, faComment, faPencilAlt, faTrash} from '@fortawesome/free-solid-svg-icons';

import Authenticated from 'Components/Layouts/Authenticated';
import CmsElement from 'Components/Utilities/CmsElement';
import {Loading, PaginationBar} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {ModalTrigger} from 'Components/Modal';
import ViewCmsElementRecordModal from 'Components/Partials/Modals/ViewCmsElementRecordModal';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';

import User from 'Services/User';
import {translation} from 'Services/TranslationHelpers';
import {getRecordValue} from 'Services/CmsHelpers';

export default class CelebrationWall extends CmsElement {
    /**
     * @var cmsElementIdentifier
     * @type {string}
     */
    cmsElementIdentifier = 'celebration-wall';

    /**
     * @var paginate
     * @type {boolean}
     */
    paginate = true;

    /**
     * @var allowUpdate
     * @type {boolean}
     */
    allowUpdate = true;

    /**
     * @var state
     */
    state = {
        working: true
    };

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {working, element, records} = this.state;

        return (
            <Authenticated>
                <div className="p-4 text-center">
                    <h1 className={"h1 my-4 font-bold"}>
                        {translation('cms', 'celebration_wall')}
                    </h1>

                    {working && <Loading />}

                    {!working && 
                        <>
                            <ModalTrigger 
                                component={CreateCmsElementRecordModal}
                                props={{
                                    showElementsDropdown: false,
                                    element_id: element.id,
                                    element_record_id: null
                                }}
                            >
                                <PrimaryButton
                                    text={translation('misc', 'add')}
                                    className="mb-8"
                                />
                            </ModalTrigger>

                            {records !== null && (!records.data || records.data.length === 0) && (
                                <p className="text-center">
                                    {translation('misc', 'no_items', {
                                        item: translation('cms', 'celebration_wall')
                                    })}
                                </p>
                            )}

                            {records && records.data && (
                                <div>
                                    <div className="my-8">
                                        <Grid gutter={20} columnWidth={400} rowHeight={10}>
                                            {records.data.map(this.renderItem)}
                                        </Grid>
                                    </div>

                                    <div className="mt-5">
                                        <PaginationBar
                                            total={records.meta.total}
                                            pageCount={records.meta.last_page}
                                            page={records.meta.current_page}
                                            goToPage={this.fetchRecords}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    }
                </div>
            </Authenticated>
        );
    }

    /**
     * @method renderItem
     * @param {object} item
     * @return {JSX.Element}
     */
    renderItem = (item) => {
        const {element} = this.state;

        let item_title = getRecordValue(item, 'title');

        return (
            <GridItem key={item.id}>
                <div>
                    <div>
                        <h2 className={"h5 mb-2"}>
                            {item_title}
                        </h2>

                        {this.renderItemBody(item, 'index')}
                    </div>

                    <div className="flex justify-between p-2">
                        <div className="ml-2 text-left">
                            <p className={"bold"}>
                                {item.user.full_name}
                            </p>
                        </div>

                        <div className={"flex items-center select-none"}>
                            {item.allow_likes && (
                                <div className="flex mr-2">
                                    <FontAwesomeIcon
                                        icon={faHeart}
                                        size="1x"
                                        className={`mx-1 cursor-pointer ${item.user_has_liked ? 'text-red-500' : 'text-black'}`}
                                        onClick={() => this.handleToggleLike(item)}
                                    />

                                    {(item.total_likes !== undefined && item.total_likes > 0) &&
                                        <div className={"text-xs"}>
                                            {item.total_likes}
                                        </div>
                                    }
                                </div>
                            )}

                            {item.allow_comments && (
                                <ModalTrigger component={ViewCmsElementRecordModal} props={{
                                    item,
                                    handleToggleLike: this.handleToggleLike,
                                    handleView: this.handleView,
                                    handleCommentNumberUpdate: this.handleCommentNumberUpdate,
                                    title: item_title,
                                    body: this.renderItemBody,
                                    content: item.user?.full_name,
                                }}>
                                    <div className="flex mr-2">
                                        <FontAwesomeIcon
                                            icon={faComment}
                                            size="1x"
                                            className="mx-1"
                                        />

                                        {item.total_comments !== undefined && item.total_comments > 0 &&
                                            <div className={"text-xs"}>
                                                {item.total_comments}
                                            </div>
                                        }
                                    </div>
                                </ModalTrigger>
                            )}

                            {(item.user.id === User.data.id || User.data.is_admin) &&
                                <>
                                    {this.allowUpdate &&
                                        <ModalTrigger 
                                            component={CreateCmsElementRecordModal}
                                            props={{
                                                showElementsDropdown: false,
                                                element_id: element.id,
                                                element_record_id: item.id
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                size="1x"
                                                className="ml-1 mr-2 cursor-pointer"
                                            />
                                        </ModalTrigger>
                                    }

                                    <FontAwesomeIcon
                                        onClick={() => this.handleRecordDelete(item)}
                                        icon={faTrash}
                                        size="1x"
                                        className="mx-1 cursor-pointer"
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </GridItem>
        );
    };

    /**
     * @method renderItemBody
     * @param {object} item
     * @param {string} source
     * @return {JSX.Element}
     */
    renderItemBody = (item, source) => {
        let item_content_type = getRecordValue(item, 'content_type');
        let item_message = getRecordValue(item, 'message');

        return (
            <div>
                {item_content_type === 'image' && (
                    <div>
                        <img src={getRecordValue(item, 'file', 'file')} className="mx-auto max-h-96"/>
                    </div>
                )}

                {item_content_type === 'video' && (
                    <div>
                        <video className="w-full max-h-48" controls>
                            <source src={getRecordValue(item, 'file', 'file')} />
                        </video>
                    </div>
                )}

                {item_message && (
                    <div className="bg-gray-400 text-white p-4">
                        {item_message.split("\n").map((i, key) => (
                            <p className={`${key === 0 ? '' : 'mb-1'}`} key={key}>
                                {i}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

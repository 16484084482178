import {DateTime} from 'luxon';

import Settings from 'Services/Settings';

/**
 * @method resourceDateIsBeforeNow
 * @param {array} resourceDate
 * @return {boolean}
 */
const resourceDateIsBeforeNow = (resourceDate) => {
    const now = DateTime.now();

    const resourceDateObject = DateTime.fromISO(resourceDate.iso_string, {zone: 'UTC'});

    return (resourceDateObject < now);
}

/**
 * @method resourceDateIsAfterNow
 * @param {array} resourceDate
 * @return {boolean}
 */
const resourceDateIsAfterNow = (resourceDate) => {
    const now = DateTime.now();

    const resourceDateObject = DateTime.fromISO(resourceDate.iso_string, {zone: 'UTC'});

    return (resourceDateObject > now);
}

/**
 * @method resourceDateIsBefore
 *
 * Date must be supplied as UTC.
 * 
 * @param {array} resourceDate
 * @param {int} day
 * @param {int} month
 * @param {int} year
 * @param {int} hour
 * @param {int} min
 * @param {int} second
 * @return {boolean}
 */
const resourceDateIsBefore = (resourceDate, day, month, year, hour, min, second) => {
    const staticDateObject = dateTimeFromBreakdown(day, month, year, hour, min, second);

    const resourceDateObject = DateTime.fromISO(resourceDate.iso_string, {zone: 'UTC'});

    return (resourceDateObject < staticDateObject);
}

/**
 * @method resourceDateIsAfter
 *
 * Date must be supplied as UTC.
 * 
 * @param {array} resourceDate
 * @param {int} day
 * @param {int} month
 * @param {int} year
 * @param {int} hour
 * @param {int} min
 * @param {int} second
 * @return {boolean}
 */
const resourceDateIsAfter = (resourceDate, day, month, year, hour, min, second) => {
    const staticDateObject = dateTimeFromBreakdown(day, month, year, hour, min, second);

    const resourceDateObject = DateTime.fromISO(resourceDate.iso_string, {zone: 'UTC'});

    return (resourceDateObject > staticDateObject);
}

/**
 * @method resourceDateIsToday
 * @param {array} resourceDate
 * @return {boolean}
 */
const resourceDateIsToday = (resourceDate) => {
    const resourceDateObject = DateTime.fromISO(resourceDate.iso_string, {zone: resourceDate.timezone});
    const now = DateTime.now();

    return resourceDateObject.hasSame(now, "day");
}

/**
 * @method isBeforeNow
 *
 * Date must be supplied as UTC.
 * 
 * @param {int} day
 * @param {int} month
 * @param {int} year
 * @param {int} hour
 * @param {int} min
 * @param {int} second
 * @return {boolean}
 */
const isBeforeNow = (day, month, year, hour, min, second) => {
    const now = DateTime.now();

    const staticDateObject = dateTimeFromBreakdown(day, month, year, hour, min, second);

    return (staticDateObject < now);
}

/**
 * @method isAfterNow
 *
 * Date must be supplied as UTC.
 * 
 * @param {int} day
 * @param {int} month
 * @param {int} year
 * @param {int} hour
 * @param {int} min
 * @param {int} second
 * @return {boolean}
 */
const isAfterNow = (day, month, year, hour, min, second) => {
    const now = DateTime.now();

    const staticDateObject = dateTimeFromBreakdown(day, month, year, hour, min, second);

    return (staticDateObject > now);
}

/**
 * @method dateTimeFromBreakdown
 *
 * Date must be supplied as UTC.
 * 
 * @param {int} day
 * @param {int} month
 * @param {int} year
 * @param {int} hour
 * @param {int} min
 * @param {int} second
 * @return {DateTime}
 */
const dateTimeFromBreakdown = (day, month, year, hour, min, second) => {
    return DateTime.utc(year, month, day, hour, min, second);
}

/**
 * @method dateToString
 *
 * Date must be supplied as dd/mm/yyyy.
 *
 * Reference: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 * 
 * @param {string} date
 * @return {string}
 */
const dateToString = (date) => {
    let object = DateTime.fromFormat(date, "dd/LL/yyyy");

    let date_string = '';

    // If language is set to English then return a fully formatted string
    // else return a language compatible string.

    if (Settings.data.language === 'en') {
        // Monday 8th February.
        date_string = object.toFormat('EEEE d') 
            + getDayOfMonthSuffix(object.toFormat('d')) 
            + ' ' + object.toFormat('MMMM');
    } else {
        // Monday February 8.
        date_string = object.toFormat('EEEE') + ' ' + object.toLocaleString({ month: 'long', day: 'numeric' });
    }

    return date_string;
}

/**
 * @method getDayOfMonthSuffix
 * 
 * @param {string} num
 * @return {string}
 */
const getDayOfMonthSuffix = (num) => {
    const th = 'th'
    const rd = 'rd'
    const nd = 'nd'
    const st = 'st'

    if (num == 11 || num == 12 || num == 13) return th

    let lastDigit = num.toString().slice(-1)

    switch (lastDigit) {
        case '1': return st
        case '2': return nd
        case '3': return rd
        default:  return th
    }
}

export {
    resourceDateIsBeforeNow,
    resourceDateIsAfterNow,
    resourceDateIsBefore,
    resourceDateIsAfter,
    resourceDateIsToday,
    isBeforeNow,
    isAfterNow,
    dateToString,
    getDayOfMonthSuffix
}

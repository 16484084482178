import React from 'react';

import { translation } from 'Services/TranslationHelpers';

/**
 * @function Footer
 * @return {JSX.Element}
 * @constructor
 */
const Footer = () => {
    return (
        <footer className="bg-app-backup h-12 flex items-center px-8">
            <p className="text-center text-sm text-white font-roboto font-light">
                &copy; {(new Date()).getFullYear()} Time To Shine. {translation('misc', 'all_rights_reserved')}.
            </p>
        </footer>
    );
};

export default Footer;

import React from 'react';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';

import Authenticated from 'Components/Layouts/Authenticated';
import { PrimaryButton } from 'Components/Button';
import { ModalTrigger } from 'Components/Modal';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';
import ViewCmsElementRecords from 'Components/Utilities/ViewCmsElementRecords';

import { translation } from 'Services/TranslationHelpers';

export default class MedicalManagement extends React.Component {
    /**
     * @method render
     * @return {*}
     */
    render () {
        return (
            <Authenticated title="Medication Management" titleIcon={faClipboardCheck}>
                <div className="mb-6">
                    <ModalTrigger
                        component={CreateCmsElementRecordModal}
                        props={{
                            showElementsDropdown: true,
                            element_id: null,
                            element_record_id: null,
                            service_user_id: null,
                            quality_assurance: false,
                            medication_management: true,
                        }}
                    >
                        <PrimaryButton
                            className="font-league-spartan font-bold text-xl"
                            text={translation('cms', 'quality_assurance_submit')}
                        />
                    </ModalTrigger>
                </div>

                <ViewCmsElementRecords
                    medication_management={true}
                />
            </Authenticated>
        );
    }
}

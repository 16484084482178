import React from 'react';

import {Select} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';
import {Alert, Loading, PaginationBar} from 'Components/Partials';
import Confirm from 'Components/Partials/Modals/Confirm';
import {ModalTrigger} from 'Components/Modal';
import {NotificationSettings} from 'Components/Comms';

import AdminUsersApi from 'Services/Api/Admin/Users';
import AdminNotificationsApi from 'Services/Api/Admin/Comms/Notifications';
import AdminNotificationLogsApi from 'Services/Api/Admin/Comms/NotificationLogs';

import AdminLayout from 'Pages/Admin/AdminLayout';

export default class AdminUserComms extends React.Component {
    /**
     * @var tabs
     * @type {array}
     */
    tabs = [
        'logs',
        'settings'
    ];

    /**
     * @var state
     */
    state = {
        user_id: this.props.match?.params?.user ?? '',
        tab: 'logs',
        working: true,
        alert: null,
        user: null,
        notifications: null,
        notification: null,
        logs: [],
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.loadUser();
        await this.loadNotifications();
        await this.loadLogs();
    }

    /**
     * @method loadUser
     */
    loadUser = async () => {
        const {user_id} = this.state;

        const response = await AdminUsersApi.get(user_id);

        this.setState({
            user: response.data.data,
        });
    }

    /**
     * @method loadNotifications
     */
    loadNotifications = async () => {
        const response = await AdminNotificationsApi.get(null);

        this.setState({
            notifications: response.data,
        });
    }

    /**
     * @method loadLogs
     * @param {integer} page
     */
    loadLogs = async (page = 1) => {
        const {user_id} = this.state;

        this.setState({ 
            working: true,
            alert: null,
        });

        const response = await AdminNotificationLogsApi.get(null, {
            page,
            user_id,
            notBroadcast: true,
        });

        this.setState({
            working: false,
            logs: response.data,
        });
    }

    /**
     * @method handleSend
     * @param {object} agenda
     */
    handleSend = async (agenda) => {
        const {user_id, notification} = this.state;

        this.setState({ working: true });

        const response = await AdminNotificationsApi.post(null, {
            user_id,
            notification
        });

        if (response.status !== 201) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message
                },
                working: false
            });
            return;
        }

        this.loadLogs();
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, alert, user, tab} = this.state;

        return (
            <AdminLayout title={'Comms - ' + (user?.full_name)}>
                <div className="m-4">
                    {working && (<Loading />)}

                    {alert !== null && (<Alert {...alert} />)}

                    {!working &&
                        <>
                            {this.renderSendBar()}

                            <div className="w-full grid grid-cols-2 mt-4 mb-8 border-2 border-gray-500 cursor-pointer">
                                {this.tabs.map((activeTab, i) => (
                                    <div 
                                        className={`
                                            text-center p-2 capitalize
                                            ${tab === activeTab ? 'bg-gray-500 text-white' : 'bg-white text-gray-500'}
                                        `}
                                        onClick={() => this.setState({tab: activeTab})}
                                        key={i}
                                    >
                                        {activeTab}
                                    </div>
                                ))}
                            </div>

                            <div className="px-2">
                                {tab === 'logs' && this.renderLogs()}
                                {tab === 'settings' && this.renderSettings()}
                            </div>
                        </>
                    }
                </div>
            </AdminLayout>
        )
    }

    renderSendBar() {
        const {notifications, notification} = this.state;

        if (notifications.length === 0) {
            return null;
        }

        return (
            <div className="flex gap-4 mb-8">
                <Select
                    containerClassName="w-full"
                    value={notification}
                    onChange={(v) => this.setState({notification: v})}
                    options={notifications}
                    placeholder="Send a notification ..."
                />

                <ModalTrigger
                    component={Confirm}
                    props={{
                        onConfirm: () => this.handleSend(),
                        message: window.base.features.notification_settings ? 
                            "Note that notifications will be filtered based on the user's notification settings before they are sent." : 
                            null
                    }}
                    disabled={!notification}
                >
                    <PrimaryButton
                        text="Send"
                    />
                </ModalTrigger>
            </div>
        );
    }

    renderLogs() {
        const {logs} = this.state;

        return (
            <>
                {logs?.meta?.total == 0 &&
                    <p>
                        The Notification Log is empty for this user.
                    </p>
                }

                {logs?.meta?.total != 0 &&
                    <>
                        <div className="overflow-x-auto">
                            <table className="table-auto">
                                <thead>
                                    <tr>
                                        <th className="text-left p-2">
                                            Notification
                                        </th>
                                        <th className="text-left p-2">
                                            Channel
                                        </th>
                                        <th className="text-left p-2">
                                            Sent At
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs.data.map((log, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="border px-4 py-2">
                                                    {log.notification_name}
                                                </td>
                                                <td className="border px-4 py-2">
                                                    {log.channel_name}
                                                </td>
                                                <td className="border px-4 py-2">
                                                    {log.created_at.date_time_timezone}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        {logs && (
                            <div className="mt-12 mb-4">
                                <PaginationBar
                                    total={logs.meta.total}
                                    pageCount={logs.meta.last_page}
                                    page={logs.meta.current_page}
                                    goToPage={this.loadLogs}
                                />
                            </div>
                        )}
                    </>
                }
            </>
        );
    }

    renderSettings() {
        const {user_id} = this.state;

        return (
            <NotificationSettings
                admin={true}
                userId={user_id}
            />
        );
    }
}
import React from 'react';
import {Link} from 'react-router-dom';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton} from 'Components/Button';
import {Alert, Loading} from 'Components/Partials';
import {FormHandler, Input, Toggle, Label} from 'Components/Form';
import {HrTitle} from 'Components/HumanResources/AdminComponents';

import UserRatesApi from 'Services/Api/Admin/HumanResources/UserRates';

import Base from '../Base';

class UserRates extends Base {
    /**
     * @var success
     * @type {string}
     */
    success = 'Successfully updated.';

    /**
     * @var state
     */
    state = {
        loading: true,
        user: null,
        user_id: this.props.match.params.user
    };

    /**
     * @method componentDidMount
     */
    async componentDidMount() {
        await this.setUser();
        await this.loadRates();
    };

    /**
     * @method loadRates
     */
    loadRates = async () => {
        const {setForm} = this.props;

        this.setState({
            loading: true,
        });

        const response = await UserRatesApi.get({
            user: this.state.user_id
        });

        if (response.success) {
            let rates = response.data.data;

            setForm({rates});

            this.setState({
                loading: false,
            });
        }
    };

    /**
     * @method handleEnableRate
     * @param {string} rate_id
     * @param {string} key
     * @param {boolean} value
     */
    handleRateValue = (rate_id, key, value) => {
        let {rates} = this.props.form;

        rates.map((rate) => {
            if (rate.id === rate_id) {
                rate[key] = value;
            }

            return rate;
        });

        this.props.setForm({
            ...this.props.form,
            rates
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<void>}
     */
    handleSubmit = (form) => {
        return UserRatesApi.patch({
            user: this.state.user_id,
        }, form);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {user, loading} = this.state;
        const {form, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <AdminLayout>
                {loading && (<Loading/>)}

                {!loading && 
                    <div className="divide-y divide-gray-200">
                        <div className="p-6">
                            <HrTitle user={user} title="Rates" />
                        </div>

                        <div className="p-6">
                            <p className="mb-4 font-bold text-center">
                                If there is an overlap between rates we will take the first rate, therefore you must ensure there is no overlap.
                            </p>

                            <p className="mb-4 font-bold text-center">
                                Note that rate changes will not be reflected in existing timesheet records.
                                <br/> 
                                You will need to manually modify each of those as required, 
                                these changes will only be applied to new records.
                            </p>

                            <Link to='/admin/human-resources/rates' target="_blank">
                                <p className="mb-8 font-bold text-center">
                                    Click <u>here</u> to create or update rates.
                                </p>
                            </Link>

                            {!form.rates || form.rates.length === 0 &&
                                <Link to='/admin/human-resources/rates'>
                                    <p className="mb-4">
                                        Rates will appear here.
                                    </p>
                                </Link>
                            }

                            {form.rates && form.rates.length !== 0 &&
                                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, false)}>
                                    {alert !== null && (<Alert {...alert} />)}

                                    {form.rates.map((rate, i) => (
                                        <div className="my-4 p-4 bg-gray-300 rounded flex flex-row items-center gap-6" key={i}>
                                            <div className="w-40">
                                                <Label
                                                    label={rate.name}
                                                    className="mb-0"
                                                />
                                            </div>

                                            <div className="flex flex-row items-center">
                                                <span className="mr-2">
                                                    £
                                                </span>

                                                <Input
                                                    containerClassName="w-96"
                                                    value={rate.value}
                                                    onChange={v => this.handleRateValue(rate.id, 'value', v)}
                                                    placeholder="Enter rate here or leave empty if unavailable"
                                                />
                                            </div>
                                        </div>
                                    ))}

                                    <div className="w-full flex">
                                        <PrimaryButton
                                            text="Save" 
                                            className="ml-auto" 
                                            disabled={working}
                                        />
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                }
            </AdminLayout>
        );
    }
}

export default FormHandler(UserRates);

import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {FormHandler, Input} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import AdminUsersApi from 'Services/Api/Admin/Users';

class UsersUpdatePassword extends React.Component {

    /**
     * @var success
     * @type {string}
     */
    success = 'User password update successfully';

    /**
     * @var state
     * @type {{user_id: string}}
     */
    state = {
        loading: true,
        user_id: this.props.match.params.user,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.props.setInitialValues({
            new_password: '',
            new_password_confirmation: '',
        });

        this.setState({loading: false});
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {user_id} = this.state;

        return AdminUsersApi.updatePassword(user_id, form);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() { 
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        return (
            <AdminLayout title="Users - Update Password">
                <form
                    className="divide-y divide-gray-200"
                    onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, true)}
                >
                    <div className="mx-6">
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid grid-cols-2 gap-4 mb-4 border-0">
                            <div>
                                <Input
                                    label="New Password"
                                    type="password"
                                    value={form.new_password}
                                    id="new_password"
                                    error={getFieldError('new_password')}
                                    onChange={v => handleInput('new_password', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    label="New Password Confirmation"
                                    type="password"
                                    value={form.new_password_confirmation}
                                    id="new_password_confirmation"
                                    error={getFieldError('new_password_confirmation')}
                                    onChange={v => handleInput('new_password_confirmation', v)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="px-6 pt-6 flex justify-end gap-4">
                        <Link to="/admin/users">
                            <SecondaryButton type="button" text="Back" />
                        </Link>

                        <PrimaryButton
                            text="Save"
                            working={working}
                        />
                    </div>
                </form>
            </AdminLayout>
        )
    }
}

export default FormHandler(UsersUpdatePassword);

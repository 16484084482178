import ApiBase from '../ApiBase';

class Tests extends ApiBase {
    /**
     * @method env
     * @param {object} data
     * @return {Promise<*>}
     */
    env = (data) => {
        return this.post('admin/tests/env', data);
    };

    /**
     * @method socket
     * @param {object} data
     * @return {Promise<*>}
     */
    socket = (data) => {
        return this.post('admin/tests/socket', data);
    };

    /**
     * @method email
     * @param {object} data
     * @return {Promise<*>}
     */
    email = (data) => {
        return this.post('admin/tests/email', data);
    };

    /**
     * @method sms
     * @param {object} data
     * @return {Promise<*>}
     */
    sms = (data) => {
        return this.post('admin/tests/sms', data);
    };

    /**
     * @method storage
     * @param {object} data
     * @return {Promise<*>}
     */
    storage = async (data) => {
        return this.post('admin/tests/storage', data);
    };
}

export default new Tests();
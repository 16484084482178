import React from 'react';
import {NavLink} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';

import {PrimaryButton} from 'Components/Button';
import {ModalTrigger} from 'Components/Modal';

export default class ActionsButton extends React.Component {
    /**
     * @var state
     * @type {{show: boolean}}
     */
    state = {
        show: false,
    };

    /**
     * @constructor
     */
    constructor(props) {
        super(props);

        this.dropdownRef = React.createRef();
        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        const {layout = 'dropdown'} = this.props;

        if (layout === 'dropdown') {
            document.addEventListener("mousedown", this.handleClickOutsideDropdown);
        }
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        const {layout = 'dropdown'} = this.props;

        if (layout === 'dropdown') {
            document.removeEventListener("mousedown", this.handleClickOutsideDropdown);
        }
    }

    /**
     * Close the dropdown whenever click outside of it.
     *
     * @method handleClickOutsideDropdown
     * @param {event} event
     */
    handleClickOutsideDropdown(event) {
        if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
            this.setState({
                show: false
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {buttons, layout = 'dropdown'} = this.props;
        const {show} = this.state;

        if (!buttons) {
            return null;
        }

        if (layout === 'dropdown') {
            return (
                <div ref={this.dropdownRef}>
                    <div
                        className="bg-light-blue-700 text-white px-3 py-2 rounded-md shadow-lg cursor-pointer select-none"
                        onClick={() => this.setState({show: !show})}
                        title="Click for more actions..."
                    >
                        <FontAwesomeIcon icon={show ? faCaretUp : faCaretDown}/>
                    </div>

                    <div className={`
                        absolute mt-2 rounded-md shadow-lg z-20 bg-light-blue-700 text-left
                        py-1 ring-1 ring-black ring-opacity-5
                        ${show ? '' : 'hidden'}
                    `}>
                        {buttons.map((button, i) => this.renderButton(button, i))}
                    </div>
                </div>
            );
        }

        if (layout === 'row') {
            return (
                <div className="flex flex-row justify-center items-center gap-2 select-none">
                    {buttons.map((button, i) => this.renderButton(button, i))}
                </div>
            );
        }
    }

    /**
     * @method renderButton
     * @param {object} button
     * @param {integer} i
     * @return {JSX.Element}
     */
    renderButton(button, i) {
        const {layout = 'dropdown'} = this.props;
        let styles = '';

        if (layout == 'dropdown') {
            styles = button.className ?? "block py-2 px-4 font-medium hover:bg-gray-100 leading-5 text-white hover:text-black cursor-pointer";
        } else if (layout === 'row') {
            styles = button.className ?? "font-medium hover:bg-gray-100 text-black cursor-pointer";
        }

        let content = button.text;

        if (button.icon) {
            content = <FontAwesomeIcon icon={button.icon} title={button.title ?? null} />;
        }

        if (button.type === "link") {
            return (
                <NavLink to={button.to} className={styles} target={button.target} key={i}>
                    {content}
                </NavLink>
            );
        } else if (button.type === "href") {
            return (
                <a href={button.to} target={button.target} key={i}>
                    <div className={styles}>
                        {content}
                    </div>
                </a>
            );
        } else if (button.type === "onClick") {
            return (
                <div onClick={button.onClick} className={styles} key={i}>
                    {content}
                </div>
            );
        } else if (button.type === "modalTrigger") {
            return (
                <ModalTrigger
                    component={button.component}
                    props={button.props}
                    key={i}
                >
                    <div className={styles}>
                        {content}
                    </div>
                </ModalTrigger>
            );
        }
    }
}

import CelebrationWall from 'Pages/Cms/Elements/CelebrationWall';
import OnDemand from 'Pages/Cms/Elements/OnDemand';
import HealthAndSafety from 'Pages/Cms/Elements/HealthAndSafety';
import Narratives from 'Pages/Cms/Elements/Narratives';

import AdminCmsElementsIndex from 'Pages/Admin/Cms/Elements';
import AdminCmsElementsShow from 'Pages/Admin/Cms/Elements/show';
import AdminCmsElementsStore from 'Pages/Admin/Cms/Elements/store';
import AdminCmsElementFieldsStore from 'Pages/Admin/Cms/Elements/Fields/store';
import AdminCmsElementsRecordsIndex from 'Pages/Admin/Cms/Elements/Records';
import AdminCmsElementsRecordsStore from 'Pages/Admin/Cms/Elements/Records/store';
import QualityAssurance from "Pages/Cms/Elements/QualityAssurance";
import MedicalManagement from "Pages/Cms/Elements/MedicalManagement";

export default [
	// Frontend
	// { path: '/celebration-wall', component: CelebrationWall, guards: ['auth'] },
	// { path: '/on-demand', component: OnDemand, guards: ['auth'] },

	{ path: '/health-and-safety', component: HealthAndSafety, guards: ['auth'] },
    { path: '/quality-assurance', component: QualityAssurance, guards: ['auth'] },
    { path: '/medical-management', component: MedicalManagement, guards: ['auth'] },

    { path: '/narratives', component: Narratives, guards: ['auth'] },

	// Admin
	{ path: '/admin/cms/elements', component: AdminCmsElementsIndex, guards: ['admin'] },
	{ path: '/admin/cms/elements/show/:element?', component: AdminCmsElementsShow, guards: ['admin'] },
	{ path: '/admin/cms/elements/store/:element?', component: AdminCmsElementsStore, guards: ['admin'] },
	{ path: '/admin/cms/elements/fields/store/:element/:field?', component: AdminCmsElementFieldsStore, guards: ['admin'] },
	{ path: '/admin/cms/elements/records/:element?', component: AdminCmsElementsRecordsIndex, guards: ['admin'] },
	{ path: '/admin/cms/elements/records/store/:element/:record?', component: AdminCmsElementsRecordsStore, guards: ['admin'] },
];

import React from 'react';

import {Card, CardBody, CardFooter, CardHeader} from 'Components/Card';
import {PrimaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Link} from 'Components/Utilities';

export default class AuthLayout extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {
            title, 
            children, 
            backLink = null, 
            backLinkText = null, 
            submitText, 
            onSubmit, 
            working, 
            alert
        } = this.props;

        return (
            <Card>
                <CardHeader>
                    {title}
                </CardHeader>

                <form autoComplete="off" onSubmit={onSubmit}>
                    <CardBody>
                        {alert !== null && (<Alert {...alert} />)}

                        {children}
                    </CardBody>

                    <CardFooter>
                        <div className={`flex ${backLink ? 'justify-between' : 'justify-end'} items-center`}>
                            {backLink &&
                                <div>
                                    <Link to={backLink}>
                                        {backLinkText}
                                    </Link>
                                </div>
                            }

                            <div>
                                <PrimaryButton
                                    text={submitText}
                                    working={working}
                                />
                            </div>
                        </div>
                    </CardFooter>
                </form>
            </Card>
        );
    }
}
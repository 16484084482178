import React from 'react';

import {Users, Chats, Chat, ActiveUsersService} from 'Components/Networking';

import ToApi from 'Services/Api/Networking/To';
import GroupsApi from 'Services/Api/Networking/Groups';
import {translation} from 'Services/TranslationHelpers';

export default class Networking extends React.Component {
    /**
     * @var state
     * @type {{selected_user: null|object}}
     * @type {{mobile_active_column: string}}
     */
    state = {
        selected_user: null,
        mobile_active_column: 'users',
        error_message: null
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        ActiveUsersService.subscribe();

        this.setSelectedUserOnLoad();
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        ActiveUsersService.unsubscribe();
    }

    /**
     * @method setSelectedUserOnLoad
     */
    setSelectedUserOnLoad = async () => {
        const {selectedMessageToType, selectedMessageToId, additional_key} = this.props;

        if (selectedMessageToType && selectedMessageToId) {
            const response = await ToApi.get(selectedMessageToId, {
                message_to_type: selectedMessageToType,
                additional_key
            });

            if (response.success) {
                this.setSelectedUser(response.data.data);
            } else {
                this.setState({
                    error_message: 'Invalid pre-select'
                });
            }
        }
    }

    /**
     * @method setSelectedUser
     * @param {object} user
     */
    setSelectedUser = (user) => {
        this.setState({
            selected_user: user,
            mobile_active_column: 'chat'
        });
    }

    /**
     * @method getColumns
     * @return {array}
     */
    getColumns = () => {
        const {displayUsers, displayChats, displayChat} = this.props;

        if (displayUsers && displayChats && displayChat) {
            return {
                'users': {
                    component: Users,
                    className: 'w-full lg:w-3/12 py-2 mr-0 lg:mr-2 h-full'
                },
                'chats': {
                    component: Chats,
                    className: 'w-full lg:w-3/12 py-2 lg:px-2'
                },
                'chat': {
                    component: Chat,
                    className: 'w-full lg:w-6/12 py-2 mx-0 lg:mx-2'
                }
            };
        }

        if (!displayUsers && !displayUsers && displayChat) {
            return {
                'chat': {
                    component: Chat,
                    className: 'w-full'
                }
            };
        }
    }

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {error_message} = this.state;

        if (error_message) {
            return (
                <div className="text-center font-bold">
                    {error_message}
                </div>
            );
        }

        return (
            <div className="networking-container h-full flex flex-col lg:flex-row">
                {this.renderMobileControls()}
                {this.renderColumns()}
            </div>
        );
    }

    /**
     * @method renderMobileControls
     * @return {*}
     */
    renderMobileControls() {
        const {mobile_active_column} = this.state;

        let columns = Object.entries(this.getColumns());

        if (columns.length === 1) {
            return null;
        }

        return (
            <div className="z-10 min-w-full h-11 grid grid-cols-3 lg:hidden">
                {columns.map((column, i) => (
                    <div
                        className={`
                            p-2 large text-center cursor-pointer border border-networking-primary
                            ${mobile_active_column === column[0] ?
                                'bg-networking-primary text-networking-primary-text' :
                                'bg-networking-primary-text text-networking-primary'}
                        `}
                        onClick={() => this.setState({mobile_active_column: column[0]})}
                        key={i}
                    >
                        {translation('networking_frontend', column[0] + '_title')}
                    </div>
                ))}
            </div>
        );
    }

    /**
     * @method renderColumns
     * @return {*}
     */
    renderColumns() {
        const {fullScreen, useGroups = true, additional_key} = this.props;
        const {mobile_active_column, selected_user} = this.state;

        let columns = Object.entries(this.getColumns());

        return (
            <div className={`flex min-w-full ${fullScreen ? 'min-h-screen h-screen' : ''}`}>
                {columns.map((column, i) => {
                    let Component = column[1].component;

                    return (
                        <div
                            className={`
                                ${column[1].className}
                                ${mobile_active_column === column[0] ? 'inline' : 'hidden'}
                                lg:inline
                            `}
                            key={i}
                        >
                            <Component
                                setSelectedUser={(user) => this.setSelectedUser(user)}
                                selected_user={selected_user}
                                fullScreen={fullScreen}
                                useGroups={useGroups}
                                additional_key={additional_key}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

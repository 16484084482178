import React from 'react';

import {PrimaryButton} from 'Components/Button';

import AdminTestsApi from 'Services/Api/Admin/Tests';

export default class EmailTest extends React.Component {
    /**
     * @var state
     */
    state = {
        working: false,
        sent: false,
    };

    /**
     * @method sendTestEmail
     */
    sendTestEmail = async () => {
        this.setState({
            working: true,
            sent: false
        });

        const response = await AdminTestsApi.email();

        if (response.status === 200) {
            this.setState({
                working: false,
                sent: true
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, sent} = this.state;

        return (
            <div className="p-4 bg-gray-300 rounded">
                <p className="font-bold flex flex-row items-center gap-4 mb-4">
                    Email Test
                </p>

                <PrimaryButton
                    text={sent ? "Sent - click here to resend" : "Send test email to my email address"}
                    onClick={this.sendTestEmail}
                    working={working}
                />
            </div>
        )
    }
}
import React from "react";

import DatePicker from "react-datepicker";

const Date = ({ onChange, className, value, ...rest }) => {
    return (
        <DatePicker
            className={`border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full sm:text-sm border-gray-300 ${className}`}
            onChange={(date) => onChange(date)}
            selected={value}
            {...rest}
        />
    );
};

export default Date;

import React from 'react';

import {ModalTrigger} from 'Components/Modal';
import AdminRunImportModal from 'Components/Partials/Modals/AdminRunImportModal';
import {PrimaryButton} from 'Components/Button';
import {Select} from 'Components/Form';
import {IndexTable} from 'Components/Utilities';

import AdminLayout from 'Pages/Admin/AdminLayout';

import AdminImportsApi from 'Services/Api/Admin/Imports';

export default class ImportsIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Type',
            value: (record) => record.type.replace(/([A-Z])/g, ' $1').trim(),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Params',
            value: (record) => record.readable_params && Object.entries(record.readable_params).map((object, key) => (
                <p key={key}>
                    {object[0]}: {object[1]}
                </p>
            )),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Status',
            value: (record) => record.completed ? 'Completed' : 'Pending',
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Results',
            value: (record) => record.completed && record.total_number_of_rows && record.results_path ? 
                <PrimaryButton
                    text={
                        (record.successful_number_of_rows === record.total_number_of_rows) ?
                        'All rows successful imported' :
                        record.successful_number_of_rows + ' out of ' + record.total_number_of_rows + ' rows successful imported'
                    }
                    onClick={() => this.handleDownloadResults(record)}
                /> : null,
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Ran On',
            value: 'created_at',
            type: 'datetime',
            orderable: null,
        },
    ];

    /**
     * @var state
     */
    state = {
        availableImports: null,
        selectedImport: null,
        key: 0
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.getAvailableImports();
    }

    /**
     * @method getAvailableImports
     */
    getAvailableImports = async () => {
        const response = await AdminImportsApi.getAvailableImports();

        this.setState({
            availableImports: response.data
        });
    }

    /**
     * @method handleDownloadResults
     * @param {object} import_data
     */
    handleDownloadResults = async (import_data) => {
        AdminImportsApi.downloadFile(
            `/admin/imports/download-results/${import_data.id}`,
            {},
            'import_results_' + import_data.id + '.csv'
        );
    }

    /**
     * @method handleRefresh
     */
    handleRefresh = () => {
        const {key} = this.state;

        this.setState({
            key: key + 1
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() { 
        const {availableImports, selectedImport, key} = this.state;

        let options = availableImports ? availableImports.map((availableImport) => (
            {
                value: availableImport.import,
                label: availableImport.import.replace(/([A-Z])/g, ' $1').trim()
            }
        )) : null;

        return (
            <AdminLayout title="Imports">
                <div className="flex flex-row justify-center gap-2 bg-gray-200 p-4 w-max mx-auto">
                    <Select
                        containerClassName="w-72"
                        placeholder="Select An Import To Run..."
                        value={selectedImport ? selectedImport.import : ''}
                        onChange={(v) => this.setState({ 
                            selectedImport: v ? availableImports.filter(n => n.import === v)[0] : null
                        })}
                        options={options}
                    />

                    <ModalTrigger
                        disabled={selectedImport === null}
                        component={AdminRunImportModal} 
                        props={{
                            selectedImport,
                            callbackOnSubmit: this.handleRefresh,
                        }}
                    >
                        <PrimaryButton
                            text="Run Import"
                        />
                    </ModalTrigger>
                </div>

                <p 
                    className="font-semibold cursor-pointer mt-8 text-center"
                    onClick={this.handleRefresh}

                >
                    Click here to refresh the table
                </p>

                <IndexTable
                    columns={this.columns}
                    displaySearchBar={false}
                    loadDataCallback={(data) => AdminImportsApi.get(null, data)}
                    eventApi={AdminImportsApi}
                    key={key}
                />
            </AdminLayout>
        )
    }
}
import React from 'react';
import {faUsers} from '@fortawesome/free-solid-svg-icons/faUsers';

import Delete from 'Components/Partials/Modals/Delete';
import AdminSendEmailModal from 'Components/Partials/Modals/AdminSendEmailModal';
import {IndexTable} from 'Components/Utilities';
import {BooleanFilter, IsEmptyFilter, DateTimeFilter} from 'Components/Filters';
import { ArchiveUser } from "Components/HumanResources/AdminComponents";

import AdminLayout from 'Pages/Admin/AdminLayout';

import AdminUsersApi from 'Services/Api/Admin/Users';

export default  class UsersIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'First Name',
            value: 'first_name',
            type: 'string',
            orderable: 'first_name'
        },
        {
            label: 'Last Name',
            value: 'last_name',
            type: 'string',
            orderable: 'last_name'
        },
        {
            label: 'Email',
            value: 'email',
            type: 'string',
            orderable: 'email'
        },
        {
            label: 'Username',
            value: 'username',
            type: 'string',
            orderable: 'username',
            filters: [
                {
                    column: 'username',
                    label: 'Username',
                    component: IsEmptyFilter
                }
            ]
        },
        {
            label: 'Subscription',
            value: 'active_cashier_subscription_name',
            type: 'string',
            orderable: null,
            feature: 'subscriptions'
        },
        {
            label: 'Group',
            value: (record) => record.groups?.map((group, i) => (
                <>
                    {group.name}{i !== (record.groups.length - 1) ? ', ' : null}
                </>
            )),
            type: 'callback',
            orderable: null,
            feature: 'groups'
        },
        {
            label: 'Admin',
            value: 'is_admin',
            type: 'boolean',
            orderable: 'is_admin',
            filters: [
                {
                    column: 'is_admin',
                    label: 'Admin',
                    component: BooleanFilter
                }
            ]
        },
        {
            label: 'Colour',
            value: (user) => (
                <div
                    className="w-10 h-10 border"
                    style={{backgroundColor: user.colour}}
                />
            ),
            type: 'callback',
            orderable: null,
            valueClassName: 'text-center'
        },
        {
            label: 'Created At',
            value: 'created_at',
            type: 'datetime',
            orderable: 'created_at',
            hideByDefault: true,
            filters: [
                {
                    column: 'created_at',
                    label: 'Created At Before',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'before'
                    }
                },
                {
                    column: 'created_at',
                    label: 'Created At After',
                    component: DateTimeFilter,
                    props: {
                        type: 'datetime',
                        direction: 'after'
                    }
                }
            ]
        }
    ];

    /**
     * @var state
     */
    state = {
        hardPassword: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        {window.base.using_hard_password &&
            this.fetchHardPassword();
        }
    }

    /**
     * @method fetchHardPassword
     */
    fetchHardPassword = async () => {
        const response = await AdminUsersApi.getHardPassword();

        this.setState({
            hardPassword: response.data.hard_password,
        });
    }

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        let buttons = [
            {
                type: "link",
                to: "/admin/users/show/"+item.id,
                text: "View"
            },
            {
                type: "link",
                to: "/admin/users/store/"+item.id,
                text: "Update"
            }
        ];

        if (item.is_admin || !window.base.using_hard_password) {
            buttons.push({
                type: "link",
                to: "/admin/users/update-password/"+item.id,
                text: "Reset Password"
            });

            buttons.push({
                type: "modalTrigger",
                component: AdminSendEmailModal,
                props: {
                    user: item,
                    onSubmit: AdminUsersApi.sendPasswordResetLink,
                    emailTitle: 'password reset link'
                },
                text: 'Send Password Reset Link'
            });
        }

        buttons.push({
            type: "modalTrigger",
            component: AdminSendEmailModal,
            props: {
                user: item,
                onSubmit: AdminUsersApi.sendUserInvite,
                emailTitle: 'user invite',
                userInvite: true
            },
            text: 'Send User Invite'
        });

        if (window.base.features.notifications) {
            buttons.push({
                type: "link",
                to: "/admin/user-comms/"+item.id,
                text: 'Comms'
            });
        }

        if (window.base.features.subscriptions || window.base.features.products) {
            buttons.push({
                type: "link",
                to: "/admin/cashier/user-billings/"+item.id,
                text: 'Billing'
            });
        }

        buttons.push({
            type: "modalTrigger",
            component: Delete,
            props: {
                message: `Are you sure you want to delete this account?
                    Once an account is deleted, all of its resources and data will be permanently deleted.
                    This action can not be reversed.`,
                itemId: item.id,
                onDeleteApi: AdminUsersApi.delete
            },
            text: 'Delete'
        });

        buttons.push({
            type: "modalTrigger",
            component: ArchiveUser,
            props: {
                userId: item.id,
            },
            text: "Archive User"
        });

        return buttons;
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Users" titleIcon={faUsers}>
                <IndexTable
                    columns={this.columns}
                    defaultOrder={{
                        order_by: 'first_name',
                        order: 'asc'
                    }}
                    displaySearchBar={true}
                    storeLink="/admin/users/store"
                    loadDataCallback={(data) => AdminUsersApi.get(null, data)}
                    rowActions={this.getRowActions}
                    rowClickRedirect={(item) => "/admin/users/store/"+item.id}
                    eventApi={AdminUsersApi}
                    report="Users"
                />
            </AdminLayout>
        )
    }
}

import React from 'react';
import {faIdCard} from '@fortawesome/free-solid-svg-icons/faIdCard';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input, DatePicker} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import {User} from 'Services';
import MeApi from 'Services/Api/HumanResources/Me';

import SettingsPage from '../SettingsPage';

class HrProfile extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Your details have been updated successfully';

    /**
     * @var success
     */
    state = {
        loading: true,
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.fetchUser();
    };

    /**
     * @method fetchUser
     */
    fetchUser = async () => {
        this.setState({ loading: true });

        const response = await MeApi.get(null);

        let user = response.data.data;

        this.props.setForm({
            ...user,
            date_of_birth: user.date_of_birth,
        });

        this.setState({ loading: false });
    }

    /**
     * @method handleSubmit
     * @param form
     */
    handleSubmit = (form) => {
        return MeApi.patch('me', form);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {loading} = this.state;
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        return (
            <SettingsPage title={"My HR Profile"} titleIcon={faIdCard}>
                {loading && (<Loading />)}

                {!loading &&
                    <form
                        className="divide-y divide-gray-200 lg:col-span-9"
                        onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success)}
                    >
                        {alert !== null && (
                            <div className="p-6 pb-0">
                                <Alert {...alert} />
                            </div>
                        )}

                        <div className="p-6">
                            <h2 className="text-md leading-6 text-gray-600">Personal Details</h2>
                        </div>

                        <div className="p-6">
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <Input
                                    label="Address 1"
                                    value={form.address?.address_1}
                                    id="address_1"
                                    error={getFieldError('address.address_1')}
                                    onChange={(v) => handleInput('address.address_1', v)}
                                />

                                <Input
                                    label="Address 2"
                                    value={form.address?.address_2}
                                    id="address_2"
                                    error={getFieldError('address.address_2')}
                                    onChange={(v) => handleInput('address.address_2', v)}
                                />

                                <Input
                                    label="City"
                                    value={form.address?.city}
                                    id="city"
                                    error={getFieldError('address.city')}
                                    onChange={(v) => handleInput('address.city', v)}
                                />

                                <Input
                                    label="County"
                                    value={form.address?.county}
                                    id="county"
                                    error={getFieldError('address.county')}
                                    onChange={(v) => handleInput('address.county', v)}
                                />

                                <Input
                                    label="Post Code"
                                    value={form.address?.post_code}
                                    id="county"
                                    error={getFieldError('address.post_code')}
                                    onChange={(v) => handleInput('address.post_code', v)}
                                />

                                <Input
                                    label="Contact Number"
                                    value={form.contact_number}
                                    id="contact_number"
                                    error={getFieldError('contact_number')}
                                    onChange={(v) => handleInput('contact_number', v)}
                                />

                                <Input
                                    label="Personal Email"
                                    value={form.personal_email}
                                    id="personal_email"
                                    error={getFieldError('personal_email')}
                                    onChange={(v) => handleInput('personal_email', v)}
                                />

                                <DatePicker
                                    label="Date of Birth"
                                    selected={form.date_of_birth}
                                    id="date_of_birth"
                                    error={getFieldError('date_of_birth')}
                                    onChange={date => handleInput('date_of_birth', date)}
                                />
                            </div>
                        </div>

                        <div className="p-6">
                            <h2 className="text-md leading-6 text-gray-600">Payment Details</h2>
                        </div>

                        <div className="p-6">
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <Input
                                    label="Bank Name"
                                    value={form.bank_name}
                                    id="bank_name"
                                    error={getFieldError('bank_name')}
                                    onChange={(v) => handleInput('bank_name', v)}
                                />

                                <Input
                                    label="Name on account"
                                    value={form.bank_account_name}
                                    id="bank_account_name"
                                    error={getFieldError('bank_account_name')}
                                    onChange={(v) => handleInput('bank_account_name', v)}
                                />

                                <Input
                                    label="Account Number"
                                    value={form.bank_account_number}
                                    id="bank_account_number"
                                    error={getFieldError('bank_account_number')}
                                    onChange={(v) => handleInput('bank_account_number', v)}
                                />

                                <Input
                                    label="Sort Code"
                                    value={form.bank_sort_code}
                                    id="bank_sort_code"
                                    error={getFieldError('bank_sort_code')}
                                    onChange={(v) => handleInput('bank_sort_code', v)}
                                />

                                <Input
                                    label="NI Number"
                                    value={form.national_insurance}
                                    id="national_insurance"
                                    error={getFieldError('national_insurance')}
                                    onChange={(v) => handleInput('national_insurance', v)}
                                />
                            </div>
                        </div>

                        <div className="p-6">
                            <h2 className="text-md leading-6 text-gray-600">Emergency Contact Details</h2>
                        </div>

                        <div className="p-6">
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <Input
                                    label="First Name"
                                    value={form.emergency_contact_first_name}
                                    id="emergency_contact_first_name"
                                    error={getFieldError('emergency_contact_first_name')}
                                    onChange={(v) => handleInput('emergency_contact_first_name', v)}
                                />

                                <Input
                                    label="Last Name"
                                    value={form.emergency_contact_last_name}
                                    id="emergency_contact_last_name"
                                    error={getFieldError('emergency_contact_last_name')}
                                    onChange={(v) => handleInput('emergency_contact_last_name', v)}
                                />

                                <Input
                                    label="Relationship"
                                    value={form.emergency_contact_relationship}
                                    id="emergency_contact_relationship"
                                    error={getFieldError('emergency_contact_relationship')}
                                    onChange={(v) => handleInput('emergency_contact_relationship', v)}
                                />

                                <Input
                                    label="Contact Number"
                                    value={form.emergency_contact_number}
                                    id="emergency_contact_number"
                                    error={getFieldError('emergency_contact_number')}
                                    onChange={(v) => handleInput('emergency_contact_number', v)}
                                />

                                <Input
                                    label="Contact Email"
                                    value={form.emergency_contact_email}
                                    id="emergency_contact_email"
                                    error={getFieldError('emergency_contact_email')}
                                    onChange={(v) => handleInput('emergency_contact_email', v)}
                                />

                                <Input
                                    label="Address 1"
                                    value={form.emergency_contact_address?.address_1}
                                    id="address_1"
                                    error={getFieldError('emergency_contact_address.address_1')}
                                    onChange={(v) => handleInput('emergency_contact_address.address_1', v)}
                                />

                                <Input
                                    label="Address 2"
                                    value={form.emergency_contact_address?.address_2}
                                    id="address_2"
                                    error={getFieldError('emergency_contact_address.address_2')}
                                    onChange={(v) => handleInput('emergency_contact_address.address_2', v)}
                                />

                                <Input
                                    label="City"
                                    value={form.emergency_contact_address?.city}
                                    id="city"
                                    error={getFieldError('emergency_contact_address.city')}
                                    onChange={(v) => handleInput('emergency_contact_address.city', v)}
                                />

                                <Input
                                    label="County"
                                    value={form.emergency_contact_address?.county}
                                    id="county"
                                    error={getFieldError('emergency_contact_address.county')}
                                    onChange={(v) => handleInput('emergency_contact_address.county', v)}
                                />

                                <Input
                                    label="Post Code"
                                    value={form.emergency_contact_address?.post_code}
                                    id="county"
                                    error={getFieldError('emergency_contact_address.post_code')}
                                    onChange={(v) => handleInput('emergency_contact_address.post_code', v)}
                                />
                            </div>
                        </div>

                        <div className="p-6 flex justify-end">
                            <PrimaryButton
                                text="Save"
                                working={working}
                            />
                        </div>
                    </form>
                }
            </SettingsPage>
        );
    }
}

export default FormHandler(HrProfile);

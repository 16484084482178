import Notifications from 'Pages/Settings/Comms/Notifications';
import NotificationSettings from 'Pages/Settings/Comms/NotificationSettings';

import AdminUserComms from 'Pages/Admin/Comms/UserComms';

export default [
    { path: '/settings/notifications', component: Notifications, guards: ['auth', 'two-factor-verified'], feature: 'notifications' },
    { path: '/settings/notification-settings', component: NotificationSettings, guards: ['auth', 'two-factor-verified'], feature: 'notification_settings' },

    { path: '/admin/user-comms/:user', component: AdminUserComms, guards: ['admin'], feature: 'notifications' },
];

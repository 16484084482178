import React from 'react';

import {Loading} from 'Components/Partials';
import {FormHandler, FileUpload, Input, Select, Textarea, Toggle, WYSIWYG, DatePicker, BodyMap, ImageUpload} from 'Components/Form';
import {Alert} from 'Components/Partials';
import {PrimaryButton, SecondaryButton} from 'Components/Button';

import ElementApi from 'Services/Api/Cms/Elements/Element';
import RecordApi from 'Services/Api/Cms/Elements/Record';
import TagApi from 'Services/Api/Cms/Elements/Tag';
import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';

import {translation} from 'Services/TranslationHelpers';
import {translationFromJson} from 'Services/TranslationHelpers2';
import {getRecordValue} from 'Services/CmsHelpers';
import {Modal, User} from "Services";
import Confirm from "Components/Partials/Modals/Confirm";

class CreateCmsElementRecord extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = this.props.element_record_id && !this.props.clone ?
        translation('misc', 'item_updated') :
        translation('misc', 'item_created');

    /**
     * @var state
     */
    state = {
        working: true,
        elements: null,
        selected_element_id: null,
        tags: null,
        errorMessage: null,
        attach_to_service_user: this.props.attach_to_service_user
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        const {showElementsDropdown, element_id, element_record_id} = this.props;

        if (!showElementsDropdown && !element_id) {
            console.error('Must pre-select an element if not showing the elements dropdown.');
            return;
        }

        if (showElementsDropdown && element_record_id) {
            console.error('Can not show elements dropdown and be updating a record.');
            return;
        }

        await this.getElements();

        if (element_id) {
            await this.setElement(element_id);
        }

        this.setState({ working: false });
    }

    /**
     * @method getElements
     * @return null
     */
    getElements = async () => {
        const {
            element_id,
            attach_to_service_user = null,
            health_and_safety = null,
            quality_assurance = null,
            medication_management = null,
        } = this.props;

        const response = await ElementApi.get(null, {
            attach_to_service_user,
            health_and_safety,
            quality_assurance,
            medication_management,
        });

        let elements = response.data.data;

        if (!User.data.is_admin) {
            elements = elements.filter(e => e.display_form_on_frontend);
        }

        this.setState({
            elements
        });
    }

    /**
     * @method setElement
     * @param {string} element_id
     * @return null
     */
    setElement =  async (element_id) => {
        const {element_record_id, setTitleCallback, clone = false} = this.props;
        const {elements} = this.state;

        let element = elements.filter(e => e.id === element_id)[0];

        if (!element) {
            this.setState({
                errorMessage: 'This form has been disabled for non-admin users.'
            });
            return;
        }

        this.setState({
            selected_element_id: element_id,
            attach_to_service_user: element.attach_to_service_user
        }, async () => {
            await this.getTags();

            let element_label = translationFromJson(element.label);

            if (element_record_id) {
                await this.getRecord(element_record_id);
            } else {
                await this.setEmptyRecord();
            }

            setTitleCallback(translation(
                'narratives',
                element_record_id && !clone ? 'update' : 'create'
            ));
        })
    }

    /**
     * @method getTags
     * @return null
     */
    getTags = async () => {
        const response = await TagApi.get(null, {
            cms_element_id: this.state.selected_element_id
        });

        this.setState({
            tags: response.data.tags,
        });
    }

    /**
     * @method getRecord
     * @param {string} element_record_id
     * @return null
     */
    getRecord = async (element_record_id) => {
        const {setForm} = this.props;
        const {elements, selected_element_id} = this.state;

        const response = await RecordApi.get(element_record_id);

        let record = response.data.data;

        let form = {};

        let element = elements.filter(o => o.id === selected_element_id)[0];

        {Object.entries(element.fields).map((field, j) => {
            form[field[1].identifier] = getRecordValue(record, field[1].identifier);
        })}

        form.active = record.active;
        form.supported_languages = record.supported_languages;
        form.allow_likes = record.allow_likes;
        form.allow_comments = record.allow_comments;
        form.tags = record.tags.map((o) => (o.tag));

        form.service_user_id = record.service_user_id ?? null;
        form.date_of_observation = record.date_of_observation;

        setForm(form);
    }

    setEmptyRecord = async () => {
        const {setInitialValues} = this.props;
        const {elements, selected_element_id} = this.state;

        let form = {};

        let element = elements.filter(o => o.id === selected_element_id)[0];

        {Object.entries(element.fields).map((field, j) => {
            let value = null;

            if (field[1].type === 'boolean') {
                value = false;
            } else if (field[1].type === 'date' || field[1].type === 'time') {
                value = new Date();
            }

            form[field[1].identifier] = value;
        })}

        form.active = true;
        form.supported_languages = Object.keys(window.base.languages);

        if (element.allow_likes) {
            form.allow_likes = true;
        } else {
            form.allow_likes = false;
        }

        if (element.allow_comments) {
            form.allow_comments = true;
        } else {
            form.allow_comments = false;
        }

        form.tags = null;

        if (this.props.service_user_id) {
            form.service_user_id = this.props.service_user_id;
        }

        form.date_of_observation = this.props.date_of_observation ?? null;

        setInitialValues(form);
    }

    /**
     * @method createRecord
     * @param {object} form
     * @return {Promise<*>}
     */
    createRecord = (form) => {
        const {elements, selected_element_id} = this.state;
        const {element_record_id, clone = false} = this.props;

        let element = elements.filter(o => o.id === selected_element_id)[0];

        let form2 = {
            ...form,
            cms_element_id: selected_element_id
        };

        const formatDateToDDMMYYYY = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };

        const today = formatDateToDDMMYYYY(new Date()) ?? null;
        const observationDate = formatDateToDDMMYYYY(new Date(form2.date_of_observation)) ?? null;

        if (observationDate !== today && form2.date_of_observation !== null && !User.isAdmin) {
            Modal.open({
                component: Confirm,
                props: {
                    title: 'Date of Observation',
                    message: 'This entry is not for the current day, are you sure you want to add to a previous day?',
                    onConfirm: () => {
                        if (element_record_id && !clone) {
                            return RecordApi.updateRecord(element_record_id, form2, element.fields);
                        } else {
                            return RecordApi.storeRecord(form2, element.fields);
                        }
                    },
                    onClose: () => {
                        // Handle the case where the user decides to change the date
                        //Modal.close();
                    }
                }
            });
        } else {
            if (element_record_id && !clone) {
                return RecordApi.updateRecord(element_record_id, form2, element.fields);
            } else {
                return RecordApi.storeRecord(form2, element.fields);
            }
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, elements, selected_element_id, errorMessage, attach_to_service_user} = this.state;
        const {showElementsDropdown, form, getFieldError, handleInput} = this.props;

        let element_options = elements ?
            elements.map((element, key) => {
                return {
                    label: translationFromJson(element.label),
                    value: element.id
                };
            }) :
            null;

        if (errorMessage) {
            return (
                <div className="text-center">
                    {errorMessage}
                </div>
            );
        }

        return (
            <div>
                {working && (<Loading />)}

                {!working && attach_to_service_user &&
                    <div>
                        <Select
                            containerClassName="mb-4"
                            label="Service User"
                            id="service_user_id"
                            value={form.service_user_id}
                            error={getFieldError('service_user_id')}
                            onChange={(v) => handleInput('service_user_id', v)}
                            isAsync
                            searchCallback={(data) => ServiceUsersApi.get(null, {
                                ...data,
                                enabled: true
                            })}
                            searchLabelKey="full_name"
                            allowNull={true}
                        />
                    </div>
                }

                {!working && showElementsDropdown &&
                    <Select
                        containerClassName="mb-4"
                        label={translation('cms', 'item_type')}
                        value={selected_element_id}
                        onChange={(v) => this.setElement(v)}
                        options={element_options}
                    />
                }

                {!working && selected_element_id && this.renderForm()}
            </div>
        );
    }

    /**
     * @method renderForm
     * @return {JSX.Element}
     */
    renderForm = () => {
        const {handleInput, handleSubmit, form, working, alert, element_record_id, onSuccess, clone = false} = this.props;
        const {elements, selected_element_id} = this.state;

        let element = elements.filter(o => o.id === selected_element_id)[0];

        return (
            <form onSubmit={(e) => handleSubmit(e, this.createRecord, onSuccess ?? this.success, (element_record_id && !clone ? false : true))}>
                {this.renderDefaultFields()}

                {Object.entries(element.fields).map((field, j) => {
                    return this.renderInput(field[1], j);
                })}

                {alert && <Alert {...alert} />}

                <div className={`w-full mt-8 flex justify-center`}>
                    <PrimaryButton
                        disabled={working}
                        text={element_record_id && !clone ? translation('misc', 'update') : translation('misc', 'create')}
                    />
                </div>
            </form>
        );
    };

    /**
     * @method renderDefaultFields
     * @return {JSX.Element}
     */
    renderDefaultFields = () => {
        const {handleInput, getFieldError, form} = this.props;
        const {elements, selected_element_id, tags, attach_to_service_user} = this.state;

        let element = elements.filter(o => o.id === selected_element_id)[0];

        let languages = Object.entries(window.base.languages).map((value, key) => {
            return {
                label: value[1],
                value: value[0]
            };
        });

        let options = tags ? tags.map((value, key) => {
            return {
                label: value,
                value: value
            };
        }) : null;

        return (
            <>
                {attach_to_service_user &&
                    <DatePicker
                        containerClassName="mb-4"
                        label="Date of Observation"
                        selected={form.date_of_observation}
                        error={getFieldError('date_of_observation')}
                        onChange={date => handleInput('date_of_observation', date)}
                        showTimeSelect={true}
                        timeIntervals={5}
                    />
                }

                {element.filter_languages && languages.length > 1 &&
                    <div>
                        <Select
                            containerClassName="mb-4"
                            label="Supported Languages"
                            value={form.supported_languages}
                            error={getFieldError('supported_languages')}
                            onChange={(v) => handleInput('supported_languages', v)}
                            options={languages}
                            isMulti
                        />
                    </div>
                }

                {element.allow_tags &&
                    <Select
                        containerClassName="mb-4"
                        label="Tags"
                        value={form.tags}
                        error={getFieldError('tags')}
                        onChange={v => handleInput('tags', v)}
                        options={options}
                        isMulti
                        isCreatable
                    />
                }

                {element.allow_likes &&
                    <Toggle
                        containerClassName="mb-4"
                        label="Allow Likes"
                        value={form.allow_likes}
                        error={getFieldError('allow_likes')}
                        onChange={v => handleInput('allow_likes', v)}
                    />
                }

                {/* Default to true for TTS
                <Toggle
                    containerClassName="mb-4"
                    label="Active"
                    value={form.active}
                    error={getFieldError('active')}
                    onChange={v => handleInput('active', v)}
                />
                */}
            </>
        );
    }

    /**
     * @method renderInput
     * @param {object} field
     * @param {string} key
     * @return {JSX.Element}
     */
    renderInput = (field, key) => {
        if (!field.admin_only || User.data.is_admin) {
            if (this.customRules(field)) {
                let label = translationFromJson(field.label);

                if (!this['renderInput_' + field.type]) {
                    console.error('Invalid field type : ' + field.type);
                }

                return this['renderInput_' + field.type](field, key, label);
            }
        }
    };

    /**
     * @method customRules
     * @param {object} field
     * @return {boolean}
     */
    customRules = (field) => {
        // Add any custom site rules here, e.g. only show one field depending on another value if element.identifier='x'.

        const {form} = this.props;
        const {elements, selected_element_id} = this.state;

        let element = elements.filter(o => o.id === selected_element_id)[0];

        if (element.identifier === 'celebration-wall') {
            if (field.identifier === 'file') {
                let content_type_field_id = element.fields.filter(o => o.identifier === 'content_type')[0].identifier;

                if (form[content_type_field_id] !== 'image' && form[content_type_field_id] !== 'video') {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * @method renderInput_text
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_text = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <Input
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                type="text"
                value={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={v => handleInput(field.identifier, v)}
                key={key}
            />
        );
    };

    /**
     * @method renderInput_textarea
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_textarea = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <Textarea
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                value={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={v => handleInput(field.identifier, v)}
                key={key}
            />
        );
    };

    /**
     * @method renderInput_file
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_file = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <>
                <FileUpload
                    containerClassName="mb-4 flex flex-col justify-center items-center gap-4"
                    label={label}
                    instructions={field.instructions}
                    value={form[field.identifier]}
                    error={getFieldError(field.identifier)}
                    onChange={v => handleInput(field.identifier, v)}
                    accept={field.file_accept}
                    placeholder={form[field.identifier] ?
                        translation('misc', 'update_file') :
                        translation('misc', 'select_file')}
                    key={key}
                />

                {form[field.identifier] && form[field.identifier].url &&
                    <div className="mb-4 text-center">
                        <a
                            href={form[field.identifier].url}
                            target="_blank"
                        >
                            <SecondaryButton
                                text="Download Existing File"
                                onClick={null}
                            />
                        </a>
                    </div>
                }
            </>
        );
    };

    /**
     * @method renderInput_boolean
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_boolean = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <Toggle
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                value={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={v => handleInput(field.identifier, v)}
                key={key}
            />
        );
    };

    /**
     * @method renderInput_multiple_choice_single_value
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_multiple_choice_single_value = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        let options = field.multiple_choice_values.map((value, key) => {
            let translation_value = translation('misc', value, null, false);

            return {
                label: translation_value && translation_value !== '' ? translation_value : value,
                value: value
            };
        });

        return (
            <Select
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                value={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={v => handleInput(field.identifier, v)}
                options={options}
                key={key}
            />
        );
    };

    /**
     * @method renderInput_wysiwyg
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_wysiwyg = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <WYSIWYG
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                value={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={value => handleInput(field.identifier, value)}
                key={key}
            />
        );
    }

    /**
     * @method renderInput_date_time
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_date_time = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <DatePicker
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                selected={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={date => handleInput(field.identifier, date)}
                showTimeSelect={true}
                timeIntervals={5}
                key={key}
            />
        );
    }

    /**
     * @method renderInput_date
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_date = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <DatePicker
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                selected={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={date => handleInput(field.identifier, date)}
                key={key}
            />
        );
    }

    /**
     * @method renderInput_time
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_time = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <DatePicker
                containerClassName="mb-4"
                label={label}
                instructions={field.instructions}
                selected={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={time => handleInput(field.identifier, time)}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={15}
                key={key}
            />
        );
    }

    /**
     * @method renderInput_body_map
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_body_map = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <BodyMap
                containerClassName="mb-4 p-4 bg-gray-200"
                label={label}
                instructions={field.instructions}
                value={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={time => handleInput(field.identifier, time)}
                key={key}
            />
        );
    }

    /**
     * @method renderInput_body_map
     * @param {object} field
     * @param {string} key
     * @param {string} label
     * @return {JSX.Element}
     */
    renderInput_image_upload = (field, key, label) => {
        const {handleInput, getFieldError, form} = this.props;

        return (
            <ImageUpload
                containerClassName="mb-4 p-4 bg-gray-200"
                label={label}
                instructions={field.instructions}
                value={form[field.identifier]}
                error={getFieldError(field.identifier)}
                onChange={time => handleInput(field.identifier, time)}
                key={key}
            />
        );
    }
}

export default FormHandler(CreateCmsElementRecord);

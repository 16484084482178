import React from 'react';
import {DateTime} from 'luxon';

import {Alert} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {FormHandler, Input, Select, DatePicker, WYSIWYG, Toggle, Textarea} from 'Components/Form';

import {translation} from 'Services/TranslationHelpers';
import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';
import AdminUsersApi from 'Services/Api/Admin/HumanResources/Users';
import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';
import User from "Services/User";

import Base from './Base';

class CreateTimesheetModal extends Base {
    /**
     * @var success
     * @type {string}
     */
    success = this.props.record && !this.props.copy ? 'Successfully updated.' : 'Successfully added.';

    /**
     * @var state
     */
    state = {
        settings: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        const {admin, record, type, setForm} = this.props;

        if (!admin && record && record.submitted === true) {
            await this.fetchUserSettings();
        }

        if (record) {
            setForm({
                title: record.title,
                type: record.type,
                description: record.description,
                start_date_time: record.start_date_time,
                end_date_time: record.end_date_time,
                all_day: record.type === 'holiday' ? false : record.all_day,
                employee_id: record.employee_id ?? null,
                employee: record.employee ?? null,
                submitted: record.submitted,
                service_user_id: record.service_user_id ?? null,
                sleeps_in: record.sleeps_in ?? false,
                mileage: record.mileage,
                time_worked_type: record.time_worked_type,
                query_resolved: record.query_resolved
            });
        } else {
            if (type) {
                this.handleTypeChange(type);
            }
        }
    }

    /**
     * @method handleTypeChange
     * @param {string} type
     */
    handleTypeChange = (type) => {
        let {setForm, form, start_date_time, end_date_time} = this.props;

        // Restrict to max date.
        if (start_date_time && this.getMaxDate()) {
            if (new Date() < new Date(start_date_time)) {
                start_date_time = null;
            }
        }

        if (end_date_time  && this.getMaxDate()) {
            if (new Date() < new Date(end_date_time)) {
                end_date_time = null;
            }
        }

        // Set Form.
        setForm({
            type: type,
            start_date_time: form.start_date_time ?? (start_date_time ? new Date(start_date_time) : new Date()),
            end_date_time: form.end_date_time ?? (end_date_time ? new Date(end_date_time) : new Date()),
            all_day: (['time_worked', 'sick', 'holiday', 'lateness'].includes(type) ? false : true),
            submitted: form.submitted ?? false,
            sleeps_in: form.sleeps_in ?? false,
            mileage: form.mileage ?? 0,
            time_worked_type: form.time_worked_type ?? null,
        });
    }

    /**
     * @method handleSubmit
     * @param {event} e
     */
    handleSubmit = async (e) => {
        const {handleSubmit, record} = this.props;

        await handleSubmit(e, this.handleSubmit2, this.success);

        const {alert} = this.props;

        if (alert.type === 'success') {
            this.props.onClose();
        }
    };

    /**
     * @method handleSubmit2
     * @param {object} form
     * @return {Promise<void>}
     */
    handleSubmit2 = (form) => {
        const {record, admin, copy} = this.props;

        // If all_day=true then set the start and end time to the start and end of the day accordingly.
        if (form.all_day && (typeof form.start_date_time === '[object Date]' || Object.prototype.toString.call(form.start_date_time) === '[object Date]')) {
            form.start_date_time = new Date(Date.UTC(form.start_date_time.getFullYear(), form.start_date_time.getMonth(), form.start_date_time.getDate(), 0, 0, 0, 0));
            form.end_date_time = new Date(Date.UTC(form.start_date_time.getFullYear(), form.start_date_time.getMonth(), (form.start_date_time.getDate() + 1), 0, 0, 0, 0));
        }

        if (record && !copy) {
            if (admin) {
                return AdminTimesheetsApi.patch({
                    record: record.id
                }, form);
            } else {
                return TimesheetsApi.patch(record.id, form);
            }
        } else {
            if (admin) {
                return AdminTimesheetsApi.post(null, form);
            } else {
                return TimesheetsApi.post(null, form);
            }
        }
    };

    /**
     * @method getMaxDate
     * @return {DateTime|null}
     */
    getMaxDate() {
        const {admin, form} = this.props;

        return !admin && form.type !== 'holiday' ?
            DateTime.now().toJSDate() :
            null;
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {settings} = this.state;
        const {record, copy, admin, handleSubmit, working, alert, onlyShowMileage = false} = this.props;

        if (!admin && record && !copy && record.submitted === true && settings?.user_cannot_edit_submitted_timesheet === true) {
            return this.renderUserCannnotEdit();
        }

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {record && !copy ? 'Update' : 'Create'} Calendar Entry
                </ModalHeader>

                <form onSubmit={this.handleSubmit}>
                    <ModalBody>
                        {alert !== null && (<Alert {...alert} />)}

                        {onlyShowMileage && this.renderOnlyShowMileage()}

                        {!onlyShowMileage && this.renderForm()}
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton
                            text={record && !copy ? 'Update' : 'Create'}
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }

    /**
     * @method renderUserCannnotEdit
     * @return {JSX.Element}
     */
    renderUserCannnotEdit() {
        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    Update Calendar Entry
                </ModalHeader>

                <ModalBody>
                    <p className="text-center font-bold">
                        {translation('human_resources', 'user_cannot_edit_submitted_timesheet_message')}
                    </p>
                </ModalBody>
            </Modal>
        );
    }

    /**
     * @method renderOnlyShowMileage
     * @return {JSX.Element}
     */
    renderOnlyShowMileage() {
        const {form, handleInput} = this.props;

        return (
            <Input
                containerClassName="mb-4"
                label="Mileage"
                type="number"
                value={form.mileage}
                onChange={v => handleInput('mileage', v)}
            />
        );
    }

    /**
     * @method renderForm
     * @return {JSX.Element}
     */
    renderForm() {
        const {record, admin, form, handleInput} = this.props;

        let types = Object.entries(window.base.hr_timesheet_types)
            .filter(value => !value[1]?.must_be_admin || User.isAdmin)
            .map((value, key) => ({
                label: value[1]?.label,
                value: value[0],
            }));

        if (record && !admin && form.type === 'time_worked') {
            return (
                <Input
                    containerClassName="mb-4"
                    label="Mileage"
                    type="number"
                    value={form.mileage}
                    onChange={v => handleInput('mileage', v)}
                />
            );
        }

        return (
            <>
                <Select
                    className="mb-4"
                    label="Type"
                    value={form.type}
                    onChange={v => this.handleTypeChange(v)}
                    options={types}
                />

                {admin &&
                    <Select
                        containerClassName="mb-4"
                        label="Staff Member"
                        id="employee_id"
                        value={form.employee_id}
                        onChange={(v) => handleInput('employee_id', v)}
                        isAsync
                        searchCallback={(data) => AdminUsersApi.get(null, data)}
                        searchLabelKey="full_name"
                        allowNull={true}
                    />
                }

                {form.type &&
                    <>
                        {!['holiday', 'sick', 'lateness'].includes(form.type) &&
                            <Select
                                containerClassName="mb-4"
                                label="Service User"
                                id="service_user_id"
                                value={form.service_user_id}
                                onChange={(v) => handleInput('service_user_id', v)}
                                isAsync
                                searchCallback={(data) => ServiceUsersApi.get(null, {
                                    ...data,
                                    enabled: true
                                })}
                                searchLabelKey="full_name"
                                allowNull={true}
                            />
                        }

                        {/**form.type === 'time_worked' &&
                            <Input
                                containerClassName="mb-4"
                                label="Title"
                                type="text"
                                value={form.title}
                                onChange={v => handleInput('title', v)}
                            />
                        **/}

                        {form.type === 'holiday' && (
                            <>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <DatePicker
                                        label="Start"
                                        selected={ form.start_date_time }
                                        onChange={ date => handleInput('start_date_time', date) }
                                        showTimeSelect={ false }
                                        selectsStart
                                        startDate={ form.start_date_time }
                                        endDate={ form.end_date_time }
                                    />

                                    <DatePicker
                                        label="End"
                                        selected={ form.end_date_time }
                                        startDate={ form.start_date_time }
                                        endDate={ form.end_date_time }
                                        minDate={ form.start_date_time }
                                        onChange={ date => handleInput('end_date_time', date) }
                                        showTimeSelect={ false }
                                        selectsEnd
                                    />
                                </div>
                            </>
                        )}

                        {form.type !== 'holiday' && (
                            <>
                                <DatePicker
                                    containerClassName="mb-4"
                                    label={form.all_day ? "Date" : "Start"}
                                    selected={form.start_date_time}
                                    onChange={date => handleInput('start_date_time', date)}
                                    showTimeSelect={!form.all_day}
                                    timeIntervals={5}
                                    maxDate={this.getMaxDate()}
                                />

                                {!form.all_day &&
                                    <DatePicker
                                        containerClassName="mb-4"
                                        label="End"
                                        selected={form.end_date_time}
                                        onChange={date => handleInput('end_date_time', date)}
                                        showTimeSelect={!form.all_day}
                                        timeIntervals={5}
                                        maxDate={this.getMaxDate()}
                                    />
                                }

                                {form.type === 'time_worked' &&
                                    <>
                                        <Toggle
                                            containerClassName="mb-4"
                                            label="Sleeps In"
                                            value={form.sleeps_in}
                                            onChange={v => handleInput('sleeps_in', v)}
                                        />

                                        <Input
                                            containerClassName="mb-4"
                                            label="Mileage"
                                            type="number"
                                            value={form.mileage}
                                            onChange={v => handleInput('mileage', v)}
                                        />

                                        {/*
                                <Select
                                    className="mb-4"
                                    label={window.base.hr_timesheet_types['time_worked'] + " Type"}
                                    value={form.time_worked_type}
                                    onChange={v => handleInput('time_worked_type', v)}
                                    options={this.time_worked_types}
                                />
                                */}
                                    </>
                                }

                                {['time_worked', 'holiday', 'sick', 'lateness'].includes(form.type) &&
                                    <Toggle
                                        containerClassName="mb-4"
                                        label="Submitted"
                                        value={form.submitted}
                                        onChange={v => handleInput('submitted', v)}
                                    />
                                }
                            </>
                        )}

                        <Textarea
                            label="Notes"
                            containerClassName="mb-4"
                            value={form.description}
                            onChange={v => handleInput('description', v)}
                        />
                    </>
                }
            </>
        );
    }
}

export default FormHandler(CreateTimesheetModal);

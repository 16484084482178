import React from 'react';

import {Modal, ModalHeader, ModalBody} from 'Components/Modal';
import {Loading, ActionsButton} from 'Components/Partials';

import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';
import {getColumns, getRecordActions} from 'Services/HrHelpers';
import {convertValue} from 'Services/BaseHelpers';

export default class ShowTimesheetModal extends React.Component {
    /**
     * @var state
     */
    state = {
        loading: true,
        record: null,
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        this.fetchRecord();

        if (this.props.admin) {
            AdminTimesheetsApi.on('updated', this.fetchRecord);
        } else {
            TimesheetsApi.on('updated', this.fetchRecord);
        }
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        if (this.props.admin) {
            AdminTimesheetsApi.removeListener('updated', this.fetchRecord);
        } else {
            TimesheetsApi.removeListener('updated', this.fetchRecord);
        }
    };

    /**
     * @method fetchRecord
     */
    fetchRecord = async () => {
        const {admin, timesheet_id} = this.props;

        this.setState({loading: true});

        if (admin) {
            var response = await AdminTimesheetsApi.get(timesheet_id);
        } else {
            var response = await TimesheetsApi.get(timesheet_id);
        }

        if (response.success) {
            this.setState({
                loading: false,
                record: response.data.data,
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {admin, onClose} = this.props;
        const {loading, record} = this.state;

        if (loading) {
            return (
                <Modal>
                    <ModalHeader onClose={this.props.onClose} />

                    <ModalBody className="px-6 pb-6">
                        <Loading/>
                    </ModalBody>
                </Modal>
            );
        }

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    <span className="capitalize">
                        {window.base.hr_timesheet_types[record.type]?.label}
                    </span>
                </ModalHeader>

                <ModalBody className="px-6 pb-6">
                    <div>
                        <div className="overflow-x-auto mb-8">
                            <table className="table-auto mx-auto">
                                {getColumns(admin, record.type).map((column, i) => (
                                    <tr key={i}>
                                        <th className="px-4 py-2 border bg-gray-300">
                                            {column.label}
                                        </th>
                                        <td className="px-4 py-2 border">
                                            {convertValue(column, record)}
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>

                        <ActionsButton
                            buttons={getRecordActions(record, admin, false)}
                            layout="row"
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

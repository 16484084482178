import React from 'react';
import profanity from 'leo-profanity';

import {isAdminPage} from 'Services/BaseHelpers';

import EmojiPicker from './EmojiPicker';
import Label from './Label';

/**
 * @function Textarea
 * @param {string} containerClassName
 * @param {string} className
 * @param {string} label
 * @param {string} placeholder
 * @param {string} id
 * @param {function} onChange
 * @param {string} value
 * @param {boolean} block
 * @param {string} instructions
 * @param {null|array} error
 * @param {boolean} readOnly
 * @param {boolean} emojiPicker
 * @param {integer} rows
 * @param {boolean} profanityCheck
 * @return {JSX.Element}
 * @constructor
 */
const Textarea = ({
    containerClassName,
    className,
    label,
    placeholder,
    id,
    onChange,
    value,
    block = true,
    instructions,
    error = null,
    readOnly = false,
    emojiPicker = false,
    rows = 2,
    profanityCheck = false
}) => {
    let classes = `
        block w-full border py-2 px-3 focus:outline-none
        sm:text-sm bg-app-backup bg-opacity-10 rounded-2xl
        ${error ? 'border-red-800' : 'border-app-backup border-opacity-10'}
        ${className && className.includes('mt-0') ? '' : 'mt-1'}
        ${className}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <div className={containerClassName}>
            {label && (
                <Label
                    label={label}
                    htmlFor={id}
                    instructions={instructions}
                    error={error}
                />
            )}

            <textarea
                readOnly={readOnly}
                id={id}
                value={value ?? ''}
                onChange={(e) =>
                    onChange(profanityCheck ? profanity.clean(e.target.value, '') : e.target.value)
                }
                placeholder={placeholder}
                className={classes}
                rows={rows}
            />

            {emojiPicker &&
                <EmojiPicker
                    buttonClassName="absolute top-4 right-6"
                    onChange={(v) => onChange(value + v)}
                />
            }
        </div>
    )
};

export default Textarea;

import React from 'react';

import {SecondaryButton} from 'Components/Button';
import {FormHandler, Textarea} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import MedicationDetailsApi from 'Services/Api/ServiceUsers/MedicationDetails';

class Store extends React.Component {

    /**
     * @var success
     * @type {string}
     */
    success = 'Data updated successfully';

    /**
     * @var state
     */
    state = {
        id: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadData();
    }

    /**
     * @method loadData
     */
    loadData = async () => {
        const {service_user_id} = this.props;

        const response = await MedicationDetailsApi.get(null, {
            service_user_id
        });

        this.setState({
            id: response?.data?.data?.id ?? response?.data?.id // For some unknown reason this is necessary.
        });

        this.props.setForm(response?.data?.data ?? response?.data);
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {id} = this.state;

        return MedicationDetailsApi.patch(id, form);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() { 
        const {readOnly = false} = this.props;

        if (readOnly) {
            return this.renderReadOnly();
        } else {
            return this.renderForm();
        }
    }

    /**
     * @method renderReadOnly
     * @return {JSX.Element}
     */
    renderReadOnly() {
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        if (!form.medication_details && !form.medication_compliance) {
            return null;
        }

        return (
            <div className="bg-app-supporting-yellow bg-opacity-50 p-6 w-full md:w-3/4 mx-auto flex flex-col justify-center items-center gap-8 text-center mt-8">
                {form.medication_details &&
                    <div>
                        <p className="font-bold mb-2">
                            Any other medication details
                        </p>

                        <p>
                            {form.medication_details}
                        </p>
                    </div>
                }

                {form.medication_compliance &&
                    <div>
                        <p className="font-bold mb-2">
                            Does the individual comply with their medication
                        </p>

                        <p>
                            {form.medication_compliance}
                        </p>
                    </div>
                }
            </div>
        );
    }

    /**
     * @method renderForm
     * @return {JSX.Element}
     */
    renderForm() {
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        return (
            <div>
                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success)}>
                    {alert !== null && (<Alert {...alert} />)}
                        
                    <Textarea
                        containerClassName="mb-8 flex flex-col justify-center items-center"
                        label="Any other medication details?"
                        value={form.medication_details}
                        id="medication_details"
                        onChange={(v) => handleInput('medication_details', v)}
                        instructions={`
                            E.g., registered pharmacy if applicable, MARS sheet, storage, 
                            do they only take their medication at home or do Time To Shine staff need to administer, etc.
                        `}
                    />

                    <Textarea
                        containerClassName="mb-8 flex flex-col justify-center items-center"
                        label="Does the individual comply with their medication? If not, explain why and how this is managed."
                        value={form.medication_compliance}
                        id="medication_compliance"
                        onChange={(v) => handleInput('medication_compliance', v)}
                    />

                    <SecondaryButton
                        text="Save"
                        working={working}
                    />
                </form>
            </div>
        )
    }
}

export default FormHandler(Store);

import React from 'react';

import {Select} from 'Components/Form';
import {PrimaryButton} from 'Components/Button';
import {Loading, Alert} from 'Components/Partials';

import SettingsApi from 'Services/Api/Settings/Settings';
import Settings from 'Services/Settings';
import {translation} from 'Services/TranslationHelpers';

export default class DisplayTimeZone extends React.Component {
    /**
     * @var setting
     * @type {string}
     */
    setting = 'timezone';

    /**
     * @var state
     * @type {{working: boolean, alert: object, editMode: boolean, value: string}}
     */
    state = {
        working: true,
        alert: null,
        editMode: this.props.editMode ?? false,
        value: null,
        timezones:  null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.loadSetting();
        this.getAvailableTimezones();

        Settings.on('change', this.loadSetting);
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        Settings.removeListener('change', this.loadSetting);
    }

    /**
     * @method loadSetting
     */
    loadSetting = async () => {
        if (Settings.data && Settings.data[this.setting]) {
            this.setState({
                value: Settings.data[this.setting],
                working: false,
            });
        }
    }

    /**
     * @method getAvailableTimezones
     */
    getAvailableTimezones = async () => {
        const response = await SettingsApi.getAvailableTimezones();

        this.setState({
            timezones: response.data.timezones.map((value, key) => {
                return {
                    label: value,
                    value: value
                };
            }),
        });
    }

    /**
     * @method updateSetting
     * @param {string} value
     */
    updateSetting = async (value) => {
        let alert = null;

        if (value !== this.state.value) {
            this.setState({
                working: true,
                alert,
            });

            const response = await SettingsApi.patch(this.setting, {value});

            if (!response.success) {
                alert = {
                    message: response.message,
                    errors: response.errors,
                    type: 'error'
                };
            } else {
                alert = {
                    type: 'success',
                    message: response.data.message
                };
            }

            await Settings.refresh();
        }

        this.setState({
            value: (alert ? this.state.value : value), // Revert to previous  value if error.
            working: false,
            alert,
            editMode: (this.props.editMode ?? (alert.type === 'error' ? true : false))
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, alert, editMode, value, timezones} = this.state;
        const {containerClassName} = this.props;

        return (
            <div className="cursor-pointer flex flex-col justify-center">
                {working && <Loading size="1x" padding="py-2" />}

                {alert && 
                    <div className={containerClassName}>
                        <Alert {...alert} />
                    </div>
                }

                {!working && !editMode && (!alert || alert.types === 'error') &&
                    <span title={translation('misc', 'click_to_update')} onClick={() => this.setState({editMode: true})}>
                        {value}
                    </span>
                }

                {!working && editMode && (!alert || alert.types === 'error') &&
                    <Select
                        containerClassName={containerClassName + ' text-gray-700'}
                        id="timezone"
                        value={value}
                        onChange={(v) => this.updateSetting(v)}
                        options={timezones}
                    />
                }
            </div>
        );
    }
}
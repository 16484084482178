import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';

import AdminLayout from 'Pages/Admin/AdminLayout';

import SiteConfigApi from 'Services/Api/Admin/SiteConfig';

export default class SietConfigIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Key',
            value: 'key',
            type: 'string',
            orderable: 'key'
        },
        {
            label: 'Value',
            value: 'value',
            type: 'string',
            orderable: 'value'
        }
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        return [
            {
                type: "link",
                to: "/admin/site-config/store/"+item.id,
                text: "Update"
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'site-config',
                    itemId: item.id,
                    onDeleteApi: SiteConfigApi.delete
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Site Config">
                <IndexTable
                    columns={this.columns}
                    displaySearchBar={false}
                    storeLink="/admin/site-config/store"
                    loadDataCallback={(data) => SiteConfigApi.get(null, data)}
                    rowActions={this.getRowActions}
                    rowClickRedirect={(item) => "/admin/site-config/store/"+item.id}
                    eventApi={SiteConfigApi}
                />
            </AdminLayout>
        )
    }
}

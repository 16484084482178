import React from 'react';

import {IndexTable} from 'Components/Utilities';

import FamilyApi from 'Services/Api/ServiceUsers/Family';
import { User } from "Services";

export default class FamilyTab extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'First Name',
            value: 'first_name',
            type: 'string',
            orderable: 'first_name'
        },
        {
            label: 'Last Name',
            value: 'last_name',
            type: 'string',
            orderable: 'last_name'
        },
        {
            label: 'Relationship',
            value: 'relationship_to_connected_service_user',
            type: 'string',
            orderable: 'relationship_to_connected_service_user'
        },
        {
            label: 'Notes',
            value: 'notes',
            type: 'string',
            orderable: null
        }
    ];

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {service_user_id} = this.props;

        return (
            <IndexTable
                columns={this.columns}
                defaultOrder={{
                    order_by: 'first_name',
                    order: 'asc'
                }}
                displaySearchBar={true}
                loadDataCallback={(data) => !User.isAdmin && User?.belongsToGroup('Service User Family') ?
                    FamilyApi.getFamily(null, {
                        ...data,
                        connected_service_user_id: service_user_id
                    })
                    : FamilyApi.getFamily(null, {
                    ...data,
                    connected_service_user_id: service_user_id
                })}
                eventApi={FamilyApi}
            />
        );
    }
}

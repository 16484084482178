import React, { useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

import { RenderWYSIWYG } from "Components/Form";

import { translationFromJson } from 'Services/TranslationHelpers2';
import PushNotificationApi from "Services/Api/PushNotification/PushNotification";
import PushNotificationLogApi from "Services/Api/PushNotification/Log";

const Alerts = () => {
    const [alerts, setAlerts] = useState(null);

    const loadData = async () => {
        const { data, success } = await PushNotificationApi.get(null);

        if (success) {
            setAlerts(data.data.sort((a, b) => a.high_priority ? -1 : 1));

            data.data.forEach((alert) => PushNotificationLogApi.post({
                push_notification: alert.id,
            }, {
                type: 'viewed',
            }));
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    if (!alerts || alerts.length === 0) {
        return null;
    }

    const handleDismiss = async (alert) => {
        const { success } = await PushNotificationLogApi.post({
            push_notification: alert.id,
        }, {
            type: 'dismissed',
        });

        if (success) {
            loadData();
        }
    };

    return (
        <div className="bg-white mb-4">
            <div className="bg-red-400 text-white px-4 py-2">
                Alerts!
            </div>

            <div className="divide-y divide-gray-200">
                {alerts?.map(alert => (
                    <div className="px-4 py-2 flex items-center" key={alert.id}>
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className={`${alert.high_priority ? 'text-red-400' : 'text-yellow-400'} mr-2`}
                        />

                        <div className="flex-1">
                            <h2 className="font-bold text-lg">{translationFromJson(alert.title)}</h2>
                            <RenderWYSIWYG content={translationFromJson(alert.content)} />
                        </div>

                        {alert.high_priority && (
                            <div className="ml-2">
                                <button
                                    onClick={() => handleDismiss(alert)}
                                    title="Dismiss"
                                    className="block rounded-full bg-red-400 text-white transition duration-200 hover:scale-110 transform px-2 py-1 flex items-center justify-center"
                                >
                                    Read and understood
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Alerts;

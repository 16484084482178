import {faEye, faCalendarCheck, faCalendarTimes} from '@fortawesome/free-regular-svg-icons';
import {faPencilAlt, faTrash, faCopy, faCheck, faQuestion} from '@fortawesome/free-solid-svg-icons';

import CreateTimesheetModal from 'Components/HumanResources/Timesheets/CreateTimesheetModal';
import ShowTimesheetModal from 'Components/HumanResources/Timesheets/ShowTimesheetModal';
import RatesBreakdownModal from 'Components/HumanResources/Timesheets/RatesBreakdownModal';
import SubmitTimesheetModal from 'Components/HumanResources/Timesheets/SubmitTimesheetModal';
import QueryTimesheetModal from 'Components/HumanResources/Timesheets/QueryTimesheetModal';
import ApproveHoliday from 'Components/HumanResources/Timesheets/ApproveHoliday';
import Delete from 'Components/Partials/Modals/Delete';
import {MultipleChoiceFilter, BooleanFilter, DateTimeFilter} from 'Components/Filters';
import {ModalTrigger} from 'Components/Modal';

import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';
import {default as ModalService} from 'Services/Modal';
import User from "Services/User";
import { convertDecimalToTime } from "Services/BaseHelpers";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons/faCircleXmark";

/**
 * @method getColumns
 * @return {object}
 */
const getColumns = (admin, type = null, hideDateFilters = false) => {
    let columns = [];

    // "Date".
    columns.push({
        label: 'Date',
        value: (record) => (
            <>
                {record.type === 'holiday' && (
                    <>
                        {(record.start_date_time?.date_timezone === record.end_date_time?.date_timezone || !record.end_date_time?.date_timezone) && (
                            <>{record.start_date_time.date_timezone}</>
                        )}

                        {/*Hacky fix until rebuild is an option...*/}
                        {record.end_date_time?.date_timezone && record.start_date_time?.date_timezone !== record.end_date_time?.date_timezone && (
                            <>{record.start_date_time?.date_utc} - {record.end_date_time?.date_utc}</>
                        )}
                    </>
                )}
                {record.type !== 'holiday' && (
                    <>
                        {
                            (record.all_day || record.start_date_time.date_timezone === record.end_date_time.date_timezone) ?
                                record.start_date_time.date_timezone :
                                record.start_date_time.date_timezone + ' - ' + record.end_date_time.date_timezone
                        }
                    </>
                )}
            </>
        ),
        type: 'callback',
        orderable: 'start_date_time',
        filters: hideDateFilters ? null : [
            {
                column: 'end_date_time',
                label: 'After',
                component: DateTimeFilter,
                props: {
                    type: 'date',
                    direction: 'after'
                }
            },
            {
                column: 'start_date_time',
                label: 'Before',
                component: DateTimeFilter,
                props: {
                    type: 'date',
                    direction: 'before'
                }
            }
        ]
    });

    // "Time".
    columns.push({
        label: 'Time',
        value: (record) => (
            <>
                {record.type === 'holiday' && (
                    <>
                        {record.days_cost} Days ({convertDecimalToTime(record.hours_cost)})
                    </>
                )}

                {record.type !== 'holiday' && (
                    <>
                        {record.all_day ?
                            null :
                            record.start_date_time.time_timezone + ' - ' + record.end_date_time.time_timezone}
                    </>
                )}
            </>
        ),
        type: 'callback',
        orderable: null
    });

    // Display "Employee" if amdin.
    if (admin) {
        columns.push({
            label: 'Employee',
            value: (record) => record.employee?.full_name ?? 'N/A',
            type: 'callback',
            orderable: 'employee.first_name',
        });
    }

    // If no type preset then display "Type".
    if (!type) {
        columns.push({
            label: 'Type',
            value: (record) => window.base.hr_timesheet_types[record.type]?.label,
            type: 'callback',
            orderable: null,
            filters: [
                {
                    column: 'type',
                    label: 'Type',
                    component: MultipleChoiceFilter,
                    props: {
                        options: Object.entries(window.base.hr_timesheet_types)
                            .filter(value => !value[1]?.must_be_admin || User.isAdmin)
                            .map((value, key) => ({
                                label: value[1]?.label,
                                value: value[0],
                            }))
                    }
                }
            ]
        });
    }

    // If no type preset and not holiday or sickness then display "Hours", "Title" and "time_worked Type".
    if (!type || (type !== 'holiday' && type !== 'sick')) {
        columns.push(
            {
                label: 'Hours',
                value: (record) => !record.all_day && record.hours && record.type !== 'sick' ?
                    record.hours.hours + 'h ' + record.hours.mins + 'm' :
                    'N/A',
                type: 'callback',
                orderable: null,
            },
            {
                label: 'Title',
                value: 'title',
                type: 'string',
                orderable: null,
                hideByDefault: true
            },
            {
                label: window.base.hr_timesheet_types['time_worked']?.label + ' Type',
                value: 'time_worked_type',
                type: 'string',
                orderable: null,
                hideByDefault: true
            }
        );
    }

    if (type !== 'holiday') {
        columns.push(
            {
                label: 'Service User',
                value: (record) => record?.service_user?.full_name,
                type: 'callback',
                orderable: null,
            },
            {
                label: 'Sleeps In',
                value: 'sleeps_in',
                type: 'boolean',
                orderable: null,
            },
            {
                label: 'Mileage',
                value: 'mileage',
                type: 'string',
                orderable: null,
            },
        );
    }

    if (type === 'holiday') {
        // "Date".
        columns.push(
            {
                label: 'Date Requested',
                value: (record) => (
                    <>
                        {(record.created_at?.date_timezone) && (
                            <>{record.created_at.date_timezone}</>
                        )}
                    </>
                ),
                type: 'callback',
                orderable: 'created_at',
                filters: hideDateFilters ? null : [
                    {
                        column: 'created_at',
                        label: 'Before',
                        component: DateTimeFilter,
                        props: {
                            type: 'date',
                            direction: 'before'
                        }
                    }
                ]
            }
        );
    }

    // "Status" and "Description".
    columns.push(
        {
            label: 'Status',
            value: (record) => (
                <>
                    {record.type === 'holiday' && (
                        <>
                            {record.is_rejected && (
                                <div className="px-4 py-2" style={{ backgroundColor: 'rgb(255, 0, 0)' }}>
                                    {`Rejected by ${record.rejected_by.full_name}`}
                                </div>
                            )}

                            {!record.is_rejected && record.approved_by && (
                                <div className="px-4 py-2" style={{ backgroundColor: 'rgb(34, 140, 34)' }}>
                                    {`Approved by ${record.approved_by.full_name}`}
                                </div>
                            )}

                            {!record.is_rejected && !record.approved_by && (
                                <div className="px-4 py-2" style={{ backgroundColor: 'rgb(255, 0, 0)' }}>
                                    Awaiting approval
                                </div>
                            )}
                        </>
                    )}

                    {record.type !== 'holiday' && (
                        <div className="px-4 py-2" style={{ backgroundColor: record.status.colour }}>
                            {record.status.string}

                            {record.submitted && record.needs_approval &&
                                <>
                                    <br/>
                                    ({record.approved_by ? `Approved by ${record.approved_by.full_name}` : 'Awaiting approval'})
                                </>
                            }
                        </div>
                    )}
                </>
            ),
            type: 'callback',
            orderable: null,
            filters: [
                {
                    column: 'submitted',
                    label: 'Submitted',
                    component: BooleanFilter,
                }
            ],
            valueClassName: "border border-app-leading",
            valueClassNameOverwrite: true
        },
        {
            label: 'Notes',
            value: 'description',
            type: 'string',
            orderable: null,
            hideByDefault: true
        }
    );

    // Total Rate
    if (admin && window.base.features.rates) {
        columns.push({
            label: 'Total Rate',
            value: (record) => (
                <ModalTrigger
                    component={RatesBreakdownModal}
                    props={{
                        timesheetIds: [record.id],
                    }}
                >
                    £{record.total_rate ? Number.parseFloat(record.total_rate).toFixed(2) : 0.00}
                </ModalTrigger>
            ),
            type: 'callback',
            orderable: null,
        });
    }

    return columns;
}

/**
 * @method getRecordActions
 * @param {object} record
 * @param {boolean} admin
 * @param {boolean} show
 */
const getRecordActions = (record, admin, show = true) => {
    let buttons = [];

    if (show) {
        // Show
        buttons.push({
            type: "onClick",
            onClick: () => {
                ModalService.open({
                    component: ShowTimesheetModal,
                    props: {
                        timesheet_id: record.id,
                        admin
                    }
                });
            },
            icon: faEye
        });
    }

    if (admin) {
        buttons.push(
            // Update
            {
                type: "onClick",
                onClick: () => {
                    ModalService.open({
                        component: CreateTimesheetModal,
                        props: {
                            record,
                            admin
                        }
                    });
                },
                icon: faPencilAlt
            },
            // Delete
            {
                type: "onClick",
                onClick: () => {
                    ModalService.open({
                        component: Delete,
                        props: {
                            itemName: 'record',
                            itemId: record.id,
                            onDeleteApi: admin ? AdminTimesheetsApi.delete : TimesheetsApi.delete
                        }
                    });
                },
                icon: faTrash
            },
        );

        // Approve/Unapprove
        if (record.needs_approval) {
            if (record.type === 'holiday') {
                buttons.push(
                    {
                        type: "onClick",
                        onClick: () => {
                            ModalService.open({
                                component: ApproveHoliday,
                                props: {
                                    timesheet: record,
                                },
                            });
                        },
                        icon: record.approved_by ? faCalendarTimes : faCalendarCheck,
                        title: record.approved_by ? 'Unapprove' : 'Approve'
                    }
                );
            } else {
                buttons.push(
                    {
                        type: "onClick",
                        onClick: () => handleApprove(record, record.approved_by ? false : true),
                        icon: record.approved_by ? faCalendarTimes : faCalendarCheck,
                        title: record.approved_by ? 'Unapprove' : 'Approve'
                    }
                );
            }
        }

        // Reject hide if rejected
        if (!record.is_rejected) {
            buttons.push(
                {
                    type: "onClick",
                    onClick: () => handleReject(record, true),
                    icon: faCircleXmark,
                    title: 'Reject'
                }
            );
        }

        buttons.push(
            // Copy
            {
                type: "onClick",
                onClick: () => {
                    ModalService.open({
                        component: CreateTimesheetModal,
                        props: {
                            record,
                            admin,
                            copy: true
                        }
                    });
                },
                icon: faCopy
            },
            // Query
            {
                type: "onClick",
                onClick: () => {
                    ModalService.open({
                        component: QueryTimesheetModal,
                        props: {
                            timesheet: record,
                            admin
                        }
                    });
                },
                icon: faQuestion
            }
        );
    } else {
        if (!record.submitted) {
            // Update
            buttons.push(
                {
                    type: "onClick",
                    onClick: () => {
                        ModalService.open({
                            component: CreateTimesheetModal,
                            props: {
                                record,
                                admin
                            }
                        });
                    },
                    icon: faPencilAlt
                }
            );

            buttons.push(
                {
                    type: "onClick",
                    onClick: () => {
                        ModalService.open({
                            component: SubmitTimesheetModal,
                            props: {
                                timesheet: record,
                                admin
                            }
                        });
                    },
                    icon: faCheck
                }
            );
        }

        buttons.push(
            {
                type: "onClick",
                onClick: () => {
                    ModalService.open({
                        component: QueryTimesheetModal,
                        props: {
                            timesheet: record,
                            admin
                        }
                    });
                },
                icon: faQuestion
            }
        );
    }

    return buttons;
}

/**
 * @method handleApprove
 * @param {object} record
 * @param {boolean} approved
 */
const handleApprove = async (record, approved) => {
    await AdminTimesheetsApi.patch({
            record: record.id
        }, {
        ...record,
        approved
    });
}

/**
 * @method handleApprove
 * @param {object} record
 * @param {boolean} rejected
 */
const handleReject = async (record, rejected) => {
    await AdminTimesheetsApi.patch({
        record: record.id
    }, {
        ...record,
        rejected
    });
}

export {
    getColumns,
    getRecordActions,
    handleApprove
}

import React from 'react';
import {DateTime} from 'luxon';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {FormHandler, Input, Select, Toggle, DatePicker, Label} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import AdminRatesApi from 'Services/Api/Admin/HumanResources/Rates';
import {dateToString} from 'Services/DateHelpers';

import AdminLayout from 'Pages/Admin/AdminLayout';
import User from "Services/User";

class RatesStore extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Rate ' + (this.props.match?.params?.rate ? 'updated' : 'created') + ' successfully';

    /**
     * @var days
     * @type {array}
     */
    days = [
        {
            label: 'Monday',
            value: 'monday'
        },
        {
            label: 'Tuesday',
            value: 'tuesday'
        },
        {
            label: 'Wednesday',
            value: 'wednesday'
        },
        {
            label: 'Thursday',
            value: 'thursday'
        },
        {
            label: 'Friday',
            value: 'friday'
        },
        {
            label: 'Saturday',
            value: 'saturday'
        },
        {
            label: 'Sunday',
            value: 'sunday'
        }
    ];

    /**
     * @var breakdown_specific_dates_types
     * @type {array}
     */
    breakdown_specific_dates_types = [
        {
            label: 'N/A',
            value: 'N/A'
        },
        {
            label: 'Ignore specific dates',
            value: 'ignore_dates'
        },
        {
            label: 'Apply only to specific dates ',
            value: 'specific_dates'
        }
    ];

    /**
     * @var state
     * @type {{rate_id: string}}
     */
    state = {
        loading: true,
        rate_id: this.props.match?.params?.rate ?? '',
        breakdown_specific_dates_type: 'N/A',
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        if (this.state.rate_id) {
            await this.loadRate();
        } else {
            await this.setEmptyRate();
        }

        this.setState({loading: false});
    }

    /**
     * @method loadRate
     */
    loadRate = async () => {
        const {rate_id} = this.state;

        const response = await AdminRatesApi.get(rate_id);

        let rate = response.data.data;

        // Handle date arrays.
        if (rate.breakdown.ignore_dates) {
            // Set the type.
            this.setState({
                breakdown_specific_dates_type: 'ignore_dates'
            });

            // Convert the dates from strings to JS dates.
            rate.breakdown.ignore_dates = this.convertDatesArray2(rate.breakdown.ignore_dates);
        } else if (rate.breakdown.specific_dates) {
            // Set the type.
            this.setState({
                breakdown_specific_dates_type: 'specific_dates'
            });

            // Convert the dates from strings to JS dates.
            rate.breakdown.specific_dates = this.convertDatesArray2(rate.breakdown.specific_dates);
        }

        // Convert the range times from strings to JS dates.
        rate.breakdown.ranges = rate.breakdown.ranges.map((object, key) => {
            return {
                ...object,
                start_time: object.start_time ? this.convertTime2(object.start_time) : null,
                end_time: object.end_time ? this.convertTime2(object.end_time) : null
            };
        });

        // Convert the sleeps_in times from strings to JS dates.
        if (rate.breakdown.sleeps_in_start_time) {
            rate.breakdown.sleeps_in_start_time = this.convertTime2(rate.breakdown.sleeps_in_start_time);
        }
        if (rate.breakdown.sleeps_in_end_time) {
            rate.breakdown.sleeps_in_end_time = this.convertTime2(rate.breakdown.sleeps_in_end_time);
        }

        this.props.setForm({
            name: rate.name,
            breakdown: rate.breakdown
        });
    }

    /**
     * @method setEmptyRate
     */
    setEmptyRate = () => {
        let form = {
            name: '',
            breakdown: {
                types: [],
                ignore_dates: null,
                specific_dates: null,
                apply_to_bank_holidays: false,
                sleeps_in: false
            }
        };

        this.props.setInitialValues(form);
    }

    /**
     * @method handleBreakdownValue
     * @param {string} key
     * @param {boolean} value
     */
    handleBreakdownValue = (key, value) => {
        let {breakdown} = this.props.form;

        breakdown[key] = value;

        this.props.setForm({
            ...this.props.form,
            breakdown
        });
    }

    /**
     * @method addRange
     */
    addRange = () => {
        let {breakdown} = this.props.form;

        if (!breakdown.ranges) {
            breakdown.ranges = [];
        }

        breakdown.ranges.push({
            start_day: null,
            start_time: null,
            end_day: null,
            end_time: null
        });

        this.props.setForm({
            ...this.props.form,
            breakdown
        });
    }

    /**
     * @method handleRangeValue
     * @param {string} key
     * @param {mixed} value
     * @param {integer} index
     */
    handleRangeValue = (key, value, index) => {
        let {breakdown} = this.props.form;

        breakdown.ranges[index][key] = value;

        this.props.setForm({
            ...this.props.form,
            breakdown
        });
    }

    /**
     * @method removeRange
     * @param {integer} index
     */
    removeRange = (index) => {
        let {breakdown} = this.props.form;

        breakdown.ranges.splice(index, 1);

        this.props.setForm({
            ...this.props.form,
            breakdown
        });
    }

    /**
     * @method handleSpecificDatesType
     * @param {string} value
     */
    handleSpecificDatesType = (value) => {
        const {breakdown_specific_dates_type} = this.state;

        // If value changed then reset ignore_dates and specific_dates to null.
        if (breakdown_specific_dates_type !== value) {
            let {breakdown} = this.props.form;

            breakdown.ignore_dates = null;
            breakdown.specific_dates = null;

            this.props.setForm({
                ...this.props.form,
                breakdown
            });

            this.setState({
                breakdown_specific_dates_type: value
            });
        }
    }

    /**
     * @method handleNewMultiDatePickerValue
     * @param {string} value
     */
    handleNewMultiDatePickerValue = (value) => {
        const {breakdown_specific_dates_type} = this.state;
        const {breakdown} = this.props.form;

        let string = this.getDayAndMonth(value);

        // If not already in array then add it.
        if (!breakdown[breakdown_specific_dates_type]
            || breakdown[breakdown_specific_dates_type]
                .filter(o => this.getDayAndMonth(o) === string).length === 0) {

            if (!breakdown[breakdown_specific_dates_type]) {
                breakdown[breakdown_specific_dates_type] = [value];
            } else {
                breakdown[breakdown_specific_dates_type].push(value);
            }

            this.props.setForm({
                ...this.props.form,
                breakdown
            });
        }
    }

    /**
     * @method handleRemoveMultiDatePickerValue
     * @param {integer} index
     */
    handleRemoveMultiDatePickerValue = (index) => {
        const {breakdown_specific_dates_type} = this.state;
        const {breakdown} = this.props.form;

        breakdown[breakdown_specific_dates_type].splice(index, 1);

        this.props.setForm({
            ...this.props.form,
            breakdown
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {rate_id} = this.state;

        form = {
            ...form,
            breakdown: this.handleBreakdownForSubmission(form)
        };

        if (rate_id) {
            return AdminRatesApi.patch(rate_id, form);
        } else {
            return AdminRatesApi.post(null, form);
        }
    };

    /**
     * @method handleBreakdownForSubmission
     *
     * Convert dates and times in to the correct format for submission.
     *
     * @param {object} form
     * @return {object}
     */
    handleBreakdownForSubmission = (form) => {
        let breakdown = {
            ...form.breakdown
        };

        // Handle date arrays. Convert the dates from JS dates to strings.
        if (breakdown.ignore_dates && breakdown.ignore_dates.length !== 0) {
            breakdown.ignore_dates = this.convertDatesArray(form.breakdown.ignore_dates);
        }

        if (breakdown.specific_dates && breakdown.specific_dates.length !== 0) {
            breakdown.specific_dates = this.convertDatesArray(form.breakdown.specific_dates);
        }

        // Convert the range times from JS dates to strings.
        if (breakdown.ranges) {
            breakdown.ranges = breakdown.ranges.map((object, key) => {
                return {
                    ...object,
                    start_time: object.start_time ? this.convertTime(object.start_time) : null,
                    end_time: object.end_time ? this.convertTime(object.end_time) : null
                };
            });
        }

        // Convert the sleeps_in times from JS dates to strings.
        if (breakdown.sleeps_in_start_time) {
            breakdown.sleeps_in_start_time = this.convertTime(breakdown.sleeps_in_start_time);
        }
        if (breakdown.sleeps_in_end_time) {
            breakdown.sleeps_in_end_time = this.convertTime(breakdown.sleeps_in_end_time);
        }

        return breakdown;
    }

    /**
     * @method convertDatesArray
     *
     * E.g. Javascript Date to "2021-08-18".
     *
     * @param {[Date]} value
     * @return {[string]}
     */
    convertDatesArray(value) {
        return value.map((date) => (
            date.toISOString().split('T')[0]
        ));
    }

    /**
     * @method convertDatesArray2
     *
     * E.g. "2021-08-18" to Javascript Date.
     *
     * @param {[string]} value
     * @return {[Date]}
     */
    convertDatesArray2(value) {
        return value.map((date) =>
            new Date(date)
        );
    }

    /**
     * @method convertTime
     *
     * E.g. Javascript Date to "11:50".
     *
     * @param {Date} value
     * @return {string}
     */
    convertTime(value) {
        let timeString = value.toTimeString();

        let spliter = timeString.split(':');

        return spliter[0] + ':' + spliter[1];
    }

    /**
     * @method convertTime2
     *
     * E.g. "11:50" to Javascript Date.
     *
     * @param {string} value
     * @return {Date}
     */
    convertTime2(value) {
        let spliter = value.split(':');

        return new Date(2000, 1, 1, spliter[0], spliter[1]);
    }

    /**
     * @method getDayAndMonth
     * @param {object} date
     * @param {string}
     */
    getDayAndMonth(date) {
        return DateTime.fromJSDate(date).toLocaleString({ month: 'long', day: 'numeric' });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {rate_id, loading} = this.state;
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        let types = Object.entries(window.base.hr_timesheet_types)
            .filter(value => !value[1]?.must_be_admin || User.isAdmin)
            .map((value, key) => ({
                label: value[1]?.label,
                value: value[0],
            }));

        return (
            <AdminLayout title={'User Rates - ' + (rate_id ? 'Update' : 'Create') + ' Rate'}>
                {loading && (<Loading />)}

                {!loading &&
                    <form
                        className="divide-y divide-gray-200"
                        onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (rate_id ? false : true))}
                    >
                        <div className="mx-6">
                            {alert !== null && (<Alert {...alert} />)}

                            <div className="my-4 p-4 bg-gray-300 rounded">
                                <Input
                                    label="Name"
                                    value={form.name}
                                    id="name"
                                    error={getFieldError('name')}
                                    onChange={(v) => handleInput('name', v)}
                                />
                            </div>

                            <div className="my-4 p-4 bg-gray-300 rounded">
                                <Select
                                    className="mb-4"
                                    label="Type"
                                    value={form.breakdown.types}
                                    onChange={v => this.handleBreakdownValue('types', v)}
                                    options={types}
                                    isMulti
                                />
                            </div>

                            {this.renderRanges()}
                            {this.renderSpecificDates()}

                            <div className="my-4 p-4 bg-gray-300 rounded">
                                <Toggle
                                    label="Apply To Bank Holidays"
                                    value={form.breakdown.apply_to_bank_holidays}
                                    error={getFieldError('apply_to_bank_holidays')}
                                    onChange={v => this.handleBreakdownValue('apply_to_bank_holidays', v)}
                                    instructions="If un-ticked then this rate will not apply to Bank Holidays.
                                        If ticked then this rate will only apply to Bank Holidays"
                                />
                            </div>

                            {this.renderSleepsIn()}
                        </div>

                        <div className="p-6 flex justify-end gap-4">
                            <Link to="/admin/human-resources/rates">
                                <SecondaryButton type="button" text="Back" />
                            </Link>

                            <PrimaryButton
                                text="Save"
                                working={working}
                            />
                        </div>
                    </form>
                }
            </AdminLayout>
        )
    }

    /**
     * @method renderRanges
     * @return {JSX.Element}
     */
    renderRanges() {
        const {breakdown} = this.props.form;

        return (
            <div className="my-4 p-4 bg-gray-300 rounded">
                <Label
                    label="Ranges"
                />

                <div>
                    <PrimaryButton
                        text="Add Range"
                        onClick={this.addRange}
                    />
                </div>

                {breakdown.ranges && breakdown.ranges.map((object, i) => (
                    <div className="my-4 grid grid-cols-5 gap-4" key={i}>
                        <Select
                            label="Start Day"
                            value={object.start_day}
                            onChange={v => this.handleRangeValue('start_day', v, i)}
                            options={this.days}
                        />

                        <DatePicker
                            label="Start Time"
                            selected={object.start_time}
                            onChange={v => this.handleRangeValue('start_time', v, i)}
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={5}
                            hideTimezone={true}
                        />

                        <Select
                            label="End Day"
                            value={object.end_day}
                            onChange={v => this.handleRangeValue('end_day', v, i)}
                            options={this.days}
                        />

                        <DatePicker
                            label="End Time"
                            selected={object.end_time}
                            onChange={v => this.handleRangeValue('end_time', v, i)}
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={5}
                            hideTimezone={true}
                        />

                        <div className="flex justify-start items-end">
                            <SecondaryButton
                                text="Remove"
                                onClick={() => this.removeRange(i)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    renderSpecificDates() {
        const {breakdown_specific_dates_type} = this.state;

        return (
            <div className="my-4 p-4 bg-gray-300 rounded">
                <Select
                    containerClassName="mb-4"
                    label="Specific Dates"
                    value={breakdown_specific_dates_type}
                    onChange={v => this.handleSpecificDatesType(v)}
                    options={this.breakdown_specific_dates_types}
                />

                {breakdown_specific_dates_type !== 'N/A' &&
                    this.renderMultiDatePicker('ignore_dates')
                }
            </div>
        );
    }

    /**
     * @method renderMultiDatePicker
     * @return {JSX.Element}
     */
    renderMultiDatePicker() {
        const {breakdown_specific_dates_type} = this.state;
        const {breakdown} = this.props.form;

        return (
            <div>
                {breakdown_specific_dates_type === 'specific_dates' &&
                    <p className="mb-4 text-center">
                        If there is an overlap between rates we will take the first rate,
                        therefore you must ensure there is no overlap.
                        <br/>
                        Make sure that any dates you enter here are set to be “ignored” on any other applicable rates.
                    </p>
                }

                <DatePicker
                    containerClassName="mb-4"
                    selected={false}
                    onChange={v => this.handleNewMultiDatePickerValue(v)}
                    showTimeSelect={false}
                    placeholderText="Click here to add a new date..."
                    dateFormatCalendar="LLLL" // Hide the year in the title
                />
                <div className="flex flex-row">
                    {breakdown[breakdown_specific_dates_type] && breakdown[breakdown_specific_dates_type]
                        .sort((a, b) => a > b)
                        .map((date, i) => (
                        <div className="m-2 p-4 bg-white rounded flex justify-between items-center gap-4 w-max" key={i}>
                            {this.getDayAndMonth(date)}

                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="1x"
                                className="text-red-500"
                                onClick={() => this.handleRemoveMultiDatePickerValue(i)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    /**
     * @method renderSleepsIn
     * @return {JSX.Element}
     */
    renderSleepsIn() {
        const {breakdown} = this.props.form;

        return (
            <div className="my-4 p-4 bg-gray-300 rounded">
                <Toggle
                    label="Sleeps In"
                    value={breakdown.sleeps_in}
                    onChange={v => this.handleBreakdownValue('sleeps_in', v)}
                    instructions='If "sleeps in" is ticked on a timesheet then a flat rate will be provided for the times defined here.'
                />

                {breakdown.sleeps_in &&
                    <div className="grid grid-cols-3 gap-4 mt-4">
                        <DatePicker
                            label="Start"
                            selected={breakdown.sleeps_in_start_time}
                            onChange={v => this.handleBreakdownValue('sleeps_in_start_time', v)}
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={5}
                            hideTimezone={true}
                        />

                        <DatePicker
                            label="End"
                            selected={breakdown.sleeps_in_end_time}
                            onChange={v => this.handleBreakdownValue('sleeps_in_end_time', v)}
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={5}
                            hideTimezone={true}
                        />

                        <div>
                            <Label
                                label="Flat Rate"
                            />

                            <div className="flex flex-row items-center">
                                <span className="mr-2">
                                    £
                                </span>

                                <Input
                                    value={breakdown.sleeps_in_value}
                                    onChange={v => this.handleBreakdownValue('sleeps_in_value', v)}
                                    placeholder="Enter flat rate here"
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default FormHandler(RatesStore);

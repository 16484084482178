import React from 'react';

import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {DateTime} from 'luxon';

import {enGB} from 'date-fns/esm/locale';
import {registerLocale} from  "react-datepicker";
registerLocale('en-gb', enGB)

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Label from 'Components/Form/Label';

import Settings from 'Services/Settings';
import {isAdminPage} from 'Services/BaseHelpers';

/*
Examples

Date Only ...
<DatePicker
    label="My Date"
    selected={form.my_date}
    onChange={date => handleInput('my_date', date)}
/>

Date & Time ...
<DatePicker
    label="My Date & Time"
    selected={form.my_date_time}
    onChange={date => handleInput('my_date_time', date)}
    showTimeSelect={true}
    timeIntervals={5}
/>

Time Only ...
<DatePicker
    label="My Time"
    selected={form.my_time}
    onChange={date => handleInput('my_time', date)}
    showTimeSelect={true}
    showTimeSelectOnly={true}
    timeIntervals={5}
/>
*/

/**
 * @param {string} containerClassName
 * @param {string} label
 * @param {string} id
 * @param {string} className
 * @param {object} selected
 * @param {object} onChange
 * @param {boolean} nowButton
 * @param {boolean} showTimeSelect
 * @param {boolean} showTimeSelectOnly
 * @param {string} instructions
 * @param {null|array} error
 * @param {boolean} hideTimezone
 * @param {null|string} placeholder
* @param {null|boolean} dayInteralButtons
 * @param {null|boolean} backDayInteralButton
 * @param {null|boolean} forwardDayInteralButton
 * @param {*[]} rest
 * @return {JSX.Element}
 */
export default class DatePicker extends React.Component {
    /**
     * @method getLabel
     * @return {string|null}
     */
    getLabel = () => {
        let {
            label,
            showTimeSelect = false,
            hideTimezone = false
        } = this.props;

        let timezone = Settings.data.timezone;

        if (showTimeSelect && !hideTimezone) {
            if (label) {
                label += " (" + timezone + ")";
            }
        }

        return label;
    }

    /**
     * @method getClasses
     * @return {string}
     */
    getClasses = () => {
        const {
            error = null,
            className,
            dayInteralButtons = false,
            nowButton = false
        } = this.props;

        let classes = `
            block w-full border py-2 px-3 focus:outline-none
            sm:text-sm bg-app-backup bg-opacity-10
            ${error ? 'border-red-800' : 'border-app-backup border-opacity-10'}
            ${className}
            ${dayInteralButtons ? 'text-center' : (nowButton ? 'rounded-l-full' : 'rounded-full')}
        `;

        if (!isAdminPage()) {
            // overwrite classes as required for non-admin pages.
        }

        return classes;
    }

    /**
     * @method getClasses
     * @return {Date|null}
     */
    getSelected = () => {
        let {
            selected,
            showTimeSelect = false
        } = this.props;

        let timezone = Settings.data.timezone;

        if (selected) {
            if (selected?.iso_string) {
                selected = new Date(selected.iso_string);
            }

            if (showTimeSelect) {
                // Convert to user's selected timezone to display the correct time.
                selected = utcToZonedTime(selected, timezone);
            } else {
                // Offset the device timezone to maintain the correct date.
                selected = new Date(selected.getTime() + (selected.getTimezoneOffset()*60*1000));
            }
        }

        return selected;
    }

    /**
     * @method getClasses
     * @param {Date|null} selected
     * @return {Date|null}
     */
    getValue = (selected) => {
        const {
            showTimeSelect = false,
            showTimeSelectOnly = false
        } = this.props;

        // If displaying a time picker then we use the default datepicker functionality
        // as displaying "Today/Yesterday/Tomorrow" prevents you from being able to type
        // in to the input to set a specific time.
        if (showTimeSelect) {
            return null;
        }

        let value = null;

        let holder = DateTime.fromJSDate(selected);

        if (!showTimeSelectOnly) {
            if (holder.hasSame(DateTime.local(), 'day')) {
                value = 'Today';
            } else if (holder.hasSame(DateTime.local().minus({ days: 1 }), 'day')) {
                value = 'Yesterday';
            } else if (holder.hasSame(DateTime.local().plus({ days: 1 }), 'day')) {
                value = 'Tomorrow';
            }
        }

        if (value && showTimeSelect) {
            value += holder.toFormat(' HH:mm');
        }

        return value;
    }

    /**
     * @method handleChange
     * @param {Date} v
     */
    handleChange = (v) => {
        const {
            onChange,
            showTimeSelect = false,
        } = this.props;

        let timezone = Settings.data.timezone;

        if (!v) {
           return onChange(null);
        }

        if (showTimeSelect) {
            // Convert from user's selected timezone to save the correct time.
            onChange(zonedTimeToUtc(v, timezone));
        } else {
            // Offset the device timezone to maintain the correct date.
            onChange(new Date(
                new Date(v.getTime() - (v.getTimezoneOffset()*60*1000))
                    .setUTCHours(0, 0, 0, 0)
            ));
        }
    }

    /**
     * @method render
     * @return {*}
     */
    render () {
        const {
            containerClassName,
            id,
            onChange,
            nowButton = false,
            showTimeSelect = false,
            showTimeSelectOnly = false,
            instructions,
            error = null,
            placeholder = null,
            dayInteralButtons = false,
            backDayInteralButton = false,
            forwardDayInteralButton = false,
            ...rest
        } = this.props;

        let label = this.getLabel();
        let classes = this.getClasses();
        let selected = this.getSelected();
        let value = this.getValue(selected);

        return (
            <div className={`w-full ${containerClassName}`}>
                {label && (
                    <Label
                        label={label}
                        htmlFor={id}
                        instructions={instructions}
                        error={error}
                    />
                )}

                <div className={`flex shadow-sm rounded-full ${label ? 'mt-1' : ''}`}>
                    {dayInteralButtons && backDayInteralButton && this.renderDayInteralButton('left')}

                    <ReactDatePicker
                        {...rest}
                        placeholderText={placeholder}
                        showTimeSelect={showTimeSelect}
                        showTimeSelectOnly={showTimeSelectOnly}
                        dateFormat={showTimeSelect ? (showTimeSelectOnly ? 'HH:mm' : 'MMMM do yyyy, HH:mm') : 'MMMM do yyyy'}
                        className={classes}
                        selected={selected ?? null}
                        value={value}
                        onChange={(v, e) => this.handleChange(v)}
                        locale="en-gb" // Set first day of the week as Monday.
                    />

                    {dayInteralButtons && forwardDayInteralButton && this.renderDayInteralButton('right')}

                    {nowButton && this.renderNowButton()}
                </div>
            </div>
        );
    }

    /**
     * @method renderDayInteralButton
     * @return {string} direction
     * @return {*}
     */
    renderDayInteralButton(direction) {
        const {
            onChange,
            nowButton = false,
        } = this.props;

        let selected = this.getSelected();

        return (
            <button
                type="button"
                onClick={() => {
                    selected.setDate(direction === 'left' ? selected.getDate() - 1 : selected.getDate() + 1);
                    onChange(selected);
                }}
                className={`
                    relative inline-flex items-center space-x-2 px-4 py-2 border
                    border-gray-300 text-sm font-medium bg-gray-50
                    hover:bg-gray-100 transition duration-200 focus:ring-0
                    text-gray-700
                    ${nowButton ? '' : (direction === 'left' ? 'rounded-l-full' : 'rounded-r-full')}
                    ${direction === 'left' ? '-mr-px' : '-ml-px'}
                `}
            >
                <FontAwesomeIcon icon={direction === 'left' ? faChevronLeft : faChevronRight} />
            </button>
        );
    }

    /**
     * @method renderNowButton
     * @return {*}
     */
    renderNowButton() {
        const {
            onChange,
            showTimeSelect = false
        } = this.props;

        return (
            <button
                type="button"
                onClick={() => onChange(new Date())}
                className={`
                    -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border
                    border-gray-300 text-sm font-medium rounded-r-full bg-gray-50
                    hover:bg-gray-100 transition duration-200 focus:ring-0
                    text-gray-700
                `}
            >
                {showTimeSelect ? 'Now' : 'Today'}
            </button>
        );
    }
}

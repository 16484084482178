import React from 'react';

import Label from './Label';

import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import QuillHtmlSourceButton from 'quill-source-code-button';

Quill.register('modules/htmlSource', QuillHtmlSourceButton);

let colours = [
    '#000000',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#444444',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466'
];

const WYSIWYG = (props) => {
    const {containerClassName, label, placeholder, onChange, value, instructions, error = null} = props;

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block', 'link'],

            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // Removed as do not work [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': colours }, { 'background': colours }],          // dropdown with default colours from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ],
        htmlSource: {},
    };

    return (
        <div className={containerClassName}>
            {label && (<Label label={label} instructions={instructions} error={error} />)}

            <div className={`bg-white ${error ? 'border border-red-800' : null}`}>
                <ReactQuill 
                    theme="snow"
                    value={value ?? null} 
                    onChange={value => {
                        if (!value || value === "<p><br></p>" || value === "<p></p>") {
                            onChange(null);
                        } else {
                            onChange(value);
                        }
                    }}
                    modules={modules}
                />
            </div>
        </div>
    );
};

const RenderWYSIWYG = (props) => {
    const {content} = props;

    if (!content || content === "<p><br></p>" || content === "<p></p>") {
        return null;
    }

    return (
        <span 
            className="ql-editor render-ql-editor"
            dangerouslySetInnerHTML={{__html: content}}
        >
        </span>
    );
}

export {
    WYSIWYG,
    RenderWYSIWYG
}

/*
Draft JS

1. yarn add draft-js, draftjs-to-html, html-to-draftjs, react-draft-wysiwyg
2. Import the below at the top of the script.
3. On mount of the component set form.foobar = convertHtmlToDraftEditorState(value).
4. Add the editor to the render() method as per below.
5. Before api submission call convertDraftEditorStateToHtml() on the form.foobar value before passing it off to the api.

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

convertHtmlToDraftEditorState = (html) => {
    if (!html || html === '') {
        return EditorState.createEmpty();
    }

    // We do not use the offical convertFromHTML() DraftJS method 
    // as it does note work with some tags, e.g. superscript.
    const { contentBlocks, entityMap } = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    return EditorState.createWithContent(contentState);
}

convertDraftEditorStateToHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}

<div className="bg-white p-2 z-10">
    <Editor
        editorState={form.foobar}
        wrapperClassName="demo-wrapper z-10"
        editorClassName="demo-editor z-10"
        onEditorStateChange={editorState => handleInput('foobar', editorState)}
    />
</div>
*/
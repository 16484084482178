import React from "react";

const YesNo = ({ name, onChange, value, max = 4 }) => {
    return (
        <div className="flex space-x-6">
            { [...Array(max).keys()].map((index) => (
                <label key={index}>
                    <input
                        type="radio"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        name={name}
                        value="yes"
                        checked={value === index + 1}
                        onChange={() => onChange(index + 1)}
                    />
                    <span className="ml-2 text-sm text-gray-500">
                        {index + 1}
                    </span>
                </label>
            ))}
        </div>
    );
};

export default YesNo;

import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {FormHandler, Input} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import SiteConfigApi from 'Services/Api/Admin/SiteConfig';

class SiteConfigStore extends React.Component {

    /**
     * @var success
     * @type {string}
     */
    success = 'Config ' + (this.props.match?.params?.siteConfig ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     * @type {{site_config_id: string}}
     */
    state = {
        site_config_id: this.props.match?.params?.siteConfig ?? '',
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.state.site_config_id) {
            this.loadSiteConfig();
        } else {
            this.props.setInitialValues({
                key: '',
                value: '',
            });
        }
    }

    /**
     * @method loadSiteConfig
     */
    loadSiteConfig = async () => {
        const {site_config_id} = this.state;

        const response = await SiteConfigApi.get(site_config_id);

        this.props.setForm(response.data.data);
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {site_config_id} = this.state;

        if (site_config_id) {
            return SiteConfigApi.patch(site_config_id, form);
        } else {
            return SiteConfigApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() { 
        const {site_config_id} = this.state;
        const {form, working, alert, setForm, handleInput, getFieldError, handleSubmit} = this.props;

        return (
            <AdminLayout title={'Site Config - ' + (site_config_id ? 'Update' : 'Create') + ' Config'}>
                <form
                    className="divide-y divide-gray-200"
                    onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (site_config_id ? false : true))}
                >
                    <div className="mx-6">
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid grid-cols-2 gap-4 mb-4 border-0">
                            <div>
                                <Input
                                    label="Key"
                                    value={form.key}
                                    id="key"
                                    error={getFieldError('key')}
                                    onChange={(v) => handleInput('key', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    label="Value"
                                    value={form.value}
                                    id="value"
                                    error={getFieldError('value')}
                                    onChange={(v) => handleInput('value', v)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="px-6 pt-6 flex justify-end gap-4">
                        <Link to="/admin/site-config">
                            <SecondaryButton type="button" text="Back" />
                        </Link>

                        <PrimaryButton
                            text="Save"
                            working={working}
                        />
                    </div>
                </form>
            </AdminLayout>
        )
    }
}

export default FormHandler(SiteConfigStore);

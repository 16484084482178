import {
    faIdCard,
    faCalendar,
    faFile,
    faThermometerThreeQuarters,
    faUmbrellaBeach,
    faBusinessTime,} from '@fortawesome/free-solid-svg-icons';

export default [
    {
        icon: faIdCard,
        title: "My HR",
        sub: [
            {
                to: "/settings/human-resources/profile",
                icon: faIdCard,
                title: "Profile",
            },
            {
                to: "/settings/human-resources/my-calendar",
                icon: faCalendar,
                title: "Calendar",
            },
            {
                to: "/settings/human-resources/timesheets",
                icon: faBusinessTime,
                title: "Timesheets",
            },
            {
                to: "/settings/human-resources/holidays",
                icon: faUmbrellaBeach,
                title: window.base.hr_timesheet_types['holiday']?.label,
            },
            {
                to: "/settings/human-resources/absences",
                icon: faThermometerThreeQuarters,
                title: window.base.hr_timesheet_types['sick']?.label,
            },
            {
                to: "/settings/human-resources/time-worked",
                icon: faBusinessTime,
                title: window.base.hr_timesheet_types['time_worked']?.label,
            },
            {
                to: "/settings/human-resources/documents",
                icon: faFile,
                title: "Documents",
            },
        ],
    },
];

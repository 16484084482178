import React from 'react';
import Picker from 'emoji-picker-react';

import Label from './Label';

export default class EmojiPicker extends React.Component {
    /**
     * @var state
     * @type {{display: boolean}}
     */
    state = {
        display: false
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {display} = this.state;

        const {
            labelPlacement = 'left', 
            containerClassName, 
            buttonClassName, 
            pickerClassName, 
            label, 
            id, 
            onChange, 
            value, 
            instructions,
            error = null} = this.props;

        return (
            <div className={`${containerClassName} flex flex-col items-center`}>
                {(labelPlacement === 'left') && label && (
                    <Label 
                        label={label} 
                        htmlFor={id} 
                        className="mb-0 mr-4"
                        instructions={instructions}
                        error={error}
                    />
                )}

                <div
                    className={`${buttonClassName} cursor-pointer`}
                    onClick={() => this.setState({display: !display})}
                >
                    &#128512;
                </div>

                {display &&
                    <div className={`${pickerClassName} mt-4`}>
                        <Picker
                            onEmojiClick={(event, emojiObject) => {
                                onChange(emojiObject.emoji);
                                this.setState({display: false});
                            }}
                            disableSkinTonePicker={true} // TODO Couldn't get this to work
                        />
                    </div>
                }

                {(labelPlacement === 'right') && label && (
                    <Label 
                        label={label} 
                        htmlFor={id} 
                        className="mb-0 ml-4"
                        instructions={instructions}
                        error={error}
                    />
                )}
            </div>
        );
    }
}
import React from 'react';

import Socket from 'Services/Socket';

export default class SocketIndicator extends React.Component {
    /**
     * @var state
     * @type {{connected: boolean}}
     */
    state = {
        connected: false
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.loadConnected();

        Socket.on('change', this.loadConnected);
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        Socket.removeListener('change', this.loadConnected);
    }

    /**
     * @method loadConnected
     */
    loadConnected = async () => {
        this.setState({
            connected: Socket.getConnected()
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {connected} = this.state;

        return (
            <div className="tooltip relative inline-block cursor-pointer">
                <div className={`w-6 h-6 rounded-full ${connected ? 'bg-green-600' : 'bg-red-500'}`} />

                <span className="tooltiptext w-56 rounded-sm bg-white text-gray-800 
                    text-base text-center p-3 z-30 absolute left-10 top-0 border border-gray-800">
                    {Socket.getMessage()}
                </span>
            </div>
        );
    }
}
import React from 'react';
import {faCalendar} from '@fortawesome/free-solid-svg-icons/faCalendar';

import SettingsPage from 'Pages/Settings/SettingsPage';

import TimesheetsCalendar from 'Components/HumanResources/Timesheets/Calendar';

export default class Calendar extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <SettingsPage title="My HR Calendar" titleIcon={faCalendar}>
                <TimesheetsCalendar
                    // title='My HR Calendar'
                    admin={false}
                    canCreate={false}
                />
            </SettingsPage>
        );
    }
}

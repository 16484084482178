import React from 'react';

import {Container} from 'Components/Utilities';

export default class Unauthenticated extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {children, pageTitle} = this.props;

        return (
            <React.Fragment>
                <Container>
                    {children}
                </Container>
            </React.Fragment>
        );
    }
}

import React from 'react';

import {Select} from 'Components/Form';

/**
 * @function AsyncSelectFilter
 * @param {string|null} containerClassName
 * @param {function} onChange
 * @param {object} filters
 * @param {string} column
 * @param {string} label
 * @param {boolean} defaultMethod
 * @param {object} props
 * @constructor
 */
const AsyncSelectFilter = ({containerClassName, onChange, filters, column, label, defaultMethod = true, props}) => {
    // defaultMethod => DualityStudio\Base\Filter\QueryFilter.php -> in().
    // !defaultMethod => Custom filter "column" on the request.
    let column_key = defaultMethod ? column + "[in]" : column;

    return (
        <Select
            containerClassName={containerClassName ?? "w-48"}
            label={label}
            placeholder="Filter..."
            onChange={(v) => onChange(column_key, v ? v.join(',') : null)}
            value={filters && filters[column_key] ? filters[column_key].split(',') : null}
            isAsync
            isMulti
            searchCallback={props.searchCallback}
            searchLabelKey={props.searchLabelKey}
            allowNull={true}
        />
    );
};

export default AsyncSelectFilter;
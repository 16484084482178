import React from 'react';

import {Container} from 'Components/Utilities';
import PushNotifications from 'Components/Partials/PushNotifications';

import Header from './Header';

export default class Authenticated extends React.Component {
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        const {children, pageTitle} = this.props;

        return (
            <div className="min-h-screen">
                <Header />

                {window.base.features.allow_push_notifications && <PushNotifications />}
                    
                <Container>
                    {children}
                </Container>
            </div>
        )
    };
}

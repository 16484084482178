import React from 'react';
import {faBell} from '@fortawesome/free-solid-svg-icons/faBell';

import {Notifications, Notification} from 'Components/Comms';
import {Loading, PaginationBar} from 'Components/Partials';

import {Toast} from 'Services';
import Me from 'Services/Api/Me/Me';

import SettingsPage from '../SettingsPage';

export default class NotificationsPage extends Notifications {
    /**
     * @method handleUpdateTeam
     * @param {object} team
     * @param {boolean} hasUrl
     * @return {Promise<void>}
     */
    handleUpdateTeam = async (team) => {
        const request = await Me.updateTeam({
            team_id: team.id
        });

        if (!request.success) {
            return Toast.error();
        }

        return true;
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, notifications} = this.state;

        return (
            <SettingsPage title="Notifications" titleIcon={faBell}>
                <div className="p-6">
                    {working && (<Loading />)}

                    {!working &&
                        <div className="relative bg-white p-2">
                            {notifications?.meta?.total == 0 &&
                                <div className="p-2 text-center text-sm text-gray-500">
                                    You currently do not have any notifications.
                                </div>
                            }

                            {notifications?.meta?.total != 0 &&
                                <>
                                    {notifications.data.map(notification => (
                                        <Notification
                                            notification={notification}
                                            key={notification.id}
                                            onRead={this.handleReadNotification}
                                            onSwitchToTeam={this.handleUpdateTeam}
                                        />
                                    ))}

                                    {notifications &&
                                        <div className="mt-12 mb-4">
                                            <PaginationBar
                                                total={notifications.meta.total}
                                                pageCount={notifications.meta.last_page}
                                                page={notifications.meta.current_page}
                                                goToPage={this.fetchNotifications}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
            </SettingsPage>
        );
    }
}

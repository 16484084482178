import ApiBase from '../ApiBase';

class Translations extends ApiBase {
    route = '/admin/translations';

    /**
     * @method getGroups
     * @return {Promise<*>}
     */
    getGroups = () => {
        return this.get(this.route + '/get-groups');
    };

    /**
     * @method update
     * @param {object} data
     * @return {Promise<boolean|{status: number}>}
     */
    update = (data) => {
        return this.patch(this.route + '/' + data.key, data);
    };
}

export default new Translations();

import ApiBase from 'Services/Api/ApiBase';

class Timesheets extends ApiBase {
    route = '/admin/human-resources/timesheets';

    /**
     * @param {string} timesheet_id
     * @returns {Promise}
     */
    getUsersWithOverlappingTimesheets(timesheet_id) {
        return this.get(`${this.route}/${timesheet_id}/overlapping-users`);
    }

    getTimesheetSummaryByEmployee = (filters) => this.get(`${this.route}/summary-by-employees`, filters);
};

export default new Timesheets();

import React from 'react';

import AdminLayout from 'Pages/Admin/AdminLayout';

import EnvTest from './EnvTest';
import SocketTest from './SocketTest';
import EmailTest from './EmailTest';
import SmsTest from './SmsTest';
import StorageTest from './StorageTest';

export default class Tests extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Site Tests">
                <div className="flex flex-col gap-2 mx-4 mb-4">
                    <EnvTest />
                    <SocketTest />
                    <EmailTest />
                    <StorageTest />
                </div>
            </AdminLayout>
        )
    }
}
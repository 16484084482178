import React from 'react';
import {Redirect, Link} from 'react-router-dom';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {FormHandler, Input, IsTranslatableWrapper, Toggle} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import ElementApi from 'Services/Api/Admin/Cms/Elements/Element';
import {translationFromJson} from 'Services/TranslationHelpers2';
import { Select } from "Components/Form";

class AdminCmsElementsStore extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Element ' + (this.props.match?.params?.element ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     * @type {{element_id: string}}
     */
    state = {
        element_id: this.props.match?.params?.element ?? '',
        redirect: null,
        showAuditFrequency: false,
    };


    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.state.element_id) {
            this.loadElement();
        } else {
            this.props.setInitialValues({
                identifier: '',
                label: null,
                allow_likes: false,
                allow_comments: false,
                allow_tags: false,
                filter_languages: false,
                display_form_on_frontend: true,
                orderable: false,
                attach_to_service_user: true,
                health_and_safety: false,
                quality_assurance: false,
                medication_management: false,
                audit_frequency: null,
            });
        }
    }

    /**
     * @method loadElement
     */
    loadElement = async () => {
        const {element_id} = this.state;

        const response = await ElementApi.get(element_id);

        this.props.setForm(response.data.data);

        if (response.data.data.audit_frequency) {
            this.setState({ showAuditFrequency: true });
        }

        this.setState({
            fields: response.data.data.fields?.map((field) => ({
                value: 'value:' + field.identifier,
                label: field.label['en'],
            })),
        })
    }

    /**
     * @method handleSubmit
     * @param {event} e
     */
    handleSubmit = async (e) => {
        const {handleSubmit} = this.props;
        const {element_id} = this.state;

        await handleSubmit(e, this.handleSubmit2, this.success, !element_id);

        const {alert} = this.props;

        if (alert.type === 'success') {
            this.setState({
                redirect: element_id ? '/admin/cms/elements/show/' + element_id : '/admin/cms/elements'
            });
        }
    };

    /**
     * @method handleSubmit2
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit2 = (form) => {
        const {element_id} = this.state;

        if (element_id) {
            return ElementApi.patch(element_id, form);
        } else {
            return ElementApi.post(null, form);
        }
    };

    handleToggleChange = (value) => {
        this.setState({ showAuditFrequency: value });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {element_id, redirect, fields, showAuditFrequency} = this.state;
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        let languages = Object.entries(window.base.languages).map((value, key) => {
            return value[0];
        });

        if (redirect) {
            return (<Redirect to={redirect} />);
        }

        const auditFrequencyOptions = [
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
            { value: 'quarterly', label: 'Quarterly' },
            { value: 'bi-annually', label: 'Bi-Annually' },
            { value: 'annually', label: 'Annually' },
        ];

        return (
            <AdminLayout title={'CMS Elements - ' + (element_id ? 'Update' : 'Create') + ' Element'}>
                <form
                    className="divide-y divide-gray-200"
                    onSubmit={this.handleSubmit}
                >
                    <div className="mx-6">
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid grid-cols-2 gap-4 mb-4 border-0">
                            <div>
                                 <IsTranslatableWrapper
                                     label="Label"
                                     value={ form.label }
                                     id="label"
                                     error={ getFieldError ( 'label' ) }
                                     onChange={ ( v ) => handleInput ( 'label', v ) }
                                     supported_languages={ languages }
                                     childComponent="Input"
                                     childProps={ {} }
                                 />
                            </div>

                            <div>
                                <Toggle
                                    label="Attach To Service User (Narrative)"
                                    labelPlacement="top"
                                    value={ form.attach_to_service_user }
                                    id="attach_to_service_user"
                                    error={ getFieldError ( 'attach_to_service_user' ) }
                                    onChange={ ( v ) => handleInput ( 'attach_to_service_user', v ) }
                                />
                            </div>

                            <div>
                                <Toggle
                                    label="Health & Safety Form"
                                    labelPlacement="top"
                                    value={ form.health_and_safety }
                                    id="health_and_safety"
                                    error={ getFieldError ( 'health_and_safety' ) }
                                    onChange={ ( v ) => handleInput ( 'health_and_safety', v ) }
                                />
                            </div>

                            <div>
                                <Toggle
                                    label="Quality Assurance"
                                    labelPlacement="top"
                                    value={ form.quality_assurance }
                                    id="quality_assurance"
                                    error={ getFieldError ( 'quality_assurance' ) }
                                    onChange={ ( v ) => handleInput ( 'quality_assurance', v ) }
                                />
                            </div>

                            <div>
                                <Toggle
                                    label="Medical Management"
                                    labelPlacement="top"
                                    value={ form.medication_management }
                                    id="medication_management"
                                    error={ getFieldError ( 'medication_management' ) }
                                    onChange={ ( v ) => handleInput ( 'medication_management', v ) }
                                />
                            </div>
                            { !form.attach_to_service_user && !form.health_and_safety &&
                                <>
                                    <div>
                                        <Toggle
                                            label="Enable Likes"
                                            labelPlacement="top"
                                            value={ form.allow_likes }
                                            id="allow_likes"
                                            error={ getFieldError ( 'allow_likes' ) }
                                            onChange={ ( v ) => handleInput ( 'allow_likes', v ) }
                                        />
                                    </div>

                                    <div>
                                        <Toggle
                                            label="Enable Comments"
                                            labelPlacement="top"
                                            value={ form.allow_comments }
                                            id="allow_comments"
                                            error={ getFieldError ( 'allow_comments' ) }
                                            onChange={ ( v ) => handleInput ( 'allow_comments', v ) }
                                        />
                                    </div>

                                    <div>
                                        <Toggle
                                            label="Enable Tags"
                                            labelPlacement="top"
                                            value={ form.allow_tags }
                                            id="allow_tags"
                                            error={ getFieldError ( 'allow_tags' ) }
                                            onChange={ ( v ) => handleInput ( 'allow_tags', v ) }
                                        />
                                    </div>

                                    { Object.keys ( window.base.languages ).length > 1 &&
                                        <div>
                                            <Toggle
                                                label="Filter Languages"
                                                labelPlacement="top"
                                                value={ form.filter_languages }
                                                id="filter_languages"
                                                error={ getFieldError ( 'filter_languages' ) }
                                                onChange={ ( v ) => handleInput ( 'filter_languages', v ) }
                                            />
                                        </div>
                                    }

                                    <div>
                                        <Toggle
                                            label="Display Form On Frontend"
                                            labelPlacement="top"
                                            value={ form.display_form_on_frontend }
                                            id="display_form_on_frontend"
                                            error={ getFieldError ( 'display_form_on_frontend' ) }
                                            onChange={ ( v ) => handleInput ( 'display_form_on_frontend', v ) }
                                        />
                                    </div>

                                    <div>
                                        <Toggle
                                            label="Orderable"
                                            labelPlacement="top"
                                            value={ form.orderable }
                                            id="orderable"
                                            error={ getFieldError ( 'orderable' ) }
                                            onChange={ ( v ) => handleInput ( 'orderable', v ) }
                                        />
                                    </div>
                                </>
                            }


                            <div>
                                <Toggle
                                    label="Enable Audit Frequency Selection"
                                    labelPlacement="top"
                                    value={ showAuditFrequency }
                                    id="show_audit_frequency"
                                    error={ getFieldError ( 'show_audit_frequency' ) }
                                    onChange={ ( v ) => this.handleToggleChange ( v ) }
                                />
                            </div>
                            { showAuditFrequency && (
                                <div>
                                    <Select
                                        label="Audit Frequency"
                                        options={ auditFrequencyOptions }
                                        value={ form.audit_frequency }
                                        onChange={ ( v ) => handleInput ( 'audit_frequency', v ) }
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="p-6 flex justify-end gap-4">
                        <Link to="/admin/cms/elements">
                            <SecondaryButton
                                type="button"
                                text="Back"
                            />
                        </Link>

                        <PrimaryButton
                            text="Save"
                            working={ working }
                        />
                    </div>
                </form>
            </AdminLayout>
        )
    }
}

export default FormHandler(AdminCmsElementsStore);

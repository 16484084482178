import React from 'react';

import { Alert } from 'Components/Partials';
import { PrimaryButton } from 'Components/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'Components/Modal';
import { FormHandler, DatePicker, Textarea } from 'Components/Form';

import TimesheetsApi from "Services/Api/HumanResources/Timesheets";
import { Toast } from "Services";

class CreateHoliday extends React.Component {
    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        const { record, setForm } = this.props;

        if (record) {
            setForm({
                ...record,
            });
        }
    };

    /**
     * @method handleRequest
     * @param form
     * @returns {Promise<*>}
     */
    handleRequest = (form) => TimesheetsApi.post(null, {
        type: 'holiday',
        submitted: true,
        sleeps_in: false,
        all_day: true,
        ...form,
    });

    /**
     * @method handleSuccess
     */
    handleSuccess = () => {
        Toast.success('Holiday created successfully.');
        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { record, working, alert, form, handleSubmit, handleInput } = this.props;

        return (
            <Modal>
                <ModalHeader onClose={ this.props.onClose }>
                    { record ? 'Update' : 'Create' } Calendar Entry
                </ModalHeader>

                <form onSubmit={ (e) => handleSubmit(e, this.handleRequest, this.handleSuccess) }>
                    <ModalBody>
                        { alert !== null && (<Alert { ...alert } />) }

                        <>
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <DatePicker
                                    label="Start"
                                    selected={ form.start_date_time }
                                    onChange={ date => handleInput('start_date_time', date) }
                                    showTimeSelect={ false }
                                    selectsStart
                                    startDate={ form.start_date_time }
                                    endDate={ form.end_date_time }
                                />

                                <DatePicker
                                    label="End"
                                    selected={ form.end_date_time }
                                    startDate={ form.start_date_time }
                                    endDate={ form.end_date_time }
                                    minDate={ form.start_date_time }
                                    onChange={ date => handleInput('end_date_time', date) }
                                    showTimeSelect={ false }
                                    selectsEnd
                                />
                            </div>

                            <Textarea
                                label="Notes"
                                containerClassName="mb-4"
                                value={ form.description }
                                onChange={ v => handleInput('description', v) }
                            />
                        </>
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton
                            text={ record ? 'Update' : 'Create' }
                            working={ working }
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler(CreateHoliday);

import React from 'react';
import TimeAgo from 'react-timeago';

import {PaginationScroll} from 'Components/Partials';
import {ProfilePic, handleMessagePreview, isSameUser, isSameUserCheck} from 'Components/Networking';

import {User, Socket} from 'Services';
import ChatsApi from 'Services/Api/Networking/Chats';
import {translation} from 'Services/TranslationHelpers';
import {mergeObjectsIntoPaginationData} from 'Services/BaseHelpers';

export default class Chats extends React.Component {
    /**
     * @method constructor
     */
    constructor() {
        super();
        this.selectedUserRef = React.createRef();
    }

    /**
     * @var state
     * @type {{users: null|object}}
     */
    state = {
        users: null,
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        const {selected_user} = this.props;

        this.subscribeToBroadcasts();

        if (selected_user) {
            this.handleSelectedUser(selected_user);
        }
    };

    /**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {selected_user} = this.props;

        if (selected_user && prevProps.selected_user !== selected_user) {
            this.scrollToSelectedUser();

            this.handleSelectedUser(selected_user);
        }
    }

    /**
     * @method componentDidUpdate
     * @param {object} selected_user
     */
    handleSelectedUser = (selected_user) => {
        /*
        TODO
        PaginationScroll resets the data when loading the first page therefore we have to wait
        until after that page has loaded to add this user. Used a hacky setTimeout solution.
         */
        setTimeout(() => {
            this.handleNewUser(selected_user);
        }, 500);
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.unsubscribeFromBroadcasts();
    }

    /**
     * @method subscribeToBroadcasts
     */
    subscribeToBroadcasts = () => {
        // Listen for new messages.
        Socket.getConnection().private('networking.' + User.id)
            .listen('NetworkingMessagePosted', (e) => {
                // If message to a group then always display it on the group,
                // else display it on the other user
                if (e.message.message_to_type !== 'App\\Models\\User') {
                    this.handleNewUser(e.message.message_to, e.message);
                } else {
                    this.handleNewUser(
                        User.id === e.message.user_from_id ?
                            e.message.message_to :
                            e.message.user_from,
                        e.message
                    );
                }
            });
    }

    /**
     * @method unsubscribeFromBroadcasts
     */
    unsubscribeFromBroadcasts = () => {
        // Stop listening for new messages.
        Socket.getConnection().leave('networking.' + User.id);
    }

    /**
     * @method handleNewUser
     * @param {object} user
     * @param {object} last_message
     * @return {Promise<void>}
     */
    handleNewUser = (user, last_message = null) => {
        const {users} = this.state;
        const {selected_user} = this.props;

        // Update last_message.
        if (last_message) {
            user.last_message = last_message;
        }

        // If selected_user then mark as read.
        if (selected_user && isSameUser(selected_user, user) && user.last_message) {
            user.last_message.read_at = true;
        }

        // Update the users.
        if (users.data.filter(o => isSameUser(o, user)).length === 0) {
            // User is not yet in the list so add it.
            return this.setState({
                users: mergeObjectsIntoPaginationData(users, [user], false, isSameUserCheck())
            });
        } else {
            // User is already in the list so update the last_message.
            return this.setState({
                users: {
                    data: users.data.map((o) => {
                        if (isSameUser(o, user)) {
                            o.last_message = user.last_message;
                        }

                        return o;
                    }),
                    meta: users.meta
                }
            });
        }
    }

    /**
     * @method scrollToSelectedUser
     */
    scrollToSelectedUser = () => {
        if (this.selectedUserRef.current !== null) {
            this.selectedUserRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    /**
     * @method sortUsers
     * @param {object} user_a
     * @param {object} user_b
     * @return {integer}
     */
    sortUsers = (user_a, user_b) => {
        // If both have no messages then leave as is.
        if (!user_a.last_message && !user_b.last_message) {
            return 1;
        }

        // Put chats with no messages on top.
        if (!user_a.last_message) {
            return -1;
        }

        if (!user_b.last_message) {
            return 1;
        }

        // Order by last_message date.
        var x = new Date(user_a.last_message.created_at.iso_string);
        var y = new Date(user_b.last_message.created_at.iso_string);

        return y - x;
    }

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {users} = this.state;
        const {additional_key} = this.props;

        return (
            <div className="h-full flex flex-col">
                <div className="h-20 hidden lg:flex flex-col justify-center">
                    <h2 className="ml-2 uppercase text-lg font-bold">
                        {translation('networking_frontend', 'chats_title')}
                    </h2>
                </div>

                <PaginationScroll
                    scrollDown={true}
                    apiCall={(page) => ChatsApi.get(null, {
                        page,
                        additional_key
                    })}
                    updateData={(data) => this.setState({users: data})}
                    data={users}
                    containerClassName="bg-networking-primary-text border border-networking-primary shadow-sm"
                    sameObjectCheck={isSameUserCheck()}
                >
                    {users && users.data.length === 0 &&
                        <div className="mt-3 p-4 text-center font-bold">
                            {translation('networking_frontend', 'connect_message')}
                        </div>
                    }

                    {users && users.data.sort((a, b) => {
                        return this.sortUsers(a, b)
                    }).map((user, i) => (this.renderUser(user)))}
                </PaginationScroll>
            </div>
        )
    }

    /**
     * @method renderUser
     * @param {object} user
     * @return {*}
     */
    renderUser(user) {
        const {selected_user, setSelectedUser} = this.props;

        let is_selected_user = (selected_user && isSameUser(selected_user, user));
        let is_unread = (user.last_message && user.last_message.user_from_id === user.id && user.last_message.read_at === null);

        return (
            <div
                className={`
                    w-full p-4 cursor-pointer border-b-2
                    ${is_selected_user ? 'bg-networking-active' : ''}
                `}
                onClick={() => setSelectedUser(user)}
                ref={is_selected_user ? this.selectedUserRef : null}
                key={user.type + '_' + user.id}
            >
                <div className="flex flex-col lg:flex-row justify-between">
                    <div className="flex flex-col lg:flex-row">
                        <div className="my-auto mx-auto lg:mx-0 lg:mr-2">
                            <ProfilePic user={user}/>
                        </div>

                        <div className="mx-auto lg:mx-0">
                            <p className="font-bold text-center lg:text-left mt-2 lg:mt-0 text-networking-secondary-text">
                                {user.name}
                            </p>

                            {user.last_message &&
                                <p className="mt-2 text-sm text-center lg:text-left">
                                    {handleMessagePreview(user.last_message.message)}
                                </p>
                            }
                        </div>
                    </div>

                    <div className="ml-0 lg:ml-2 mt-1 lg:mt-0">
                        {user.last_message &&
                            <>
                                <p className="mt-1 font-bold text-center lg:text-right text-xs">
                                    <TimeAgo date={user.last_message.created_at.iso_string}/>
                                </p>

                                {is_unread &&
                                    <p className="mt-2 lg:mt-1 text-sm text-center lg:text-right font-bold">
                                        {translation('networking_frontend', 'unread_message')}
                                    </p>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

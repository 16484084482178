import React from 'react';
import {Link} from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {FormHandler, Input, Toggle, WYSIWYG, DatePicker, Select, IsTranslatableWrapper} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

import AdminPushNotificationsApi from 'Services/Api/Admin/PushNotifications';
import {Event} from 'Services';

class PushNotificationsStore extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Push notification ' + (this.props.match?.params?.pushNotification ? 'updated' : 'created') + ' successfully';

    /**
     * @var types
     * @type {array}
     */
    types = [
        {
            label: 'Modal',
            value: 'modal'
        },
        {
            label: 'Inline',
            value: 'inline'
        }
    ];

    /**
     * @var state
     * @type {{push_notification_id: string}}
     */
    state = {
        push_notification_id: this.props.match?.params?.pushNotification ?? '',
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.state.push_notification_id) {
            this.loadPushNotification();
        } else {
            this.props.setInitialValues({
                'supported_languages': Object.keys(window.base.languages),
                'type': 'inline',
                'title': '',
                'start_date_time': new Date(),
                'end_date_time': new Date(),
                'display_once_per_user': true,
                'content': null,
                high_priority: false,
            });
        }
    }

    /**
     * @method loadPushNotification
     * @param {int} page
     */
    loadPushNotification = async (page = 1) => {
        const {push_notification_id} = this.state;

        const response = await AdminPushNotificationsApi.get(push_notification_id);

        let push_notification = response.data.data;

        this.props.setForm({
            'supported_languages': push_notification.supported_languages,
            'type': push_notification.type,
            'title': push_notification.title,
            'start_date_time': push_notification.start_date_time,
            'end_date_time': push_notification.end_date_time,
            'display_once_per_user': push_notification.display_once_per_user,
            'content': push_notification.content,
            high_priority: push_notification.high_priority,
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {push_notification_id} = this.state;

        if (push_notification_id) {
            return AdminPushNotificationsApi.patch(push_notification_id, form);
        } else {
            return AdminPushNotificationsApi.post(null, form);
        }
    };

    handlePreview = () => {
        Event.emit('admin-push-notification-preview', this.props.form);
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {push_notification_id} = this.state;
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        let languages = Object.entries(window.base.languages).map((value, key) => {
            return {
                label: value[1],
                value: value[0]
            };
        });

        return (
            <AdminLayout title={'Push Notifications - ' + (push_notification_id ? 'Update' : 'Create') + ' Push Notification'}>
                {!window.base.features.allow_push_notifications &&
                    <p className="m-6 font-bold text-center">
                        This feature has been disabled.
                    </p>
                }

                {window.base.features.allow_push_notifications &&
                    <form
                        className="divide-y divide-gray-200"
                        onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (push_notification_id ? false : true))}
                    >
                        <div className="mx-6">
                            {alert !== null && (<Alert {...alert} />)}

                            <div className="grid grid-cols-2 gap-4 mb-4">
                                {languages.length > 1 &&
                                    <div>
                                        <Select
                                            label="Supported Languages"
                                            value={form.supported_languages}
                                            error={getFieldError('supported_languages')}
                                            onChange={(v) => handleInput('supported_languages', v)}
                                            options={languages}
                                            isMulti
                                        />
                                    </div>
                                }

                                {/*<div>*/}
                                {/*    <Select*/}
                                {/*        label="Type"*/}
                                {/*        value={form.type}*/}
                                {/*        error={getFieldError('type')}*/}
                                {/*        onChange={(v) => handleInput('type', v)}*/}
                                {/*        options={this.types}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                <div className="col-span-2">
                                    <IsTranslatableWrapper
                                        label="Title"
                                        value={form.title}
                                        id="title"
                                        error={getFieldError('title')}
                                        onChange={(v) => handleInput('title', v)}
                                        supported_languages={form.supported_languages}
                                        childComponent="Input"
                                        childProps={{}}
                                    />
                                </div>

                                <div>
                                    <DatePicker
                                        label="Start Date Time"
                                        selected={form.start_date_time}
                                        error={getFieldError('start_date_time')}
                                        onChange={date => handleInput('start_date_time', date)}
                                        showTimeSelect={true}
                                        nowButton={true}
                                    />
                                </div>

                                <div>
                                    <DatePicker
                                        label="End Date Time"
                                        selected={form.end_date_time}
                                        error={getFieldError('end_date_time')}
                                        onChange={date => handleInput('end_date_time', date)}
                                        showTimeSelect={true}
                                        nowButton={true}
                                    />
                                </div>

                                <div className="col-span-2">
                                    <IsTranslatableWrapper
                                        label="Notification Content"
                                        value={form.content}
                                        id="content"
                                        error={getFieldError('content')}
                                        onChange={(v) => handleInput('content', v)}
                                        supported_languages={form.supported_languages}
                                        childComponent="WYSIWYG"
                                        childProps={{}}
                                    />
                                </div>

                                <div className="my-auto flex space-x-6">
                                    <Toggle
                                        label="Display Once Per User"
                                        value={form.display_once_per_user}
                                        error={getFieldError('display_once_per_user')}
                                        onChange={v => handleInput('display_once_per_user', v)}
                                    />
                                    <Toggle
                                        label="High Priority"
                                        value={form.high_priority}
                                        error={getFieldError('high_priority')}
                                        onChange={v => handleInput('high_priority', v)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="px-6 pt-6 flex justify-end gap-4">
                            <Link to="/admin/push-notifications">
                                <SecondaryButton type="button" text="Back" />
                            </Link>

                            <PrimaryButton
                                text="Save"
                                working={working}
                            />
                        </div>
                    </form>
                }
            </AdminLayout>
        )
    }
}

export default FormHandler(PushNotificationsStore);

import ApiBase from '../ApiBase';

class Imports extends ApiBase {
    route = '/admin/imports';

    /**
     * @method getAvailableImports
     * @return {Promise<*>}
     */
    getAvailableImports = () => {
        return this.get('/admin/imports/available-imports');
    }
}

export default new Imports;
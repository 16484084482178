import React from 'react'

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, DangerButton} from 'Components/Button';
import {Loading, Alert} from 'Components/Partials';
import {Select, Toggle} from 'Components/Form';
import {BooleanFilter, IsEmptyFilter} from 'Components/Filters';

import AdminReportsApi from 'Services/Api/Admin/Reports';
import AdminCmsElementApi from 'Services/Api/Admin/Cms/Elements/Element';

import {translationFromJson} from 'Services/TranslationHelpers2';
import {getFieldsFilter} from 'Services/CmsHelpers';

export default class AdminRunReportModal extends React.Component  {
    /**
     * @var state
     */
    state = {
        alert: null,
        working: false,
        params: null,
        displayFilters: false,
        cms_elements: null,
    };

    /**
     * @method componentDidUpdate
     */
    async componentDidMount() {
        const {type, cms_element_identifier} = this.props;

        if (type === 'CmsElementRecords') {
            await this.getCmsElements();

            this.setState({
                params: {
                    cms_element_identifier: cms_element_identifier ?? null
                }
            });
        }
    }

    /**
     * @method getCmsElements
     */
    getCmsElements = async () => {
        this.setState({working: true});

        const response = await AdminCmsElementApi.get(null);

        this.setState({
            working: false,
            cms_elements: response.data.data,
        });
    }

    /**
     * @method getFilters
     * @param {array}
     */
    getFilters = () => {
        const {type} = this.props;
        const {params, cms_elements} = this.state;

        if (type === 'Users') {
            return [
                {
                    column: 'username',
                    component: IsEmptyFilter
                },
                {
                    column: 'is_admin',
                    label: 'Admin',
                    component: BooleanFilter
                }
            ];
        } else if (type === 'CmsElementRecords') {
            if (params?.cms_element_identifier) {
                let fields = cms_elements.filter(element => element.identifier === params.cms_element_identifier)[0].fields;

                return getFieldsFilter(fields);
            }
        }

        return null;
    }

    /**
     * @method handleParams
     * @param {string} key
     * @param {string} value
     */
    handleParams = (key, value) => {
        const {params} = this.state;

        this.setState({
            params: {
                ...params,
                [key]: value
            }
        });
    };

    /**
     * @method handleSubmit
     */
    handleSubmit = async () => {
        this.setState({
            alert: null,
            working: true
        });

        const response = await AdminReportsApi.storeReport(
            this.props.type,
            this.state.params
        );

        if (response.status !== 201) {
            this.setState({
                alert: {
                    type: 'error',
                    message: response.message,
                    errors: response.errors
                },
                working: false
            });
            return;
        }

        this.props.callbackOnSubmit(1);

        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {type} = this.props;
        const {alert, working} = this.state;

        return (
            <Modal>
                <ModalBody>
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="my-2 text-center black text-2xl leading-9 font-extrabold">
                            {/* https://stackoverflow.com/questions/5582228/insert-space-before-capital-letters */}
                            { "Run " + type.replace(/([A-Z])/g, ' $1').trim() + " Report" }
                        </h2>

                        {alert !== null && (<Alert {...alert} />)}
                    </div>

                    {this.renderParams()}
                </ModalBody>

                <ModalFooter alignment="center">
                    {working && (<Loading color="text-black"/>)}

                    {!working &&
                        <div className="flex justify-around space-between">
                            <PrimaryButton className="mr-2" text="Cancel" onClick={this.props.onClose} />
                            <DangerButton onClick={this.handleSubmit} text="Run" />
                        </div>
                    }
                </ModalFooter>
            </Modal>
        )
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    renderParams() {
        const {type} = this.props;
        const {params, displayFilters, cms_elements} = this.state;

        let filters = this.getFilters();

        let cms_element_options = cms_elements ? cms_elements.map((element) => (
            {
                label: translationFromJson(element.label),
                value: element.identifier
            }
        )) : null;

        if (filters || type === 'CmsElementRecords') {
            return (
                <div>
                    {type === 'CmsElementRecords' &&
                        <Select
                            containerClassName="mt-8"
                            label="Cms Element"
                            value={params?.cms_element_identifier}
                            onChange={(v) => this.handleParams('cms_element_identifier', v)}
                            options={cms_element_options}
                        />
                    }

                    {filters &&
                        <Toggle
                            containerClassName={`flex justify-center mt-8 ${displayFilters ? 'mb-4' : ''}`}
                            label="Filter"
                            value={displayFilters}
                            onChange={(v) => this.setState({
                                displayFilters: v,
                                params: {
                                    cms_element_identifier: params?.cms_element_identifier
                                }
                            })}
                        />
                    }

                    {displayFilters && filters && filters.map((filter, i) => {
                        let Filter = filter.component;

                        return (
                            <div className="mb-4 text-black" key={i}>
                                <Filter
                                    containerClassName="w-full"
                                    onChange={(column, value) => this.handleParams(column, value)}
                                    filters={params}
                                    column={filter.column}
                                    props={filter.props}
                                    label={filter.label}
                                    defaultMethod={filter.defaultMethod}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        }

        return null;
    }
}

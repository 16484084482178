import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, DangerButton} from 'Components/Button';
import {FormHandler, Toggle} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';

class AdminSendEmailModal extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'The ' + this.props.emailTitle + ' has been sent.';

    /**
     * @method componentDidMount
     */
    componentDidMount() {        
        const {userInvite, setInitialValues} = this.props;

        if (userInvite) {
            setInitialValues({
                send_password_reset_link: false,
            });
        }
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        return this.props.onSubmit(this.props.user.id, form);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;
        const {title, message, user, emailTitle, userInvite} = this.props;

        let send_password_reset_link = false;

        if (userInvite && (user.is_admin || !window.base.using_hard_password)) {
            send_password_reset_link = true;
        }

        return (
            <Modal>
                <form
                    onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, false)}
                >
                    <ModalHeader onClose={this.props.onClose}>
                        {title ?? 'Are you sure?'}
                    </ModalHeader>

                    <ModalBody>
                        {alert !== null && (<Alert {...alert} />)}

                        {!send_password_reset_link &&
                            <p className="text-center">
                                {message ?? 'Are you sure you want to send a ' + emailTitle + ' to ' + this.props.user.email + '?'}
                            </p>
                        }

                        {send_password_reset_link &&
                            <div className="flex flex-col justify-center items-center">
                                <p className="mb-2">
                                    Do you want to include a Set Password link in the invite?
                                </p>
                                <Toggle
                                    value={form.send_password_reset_link}
                                    id="send_password_reset_link"
                                    error={getFieldError('send_password_reset_link')}
                                    onChange={(v) => handleInput('send_password_reset_link', v)}
                                />
                            </div>
                        }
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <DangerButton 
                            className="mr-2" 
                            text="Close" 
                            working={working}
                            onClick={this.props.onClose} 
                        />
                        <PrimaryButton 
                            working={working}
                            text="Send" 
                        />
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
};

export default FormHandler(AdminSendEmailModal);
import React from 'react';

import {Select} from 'Components/Form';

/**
 * @function BooleanFilter
 * @param {string|null} containerClassName
 * @param {function} onChange
 * @param {object} filters
 * @param {string} column
 * @param {string} label
 * @param {boolean} defaultMethod
 * @constructor
 */
const BooleanFilter = ({containerClassName, onChange, filters, column, label, defaultMethod = true}) => {
    // defaultMethod => DualityStudio\Base\Filter\QueryFilter.php -> boolean().
    // !defaultMethod => Custom filter "column" on the request.
    let column_key = defaultMethod ? column + "[boolean]" : column;

    return (
        <Select
            containerClassName={containerClassName ?? "w-28"}
            label={label}
            placeholder="Filter..."
            onChange={(v) => onChange(column_key, v)}
            value={filters && filters[column_key] ? filters[column_key] : null}
            options={[
                {
                    label: 'Yes',
                    value: 'Yes'
                },
                {
                    label: 'No',
                    value: 'No'
                }
            ]}
            isClearable={true}
        />
    );
};

export default BooleanFilter;

import React from 'react';
import {isAdminPage} from 'Services/BaseHelpers';

import Label from './Label';

/**
 * @function Checkbox
 * @param {string} labelPlacement
 * @param {string} containerClassName
 * @param {string} label
 * @param {string} id
 * @param {function} onChange
 * @param {*} value
 * @param {string} instructions
 * @param {null|array} error
 * @return {JSX.Element}
 * @constructor
 */
const Checkbox = ({labelPlacement = 'left', containerClassName, label, id, onChange, value, instructions, error = null}) => {
    let classes = `
        h-4 w-4 text-indigo-600 transition duration-150 ease-in-out appearance-none rounded-md
        border-gray-500 border hover:border-gray-600 focus:border-indigo-500
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <div className={`${containerClassName} flex items-center`}>
            {(labelPlacement === 'left') && label && (
                <Label 
                    label={label} 
                    htmlFor={id} 
                    className="mb-0 mr-4"
                    instructions={instructions}
                    error={error}
                />
            )}

            <input
                type="checkbox"
                id={id}
                checked={value}
                onChange={onChange}
                className={classes}
            />

            {(labelPlacement === 'right') && label && (
                <Label 
                    label={label} 
                    htmlFor={id} 
                    className="mb-0 ml-4"
                    instructions={instructions}
                    error={error}
                />
            )}
        </div>
    )
};

export default Checkbox;

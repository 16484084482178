import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';
import {Modal, ModalHeader, ModalBody} from 'Components/Modal';

import MedicationsTakenApi from 'Services/Api/ServiceUsers/ServiceUserMedicationsTaken';

import update from './update';

export default class Index extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Administered By',
            value: (record) => record.administered_by?.full_name,
            type: 'callback',
            orderable: null
        },
        {
            label: 'Date & Time',
            value: 'date_time',
            type: 'datetime',
            orderable: 'date_time'
        },
        {
            label: 'Notes',
            value: 'notes',
            type: 'string',
            orderable: 'notes',
            hideByDefault: true
        },
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        return [
            {
                type: "modalTrigger",
                component: update,
                props: {
                    medication_taken: item
                },
                text: 'Update'
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'medication taken',
                    itemId: item.id,
                    onDeleteApi: MedicationsTakenApi.delete
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {medication} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    Medications Taken - {medication.name}
                </ModalHeader>

                <ModalBody>
                    <IndexTable
                        columns={this.columns}
                        displaySearchBar={false}
                        loadDataCallback={(data) => MedicationsTakenApi.get(null, {
                            ...data,
                            service_user_medication_id: medication.id
                        })}
                        rowActions={this.getRowActions}
                        eventApi={MedicationsTakenApi}
                    />
                </ModalBody>
            </Modal>
        )
    }
}

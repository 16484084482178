import React from 'react';

import SocketIndicator from 'Components/Utilities/SocketIndicator';
import {PrimaryButton} from 'Components/Button';

import {Socket} from 'Services';
import AdminTestsApi from 'Services/Api/Admin/Tests';

export default class SocketTest extends React.Component {
    /**
     * @var event_id
     * @type string
     */
    event_id = Math.random();

    /**
     * @var state
     */
    state = {
        working: false,
        sent: false,
        received: false,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        Socket.getConnection()
            .private('tests')
            .listen('SocketTest', (e) => {
                if (e.id === this.event_id) {
                    this.receivedTestEvent();
                }
            });
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        Socket.getConnection().leave('tests');
    };

    /**
     * @method sendTestEvent
     */
    sendTestEvent = async () => {
        this.setState({
            working: true,
            received: false
        });

        const response = await AdminTestsApi.socket({
            id: this.event_id
        });

        if (response.status === 200) {
            this.setState({
                working: false,
                sent: true,
            });
        }
    }

    /**
     * @method receivedTestEvent
     */
    receivedTestEvent = () => {
        this.setState({
            received: true
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {working, sent, received} = this.state;

        return (
            <div className="p-4 bg-gray-300 rounded">
                <p className="font-bold flex flex-row items-center gap-4 mb-4">
                    Socket Test <SocketIndicator />
                </p>

                <PrimaryButton
                    text={received ? 
                        "Test event received - click here to resend" : 
                        sent ? 
                            "Test event sent, awaiting receipt - click here to resend" : 
                            "Send test event"}
                    onClick={this.sendTestEvent}
                    working={working}
                />
            </div>
        )
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import IdleTimer from 'react-idle-timer'

import Router from 'Components/Router';
import { Loading } from 'Components/Partials';

import User from 'Services/User';
import Settings from 'Services/Settings';
import Auth from 'Services/Api/Auth/Auth';
import Me from 'Services/Api/Me/Me';
import TwoFactor from 'Services/Api/Me/TwoFactor';
import Teams from 'Services/Api/Teams/Teams';
import { Event, Modal } from 'Services';

import 'react-toastify/dist/ReactToastify.min.css';

export default class App extends React.Component {
    /**
     * @var idle_timout (seconds)
     * @type {integer}
     */
    idle_timout = 300;

    /**
     * @constructor
     */
    constructor(props) {
        super(props);

        this.idleTimer = null;
        this.handleOnIdle = this.handleOnIdle.bind(this);
    }

    /**
     * @var state
     * @type {{loading: boolean}}
     */
    state = {
        loading: true,
        redirect: null,
        deepLink: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await Settings.refresh();

        this.loadUser();

        Auth.on('login', this.fetchUser);
        Auth.on('logout', this.logoutUser);

        Auth.on('login', this.refreshSettingsWithAlert);
        Auth.on('logout', this.refreshSettingsWithAlert);

        if (window.base.features.allow_profile_change) {
            Me.on('update', User.refresh);
        }

        if (window.base.features.avatar) {
            Me.on('update-avatar', User.refresh);
        }

        if (window.base.features.delete_account) {
            Me.on('delete', this.logoutUser);
        }

        if (!window.base.features.verify_registrations) {
            Auth.on('register', this.fetchUser);
        }

        if (window.base.features.teams) {
            Me.on('update-team', User.refresh);
            Teams.on('updated', User.refresh);
        }

        if (window.base.features.two_factor) {
            TwoFactor.on('disable', User.refresh);
            TwoFactor.on('enable', User.refresh);

            Auth.on('two-factor', User.refresh);
        }
    };

    refreshSettingsWithAlert = () => {
        Settings.refresh(true);
    }

    /**
     * @method loadUser
     * @return {Promise<void>}
     */
    loadUser = async () => {
        await User.refresh();

        let deepLink = null;

        if (User.loggedIn) {
            Event.on('api-connection-expired', this.logoutUser);
        } else if (window.location.pathname !== '' && window.location.pathname !== '/') {
            deepLink = window.location.pathname;
        }

        this.finishLoading(deepLink, null);
    }

    /**
     * @method fetchUser
     * @return {Promise<void>}
     */
    fetchUser = async () => {
        await User.refresh();

        Event.on('api-connection-expired', this.logoutUser);

        this.finishLoading(null, this.state.deepLink);
    };

    /**
     * @method finishLoading
     * @param {string} deepLink
     * @param {string} redirect
     */
    finishLoading = (deepLink = null, redirect = null) => {
        this.setState({
            loading: false,
            deepLink,
            redirect
        });
    };

    /**
     * @method logoutUser
     * @return {Promise<void>}
     */
    logoutUser = () => {
        Modal.emit('close-all');

        User.logout();

        Event.removeListener('api-connection-expired', this.logoutUser);

        this.finishLoading();
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        Auth.removeListener('login', this.fetchUser);
        Auth.removeListener('logout', this.logoutUser);

        Auth.removeListener('login', this.refreshSettingsWithAlert);
        Auth.removeListener('logout', this.refreshSettingsWithAlert);

        if (window.base.features.allow_profile_change) {
            Me.removeListener('update', User.refresh);
        }

        if (window.base.features.avatar) {
            Me.removeListener('update-avatar', User.refresh);
        }

        if (window.base.features.delete_account) {
            Me.removeListener('delete', this.logoutUser);
        }

        if (!window.base.features.verify_registrations) {
            Auth.removeListener('register', this.fetchUser);
        }

        if (window.base.features.teams) {
            Me.removeListener('update-team', User.refresh);
            Teams.removeListener('updated', User.refresh);
        }

        if (window.base.features.two_factor) {
            TwoFactor.removeListener('disable', User.refresh);
            TwoFactor.removeListener('enable', User.refresh);

            Auth.removeListener('two-factor', User.refresh);
        }
    };

    /**
     * @method handleOnIdle
     * @param {event} event
     */
    handleOnIdle = async () => {
        if (User.loggedIn && process.env.MIX_APP_ENV !== 'local') {
            await Auth.logout();

            window.location.reload();
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { loading, redirect } = this.state;

        return (
            <>
                <IdleTimer
                    ref={ ref => {
                        this.idleTimer = ref
                    } }
                    timeout={ 1000 * this.idle_timout } // In milliseconds.
                    debounce={ 250 }
                    onIdle={ this.handleOnIdle }
                />

                { loading ? <Loading/> : <Router redirect={ redirect }/> }

                <ToastContainer
                    hideProgressBar={ true }
                    closeButton={ false }
                    autoClose={ 5000 }
                />
            </>
        );
    };
}

ReactDOM.render(<App/>, document.getElementById('app'));

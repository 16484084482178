import React from 'react';

import {PrimaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {Input, FormHandler, Toggle, FileUpload} from 'Components/Form';
import HrCategoryField from 'Components/Form/HrCategoryField';

import DocumentsApi from 'Services/Api/Admin/HumanResources/Documents';

class Create extends React.Component {
    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        const { document } = this.props;

        if (document) {
            this.props.setInitialValues({
                ...document,
                categories: document.categories.map(category => category.name)
            });
        } else {
            this.props.setInitialValues({
                visible_to_user: true
            });
        }
    };

    /**
     * @method request
     * @param {object} data
     * @return {*}
     */
    request = async (data) => {
        if (this.props.document) {
            return DocumentsApi.patch({
                user: this.props.user.id,
                document: this.props.document.id
            }, data, 'update');
        } else {
            return DocumentsApi.post({
                user: this.props.user.id
            }, data, 'create');
        }
    };

    /**
     * @method handleFile
     * @param {File} file
     * @return {Promise<void>}
     */
    handleFile = async (file) => {
        if (!this.props.form.name) {
            await this.props.handleInput('name', file.name);
        }

        this.props.handleInput('file', file);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { working, form, alert, handleInput, getFieldError, handleSubmit } = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {this.props.document ? 'Update' : 'Create'} Document
                </ModalHeader>

                <form
                    onSubmit={(e) => handleSubmit(e, this.request, this.props.onClose)}
                >
                    <ModalBody>
                        {alert && <Alert {...alert} />}

                        <Input
                            label="Name"
                            value={form.name}
                            error={getFieldError('name')}
                            onChange={(v) => handleInput('name', v)}
                            containerClassName="mb-4"
                        />

                        <FileUpload
                            containerClassName="mb-4 flex flex-col justify-center items-center gap-4"
                            value={form.file}
                            error={getFieldError('file')}
                            onChange={this.handleFile}
                            dropzone={true}
                            accept="application/pdf"
                        />

                        <Toggle
                            label="Visible to User"
                            value={form.visible_to_user}
                            error={getFieldError('visible_to_user')}
                            onChange={(v) => handleInput('visible_to_user', v)}
                            containerClassName="mb-4"
                        />

                        <HrCategoryField
                            type="document"
                            value={form.categories ?? []}
                            error={getFieldError('categories')}
                            onChange={(v) => handleInput('categories', v)}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <PrimaryButton
                            text={this.props.document ? 'Update' : 'Create'}
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler(Create);

import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {PrimaryButton, DangerButton} from 'Components/Button';
import {Alert} from 'Components/Partials';

import {translation} from 'Services/TranslationHelpers';

export default class Delete extends React.Component {
    /**
     * @var state
     */
    state = {
        working: false,
        alert: null,
    }

    /**
     * @method handleDelete
     * @return {Promise<void>}
     */
    handleDelete = async () => {
        const {itemId, onDelete, onDeleteApi} = this.props;

        if (onDeleteApi) {
            this.setState({ 
                working: true,
                alert: null,
            });

            let data = {
                confirmed: true
            };

            const response = await onDeleteApi(itemId, 'updated', 204, {data});

            if (response.status !== 204) {
                this.setState({
                    working: false,
                    alert: {
                        type: 'error',
                        message: response.message
                    },
                });

                return;
            }
        } else {
            await onDelete();
        }

        this.props.onClose();
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {title, message} = this.props;
        const {alert, working} = this.state;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {title ?? translation('misc', 'are_you_sure')}
                </ModalHeader>

                <ModalBody>
                    {alert !== null && (<Alert {...alert} />)}

                    <p className="text-center">
                        {message ?? translation('misc', 'are_you_sure_delete', {
                            item: (this.props.itemName ?? 'item')
                        })}
                    </p>
                </ModalBody>

                <ModalFooter alignment="center">
                    <PrimaryButton 
                        text={translation('misc', 'cancel')} 
                        onClick={this.props.onClose}
                        working={working}
                    />

                    <DangerButton 
                        onClick={this.handleDelete} 
                        text={translation('misc', 'continue')}
                        working={working}
                    />
                </ModalFooter>
            </Modal>
        )
    }
};

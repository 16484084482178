import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { FormHandler } from "Components/Form";
import { Loading } from "Components/Partials";
import { Nav, Section, SectionQuestions } from "./Components";

import SurveySectionApi from "Services/Api/Survey/Sections";
import SurveyQuestionsApi from "Services/Api/Survey/Questions";
import SurveyResponseApi from "Services/Api/Survey/Response";

const Survey = ({ match, alert, form, handleInput, handleSubmit }) => {
    const survey = match.params.survey;

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [section, setSection] = useState(null);
    const [sections, setSections] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [sectionsCompleted, setSectionsCompleted] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        (async () => {
            const { success, data } = await SurveySectionApi.get({
                survey,
            });

            if (success) {
                setLoading(false);
                setSections(data.data);
                setSection(match.params.section ? data.data.find(s => s?.slug === match.params.section) : data.data[0]);
            } else {
                setError(true);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!section?.slug) {
                return;
            }

            setLoading(true);

            const { data, success } = await SurveyQuestionsApi.get({
                survey,
                section: section?.slug,
            }, {
                section_id: section?.id,
            });

            if (success) {
                setLoading(false);
                setQuestions(data.data);
            }
        })();
    }, [section]);

    const handleNext = () => {
        // If not the last section, go to the next section.
        if (sections[sections.length - 1]?.slug !== section?.slug) {
            setSectionsCompleted([...sectionsCompleted, section?.slug]);

            setSection(sections[sections.findIndex(s => s?.slug === section?.slug) + 1]);
        }
    };

    const handleRequest = (form) => SurveyResponseApi.post({
        survey,
    }, form);

    if (error) {
        return (
            <div className="h-screen w-screen flex items-center justify-center">
                <h1 className="text-2xl font-semibold text-gray-900">
                    Survey not found
                </h1>
            </div>
        );
    }

    return (
        <div className="mx-auto max-w-7xl w-full py-8">
            {submitted && (
                <Section>
                    <h1 className="text-2xl font-semibold text-gray-900 text-center">
                        Thank you for completing the survey!
                    </h1>
                </Section>
            )}

            {!submitted && (
                <>
                    {loading && (
                        <Loading />
                    )}

                    {!loading && (
                        <>
                            <div className="grid grid-cols-5 gap-4">
                                <Nav
                                    sectionsCompleted={sectionsCompleted}
                                    activeSection={section}
                                    sections={sections}
                                    handleSelectSection={setSection}
                                />

                                <div className="col-span-4">
                                    <form onSubmit={(e) => handleSubmit(e, handleRequest, () => setSubmitted(true))}>
                                        <Section>
                                            {section && (
                                                <SectionQuestions
                                                    alert={alert}
                                                    questions={questions}
                                                    form={form}
                                                    handleInput={handleInput}
                                                    survey={survey}
                                                    section={section}
                                                    handleNext={handleNext}
                                                    isLast={sections[sections.length - 1]?.slug === section?.slug}
                                                />
                                            )}
                                        </Section>
                                    </form>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default withRouter(FormHandler(Survey));

import {Event, User} from 'Services';
import Socket from 'Services/Socket';

export default new class ActiveUsersService {
    /**
     * @var connected
     * @type {boolean}
     */
    connected = false;

    /**
     * @var activeUsers
     * @type {array}
     */
    activeUsers = [];

    /**
     * @method getConnection
     */
    subscribe = () => {
        if (!this.connected) {
            Socket.getConnection().join('networking_present')
                .here((users) => {
                    this.setActiveUsers(users);
                })
                .joining((user) => {
                    this.addActiveUser(user);
                })
                .leaving((user) => {
                    this.removeActiveUser(user);
                });

            this.connected = true;
        }
    };

    /**
     * @method unsubscribe
     */
    unsubscribe = () => {
        if (this.connected) {
            Socket.getConnection().leave('networking_present');
        }
    }

    /**
     * @method getActiveUsers
     * @return {array}
     */
    getActiveUsers = () => {
        this.subscribe();
        return this.activeUsers;
    }

    /**
     * @method getActiveUserIds
     * @return {array}
     */
    getActiveUserIds = () => {
        this.subscribe();
        return this.activeUsers.map(user => user.id);
    }

    /**
     * @method setActiveUsers
     * @param {object} users
     */
    setActiveUsers = (users) => {
        this.activeUsers = users.filter(o => o.id !== User.id);

        Event.emit('networking-active-users');
    }

    /**
     * @method addActiveUser
     * @param {object} user
     */
    addActiveUser = (user) => {
        if (user.id !== User.id) {
            this.activeUsers.push(user);

            Event.emit('networking-active-users');
        }
    }

    /**
     * @method removeActiveUser
     * @param {object} user
     */
    removeActiveUser = (user) => {
        this.activeUsers = this.activeUsers.filter(o => o.id !== user.id);

        Event.emit('networking-active-users');
    }
}

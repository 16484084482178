import React from 'react';
import {Redirect} from 'react-router-dom';

import User from 'Services/User';

/**
 * @function ActiveTeam
 * @param {*|JSX.Element} component
 * @param {string[]} parameters
 * @return {*|JSX.Element}
 * @constructor
 */
const ActiveTeam = ({component, parameters}) => {
    const result = User.loggedIn && User.data.active_team?.name === parameters[0];

    return result ? component : <Redirect to="/" />;
};

export default ActiveTeam;

import React from 'react';
import { withRouter, Link } from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import { PrimaryButton } from "Components/Button";

class ResponsesView extends React.Component {
    /**
     * @var id
     */
    surveyId = this.props.match.params?.survey ?? null;

    /**
     * @var id
     */
    responseId = this.props.match.params?.response ?? null;

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Survey - Responses">
                <div className="flex justify-center mb-8">
                    <Link to={`/admin/surveys/${this.surveyId}/responses`}>
                        <PrimaryButton
                            text="Back"
                            onClick={this.handleExport}
                        />
                    </Link>
                </div>

                <embed
                    src={`/api/v1/admin/surveys/${this.surveyId}/responses/${this.responseId}/export`}
                    type="application/pdf"
                    className="w-full max-w-4xl mx-auto h-[60rem]"
                />
            </AdminLayout>
        );
    }
}

export default withRouter(ResponsesView);

import React from 'react';
import profanity from 'leo-profanity';

import {isAdminPage} from 'Services/BaseHelpers';

import Label from './Label';

const Input = React.forwardRef(({
    containerClassName,
    className,
    type,
    label,
    placeholder,
    id,
    onChange,
    value,
    readOnly,
    instructions,
    error = null,
    labelTextColor = null,
    profanityCheck = false
}, ref) => {
    let classes = `
        block w-full border py-2 px-3 focus:outline-none
        sm:text-sm bg-app-backup bg-opacity-10 rounded-full
        ${error ? 'border-red-800' : 'border-app-backup border-opacity-10'}
        ${className && className.includes('mt-0') ? '' : 'mt-1'}
        ${className}
    `;

    if (!isAdminPage()) {
        // overwrite classes as required for non-admin pages.
    }

    return (
        <div className={containerClassName}>
            {label && (
                <Label 
                    label={label} 
                    htmlFor={id} 
                    instructions={instructions}
                    textColor={labelTextColor}
                    error={error}
                />
            )}

            <input
                ref={ref}
                readOnly={readOnly}
                type={type}
                id={id}
                value={value ?? ''}
                onChange={(e) => {
                    if (type === 'file') {
                        onChange(e.target.files);
                    } else {
                        onChange(profanityCheck ? profanity.clean(e.target.value, '') : e.target.value);
                    }
                }}
                placeholder={placeholder}
                className={classes}
            />
        </div>
    );
});

export default Input;

import React from 'react';
import TimeAgo from 'react-timeago';

import {PaginationScroll} from 'Components/Partials';
import {ProfilePic, isSameUser, isSameUserCheck, isThisUser} from 'Components/Networking';

import {User, Socket} from 'Services';
import ChatsApi from 'Services/Api/Networking/Chats';
import {translation} from 'Services/TranslationHelpers';
import {mergeObjectsIntoPaginationData} from 'Services/BaseHelpers';

export default class Chat extends React.Component {
    /**
     * @var displayProfilePic
     * @type {boolean}
     */
    displayProfilePic = true;

    /**
     * @var displayTimestamp
     * @type {boolean}
     */
    displayTimestamp = true;

    /**
     * @var state
     * @type {{messages: null|object}}
     * @type {{new_message: string}}
     */
    state = {
        messages: null,
        new_message: '',
        scrollToStart: false,
    }

    /**
     * @method constructor
     */
    constructor() {
        super();
        this.newMessageRef = React.createRef();
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.subscribeToBroadcasts();
    };

    /**
     * @method componentDidUpdate
     */
    componentDidUpdate() {
        // Commented out by default as most clients don't want this.
        // this.focusOnNewMessageInput();
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.unsubscribeFromBroadcasts();
    }

    /**
     * @method subscribeToBroadcasts
     */
    subscribeToBroadcasts = () => {
        // Listen for new messages.
        Socket.getConnection().private('networking.' + User.id)
            .listen('NetworkingMessagePosted', (e) => {
                this.handleNewMessage(e.message);
            });
    }

    /**
     * @method unsubscribeFromBroadcasts
     */
    unsubscribeFromBroadcasts = () => {
        // Stop listening for new messages.
        Socket.getConnection().leave('networking.' + User.id);
    }

    /**
     * @method handleNewMessage
     * @param {object} message
     */
    handleNewMessage = (message) => {
        const {selected_user} = this.props;
        const {messages} = this.state;

        if (selected_user) {
            if ((selected_user.type === "App\\Models\\NetworkingGroup" && isSameUser(selected_user, message.message_to))
                || (isSameUser(selected_user, message.user_from) && isThisUser(message.message_to))
                || (isThisUser(message.user_from) && isSameUser(selected_user, message.message_to)))
            {
                this.setState({
                    messages: mergeObjectsIntoPaginationData(messages, [message]),
                    scrollToStart: true
                });
            }
        }
    }

    /**
     * @method focusOnNewMessageInput
     */
    focusOnNewMessageInput = () => {
        if (this.newMessageRef.current !== null) {
            this.newMessageRef.current.focus();
        }
    }

    /**
     * @method sendMessage
     */
    sendMessage = async () => {
        const {selected_user, additional_key} = this.props;
        const {new_message} = this.state;

        if (new_message !== null && new_message !== '') {
            // Set new_message to empty straight away so the message can't be sent twice by accident
            this.setState({
                new_message: '',
            });

            const response = await ChatsApi.postMessage(
                selected_user.type,
                selected_user.id,
                new_message,
                additional_key
            );

            this.handleNewMessage(response.data.data);
        }
    }

    /**
     * @method handleNewMessageKeyDown
     * @param {event} e
     */
    handleNewMessageKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.sendMessage();
        }
    }

    /**
     * @method sortMessages
     * @param {object} message_a
     * @param {object} message_b
     * @return {integer}
     */
    sortMessages = (message_a, message_b) => {
        // Order by message created_at date.
        var x = new Date(message_a.created_at.iso_string);
        var y = new Date(message_b.created_at.iso_string);

        return y - x;
    }

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {selected_user, fullScreen} = this.props;

        return (
            <div className={fullScreen ? "bg-networking-primary-text border border-networking-primary h-full" : "bg-gray-400 p-4 rounded-lg h-full max-h-96"}>
                {!selected_user &&
                    <div className="mt-3 p-4 text-center font-bold border-0">
                        {translation('networking_frontend', 'connect_message')}
                    </div>
                }

                {selected_user && this.renderSelectedChat()}
            </div>
        )
    }

    /**
     * @method renderSelectedChat
     * @return {*}
     */
    renderSelectedChat() {
        const {new_message} = this.state;
        const {selected_user, fullScreen} = this.props;

        return (
            <div className='flex h-full flex-col justify-between'>
                {(selected_user.type === "App\\Models\\User" || selected_user.type === "App\\Models\\NetworkingGroup") &&
                    <div className="border-b-2 w-full">
                        <div className="flex px-4 py-3">
                            <div className="my-auto mr-3">
                                <ProfilePic user={selected_user} />
                            </div>

                            <div>
                                <p className="font-bold text-networking-secondary-text">
                                    {selected_user.name}
                                </p>

                                {selected_user.subtitle && selected_user.subtitle !== '' &&
                                    <p className="mt-2 text-sm">
                                        {selected_user.subtitle}
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                }

                {this.renderMessages()}

                <div className={`p-4 flex border-t-2 ${fullScreen ? 'h-48' : 'h-36'}`}>
                    <input
                        value={new_message}
                        onChange={(e) => this.setState({'new_message': e.target.value})}
                        placeholder={translation('networking_frontend', 'new_message')}
                        onKeyDown={this.handleNewMessageKeyDown}
                        ref={this.newMessageRef}
                        className="bg-networking-secondary border-0 focus:outline-none
                    		focus:border-0 focus:ring-0 outline-none w-full px-4"
                    />

                    <button
                        className="w-24 bg-networking-message"
                        onClick={() => this.sendMessage()}
                    >
                        {translation('networking_frontend', 'send_text')}
                    </button>
                </div>
            </div>
        )
    }

    /**
     * @method renderMessages
     * @return {*}
     */
    renderMessages() {
        const {messages, scrollToStart} = this.state;
        const {selected_user, additional_key} = this.props;

        return (
            <PaginationScroll
                scrollDown={false}
                apiCall={(page) => ChatsApi.getChat(selected_user.type, selected_user.id, page, additional_key)}
                updateData={(data) => this.setState({messages: data})}
                data={messages}
                containerClassName="p-4"
                scrollToStart={scrollToStart}
                scrollToStartCallback={(v) => this.setState({scrollToStart: v})}
                key={selected_user.type + '_' + selected_user.id}
            >
                {messages && messages.data.length === 0 &&
                    <div className="text-center mt-4">
                        <p className="font-bold">
                            {translation('networking_frontend', 'selected_chat_no_user')}
                        </p>
                    </div>
                }

                {messages && messages.data.sort((a, b) => {
                    return this.sortMessages(a, b)
                })
                    .map((message, i) => (this.renderMessage(message)))
                    .reverse()}
            </PaginationScroll>
        );
    }

    /**
     * @method renderMessage
     * @param {object} message
     * @return {*}
     */
    renderMessage(message) {
        const {selected_user, fullScreen} = this.props;

        const fromSelf = (User.id === message.user_from_id);

        return (
            <div key={message.id}>
                <div
                    className={`
                        message-container flex
                        ${fromSelf ? 'flex-row-reverse' : ''}
                    `}
                >
                    {this.displayProfilePic && fullScreen &&
                        <div className="mx-2">
                            <ProfilePic
                                user={message.user_from}
                                hide_active_status={fromSelf}
                                size="small"
                            />
                        </div>
                    }

                    <div
                        className={`
                            p-4 m-1 rounded-2xl border
                            ${fullScreen ? 'w-3/5' : 'w-full'}
                            ${fromSelf ?
                                'bg-networking-active border-networking-active text-networking-primary-text rounded-br-none' :
                                'bg-networking-secondary border-networking-secondary text-networking-secondary-text rounded-bl-none'}
                        `}
                    >
                        <p>
                            {message.message}
                        </p>

                        <div className={`mt-2 flex justify-between ${fullScreen ? 'flex-row' : 'flex-col flex-col-reverse'}`}>
                            {this.displayTimestamp &&
                                <div className={`text-xs self-start ${fullScreen ? '' : 'mt-1'}`}>
                                    <TimeAgo date={message.created_at.iso_string}/>
                                </div>
                            }

                            {message.message_to_type !== "App\\Models\\User" && !fromSelf &&
                                <div className={`text-xs justify-self-end ${fullScreen ? 'ml-1' : ''}`}>
                                    {message.user_from.name}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import PushNotificationModal from 'Components/Partials/Modals/PushNotificationModal'
import {RenderWYSIWYG} from 'Components/Form';

import Auth from 'Services/Api/Auth/Auth';
import PushNotificationApi from 'Services/Api/PushNotification/PushNotification';
import {Modal, Socket, User, Settings, Event} from 'Services';
import { translationFromJson } from 'Services/TranslationHelpers2';

export default class PushNotifications extends React.Component {
    /**
     * @var state
     * @type {{pushNotification: null}}
     */
    state = {
        pushNotification: null,
        preview: false,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        if (User.loggedIn) {
            this.fetchPushNotifications();
            this.subscribeToPushNotifications();
        }

        Auth.on('login', this.fetchPushNotifications);
        Auth.on('login', this.subscribeToPushNotifications);
        Auth.on('logout', this.unsubscribeFromPushNotifications);

        Event.on('admin-push-notification-preview', this.handlePreview);
    };

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        this.unsubscribeFromPushNotifications();

        Auth.removeListener('login', this.fetchPushNotifications);
        Auth.removeListener('login', this.subscribeToPushNotifications);
        Auth.removeListener('logout', this.unsubscribeFromPushNotifications);

        Event.removeListener('admin-push-notification-preview', this.handlePreview);
    }

    /**
     * @method fetchPushNotifications
     */
    fetchPushNotifications = async () => {
        const request = await PushNotificationApi.get(null);

        if (request.success) {
            request.data.data.map((pushNotification, i) => {
                this.postPushNotification(pushNotification);
            });
        }
    };

    /**
     * @method subscribeToPushNotifications
     */
    subscribeToPushNotifications = () => {
        Socket.getConnection().private('push_notifications')
            .listen('SendPushNotification', (e) => {
                this.postPushNotification(e.pushNotification);
            });
    }

    /**
     * @method unsubscribeFromPushNotifications
     */
    unsubscribeFromPushNotifications = () => {
        Socket.getConnection().leave('push_notifications');
    }

    /**
     * @method postPushNotification
     * @param {object} pushNotification
     * @param {boolean} preview
     */
    postPushNotification = (pushNotification, preview = false) => {
        let item_name = 'push_notification_' + pushNotification.id + '_' + User.id;

        if (!pushNotification.supported_languages.includes(Settings.data.language)) {
            return;
        }

        if (!pushNotification.display_once_per_user || !localStorage.getItem(item_name)) {
            if (pushNotification.type === 'modal') {
                Modal.open({
                    component: PushNotificationModal,
                    props: {
                        pushNotification: pushNotification,
                        preview,
                    }
                });
            } else if (pushNotification.type === 'inline') {
                this.setState({
                    pushNotification,
                    preview
                });
            }
        }
    }

    /**
     * @method handleDismiss
     */
    handleDismiss = () => {
        const {pushNotification, preview} = this.state;

        if (!preview) {
            let item_name = 'push_notification_' + pushNotification.id + '_' + User.id;

            localStorage.setItem(item_name, new Date());
        }

        this.setState({
            pushNotification: null
        });
    }

    /**
     * @method handlePreview
     * @param {object} pushNotification
     */
    handlePreview = (pushNotification) => {
        this.postPushNotification(pushNotification, true);
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {pushNotification} = this.state;

        if (!pushNotification || pushNotification.type !== 'inline') {
            return null;
        }

        return (
            <div className="absolute top-0 w-full bg-white flex items-center justify-center py-4 px-12 text-black border-2 border-black text-2xl z-20">
                <div
                    className="cursor-pointer rounded-full w-8 h-8 bg-white text-black flex items-center 
                        justify-center absolute top-4 right-4 transition duration-200 transform hover:scale-110"
                    onClick={this.handleDismiss}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </div>

                <div>
                    <p className="text-center">
                        {translationFromJson(pushNotification.title)}
                    </p>

                    {pushNotification.content &&
                        <RenderWYSIWYG content={translationFromJson(pushNotification.content).replace('target="_blank"', '')} />
                    }
                </div>
            </div>
         );
    }
}
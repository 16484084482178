import ApiBase from '../../../ApiBase';

class Field extends ApiBase {
    route = '/admin/cms/elements/fields';

    /**
     * @method indexAvailableFieldTypes
     * @return {Promise<*>}
     */
    indexAvailableFieldTypes = () => {
        return this.get(this.route + '/index-available-field-types');
    };

    /**
     * @method updateOrder
     * @param {object} data
     * @return {Promise<*>}
     */
    updateOrder = (data) => {
        return this.patch(this.route + '/update-order', data);
    };
}

export default new Field();

import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {DangerButton} from 'Components/Button';
import CreateCmsElementRecord from 'Components/Utilities/CreateCmsElementRecord';

import {translation} from 'Services/TranslationHelpers';

export default class CreateCmsElementRecordModal extends React.Component {
    /**
     * @var state
     */
    state = {
        title: null,
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {
            onClose,
            showElementsDropdown,
            element_id,
            element_record_id,
            service_user_id,
            clone,
            date_of_observation,
            attach_to_service_user = null,
            health_and_safety = null,
            quality_assurance = null,
            medication_management = null,
        } = this.props;
        const {title} = this.state;

        return (
            <Modal size="max-w-screen-lg">
                <ModalHeader onClose={onClose}>
                    <span className="capitalize">
                        {title ?? translation('narratives', 'create')}
                    </span>
                </ModalHeader>

                <ModalBody>
                    <CreateCmsElementRecord
                        showElementsDropdown={showElementsDropdown}
                        element_id={element_id}
                        element_record_id={element_record_id}
                        admin={false}
                        setTitleCallback={(v) => this.setState({title: v})}
                        service_user_id={service_user_id}
                        onSuccess={onClose}
                        clone={clone}
                        date_of_observation={date_of_observation}
                        attach_to_service_user={attach_to_service_user}
                        health_and_safety={health_and_safety}
                        quality_assurance={quality_assurance}
                        medication_management={medication_management}
                    />
                </ModalBody>

                <ModalFooter alignment="center">
                    <DangerButton text={translation('misc', 'close')} onClick={onClose} />
                </ModalFooter>
            </Modal>
        )
    }
}

import {EventEmitter} from 'events';

import Me from './Api/Me/Me';

import {getAvatarUrl} from './AuthHelpers';

class User extends EventEmitter {
    /**
     * @var data
     * @type {null|object}
     */
    data = null;

    /**
     * @method loggedIn
     * @return {boolean}
     */
    get loggedIn () {
        return this.data !== null;
    };

    /**
     * @method id
     * @return {string}
     */
    get id () {
        return this.data !== null ? this.data.id : null;
    }

    /**
     * @method id
     * @return {string}
     */
    get isAdmin () {
        return this.data !== null ? this.data.is_admin === true : null;
    };

    /**
     * @method refresh
     * @return {Promise<void>}
     */
    refresh = async () => {
        const request = await Me.getMe();

        this.data = request.success ? request.data.data : null;

        this.emit('change');
    };

    /**
     * @method logout
     */
    logout = () => {
        this.emit('change');

        this.data = null;
    };

    /**
     * @method getAvatarUrl
     * @return {string|*}
     */
    getAvatarUrl = () => {
        return getAvatarUrl(this.data);
    };

    /**
     * @method hasScope
     * @param {array|string} scopes
     * @return {boolean}
     */
    hasScope = (scopes) => {
        if (this.data.is_admin) {
            return true;
        }

        // If no scopes then return false.
        if (!this.data.scopes || this.data.scopes.length === 0) {
            return false;
        }

        // Convert string scope to an array.
        scopes = typeof scopes === 'string' ? [scopes] : scopes;

        // If scope match then return true.
        if (scopes.filter(scope => this.data.scopes.includes(scope)).length > 0) {
            return true;
        }

        // Check for scope-prefix match.
        for (const scope of scopes) {
            for (const scope2 of this.data.scopes) {
                if (scope2.indexOf('.*') !== -1) {
                    const prefix = scope.split('.')[0];
                    const prefix2 = scope2.split('.')[0];

                    if (prefix === prefix2) {
                        return true;
                    }
                }
            }
        }

        // No scope match so return false.
        return false;
    };

    /**
     * @method belongsToGroup
     * @param {string} group_name
     * @return {boolean}
     */
    belongsToGroup = (group_name) => {
        if (this.data.is_admin) {
            return true;
        }

        if (this.data.all_groups) {
            let names = this.data.all_groups.map((group) => group.name);

            return names.includes(group_name);
        }

        return false;
    }
}

export default new User;

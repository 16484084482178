import React from 'react';

import AdminLayout from 'Pages/Admin/AdminLayout';

import Table from 'Components/HumanResources/Timesheets/Table';

export default class Holidays extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout>
                <Table
                    type='holiday'
                    title={window.base.hr_timesheet_types['holiday']?.label}
                    admin={true}
                    userId={this.props.match?.params?.user ?? null}
                />
            </AdminLayout>
        );
    }
}

import ApiBase from 'Services/Api/ApiBase';

class Documents extends ApiBase {
    route = '/service-users/:user/documents';

    //add delete method
    deleteDocuments = async ( document, user ) => {
        return await this.delete ( {
            route: ':document',
            user: user,
            document: document
        } );
    }

    getFamilyDocuments = async ( user,data ) => {
        return await this.get ( '/service-users/service-users-family/' + user + '/documents/', data );
    }
}
export default new Documents();

import React from 'react';

import {faBellSlash} from '@fortawesome/free-solid-svg-icons/faBellSlash';

import {NotificationSettings} from 'Components/Comms';

import SettingsPage from '../SettingsPage';

export default class NotificationSettingsPage extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <SettingsPage title="Notifications Settings" titleIcon={faBellSlash}>
                <div className="p-6">
                    <NotificationSettings
                        admin={false}
                    />
                </div>
            </SettingsPage>
        );
    }
}
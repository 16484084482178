import React from 'react';

import AdminLayout from 'Pages/Admin/AdminLayout';

import ViewCmsElementRecords from 'Components/Utilities/ViewCmsElementRecords';

export default class Index extends React.Component {

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="CMS Records">
                <ViewCmsElementRecords />
            </AdminLayout>
        );
    }
}

import React from 'react';

import Unauthenticated from 'Components/Layouts/Unauthenticated';
import AuthLayout from 'Components/Layouts/AuthLayout';
import {FormHandler, Input} from 'Components/Form';

import Auth from 'Services/Api/Auth/Auth';

class Request extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Your password reset email is on the way';

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <Unauthenticated containerClassName="space-y-8 md:space-y-0 flex flex-col md:flex-row h-full md:justify-between p-8 md:px-24">
                <AuthLayout
                    title="Request a password reset"
                    backLink="/login"
                    backLinkText="Back to login"
                    submitText="Reset"
                    onSubmit={(e) => handleSubmit(e, Auth.requestPasswordEmail, this.success, true)}
                    working={working}
                    alert={alert}
                >
                    <div>
                        <Input
                            label="Email"
                            type="email"
                            value={form.email}
                            onChange={v => handleInput('email', v)}
                        />
                    </div>
                </AuthLayout>
            </Unauthenticated>
        );
    }
}

export default FormHandler(Request);

import React from 'react';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, FileUpload} from 'Components/Form';
import {Alert} from 'Components/Partials';

import {User} from 'Services';
import {getFallbackAvatarUrl} from 'Services/AuthHelpers';
import Me from 'Services/Api/Me/Me';

import SettingsPage from '../SettingsPage';

class Avatar extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Your avatar has been updated successfully';

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.props.setForm({
            avatar_path: User.data.avatar_path
        });
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {form, working, alert, handleInput, handleSubmit} = this.props;

        return (
            <SettingsPage>
                <form
                    autoComplete="off"
                    className="divide-y divide-gray-200 lg:col-span-9"
                    onSubmit={(e) => handleSubmit(e, Me.updateAvatar, this.success)}
                >
                    <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">Avatar</h2>
                    </div>

                    <div className="p-6">
                        {alert !== null && (<Alert {...alert} />)}

                        <FileUpload
                            containerClassName="flex flex-row justify-start items-center gap-4"
                            value={form.avatar_path}
                            onChange={v => handleInput('avatar_path', v)}
                            accept="image/*"
                            placeholder="Change"
                            fallbackImage={getFallbackAvatarUrl(User.data)}
                        />
                    </div>

                    <div className="p-6 flex justify-end">
                        <PrimaryButton
                            text="Save"
                            working={working}
                        />
                    </div>
                </form>
            </SettingsPage>
        );
    }
}

export default FormHandler(Avatar);

import React from 'react';
import isEqual from 'lodash/isEqual';

import {Input, Textarea, Select, Toggle, WYSIWYG, Label} from 'Components/Form';

export default class IsTranslatableWrapper extends React.Component {
    /**
     * @var supportedComponents
     */
    supportedComponents = {
        Input: Input,
        Textarea: Textarea,
        WYSIWYG: WYSIWYG,
    };

    /**
     * @var state
     * @type {{selected_language: string}}
     */
    state = {
        selected_language: this.props.supported_languages ?
            this.props.supported_languages[0] :
            null
    };

    /**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        // Reset the selected_language each time the supported_languages change.
        if (!isEqual(prevProps.supported_languages, this.props.supported_languages)) {
            this.setState({
                selected_language: this.props.supported_languages ?
                    this.props.supported_languages[0] :
                    null
            })
        }
    }

    /**
     * @method handleChange
     * @param {string} value
     */
    handleChange = (new_value) => {
        const {supported_languages, onChange, value} = this.props;
        const {selected_language} = this.state;

        if (selected_language === 'all') {
            let new_values = {
                'all': new_value
            };

            supported_languages.forEach((language) => {
                new_values[language] = new_value;
            });

            onChange(new_values);
        } else {
            onChange({
                ...value,
                [selected_language]: new_value,
            });
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const {
            containerClassName,
            label,
            instructions,
            error = null,
            supported_languages,
            value,
            id,
            childComponent,
            childProps} = this.props;

        const {selected_language} = this.state;

        if (!supported_languages) {
            return null;
        }

        const ChildComponent = this.supportedComponents[childComponent];

        let languages = Object.entries(window.base.languages)
            .filter(value => supported_languages.indexOf(value[0]) !== -1)
            .map((value, key) => {
                return {
                    label: value[1],
                    value: value[0]
                };
            });

        languages.unshift({
            label: 'Same Value For All Languages',
            value: 'all'
        });

        return (
            <div className={containerClassName ?? (languages.length > 2 ? 'p-4 bg-gray-400' : '')}>
                {label && (
                    <Label
                        label={label + (languages.length > 2 ? ' - Enter a value below for each language' : '')}
                        htmlFor={id}
                        textColor={languages.length > 2 ? 'text-white' : null}
                        instructions={instructions}
                        error={error}
                    />
                )}

                {languages.length > 2 &&
                    <Select
                        containerClassName="mb-2 w-full"
                        id={id}
                        value={selected_language}
                        error={error}
                        onChange={(v) => this.setState({selected_language: v})}
                        options={languages}
                    />
                }

                {selected_language &&
                    <div className="w-full">
                        <ChildComponent
                            {...childProps}
                            value={value && value[selected_language] ? value[selected_language] : null}
                            id={id + '_' + selected_language}
                            error={error}
                            onChange={(v) => this.handleChange(v)}
                        />
                    </div>
                }
            </div>
        );
    }
};

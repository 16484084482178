import React from 'react';
import {SketchPicker} from 'react-color';

import Label from './Label';

export default class ColourPicker extends React.Component {
    /**
     * @var state
     * @type {{display: boolean}}
     */
    state = {
        display: false
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {display} = this.state;

        const {
            labelPlacement = 'left', 
            containerClassName, 
            label, 
            id, 
            onChange, 
            value, 
            instructions,
            editable = true,
            error = null} = this.props;

        return (
            <div className={`${containerClassName} flex items-center`}>
                {(labelPlacement === 'left') && label && (
                    <Label 
                        label={label} 
                        htmlFor={id} 
                        className="mb-0 mr-4"
                        instructions={instructions}
                        error={error}
                    />
                )}

                {!display &&
                    <div
                        className="w-10 h-10 border cursor-pointer"
                        style={{backgroundColor: value}}
                        onClick={() => editable ? this.setState({display: true}) : null}
                    />
                }

                {display &&
                    <div className="absolute">
                        <SketchPicker
                            id={id}
                            color={value ?? '#ffffff'}
                            onChangeComplete={(color) => {
                                onChange(color.hex);
                                this.setState({display: false});
                            }}
                        />
                    </div>
                }

                {(labelPlacement === 'right') && label && (
                    <Label 
                        label={label} 
                        htmlFor={id} 
                        className="mb-0 ml-4"
                        instructions={instructions}
                        error={error}
                    />
                )}
            </div>
        );
    }
}
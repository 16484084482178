import React from 'react';

import CreateTimesheetModal from 'Components/HumanResources/Timesheets/CreateTimesheetModal';
import {HrTitle} from 'Components/HumanResources/AdminComponents';
import IndexTable from 'Components/HumanResources/Timesheets/IndexTable';

import {getColumns, getRecordActions} from 'Services/HrHelpers';
import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';

import Base from 'Components/HumanResources/Timesheets/Base';
import User from "Services/User";

export default class Table extends Base {
    /**
     * @var state
     */
    state = {
        user: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        if (this.props.admin && this.props.userId) {
            await this.fetchUser();
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {type, title, admin, userId, service_user_id, defaultParams, showTotal} = this.props;
        const {user} = this.state;

        let columns = getColumns(admin, type);

        if (userId) {
            columns = columns.filter((c) => c.label !== 'Employee');
        }

        if (admin) {
            var callback = (data) => AdminTimesheetsApi.get(null, {
                ...data,
                ...defaultParams,
                paginate: true,
                employee_id: userId,
                service_user_id,
                type
            });
        } else {

            const url = User.belongsToGroup('Service User Family') && !User.isAdmin ? 'service-users-family' : null;
            var callback = (data) => TimesheetsApi.get(url, {
                ...data,
                ...defaultParams,
                paginate: true,
                service_user_id,
                type
            });
        }

        return (
            <div>
                {admin && title &&
                    <div className="mb-6 p-6 border-b border-gray-20">
                        <HrTitle user={user} title={title} />
                    </div>
                }

                <IndexTable
                    columns={columns}
                    displaySearchBar={false}
                    storeModal={admin ? {
                        component: CreateTimesheetModal,
                        props:{
                            type: type ?? 'time_worked',
                            admin: this.props.admin,
                        }
                    } : null}
                    actionsJustify={admin ? 'between' : 'center'}
                    loadDataCallback={callback}
                    rowActions={(record) => getRecordActions(record, admin)}
                    rowActionsLayout="row"
                    eventApi={admin ? AdminTimesheetsApi : TimesheetsApi}
                    showTotal={showTotal}
                />
            </div>
        );
    }
}

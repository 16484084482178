import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight, faCaretUp, faCaretDown, faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';

import Label from 'Components/Form/Label';
import {Loading} from 'Components/Partials';

import {asset} from 'Services/BaseHelpers';

export default class BodyMap extends React.Component {
    /**
     * @var images
     * @type object
     */
    images = {
        'body-front': '/images/BodyMap/body-front.png',
        'body-rear': '/images/BodyMap/body-rear.png',
        'head': '/images/BodyMap/head.png',
    };

    /**
     * @var state
     * @type {{loading: boolean}}
     */
    state = {
        loading: false,
        currentIndex: null,
        imageIndex: 'body-front',
    };

    /**
     * @method addIndex
     * @param {integer} index
     */
    addIndex = () => {
        const {value, onChange} = this.props;

        onChange([
            ...value ?? [],
            {
                image: this.state.imageIndex,
                top: 50,
                left: 50,
                size: 1
            }
        ]);

        this.setState({
            currentIndex: value?.length ?? 0,
        });
    }

    /**
     * @method removeIndex
     * @param {integer} index
     */
    removeIndex = (index) => {
        let { value, onChange } = this.props;

        value.splice(index, 1);

        onChange(value);

        this.setState({
            currentIndex: (this.state.currentIndex - 1) > -1 ? this.state.currentIndex - 1 : null,
        });
    }

    /**
     * @method handleNextImage
     */
    handleNextImage = () => {
        const { imageIndex } = this.state;
        const imageKeys = Object.keys(this.images);
        const currentIndex = imageKeys.indexOf(imageIndex);
        const nextIndex = currentIndex + 1 >= imageKeys.length ? 0 : currentIndex + 1;

        this.setState({
            imageIndex: imageKeys[nextIndex],
        });
    };

    /**
     * @method handlePreviousImage
     */
    handlePreviousImage = () => {
        const { imageIndex } = this.state;
        const imageKeys = Object.keys(this.images);
        const currentIndex = imageKeys.indexOf(imageIndex);
        const nextIndex = currentIndex - 1 < 0 ? imageKeys.length - 1 : currentIndex - 1;

        this.setState({
            imageIndex: imageKeys[nextIndex],
        });
    };

    /**
     * @method handleSizeChange
     * @param {boolean} plus
     */
    handleSizeChange = (plus) => {
        let {value, onChange} = this.props;
        const {currentIndex} = this.state;

        if (plus) {
            value[currentIndex].size = value[currentIndex].size + 0.2;
        } else {
            value[currentIndex].size = value[currentIndex].size - 0.2;
        }

        onChange(value);
    }

    /**
     * @method handleImageClick
     * @param {event} e
     */
    handleImageClick = (e) => {
        let {value, onChange} = this.props;
        const {currentIndex} = this.state;

        if (currentIndex === null) {
            return null;
        }

        value[currentIndex].top = e.nativeEvent.offsetY;
        value[currentIndex].left = e.nativeEvent.offsetX;

        onChange(value);
    };

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {containerClassName, label, id, instructions, error = null} = this.props;
        const {loading} = this.state;

        return (
            <div className={`w-full select-none ${containerClassName}`}>
                {label && (
                    <Label
                        label={label}
                        htmlFor={id}
                        instructions={instructions}
                        error={error}
                    />
                )}

                {loading && <Loading />}

                {!loading && this.renderBodyMap()}
            </div>
        );
    }

    /**
     * @method renderBodyMap
     * @return {*}
     */
    renderBodyMap() {
        const { imageIndex } = this.state;
        const { value } = this.props;

        return (
            <div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
                    {this.renderIndexSelector()}
                    {this.renderPointSizeControl()}
                </div>

                <div className="flex flex-col sm:flex-row justify-between items-center select-none">
                    <div
                        className="cursor-pointer transform transition duration-200 hover:scale-110 text-light-blue-700"
                        onClick={this.handlePreviousImage}
                    >
                        <FontAwesomeIcon icon={faCaretLeft} size="3x" className="hidden sm:flex"/>
                        <FontAwesomeIcon icon={faCaretUp} size="3x" className="sm:hidden"/>
                    </div>

                    <div className="relative">
                        <img
                            src={asset(this.images[imageIndex])}
                            className="max-h-96 max-w-lg mx-auto"
                            onClick={this.handleImageClick}
                        />

                        {value?.map((o, i) => {
                            if (o.image !== imageIndex) {
                                return null;
                            }

                            return (
                                <div
                                    onClick={() => this.setState({currentIndex: i})}
                                    key={`point-${i}`}
                                    className={`absolute rounded bg-red-500 ${i === this.state.currentIndex ? 'ring-2 ring-red-500 ring-offset-2' : ''}`}
                                    style={{
                                        top: `${o.top}px`,
                                        left: `${o.left}px`,
                                        height: `${o.size}rem`,
                                        width: `${o.size}rem`,
                                    }}
                                />
                            );
                        })}
                    </div>

                    <div
                        className="cursor-pointer transform transition duration-200 hover:scale-110 text-light-blue-700"
                        onClick={this.handleNextImage}
                    >
                        <FontAwesomeIcon icon={faCaretRight} size="3x" className="hidden sm:flex"/>
                        <FontAwesomeIcon icon={faCaretDown} size="3x" className="sm:hidden"/>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * @method renderIndexSelector
     * @return {*}
     */
    renderIndexSelector() {
        const { currentIndex } = this.state;

        return (
            <div className="p-4 text-center bg-gray-400 flex flex-col justify-center items-center gap-4">
                <p
                    className="font-bold cursor-pointer"
                    onClick={this.addIndex}
                >
                    Add dot
                </p>

                {currentIndex !== null && (
                    <p
                        className="font-bold cursor-pointer"
                        onClick={this.removeIndex}
                    >
                        Remove dot
                    </p>
                )}
            </div>
        );
    }

    /**
     * @method renderPointSizeControl
     * @return {*}
     */
    renderPointSizeControl() {
        return (
            <div className="p-4 bg-gray-400 flex flex-col justify-center items-center gap-4">
                <p className="font-bold text-center">
                    Point Size
                </p>

                <div className="flex justify-center items-center gap-4">
                    <FontAwesomeIcon
                        icon={faMinus}
                        size="1x"
                        className="cursor-pointer"
                        onClick={() => this.handleSizeChange(false)}
                    />

                    <FontAwesomeIcon
                        icon={faPlus}
                        size="1x"
                        className="cursor-pointer"
                        onClick={() => this.handleSizeChange(true)}
                    />
                </div>
            </div>
        );
    }
}

import React from "react";

import { Date, Rating, Text, Textarea, YesNo } from "./Fields";

const Question = ({ question, form, handleInput }) => {
    const fieldProps = {
        value: form[question?.id] ?? '',
        onChange: (value) => handleInput(question?.id, value),
        name: question?.id,
    };

    // Check if the page has a rating answer and render the rating key

    return (
        <div>
            <div className="flex justify-between items-center w-full">
                <div className="flex flex-col w-full">
                    { (question?.type === 'paragraph' || question?.type === 'heading') ? (
                        <>
                            { question?.type === 'paragraph' && (
                                <p className="text-sm text-gray-500">
                                    { question?.text }
                                </p>
                            )}

                            { question?.type === 'heading' && (
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    { question?.text }
                                </h3>
                            )}
                        </>
                    ) : (
                        <>
                            <label className="text-sm font-medium leading-6 text-gray-900">
                                { question?.text }
                            </label>

                            {question?.type === 'date' && (
                                <Date { ...fieldProps } />
                            )}

                            {question?.type === 'text' && (
                                <Text
                                    placeholder="Your answer"
                                    { ...fieldProps }
                                />
                            )}

                            {question?.type === 'textarea' && (
                                <Textarea
                                    placeholder="Your answer"
                                    rows={5}
                                    { ...fieldProps }
                                />
                            )}

                            {question?.type === 'yes-no' && (
                                <YesNo
                                    name={question?.id}
                                    placeholder="Your answer"
                                    rows={5}
                                    { ...fieldProps }
                                />
                            )}

                            {question?.type === 'rating' && (
                                <Rating
                                    name={question?.id}
                                    { ...fieldProps }
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Question;

import React from 'react';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {WYSIWYG, RenderWYSIWYG} from 'Components/Form';

export default class WYSIWYGExample extends React.Component {
    /**
     * @var state
     */
    state = {
        value: 'Type here...',
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {value} = this.state;

        return (
            <AdminLayout title="WYSIWYG Example Builder">
                <div className="px-6">
                    <div className="bg-gray-300 p-4 rounded">
                        <WYSIWYG
                            value={value}
                            onChange={(v) => this.setState({value: v})}
                        />
                    </div>

                    <div className="bg-gray-300 p-4 rounded my-8">
                        <h2 className="font-bold mb-2">
                            HTML Output
                        </h2>

                        {value}
                    </div>

                    <div className="bg-gray-300 p-4 rounded mb-6">
                        <h2 className="font-bold mb-2">
                            Rendered Output
                        </h2>

                        <RenderWYSIWYG
                            content={value}
                        />
                    </div>
                </div>
            </AdminLayout>
        )
    }
}
import React from 'react';

import {PrimaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Link} from 'Components/Utilities';

export default class AuthLayout extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {
            title, 
            children, 
            backLink = null, 
            backLinkText = null, 
            submitText, 
            onSubmit, 
            working, 
            alert
        } = this.props;

        return (
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto">
                <h1 className="text-center text-4xl font-bold text-light-blue-700 mt-8 lg:mt-20 mb-12">
                    {title}
                </h1>

                <form autoComplete="off" onSubmit={onSubmit}>
                    {alert !== null && (<Alert {...alert} />)}

                    {children}

                    <div className="text-center my-12">
                        <PrimaryButton
                            text={submitText}
                            working={working}
                        />
                    </div>
                </form>

                {backLink &&
                    <div className="text-center my-12">
                        <Link to={backLink}>
                            {backLinkText}
                        </Link>
                    </div>
                }
            </div>
        );
    }
}
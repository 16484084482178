import React from 'react';
import { Link, withRouter } from "react-router-dom";

import AdminLayout from 'Pages/Admin/AdminLayout';

import { Alert, Loading } from "Components/Partials";
import { Input, FormHandler } from "Components/Form";
import { PrimaryButton, SecondaryButton } from "Components/Button";

import ImportSurvey from "./ImportSurvey";

import AdminSurveyApi from "Services/Api/Admin/Survey";

class SurveysCreate extends React.Component {
    /**
     * @var state
     * @type {{loading: boolean}}
     */
    state = {
        loading: false,
    };

    /**
     * @var id
     * @type {*}
     */
    id = this.props.match.params.survey ?? null;

    /**
     * @var successMessage
     * @type {string}
     */
    successMessage = 'Survey updated successfully.';

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        if (this.id) {
            this.fetchSurvey();
        }
    };

    /**
     * @method fetchSurvey
     * @return {Promise<void>}
     */
    fetchSurvey = async () => {
        this.setState({ loading: true });

        const { data, success } = await AdminSurveyApi.get({
            survey: this.id,
        });

        if (success) {
            await this.props.setForm(data.data);
        }

        this.setState({ loading: false });
    };

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => this.id ? AdminSurveyApi.patch({ survey: this.id }, form) : AdminSurveyApi.post(null, form);

    /**
     * @method handleSuccess
     * @param {object} data
     */
    handleSuccess = ({ data }) => {
        this.props.history.push(`/admin/surveys/${data.data.id}`);
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { loading } = this.state;
        const { form, alert, working, handleInput, handleSubmit, getFieldError } = this.props;

        return (
            <AdminLayout title={`${this.id ? 'Update' : 'Create'} Survey`}>
                {loading && (<Loading />)}

                {!loading && (
                    <div>
                        {this.id && (
                            <div className="flex justify-end mx-6">
                                <a href={form.url} target="_blank">
                                    <PrimaryButton text="View" />
                                </a>
                            </div>
                        )}

                        <form
                            className="divide-y divide-gray-200"
                            onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.id ? this.successMessage : this.handleSuccess)}
                        >
                            <div className="mx-6">
                                {alert !== null && (<Alert {...alert} />)}

                                <div className="my-4 p-4 bg-gray-300 rounded">
                                    <Input
                                        label="Name"
                                        value={form.name}
                                        id="name"
                                        error={getFieldError('name')}
                                        onChange={(v) => handleInput('name', v)}
                                    />
                                </div>
                            </div>

                            <div className="p-6 flex justify-end gap-4">
                                <Link to="/admin/surveys">
                                    <SecondaryButton type="button" text="Back" />
                                </Link>

                                <PrimaryButton
                                    text="Save"
                                    working={working}
                                />
                            </div>
                        </form>

                        {this.id && (
                            <ImportSurvey survey={this.id} />
                        )}
                    </div>
                )}
            </AdminLayout>
        );
    }
}

export default FormHandler(withRouter(SurveysCreate));

import React from 'react';

import AdminLayout from 'Pages/Admin/AdminLayout';

import { IndexTable } from "Components/Utilities";

import AdminSurveyApi from "Services/Api/Admin/Survey";

export default class SurveysIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Name',
            value: 'name',
            type: 'string',
            orderable: 'name'
        },
        {
            label: 'URL',
            value: 'url',
            type: 'string',
            orderable: false,
        }
    ];

    /**
     * @method getRowActions
     * @param {object} item
     * @returns {[{layout: string, to: `"admin/surveys/${string}`, type: string, title: string}]}
     */
    getRowActions = (item) => [
        {
            type: "link",
            to: `/admin/surveys/${item.id}`,
            text: "Edit",
            className: "bg-app-backup border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-robot text-white",
        },
        {
            type: "link",
            to: `/admin/surveys/${item.id}/responses`,
            text: "Responses",
            className: "bg-app-backup border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-robot text-white",
        },
    ];

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Surveys">
                <IndexTable
                    columns={this.columns}
                    displaySearchBar={false}
                    storeLink="/admin/surveys/create"
                    loadDataCallback={(data) => AdminSurveyApi.get(null, data)}
                    rowActionsLayout="row"
                    rowActions={this.getRowActions}
                    eventApi={AdminSurveyApi}
                />
            </AdminLayout>
        );
    }
}

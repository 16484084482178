import ApiBase from '../ApiBase';

class Reports extends ApiBase {
    route = '/admin/reports';

    /**
     * @method getAvailableReports
     * @return {Promise<*>}
     */
    getAvailableReports = () => {
        return this.get('/admin/reports/available-reports');
    }

    /**
     * @method storeReport
     * @param {string} type
     * @param {object} params
     * @return {Promise<*>}
     */
    storeReport = (type, params) => {
        let new_params = {};

        // Convert `username[isEmpty]` to `username` and `isEmpty`.
        if (params) {
            Object.entries(params).map((param) => {
                let key = param[0];
                let value = param[1];

                if (key.includes('[')) {
                    let spliter = key.split('[');

                    key = spliter[0];

                    let key2 = spliter[1].split(']')[0];

                    value = {
                        [key2]: value
                    };
                }

                new_params[key] = value;
            });
        }

        return this.post('/admin/reports', {
            type,
            params: new_params
        });
    }
}

export default new Reports;
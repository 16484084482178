import React from "react";

import SettingsPage from "Pages/Settings/SettingsPage";

import { Loading } from "Components/Partials";

import AnnualLeave from "./AnnualLeave";

import HolidaysApi from "Services/Api/HumanResources/Holiday";

class Holidays extends React.Component {
    /**
     * @var state
     * @type {{holidayStats: null, working: boolean}}
     */
    state = {
        working: true,
        holidayStats: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.fetchHolidays();
    };

    /**
     * @method fetchHolidays
     * @returns {Promise<void>}
     */
    fetchHolidays = async () => {
        this.setState({ working: true });

        const request = await HolidaysApi.get(null);

        if (request.success) {
            return this.setState({
                working: false,
                holidayStats: request.data.data,
            });
        }
    };

    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        const { working, holidayStats } = this.state;

        return (
            <SettingsPage>
                <div className="p-6 border-b border-gray-20">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Annual Leave
                    </h2>
                </div>

                {working && (
                    <Loading />
                )}

                {!working && (
                    <>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="bg-gray-200 flex flex-col justify-between items-center text-center px-4 py-8">
                                <span className="mb-4 text-xl">Yearly allowance</span><br />
                                <span className="text-3xl text-light">{holidayStats?.allowance ?? 0}</span>
                            </div>
                            <div className="bg-gray-200 flex flex-col justify-between items-center text-center px-4 py-8">
                                <span className="mb-4 text-xl">Days used</span><br />
                                <span className="text-3xl text-light">{holidayStats?.taken ?? 0}</span>
                            </div>
                            <div className="bg-gray-200 flex flex-col justify-between items-center text-center px-4 py-8">
                                <span className="mb-4 text-xl">Days remaining</span><br />
                                <span className="text-3xl text-light">{holidayStats?.remaining ?? 0}</span>
                            </div>
                            <div className="bg-gray-200 flex flex-col justify-between items-center text-center px-4 py-8">
                                <span className="mb-4 text-xl">Running no of hours</span><br />
                                <span className="text-3xl text-light">{holidayStats?.hours_per_day ?? 0}</span>
                            </div>
                        </div>

                        <div className="mt-10">
                            <AnnualLeave />
                        </div>
                    </>
                )}
            </SettingsPage>
        );
    }
}

export default Holidays;

import React from 'react';
import {NavLink, Link, withRouter} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faSignOutAlt, faSignInAlt, faUsersCog, faHome, faUser} from '@fortawesome/free-solid-svg-icons';

import User from 'Services/User';
import {asset, filterItemsByAccess} from 'Services/BaseHelpers';

class Header extends React.Component {
    /**
     * @var navItems
     * @type {string}
     */
    navItems = [
        {
            to: "/",
            icon: faHome,
            title: "Home",
        },
        {
            to: "/settings/profile",
            icon: faUser,
            title: "My Profile",
            feature: "allow_profile_change"
        },
        {
            to: "/admin",
            icon: faUsersCog,
            title: "Admin",
            is_admin: true
        },
    ];

    /**
     * @var state
     * @type {{showOnMobile: boolean}}
     */
    state = {
        showOnMobile: false,
    }

    /**
     * @method render
     * @returns {JSX.Element}
     */
    render() {
        const {showOnMobile} = this.state;

        return (
            <div className="bg-light-blue-900 text-white px-8 py-6 select-none flex items-center flex-wrap lg:flex-nowrap mb-4">
                <Link to="/">
                    <img
                        src="https://tailwindui.com/img/logos/workflow-mark-teal-400.svg"
                        alt="Logo"
                        className="block w-36 align-self-center"
                    />
                </Link>

                {User.loggedIn &&
                    <>
                        <FontAwesomeIcon 
                            icon={faBars} 
                            size="2x"
                            onClick={() => this.setState({showOnMobile: !showOnMobile})}
                            className="block lg:hidden cursor-pointer ml-auto mr-2"
                        />

                        <div className={`
                            ${showOnMobile ? 'flex' : 'hidden lg:flex'} 
                            flex-col lg:items-end p-2 lg:p-0 w-full
                        `}>
                            <div className="flex flex-col lg:flex-row justify-center items-center gap-4 lg:gap-8 text-center">
                                {filterItemsByAccess(this.navItems).map((navItem, i) => 
                                    this.renderNavItem(navItem, i))
                                }
                                
                                <Link to="/logout">
                                    <FontAwesomeIcon 
                                        icon={faSignOutAlt} 
                                        size="1x"
                                    />
                                </Link>
                            </div>
                        </div>
                    </>
                }

                {!User.loggedIn &&
                    <div className="ml-auto mr-2">
                        <Link to="/login">
                            <FontAwesomeIcon 
                                icon={faSignInAlt} 
                                size="1x"
                            />
                        </Link>
                    </div>
                }
            </div>
        );
    }

    /**
     * @method renderNavItem
     * @param {object} navItem
     * @param {integer} key
     * @returns {JSX.Element}
     */
    renderNavItem = (navItem, key) => {
        return (
            <div key={key}>
                <Link to={navItem.to} className="flex flex-row justify-center items-center gap-2 font-bold">
                    {navItem.icon &&
                        <FontAwesomeIcon 
                            icon={navItem.icon} 
                            size="1x"
                        />
                    }

                    {navItem.title}
                </Link>
            </div>
        );
    };
}

export default withRouter(Header);
import React from 'react';

import {ModalTrigger} from 'Components/Modal';
import AdminRunReportModal from 'Components/Partials/Modals/AdminRunReportModal';
import {PrimaryButton} from 'Components/Button';
import {Select} from 'Components/Form';
import {IndexTable} from 'Components/Utilities';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {Modal} from 'Services';
import AdminReportsApi from 'Services/Api/Admin/Reports';

export default class ReportsIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Type',
            value: (record) => record.type.replace(/([A-Z])/g, ' $1').trim(),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Params',
            value: (record) => record.readable_params && Object.entries(record.readable_params).map((object, key) => (
                <p key={key}>
                    {object[0]}: {object[1]}
                </p>
            )),
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Status',
            value: (record) => record.completed ? 'Completed' : 'Pending',
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Ran On',
            value: 'created_at',
            type: 'datetime',
            orderable: null,
        },
        {
            label: 'Download',
            value: (record) => record.completed ? (
                <PrimaryButton
                    onClick={() => this.downloadReport(record)}
                    text="Download"
                />
            ) : 'pending ...',
            type: 'callback',
            orderable: null,
        },
    ];

    /**
     * @var state
     */
    state = {
        availableReports: null,
        type: null,
        key: 0
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.getAvailableReports();
        
        this.loadModal();
    }

    /**
     * @method getAvailableReports
     */
    getAvailableReports = async () => {
        const response = await AdminReportsApi.getAvailableReports();

        this.setState({
            availableReports: response.data.map((report) => (
                {
                    value: report,
                    label: report.replace(/([A-Z])/g, ' $1').trim()
                }
            )),
        });
    }

    /**
     * @method loadModal
     */
    loadModal = () => {
        if (this.props.location.search) {            
            let report = new URLSearchParams(this.props.location.search).get("report");

            if (report) {
                Modal.open({
                    component: AdminRunReportModal,
                    props: {
                        type: report,
                        callbackOnSubmit: this.handleRefresh,
                        cms_element_identifier: new URLSearchParams(this.props.location.search).get("cms_element_identifier") ?? null
                    }
                });
            }
        }
    }

    /**
     * @method downloadReport
     * @param {string} report
     */
    downloadReport = async (report) => {
        AdminReportsApi.downloadFile(
            `/admin/reports/${report.id}`,
            {},
            report.file_name
        );
    }

    /**
     * @method handleRefresh
     */
    handleRefresh = () => {
        const {key} = this.state;

        this.setState({
            key: key + 1
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() { 
        const {availableReports, type, key} = this.state;

        return (
            <AdminLayout title="Reports">
                <div className="flex flex-row justify-center gap-2 bg-gray-200 p-4 w-max mx-auto">
                    <Select
                        containerClassName="w-72"
                        placeholder="Select A Report To Run..."
                        value={type}
                        onChange={(v) => this.setState({ 
                            type: v
                        })}
                        options={availableReports}
                    />

                    <ModalTrigger
                        disabled={type === null || type === ""}
                        component={AdminRunReportModal} 
                        props={{
                            type,
                            callbackOnSubmit: this.handleRefresh,
                        }}
                    >
                        <PrimaryButton
                            text="Run Report"
                        />
                    </ModalTrigger>
                </div>

                <p 
                    className="font-semibold cursor-pointer mt-8 text-center"
                    onClick={this.handleRefresh}
                >
                    Click here to refresh the table
                </p>

                <IndexTable
                    columns={this.columns}
                    displaySearchBar={false}
                    loadDataCallback={(data) => AdminReportsApi.get(null, data)}
                    eventApi={AdminReportsApi}
                    key={key}
                />
            </AdminLayout>
        )
    }
}
import React from 'react';
import { Link, withRouter } from "react-router-dom";

import {IndexTable} from 'Components/Utilities';
import {BooleanFilter, DateTimeFilter} from 'Components/Filters';

import AdminLayout from 'Pages/Admin/AdminLayout';

import AdminPushNotificationLogsApi from 'Services/Api/Admin/PushNotificationLogs';
import { PrimaryButton } from "Components/Button";

class PushNotificationLogs extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Type',
            value: 'type',
            type: 'string',
            orderable: 'type'
        },
        {
            label: 'User',
            value: (record) => `${record.user.first_name} ${record.user.last_name}`,
            type: 'callback',
            orderable: null,
        },
        {
            label: 'Created At',
            value: 'created_at',
            type: 'datetime',
            orderable: 'created_at',
        }
    ];

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Logs">
                <div className="flex justify-end">
                    <Link to="/admin/push-notifications">
                        <PrimaryButton
                            text="Back"
                        />
                    </Link>
                </div>

                <IndexTable
                    columns={this.columns}
                    displaySearchBar={false}
                    loadDataCallback={(data) => AdminPushNotificationLogsApi.get({
                        push_notification: this.props.match.params.pushNotification,
                    }, data)}
                />
            </AdminLayout>
        )
    }
}

export default withRouter(PushNotificationLogs);

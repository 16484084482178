import React from 'react';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';

import Authenticated from "Components/Layouts/Authenticated";
import {Networking} from 'Components/Networking';

export default class NetworkingIndex extends React.Component {
    /**
     * @method render
     * @return {*}
     */
    render() {
        return (
            <Authenticated title="Messages" titleIcon={faComments}>
                <div className="min-h-screen text-black">
                    <Networking
                        displayUsers={true}
                        displayChats={true}
                        displayChat={true}
                        fullScreen={true}
                        useGroups={false}
                        selectedMessageToType='App\Models\User'
                        selectedMessageToId={this.props.match?.params?.user}
                    />
                </div>
            </Authenticated>
        )
    }
}

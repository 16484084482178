import React from 'react';

export default ({ children, containerClassName }) => (
    <div className="w-screen min-h-screen flex flex-col bg-app-supporting-grey">
        <div className="md:h-3/5vh flex items-center p-8 md:p-24 bg-app-leading w-full md:bg-unauthenticated md:bg-cover md:bg-no-repeat">
            <h1 className="font-league-spartan text-4xl md:text-8xl text-white">
                Strive
            </h1>
        </div>

        <div className={`flex-1 ${containerClassName}`}>
            {children}
        </div>
    </div>
);

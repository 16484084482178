import React from 'react';
import {withRouter} from 'react-router-dom';

import Unauthenticated from 'Components/Layouts/Unauthenticated';
import AuthLayout from 'Components/Layouts/AuthLayout';
import {FormHandler, Input} from 'Components/Form';

import Auth from 'Services/Api/Auth/Auth';

class Reset extends React.Component {
    /**
     * @var state
     * @type {{reset: boolean}}
     */
    state = {
        reset: true,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        let reset = true;

        if (this.props.location.search) {
            let holder = new URLSearchParams(this.props.location.search).get("reset");

            if (holder === "false") {
                reset = false;
            }
        }

        this.setState({reset});
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        return Auth.resetPassword(
            this.props.match.params.token,
            form
        );
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {reset} = this.state;
        const {form, handleInput, getFieldError, handleSubmit, working, alert} = this.props;

        return (
            <Unauthenticated containerClassName="space-y-8 md:space-y-0 flex flex-col md:flex-row h-full md:justify-between p-8 md:px-24">
                <AuthLayout
                    title={(reset ? 'Reset' : 'Set') + ' your password'}
                    backLink="/login"
                    backLinkText={(reset ? 'Back' : 'Go') + ' to login'}
                    submitText={reset ? 'Reset' : 'Set'}
                    onSubmit={(e) => handleSubmit(
                        e,
                        this.handleSubmit,
                        ('Your password has been ' + (reset ? 'reset' : 'set, click the "go to login" link below to access the site')),
                        true
                    )}
                    working={working}
                    alert={alert}
                    key={reset}
                >
                    <Input
                        containerClassName="mb-4"
                        label="Email"
                        type="email"
                        value={form.email}
                        error={getFieldError('email')}
                        onChange={v => handleInput('email', v)}
                    />

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Input
                                label="Password"
                                type="password"
                                value={form.password}
                                error={getFieldError('password')}
                                onChange={v => handleInput('password', v)}
                            />
                        </div>

                        <div>
                            <Input
                                label="Password Confirmation"
                                type="password"
                                value={form.password_confirmation}
                                error={getFieldError('password_confirmation')}
                                onChange={v => handleInput('password_confirmation', v)}
                            />
                        </div>
                    </div>
                </AuthLayout>
            </Unauthenticated>
        );
    }
}

export default FormHandler(withRouter(Reset));

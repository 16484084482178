const getAvatarUrl = (data) => {
    if (!data.avatar_path) {
        return getFallbackAvatarUrl(data);
    }

    return data.avatar_path.url;
}

const getFallbackAvatarUrl = (data) => {
    return `https://ui-avatars.com/api/?name=${data.full_name}&color=7F9CF5&background=EBF4FF`;
}

export {
    getAvatarUrl,
    getFallbackAvatarUrl
}

import React from 'react';

import {SecondaryButton} from 'Components/Button';
import {Alert} from 'Components/Partials';
import {Link} from 'Components/Utilities';

export default class AuthLayout extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {
            title, 
            children, 
            backLink = null, 
            backLinkText = null, 
            submitText, 
            onSubmit, 
            working, 
            alert
        } = this.props;

        return (
            <>
                <div className="w-full md:w-1/2 lg:w-1/3 flex items-center">
                    <h2 className="font-roboto font-light text-3xl text-app-backup">Welcome back</h2>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 flex items-center">
                    <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        className="w-full space-y-4"
                    >
                        {alert !== null && (<Alert {...alert} />)}

                        {children}

                        <div className="flex justify-end">
                            {backLink &&
                                <div className="flex-1 flex items-center">
                                    <Link to={backLink}>
                                        {backLinkText}
                                    </Link>
                                </div>
                            }

                            <div>
                                <SecondaryButton
                                    text={submitText}
                                    working={working}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}
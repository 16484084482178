import chroma from 'chroma-js';


/**
 * @method getStyles_default
 * @return {object}
 */
const getStyles_default = () => {
    return {
        control: (styles, state) => ({
            ...styles,
            borderRadius: '9999px',
            backgroundColor: 'rgba(69, 70, 153, 0.1)',
            borderColor: state.isFocus ? 'rgba(69, 70, 153, 0.1)' : 'rgba(69, 70, 153, 0.1)',
            outline: 'none',
        }),
    };
};

/**
 * Use a different colour for each option.
 * Source: https://react-select.com/styles#select-props
 *
 * @method getStyles_colouredOptions
 * @return {object}
 */
const getStyles_colouredOptions = () => {
    return {
        control: styles => ({
            ...styles,
            backgroundColor: 'white'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            let colors = getStyles_colouredOptions_colors(data.color);

            return {
                ...styles,
                backgroundColor: isDisabled ?
                    null :
                    isSelected ?
                        colors.backgroundColor :
                        isFocused ?
                            colors.focusedBackgroundColor :
                            colors.backgroundColor,
                color: isDisabled ? '#ccc' : colors.textColor,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (colors.backgroundColor),
                },
            };
        },
        multiValue: (styles, { data }) => {
            let colors = getStyles_colouredOptions_colors(data.color);

            return {
                ...styles,
                backgroundColor: colors.backgroundColor,
            };
        },
        multiValueLabel: (styles, { data }) => {
            let colors = getStyles_colouredOptions_colors(data.color);

            return {
                ...styles,
                color: colors.textColor,
            };
        },
        multiValueRemove: (styles, { data }) => {
            let colors = getStyles_colouredOptions_colors(data.color);

            return {
                ...styles,
                color: colors.textColor,
                ':hover': {
                    backgroundColor: colors.backgroundColor,
                    color: colors.textColor,
                },
            };
        },
    };
}

/**
 * @method getStyles_colouredOptions_colors
 * @param {string} color
 * @return {object}
 */
const getStyles_colouredOptions_colors = (color) => {
    if (!color) {
        color = '#3685D4';
    }

    return {
        backgroundColor: color,
        focusedBackgroundColor: chroma(color).alpha(0.7).css(),
        textColor: chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
    };
}

export {
    getStyles_default,
    getStyles_colouredOptions
}

import AdminIndex from 'Pages/Admin';

import ReportsIndex from 'Pages/Admin/Reports';
import ImportsIndex from 'Pages/Admin/Imports';
import StatsIndex from 'Pages/Admin/Stats';

import UsersIndex from 'Pages/Admin/Users';
import UsersShow from 'Pages/Admin/Users/show';
import UsersStore from 'Pages/Admin/Users/store';
import UsersUpdatePassword from 'Pages/Admin/Users/UpdatePassword';

import GroupsIndex from 'Pages/Admin/Groups';
import GroupsStore from 'Pages/Admin/Groups/store';

import TranslationsIndex from 'Pages/Admin/Translations';
import TranslationsShow from 'Pages/Admin/Translations/show';
import TranslationsWYSIWYGExample from 'Pages/Admin/Translations/WYSIWYGExample';

import PushNotificationsIndex from 'Pages/Admin/PushNotifications';
import PushNotificationsStore from 'Pages/Admin/PushNotifications/store';
import PushNotificationLogs from 'Pages/Admin/PushNotifications/Logs';

import TestsIndex from 'Pages/Admin/Tests/index';

import SiteConfigIndex from 'Pages/Admin/SiteConfig';
import SiteConfigStore from 'Pages/Admin/SiteConfig/store';

import AdminSurveyIndex from 'Pages/Admin/Surveys';
import AdminSurveyCreate from 'Pages/Admin/Surveys/Create';
import AdminSurveyResponses from 'Pages/Admin/Surveys/Responses';
import AdminSurveyResponsesView from 'Pages/Admin/Surveys/ResponsesView';

import Archive from 'Pages/Admin/Archive';

export default [
    { path: '/admin', component: AdminIndex, guards: ['admin'] },

    { path: '/admin/reports', component: ReportsIndex, guards: ['admin'] },
    { path: '/admin/imports', component: ImportsIndex, guards: ['admin'] },
    { path: '/admin/stats', component: StatsIndex, guards: ['admin'] },

    { path: '/admin/users', component: UsersIndex, guards: ['admin'] },
    { path: '/admin/users/show/:user', component: UsersShow, guards: ['admin'] },
    { path: '/admin/users/store/:user?', component: UsersStore, guards: ['admin'] },
    { path: '/admin/users/update-password/:user?', component: UsersUpdatePassword, guards: ['admin'] },

    { path: '/admin/groups', component: GroupsIndex, guards: ['admin'] },
    { path: '/admin/groups/store/:group?', component: GroupsStore, guards: ['admin'] },

    { path: '/admin/translations', component: TranslationsIndex, guards: ['admin'] },
    { path: '/admin/translations/show/:group/:key', component: TranslationsShow, guards: ['admin'] },
    { path: '/admin/translations/wysiwyg-example', component: TranslationsWYSIWYGExample, guards: ['admin'] },

    { path: '/admin/push-notifications', component: PushNotificationsIndex, guards: ['admin'] },
    { path: '/admin/push-notifications/store/:pushNotification?', component: PushNotificationsStore, guards: ['admin'] },
    { path: '/admin/push-notifications/:pushNotification/logs', component: PushNotificationLogs, guards: ['admin'] },

    { path: '/admin/tests', component: TestsIndex, guards: ['admin'] },

    { path: '/admin/site-config', component: SiteConfigIndex, guards: ['admin'] },
    { path: '/admin/site-config/store/:siteConfig?', component: SiteConfigStore, guards: ['admin'] },

    { path: '/admin/surveys', component: AdminSurveyIndex, guards: ['admin'] },
    { path: '/admin/surveys/create', component: AdminSurveyCreate, guards: ['admin'] },
    { path: '/admin/surveys/:survey', component: AdminSurveyCreate, guards: ['admin'] },
    { path: '/admin/surveys/:survey/responses', component: AdminSurveyResponses, guards: ['admin'] },
    { path: '/admin/surveys/:survey/responses/:response', component: AdminSurveyResponsesView, guards: ['admin'] },

    { path: '/admin/archive', component: Archive, guards: ['admin'] },
];

import React from 'react';

import {Alert} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import {FormHandler, Input, Textarea, DatePicker, Select} from 'Components/Form';

import NarrativeScheduleApi from 'Services/Api/ServiceUsers/NarrativeSchedule';
import AdminCmsElementApi from 'Services/Api/Admin/Cms/Elements/Element';
import {translationFromJson} from 'Services/TranslationHelpers2';

class Store extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = this.props.narrativeSchedule ? 'Successfully updated.' : 'Successfully added.';

    /**
     * @var times
     * @type {array}
     */
    times = [
        {
            label: 'Morning',
            value: 'morning'
        },
        {
            label: 'Afternoon',
            value: 'afternoon'
        },
        {
            label: 'Evening',
            value: 'evening'
        }
    ];

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.props.narrativeSchedule) {
            this.props.setForm(this.props.narrativeSchedule);
        }
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<void>}
     */
    handleSubmit = (form) => {
        const {service_user_id, narrativeSchedule} = this.props;

        form = {
            ...form,
            service_user_id
        };

        if (narrativeSchedule) {
            return NarrativeScheduleApi.patch(narrativeSchedule.id, form);
        } else {
            return NarrativeScheduleApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {narrativeSchedule, service_user_id, form, handleInput, handleSubmit, working, alert} = this.props;

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {narrativeSchedule ? 'Update' : 'Create'} Service User Narrative Schedule
                </ModalHeader>

                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, !narrativeSchedule)}>
                    <ModalBody>
                        {alert !== null && (<Alert {...alert} />)}

                        <Select
                            containerClassName="mb-4"
                            label="Narrative"
                            id="cms_element_id"
                            value={form.cms_element_id}
                            onChange={v => handleInput('cms_element_id', v)}
                            isAsync
                            searchCallback={(data) => AdminCmsElementApi.get(null, {
                                ...data,
                                attach_to_service_user: true,
                                paginate: true
                            })}
                            searchLabelKey={(record) => translationFromJson(record?.label)}
                            allowNull={false}
                        />

                        <Select
                            containerClassName="mb-4"
                            label="Time"
                            value={form.time}
                            onChange={(v) => handleInput('time', v)}
                            options={this.times}
                        />
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton 
                            text={narrativeSchedule ? 'Update' : 'Create'} 
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler(Store);

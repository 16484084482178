import React from 'react';
import {faBusinessTime} from '@fortawesome/free-solid-svg-icons/faBusinessTime';

import SettingsPage from 'Pages/Settings/SettingsPage';

import Table from 'Components/HumanResources/Timesheets/Table';

export default class TimeSheets extends React.Component {
    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <SettingsPage title="My HR Timesheets" titleIcon={faBusinessTime}>
                <Table
                    title='My HR Time Sheets'
                    admin={false}
                />
            </SettingsPage>
        );
    }
}

import React from 'react';
import {faIdCard} from '@fortawesome/free-solid-svg-icons/faIdCard';

import {HrDropdown} from 'Components/HumanResources/AdminComponents';
import {IndexTable} from 'Components/Utilities';

import AdminUsersApi from 'Services/Api/Admin/HumanResources/Users';

import AdminLayout from 'Pages/Admin/AdminLayout';

export default class UsersIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'First Name',
            value: 'first_name',
            type: 'string',
            orderable: 'first_name'
        },
        {
            label: 'Last Name',
            value: 'last_name',
            type: 'string',
            orderable: 'last_name'
        },
        {
            label: 'Email',
            value: 'email',
            type: 'string',
            orderable: 'email'
        },
        {
            label: '',
            value: (record) => <HrDropdown user={record} />,
            type: 'callback',
            orderable: null
        },
    ];

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <AdminLayout title="Staff HR" titleIcon={faIdCard}>
                <IndexTable
                    columns={this.columns}
                    defaultOrder={{
                        order_by: 'first_name',
                        order: 'asc'
                    }}
                    displaySearchBar={true}
                    loadDataCallback={(data) => AdminUsersApi.get(null, data)}
                    eventApi={AdminUsersApi}
                />
            </AdminLayout>
        )
    }
}

import React from 'react';

import AuthLayoutDefault from 'Components/Layouts/Default/AuthLayout';
import AuthLayoutTopMenu from 'Components/Layouts/TopMenu/AuthLayout';
import AuthLayoutCornerMenu from 'Components/Layouts/CornerMenu/AuthLayout';
import AuthLayoutSideMenu from 'Components/Layouts/SideMenu/AuthLayout';
import AuthLayoutTTS from 'Components/Layouts/TTS/AuthLayout';

export default class AuthLayout extends React.Component {
    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        switch(window.base.site_layout) {
            case 'top-menu':
                return (
                    <AuthLayoutTopMenu
                        {...this.props}
                    />
                );
            case 'corner-menu':
                return (
                    <AuthLayoutCornerMenu
                        {...this.props}
                    />
                );
            case 'side-menu':
                return (
                    <AuthLayoutSideMenu
                        {...this.props}
                    />
                );
            case 'tts':
                return (
                    <AuthLayoutTTS
                        {...this.props}
                    />
                );
            default:
                return (
                    <AuthLayoutDefault
                        {...this.props}
                    />
                );
        }
    };
}

import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';

export default [
    {
        icon: faPencilAlt,
        title: "CMS",
        sub: [
            {
                to: "/admin/cms/elements",
                icon: faPencilAlt,
                title: "CMS Elements",
            },
            {
                to: "/admin/cms/elements/records",
                icon: faPencilAlt,
                title: "CMS Records",
            },
        ],
    }
];